// @flow
import { useMemo, useState, useContext } from "react";
import {
  faBalanceScale,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css, ThemeContext } from "styled-components";
import { smallParagraph } from "@nested/brand";
import { getImage, type QueryRenderProps } from "@nested/utils";
import { VIEWING_FEEDBACK_QUERY } from "./ViewingFeedback";
import { PublicDealQuery } from "../../components/AuthenticatedQuery";
import {
  SectionWrapper,
  IconAndText,
  ExampleBox,
  SectionHeader,
  SectionCard,
  UnpaddedSectionCard,
  SectionHeaderSubtext,
  SectionCardPadding,
  ImageAndTextCardContent,
  FullWidthCardButton,
} from "../../components/SectionCard";
import { ViewingCardList } from "./ViewingCardList";
import { sortEnquiriesByViewingUpdateDate } from "./utils";
import { BuyerAvatarSmall } from "../../components/BuyerAvatar/BuyerAvatar";
import { ViewingFeedbackModal } from "./ViewingFeedbackModal";
import { firstImpressionsExampleEnquiry } from "./firstImpressionsExampleEnquiry";

const modalButton = css`
  background-color: white;
  border: none;
  display: block;
  padding: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
`;

const exampleBuyer = css`
  display: flex;
  align-items: center;
`;

const textContainer = css`
  margin-left: 15px;
  flex-grow: 1;
`;

const buyerName = css`
  ${smallParagraph}
  color: ${({ theme }) => theme.palette.blue100};
  font-weight: 500;
  margin: 0;
`;

const lastUpdated = css`
  ${buyerName}
  color: ${({ theme }) => theme.palette.hague70};
`;

const chevron = css`
  color: ${({ theme }) => theme.palette.hague20};
  font-size: 21px;
  line-height: 25px;
`;

const FirstImpressionsContent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <SectionWrapper data-test="nav-card:viewing-feedback">
      <SectionHeader css="padding: 0;">Viewing feedback</SectionHeader>
      <SectionHeaderSubtext> Available after listing</SectionHeaderSubtext>
      <UnpaddedSectionCard>
        <SectionCardPadding css="margin-top: 20px">
          <IconAndText
            icon={faBalanceScale}
            text="Find out what your potential buyers like and dislike about your home."
          />
          <button
            onClick={() => setModalOpen(true)}
            css={modalButton}
            data-test="first-impressions-example-box"
          >
            <ExampleBox css="padding: 15px 10px;">
              <div css={exampleBuyer}>
                <BuyerAvatarSmall avatarUrl="https://nested.imgix.net/account/buyer-avatars/avatars_9_B.png?bg=FFE7AA" />
                <div css={textContainer}>
                  <p css={buyerName}>Francis Flamingo</p>
                  <p css={lastUpdated}>Received yesterday at 13:00</p>
                </div>
                <FontAwesomeIcon icon={faChevronRight} css={chevron} />
              </div>
            </ExampleBox>
          </button>
          <ViewingFeedbackModal
            open={modalOpen}
            buyerEnquiry={firstImpressionsExampleEnquiry}
            onClose={() => setModalOpen(false)}
            next={null}
            previous={null}
            exampleMode
          />
        </SectionCardPadding>
      </UnpaddedSectionCard>
    </SectionWrapper>
  );
};

const ClosedContent = ({ theme }: { theme: any }) => {
  return (
    <SectionWrapper data-test="nav-card:viewing-feedback">
      <SectionHeader>Viewing feedback</SectionHeader>
      <SectionCard>
        <ImageAndTextCardContent
          largeText
          text="Feedback will appear here after viewings"
          imageSrc={getImage(
            "your-account/viewings-tab/pink-paper-and-pen.png",
          )}
          imageAlt="notepad"
          circleColor={theme.palette.pink40}
        />
      </SectionCard>
    </SectionWrapper>
  );
};

export const ViewingFeedbackCard = ({
  deal,
}: {
  deal: ?ListingSectionQuery_publicDeal,
}) => {
  const theme = useContext(ThemeContext);
  const isListed = Boolean(deal && deal.isListed);
  const isClosed = deal && deal.opportunityStatusValue === "s07_closed";

  if (isClosed) {
    return <ClosedContent theme={theme} />;
  }

  if (!isListed) {
    return <FirstImpressionsContent />;
  }

  return (
    <SectionWrapper data-test="nav-card:viewing-feedback">
      <SectionHeader>Viewing feedback</SectionHeader>
      <UnpaddedSectionCard>
        <PublicDealQuery
          errorComponentName="viewing feedback"
          query={VIEWING_FEEDBACK_QUERY}
        >
          {({ data }: QueryRenderProps<ViewingFeedbackDeal>) => {
            const {
              publicDeal: { buyerEnquiries },
            } = data;

            const hasCompletedViewings = buyerEnquiries.length > 0;

            if (hasCompletedViewings) {
              return <RecentViewingSummary buyerEnquiries={buyerEnquiries} />;
            }
            return (
              <>
                <SectionCardPadding>
                  <ImageAndTextCardContent
                    largeText
                    text="What potential buyers liked and disliked about your home"
                    imageSrc={getImage(
                      "your-account/viewings-tab/pink-paper-and-pen.png",
                    )}
                    imageAlt="notpad"
                    circleColor={theme.palette.pink40}
                  />
                </SectionCardPadding>

                <FullWidthCardButton
                  path="/listing/viewings/viewing-feedback"
                  buttonText="All feedback"
                />
              </>
            );
          }}
        </PublicDealQuery>
      </UnpaddedSectionCard>
    </SectionWrapper>
  );
};

const ViewingsWrapper = styled(SectionCardPadding)`
  & > div:first-child {
    margin-top: 0;
  }
`;

const RecentViewingSummary = ({ buyerEnquiries }) => {
  const totalBuyers = buyerEnquiries.length;

  const sortedEnquiries =
    useMemo(
      () => sortEnquiriesByViewingUpdateDate(buyerEnquiries),
      [buyerEnquiries],
    ) || [];

  return (
    <div>
      <ViewingsWrapper>
        <ViewingCardList enquiries={sortedEnquiries.slice(0, 3)} />
      </ViewingsWrapper>
      {totalBuyers > 3 && (
        <FullWidthCardButton
          path="/listing/viewings/viewing-feedback"
          buttonText={`See ${totalBuyers - 3} more`}
        />
      )}
    </div>
  );
};
