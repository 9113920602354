// @flow
import { gql, useApolloClient } from "@apollo/client";
import styled from "styled-components";
import { Timeline } from "@nested/brand/src/components/Timeline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { useAuth } from "@nested/auth";
import { DefaultWrapper } from "../PageWrapper";
import { PageHeader, StandardPageHeader } from "../../components/Heading";
import { AuthenticatedQuery } from "../../components/AuthenticatedQuery";
import { useDeal } from "../../hooks/useDeal";

// Temp query to just get boilerplate page in
const ACTIVITY = gql`
  query ActivityTimeline($externalDealId: ID) {
    saleActivityTimeline(externalDealId: $externalDealId) {
      isExample
      timeline {
        ... on SaleActivityCall {
          id
          nestedUser {
            id
            email
            firstName
          }
          note {
            id
            text
          }
          recipient
          timestamp
        }
        ... on SaleActivityEmailThread {
          id
          subject
          emails {
            id
            senderEmail
            senderName
            snippet
            sentAt
          }
        }
        ... on SaleActivityMilestone {
          id
          note {
            id
            text
            nestedUser {
              id
              email
              firstName
            }
          }
          timestamp
          title
        }
        ... on SaleActivityNote {
          id
          nestedUser {
            id
            email
            firstName
          }
          text
          timestamp
        }
        ... on SaleActivitySms {
          id
          nestedUser {
            id
            email
            firstName
          }
          recipient
          smsBody
          timestamp
        }
      }
    }
  }
`;

const GET_EMAIL = gql`
  query GetEmailBody($id: ID) {
    getSaleActivityEmail(id: $id) {
      id
      body
      recipients {
        id
        email
        name
        type
      }
    }
  }
`;

const ExampleContent = styled.div`
  box-shadow: 3px 3px 15px -2px #0a425426;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Subtext = styled.div`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  flex-grow: 1;
`;

const ExampleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ExampleHeaderComments = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: flex;
  background-color: white;
  color: ${({ theme }) => theme.palette.terracotta100};
  border-radius: 5px;
  align-items: center;
  justify-content: space-around;
`;

const TabletMobileHeader = styled(StandardPageHeader)`
  ${media.desktop`
    display: none;
  `}
`;

const ExampleTimeline = styled(Timeline)`
  margin-top: 32px;
  ::before {
    content: "Example";
    background-color: ${({ theme }) => theme.palette.terracotta150};
    color: ${({ theme }) => theme.palette.hague150};
    border-radius: 5px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: -11px;
    padding: 1px 7px;
  }
`;

const ExampleSection = ({ timeline, fetchEmail }) => {
  return (
    <>
      <TabletMobileHeader>Sale status</TabletMobileHeader>
      <Subtext>Available once under offer</Subtext>
      <ExampleContent>
        <ExampleHeader>
          <ExampleHeaderComments>
            <FontAwesomeIcon icon={faComments} />
          </ExampleHeaderComments>
          <Subtext>
            Get regular updates on the work we're doing to ensure your sale goes
            through.
          </Subtext>
        </ExampleHeader>
        <ExampleTimeline events={timeline} fetchEmail={fetchEmail} />
      </ExampleContent>
    </>
  );
};

export const SaleActivity = () => {
  const { dealId } = useDeal();
  const apolloClient = useApolloClient();
  const auth = useAuth();

  const fetchEmail = async (id) => {
    try {
      const result = await apolloClient.query({
        query: GET_EMAIL,
        variables: { id },
        context: { auth },
      });
      if (result?.error) {
        return null;
      }
      if (result?.data?.getSaleActivityEmail) {
        return result?.data?.getSaleActivityEmail;
      }
    } catch (e) {
      return null;
    }

    return null;
  };

  return (
    <DefaultWrapper>
      <PageHeader>Activity</PageHeader>
      <AuthenticatedQuery
        query={ACTIVITY}
        variables={{ externalDealId: dealId }}
      >
        {({ data }) => {
          if (
            !(data.saleActivityTimeline && data.saleActivityTimeline.timeline)
          )
            return null;

          const { isExample, timeline } = data.saleActivityTimeline;

          return isExample ? (
            <ExampleSection timeline={timeline} fetchEmail={fetchEmail} />
          ) : (
            <Timeline events={timeline} fetchEmail={fetchEmail} />
          );
        }}
      </AuthenticatedQuery>
    </DefaultWrapper>
  );
};
