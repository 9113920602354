export const listingPerformanceExampleData = {
  __typename: "PublicDeal",
  id: "1vro3rkg",
  currentListingPrice: "525000.00",
  hasPriceAdjustment: false,
  dateUnderOffer: null,
  property: {
    __typename: "PublicDealProperty",
    type: "flat",
    bedrooms: 2,
    postcode: "",
  },
  dailyPortalViews: [
    { __typename: "PublicDailyPortalViews", dayNumber: 1, views: 351 },
    { __typename: "PublicDailyPortalViews", dayNumber: 2, views: 239 },
    { __typename: "PublicDailyPortalViews", dayNumber: 3, views: 145 },
    { __typename: "PublicDailyPortalViews", dayNumber: 4, views: 104 },
    { __typename: "PublicDailyPortalViews", dayNumber: 5, views: 88 },
    { __typename: "PublicDailyPortalViews", dayNumber: 6, views: 87 },
    { __typename: "PublicDailyPortalViews", dayNumber: 7, views: 78 },
    { __typename: "PublicDailyPortalViews", dayNumber: 8, views: 75 },
    { __typename: "PublicDailyPortalViews", dayNumber: 9, views: 65 },
    { __typename: "PublicDailyPortalViews", dayNumber: 10, views: 54 },
  ],
  averageDailyPortalViews: [
    { __typename: "PublicDailyPortalViews", dayNumber: 1, views: 249 },
    { __typename: "PublicDailyPortalViews", dayNumber: 2, views: 157 },
    { __typename: "PublicDailyPortalViews", dayNumber: 3, views: 82 },
    { __typename: "PublicDailyPortalViews", dayNumber: 4, views: 72 },
    { __typename: "PublicDailyPortalViews", dayNumber: 5, views: 63 },
    { __typename: "PublicDailyPortalViews", dayNumber: 6, views: 57 },
    { __typename: "PublicDailyPortalViews", dayNumber: 7, views: 55 },
    { __typename: "PublicDailyPortalViews", dayNumber: 8, views: 45 },
    { __typename: "PublicDailyPortalViews", dayNumber: 9, views: 41 },
    { __typename: "PublicDailyPortalViews", dayNumber: 10, views: 41 },
    { __typename: "PublicDailyPortalViews", dayNumber: 11, views: 44 },
    { __typename: "PublicDailyPortalViews", dayNumber: 12, views: 43 },
    { __typename: "PublicDailyPortalViews", dayNumber: 13, views: 41 },
    { __typename: "PublicDailyPortalViews", dayNumber: 14, views: 40 },
    { __typename: "PublicDailyPortalViews", dayNumber: 15, views: 38 },
    { __typename: "PublicDailyPortalViews", dayNumber: 16, views: 38 },
    { __typename: "PublicDailyPortalViews", dayNumber: 17, views: 40 },
    { __typename: "PublicDailyPortalViews", dayNumber: 18, views: 41 },
    { __typename: "PublicDailyPortalViews", dayNumber: 19, views: 40 },
    { __typename: "PublicDailyPortalViews", dayNumber: 20, views: 39 },
    { __typename: "PublicDailyPortalViews", dayNumber: 21, views: 35 },
    { __typename: "PublicDailyPortalViews", dayNumber: 22, views: 35 },
    { __typename: "PublicDailyPortalViews", dayNumber: 23, views: 37 },
    { __typename: "PublicDailyPortalViews", dayNumber: 24, views: 43 },
    { __typename: "PublicDailyPortalViews", dayNumber: 25, views: 43 },
    { __typename: "PublicDailyPortalViews", dayNumber: 26, views: 45 },
    { __typename: "PublicDailyPortalViews", dayNumber: 27, views: 44 },
    { __typename: "PublicDailyPortalViews", dayNumber: 28, views: 38 },
  ],
  weeklyEnquiries: [
    { __typename: "PublicWeeklyEnquiries", weekNumber: 1, enquiries: 10 },
    { __typename: "PublicWeeklyEnquiries", weekNumber: 2, enquiries: 4 },
  ],
};
