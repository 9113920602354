// @flow
import { css } from "styled-components";

const daySummary = css`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.blue150 : "initial"};
  color: ${({ isSelected }) => (isSelected ? "white" : "inherit")};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.palette.blue150 : theme.palette.blue40};
  border-bottom: initial;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-of-type {
    border-bottom: 1px solid
      ${({ theme, isSelected }) =>
        isSelected ? theme.palette.blue150 : theme.palette.blue40};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const dayOfWeekStyles = css`
  color: ${({ theme, isSelected }) =>
    isSelected ? "white" : theme.palette.hague70};
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  width: 40px;
`;

const daytime = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
`;

type DaySummary = {
  id: ?number,
  dayOfWeek: ?string,
  availableTimes: string,
};

type Props = {
  formattedSummaryData: DaySummary[],
  daySelected: ?number,
  setDaySelected: (n: ?number) => void,
};

export const DayOfWeekPicker = ({
  formattedSummaryData,
  daySelected,
  setDaySelected,
}: Props) => {
  return (
    <div>
      {formattedSummaryData.map((day) => (
        <div
          css={daySummary}
          data-test="select-day-button"
          isSelected={daySelected === day.id}
          key={day.id}
          onClick={() => setDaySelected(day.id)}
          onKeyDown={() => setDaySelected(day.id)}
          role="button"
          tabIndex={0}
        >
          <div css={dayOfWeekStyles} isSelected={daySelected === day.id}>
            {day.dayOfWeek}
          </div>
          <div css={daytime}>{day.availableTimes}</div>
        </div>
      ))}
    </div>
  );
};
