import moment from "moment";

const createPastDate = (daysAgo, hour, minute) => {
  return moment()
    .subtract(daysAgo, "days")
    .set("hour", hour)
    .set("minute", minute)
    .format("YYYY-MM-DD");
};

export const enquiriesExampleData = [
  {
    id: "1",
    buyer: {
      id: "1",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_1_B.png?bg=B5DBF5",
      firstName: "Ben",
      surnameInitial: "R",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(2, 16, 11),
    datetimeEnquiryCreated: moment().subtract(4, "days").format("YYYY-MM-DD"),
  },
  {
    id: "2",
    buyer: {
      id: "2",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_2_B.png?bg=FFEEF1",
      firstName: "Ed",
      surnameInitial: "S",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Cash buyer",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(8, 14, 57),
    datetimeEnquiryCreated: moment().subtract(9, "days").format("YYYY-MM-DD"),
  },
  {
    id: "3",
    buyer: {
      id: "3",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_3_B.png?bg=E5D8CA",
      firstName: "Will",
      surnameInitial: "T",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(7, 9, 45),
    datetimeEnquiryCreated: moment().subtract(8, "days").format("YYYY-MM-DD"),
  },
  {
    id: "4",
    buyer: {
      id: "4",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_4_B.png?bg=FFE7AA",
      firstName: "Tom",
      surnameInitial: "B",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(6, 16, 37),
    datetimeEnquiryCreated: moment().subtract(8, "days").format("YYYY-MM-DD"),
  },
  {
    id: "5",
    buyer: {
      id: "5",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_5_B.png?bg=B5DBF5",
      firstName: "Emma",
      surnameInitial: "V",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Mortgage in principle obtained",
      buyerOffers: [],
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(6, 17, 11),
    datetimeEnquiryCreated: moment().subtract(7, "days").format("YYYY-MM-DD"),
  },
  {
    id: "6",
    buyer: {
      id: "6",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_6_B.png?bg=FFE7AA",
      firstName: "Dmitrijs",
      surnameInitial: "J",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: false,
    contactLastUpdatedAt: createPastDate(4, 10, 27),
    datetimeEnquiryCreated: moment().subtract(6, "days").format("YYYY-MM-DD"),
  },
  {
    id: "7",
    buyer: {
      id: "7",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_7_B.png?bg=E5D8CA",
      firstName: "Harry",
      surnameInitial: "M",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: false,
    contactLastUpdatedAt: createPastDate(4, 9, 15),
    datetimeEnquiryCreated: moment().subtract(6, "days").format("YYYY-MM-DD"),
  },
  {
    id: "8",
    buyer: {
      id: "8",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_8_B.png?bg=FFEEF1",
      firstName: "Anne-Laure",
      surnameInitial: "C",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(3, 9, 11),
    datetimeEnquiryCreated: moment().subtract(6, "days").format("YYYY-MM-DD"),
  },
  {
    id: "9",
    buyer: {
      id: "9",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_9_B.png?bg=FFE7AA",
      firstName: "Morgan",
      surnameInitial: "S",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: false,
    contactLastUpdatedAt: createPastDate(3, 11, 11),
    datetimeEnquiryCreated: moment().subtract(5, "days").format("YYYY-MM-DD"),
  },
  {
    id: "10",
    buyer: {
      id: "10",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_2_B.png?bg=FE5D8CA",
      firstName: "Jessie",
      surnameInitial: "B",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    currentlyInterested: true,
    falloutDetails: null,
    contactLastUpdatedAt: createPastDate(2, 13, 59),
    datetimeEnquiryCreated: moment().subtract(4, "days").format("YYYY-MM-DD"),
  },
  {
    id: "11",
    buyer: {
      id: "11",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_6_B.png?bg=FFEEF1",
      firstName: "Matthew",
      surnameInitial: "D",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    currentlyInterested: true,
    falloutDetails: null,
    contactLastUpdatedAt: createPastDate(2, 16, 40),
    datetimeEnquiryCreated: moment().subtract(4, "days").format("YYYY-MM-DD"),
  },
  {
    id: "12",
    buyer: {
      id: "12",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_1_B.png?bg=FFEEF1",
      firstName: "Josh",
      surnameInitial: "H",
      buyerPosition: "Property to sell - Not on the market",
      financialPosition: "No mortgage in principle yet",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "DID_NOT_BOOK_VIEWING",
    currentlyInterested: false,
    contactLastUpdatedAt: createPastDate(5, 16, 4),
    falloutDetails:
      "We contacted the buyer and determined that they are not proceedable at this time as he is not yet on the market. We will keep in touch and let you know if their situation changes.",
    datetimeEnquiryCreated: moment().subtract(8, "days").format("YYYY-MM-DD"),
  },
  {
    id: "13",
    buyer: {
      id: "13",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_3_B.png?bg=FFEEF1",
      firstName: "Reiss",
      surnameInitial: "B",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "No mortgage in principle yet",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "DID_NOT_BOOK_VIEWING",
    currentlyInterested: false,
    contactLastUpdatedAt: createPastDate(3, 9, 3),
    falloutDetails:
      "We contacted the buyer and determined that they couldn’t afford your property. Their budget is £450,000.",
    datetimeEnquiryCreated: moment().subtract(5, "days").format("YYYY-MM-DD"),
  },
  {
    id: "14",
    buyer: {
      id: "14",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_8_B.png?bg=FFEEF1",
      firstName: "Amir",
      surnameInitial: "G",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "No mortgage in principle yet",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "NEW_ENQUIRY",
    currentlyInterested: false,
    contactLastUpdatedAt: moment().subtract(1, "hours").toISOString(),
    falloutDetails: null,
    datetimeEnquiryCreated: moment().subtract(1, "days").format("YYYY-MM-DD"),
  },
];
