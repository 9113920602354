// @flow
import { useHistory } from "react-router";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { media, smallParagraph } from "@nested/brand";

const BackButtonContainer = styled.div`
  box-sizing: border-box;
  height: 50px;
  padding: 14px 15px;
  border-bottom: ${({ theme }) => theme.palette.hague10} solid 1px;
  ${media.tablet`
    border-bottom: none;
    height: 80px;
    padding: 30px 0;
  `}
`;

const BackButtonLink = styled.a`
  ${smallParagraph}
  font-weight: 500;
  margin: 0;
  span {
    margin-left: 5px;
  }
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const BackButton = () => {
  const history = useHistory();
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <BackButtonContainer>
      <BackButtonLink href="#" onClick={goBack}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span>Back</span>
      </BackButtonLink>
    </BackButtonContainer>
  );
};
