// @flow
import type { QueryRenderProps } from "@nested/utils";
import { ListingPerformance } from "./ListingPerformance";
import { EnquiriesCard } from "./EnquiriesCard";
import { DefaultWrapper } from "../PageWrapper";
import { DEAL_QUERY } from "../Listing/Listing";
import { PublicDealQuery } from "../../components/AuthenticatedQuery";
import { PageHeader } from "../../components/Heading";
import { PreCloseContactCta } from "../../components/PreCloseContactCta/PreCloseContactCta";

export const Interest = () => (
  <DefaultWrapper>
    <PageHeader>Interest</PageHeader>
    <PublicDealQuery query={DEAL_QUERY}>
      {({ data }: QueryRenderProps<ListingSectionQuery>) => (
        <>
          <ListingPerformance deal={data?.publicDeal} />
          <EnquiriesCard deal={data?.publicDeal} />
          <PreCloseContactCta deal={data?.publicDeal} />
        </>
      )}
    </PublicDealQuery>
  </DefaultWrapper>
);
