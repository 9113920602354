// @flow
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { css } from "styled-components";

import { Button } from "@nested/component-library";
import { Sentry } from "@nested/isomorphic-sentry";
import { useAuth } from "@nested/auth";
import { media } from "@nested/brand";
import { FormError } from "../../components/FormError";

import { Card } from "./Card";

type Props = {
  email: string,
};

const PasswordReset = ({ email }: Props) => {
  const { resetPassword } = useAuth();

  const handlePasswordReset = async () => {
    try {
      await resetPassword(email);
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return {
        [FORM_ERROR]:
          "Oh no, we can't seem to reset your password. Please try again later.",
      };
    }
  };

  return (
    <Form
      initialValues={{}}
      onSubmit={handlePasswordReset}
      render={({
        handleSubmit,
        hasSubmitErrors,
        submitSucceeded,
        submitError,
        submitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {hasSubmitErrors && (
            <FormError data-test="form-submit-error">{submitError}</FormError>
          )}
          {submitSucceeded ? (
            <Card>
              <h3 data-test="password-reset-form-success-message">
                We&apos;ve emailed you a link to reset your password!
              </h3>
              <p>
                Check your inbox and follow the steps in the email we sent to
                reset your Nested account password.
              </p>
            </Card>
          ) : (
            <Card>
              <h3 data-test="password-reset-form-success-message">
                Need to change your password?
              </h3>
              <p>
                To change your password we need to send you an email. Tap the
                button below and we will send you one right away.
              </p>
              <Button
                disabled={submitting}
                type="standard"
                css={css`
                  margin-top: 20px;

                  ${media.tablet`
                  margin-top: 30px;
                  `}
                `}
              >
                Reset my password
              </Button>
            </Card>
          )}
        </form>
      )}
    />
  );
};

export { PasswordReset };
