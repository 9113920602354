// @flow
import { useEffect, useRef } from "react";
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { css } from "styled-components";
import { SideBarMenu } from "./SideBarMenu";

const backgroundStyle = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background-color: ${({ open }) =>
    open ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0)"};
  z-index: 99;
  pointer-events: ${({ open }) => (open ? "unset" : "none")};
  transition: background-color 200ms ease-out;
`;

const closeButtonStyle = css`
  cursor: pointer;
  display: block;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 48px;
  right: 25px;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  border: none;
  z-index: 9999;

  div:first-child {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.sand500};
    transform: rotate(45deg);
  }
  div:last-child {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.sand500};
    transform: rotate(-45deg);
  }
`;

const menuStyle = css`
  position: absolute;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: ${({ open }) => (open ? "0px" : "-250px")};
  width: 300px;
  will-change: left;
  visibility: ${({ open }) => (open ? "visbile" : "hidden")};
  transition: left 200ms ease-out, visibility 200ms ease-out;
`;

type Props = {
  open: boolean,
  onClose: () => void,
  notifications: ?number,
};

export const MobileMenu = ({ open, onClose, notifications }: Props) => {
  const menuRef = useRef();

  useEffect(() => {
    if (open) {
      disableBodyScroll(menuRef.current);
    } else {
      enableBodyScroll(menuRef.current);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    // eslint-disable-next-line
    <div onClick={onClose} open={open} css={backgroundStyle}>
      {/* eslint-disable-next-line */}
      <div
        ref={menuRef}
        onClick={(e) => e.stopPropagation()}
        open={open}
        css={menuStyle}
      >
        <button type="button" onClick={onClose} css={closeButtonStyle}>
          <div />
          <div />
        </button>
        <SideBarMenu notifications={notifications} onItemClick={onClose} />
      </div>
    </div>
  );
};
