// @flow
import { useMemo } from "react";
import { css } from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { getImage } from "@nested/utils";
import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { OFFERS_QUERY } from "../../pages/Offers/Offers";
import {
  extractRenegotiatedOffers,
  getHeading,
  isActiveOffer,
} from "../../pages/Offers/utils";

const wrapperStyle = css`
  background-color: ${({ theme }) => theme.palette.pink40};
  margin: 0 15px 30px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  position: relative;
  min-height: 72px;
  ${media.tablet`
    margin: 30px;
  `}
`;

const headingStyle = css`
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
`;

const subheadingStyle = css`
  font-size: 14px;
  line-height: 21px;
`;

const partyImageStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90px;
  z-index: 0;
`;

const activeOffersImageStyle = css`
  width: 90px;
  background-image: url("${getImage("illustrations/offer-banner-shape.svg")}");
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: none;

  /* 360px */
  @media (min-width: 22.5em) {
    display: block;
  }
`;

const textWrapperStyle = css`
  padding: 15px;
  align-self: center;
  flex-grow: 1;
`;

const chevronWrapperStyle = css`
  align-self: center;
  padding-right: 15px;
  color: ${({ theme }) => theme.palette.pink500};
`;

const linkOverlayStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const OfferBanner = () => {
  const { data, errors, loading } = usePublicDealQuery(OFFERS_QUERY);
  const buyers = data?.publicDeal?.buyerEnquiries || [];

  const allOffers = useMemo(
    () => extractRenegotiatedOffers(buyers.flatMap((b) => b.buyerOffers)),
    [buyers],
  );

  if (errors || loading || allOffers.length === 0) {
    return null;
  }

  const activeOffers = allOffers.filter(isActiveOffer);

  const { heading, subheading } = getHeading(allOffers);

  return (
    <div css={wrapperStyle}>
      <Link css={linkOverlayStyle} to="/listing/offers" />
      {activeOffers.length > 0 && (
        <div css={activeOffersImageStyle}>
          <img
            css={partyImageStyle}
            alt=""
            src={getImage("illustrations/offer-banner-party.png")}
          />
        </div>
      )}
      <div css={textWrapperStyle}>
        {heading && <div css={headingStyle}>{heading}</div>}
        {subheading && <div css={subheadingStyle}>{subheading}</div>}
      </div>
      <div css={chevronWrapperStyle}>
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </div>
    </div>
  );
};
