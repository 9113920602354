// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";

export const makeSillhouette = (url: string) => {
  const plainUrl = url.split("?")[0];
  return `${plainUrl}?bri=-100`;
};

const greyOutAvatar = css`
  opacity: 0.3;
  background-color: #c2aa8f; /* extra dark brown so it works at low opacity */
`;

const imageContainer = css`
  display: inline-block;
  background: white;
  border-radius: 100%;
  position: relative;
`;

const image = css`
  border-radius: 100%;
  border: 3px solid white;
  ${({ anonymised }) => (anonymised ? greyOutAvatar : "")}
`;

const statusIcon = css`
  border-radius: 100%;
  border: 2px solid white;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  color: #ffffff;
  position: absolute;
`;

const tabletOnlyStyles = css`
  display: none;
  ${media.tablet`
    display: inline-block;
  `}
`;

const smallImageContainer = css`
  ${imageContainer}
  width: 56px;
  height: 56px;
`;

const mediumImageContainer = css`
  ${imageContainer}
  width: 66px;
  height: 66px;
`;

const largeImageContainer = css`
  ${imageContainer}
  width: 76px;
  height: 76px;
  ${({ tabletOnly }) => (tabletOnly ? tabletOnlyStyles : "")}
`;

const smallImage = css`
  ${image}
  width: 50px;
  height: 50px;
`;

const mediumImage = css`
  ${image}
  width: 60px;
  height: 60px;
`;

const largeImage = css`
  ${image}
  width: 70px;
  height: 70px;
`;

const smallStatusIcon = css`
  ${statusIcon}
  line-height: 20px;
  font-size: 12px;
  width: 18px;
  height: 18px;
  bottom: -4px;
  right: -4px;
`;

const mediumStatusIcon = css`
  ${statusIcon}
  width: 24px;
  height: 24px;
  bottom: -7px;
  right: -7px;
`;

const largeStatusIcon = css`
  ${mediumStatusIcon}
  bottom: 0px;
  right: 0px;
`;

const cross = css`
  background-color: ${({ theme }) => theme.palette.hague};
`;

const tick = css`
  background-color: ${({ theme }) => theme.palette.enquiryGreen};
`;

const smallAvatarCross = css`
  ${smallStatusIcon}
  ${cross}
`;

const smallAvatarTick = css`
  ${smallStatusIcon}
  ${tick}
`;

const mediumAvatarCross = css`
  ${mediumStatusIcon}
  ${cross}
`;

const mediumAvatarTick = css`
  ${mediumStatusIcon}
  ${tick}
`;

const largeAvatarCross = css`
  ${largeStatusIcon}
  ${cross}
`;

const largeAvatarTick = css`
  ${largeStatusIcon}
  ${tick}
`;

type Props = {
  avatarUrl: string,
  anonymised?: boolean,
  withTick?: boolean,
  withCross?: boolean,
  className?: string,
  displayOnDesktop?: boolean,
};

export const BuyerAvatarSmall = ({
  avatarUrl,
  anonymised,
  withTick,
  withCross,
  className,
}: Props) => {
  const imageUrl = anonymised ? makeSillhouette(avatarUrl) : avatarUrl;

  return (
    <div css={smallImageContainer} className={className}>
      <img
        src={imageUrl}
        alt="Buyer avatar"
        const
        css={smallImage}
        anonymised={anonymised}
      />
      {withCross && (
        <div css={smallAvatarCross}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      {withTick && (
        <div css={smallAvatarTick}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
    </div>
  );
};

export const BuyerAvatarMedium = ({
  avatarUrl,
  anonymised,
  withTick,
  withCross,
  className,
}: Props) => {
  const imageUrl = anonymised ? makeSillhouette(avatarUrl) : avatarUrl;

  return (
    <div css={mediumImageContainer} className={className}>
      <img
        src={imageUrl}
        alt="Buyer avatar"
        const
        css={mediumImage}
        anonymised={anonymised}
      />
      {withCross && (
        <div css={mediumAvatarCross}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      {withTick && (
        <div css={mediumAvatarTick}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
    </div>
  );
};

const modalAvatarStyles = css`
  display: inline-block;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  ${media.tablet`
    display: ${({ displayOnDesktop }) => (displayOnDesktop ? "block" : "none")};
  `}
`;

export const ModalAvatar = ({
  avatarUrl,
  anonymised,
  className,
  withTick,
  withCross,
  displayOnDesktop,
}: Props) => {
  return (
    <div
      className={className}
      css={modalAvatarStyles}
      displayOnDesktop={displayOnDesktop}
    >
      <BuyerAvatarMedium
        avatarUrl={avatarUrl}
        anonymised={anonymised}
        withTick={withTick}
        withCross={withCross}
      />
    </div>
  );
};

export const BuyerAvatarLarge = ({
  avatarUrl,
  anonymised,
  withTick,
  withCross,
  tabletOnly,
}: Props & { tabletOnly?: boolean }) => {
  const imageUrl = anonymised ? makeSillhouette(avatarUrl) : avatarUrl;

  return (
    <div css={largeImageContainer} tabletOnly={tabletOnly}>
      <img
        src={imageUrl}
        alt="Buyer avatar"
        const
        css={largeImage}
        anonymised={anonymised}
      />
      {withCross && (
        <div css={largeAvatarCross}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      {withTick && (
        <div css={largeAvatarTick}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
    </div>
  );
};
