export const initialTimeslots = {
  65378: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65378",
    timeSlotEnd: "09:00:00",
    timeSlotStart: "08:00:00",
  },
  65379: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65379",
    timeSlotEnd: "10:00:00",
    timeSlotStart: "09:00:00",
  },
  65380: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65380",
    timeSlotEnd: "11:00:00",
    timeSlotStart: "10:00:00",
  },
  65381: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65381",
    timeSlotEnd: "12:00:00",
    timeSlotStart: "11:00:00",
  },
  65382: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65382",
    timeSlotEnd: "13:00:00",
    timeSlotStart: "12:00:00",
  },
  65383: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65383",
    timeSlotEnd: "14:00:00",
    timeSlotStart: "13:00:00",
  },
  65384: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65384",
    timeSlotEnd: "15:00:00",
    timeSlotStart: "14:00:00",
  },
  65385: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65385",
    timeSlotEnd: "16:00:00",
    timeSlotStart: "15:00:00",
  },
  65386: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65386",
    timeSlotEnd: "17:00:00",
    timeSlotStart: "16:00:00",
  },
  65387: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65387",
    timeSlotEnd: "18:00:00",
    timeSlotStart: "17:00:00",
  },
  65388: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65388",
    timeSlotEnd: "19:00:00",
    timeSlotStart: "18:00:00",
  },
  65389: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 1,
    id: "65389",
    timeSlotEnd: "20:00:00",
    timeSlotStart: "19:00:00",
  },
  65390: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65390",
    timeSlotEnd: "09:00:00",
    timeSlotStart: "08:00:00",
  },
  65391: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65391",
    timeSlotEnd: "10:00:00",
    timeSlotStart: "09:00:00",
  },
  65392: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65392",
    timeSlotEnd: "11:00:00",
    timeSlotStart: "10:00:00",
  },
  65393: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65393",
    timeSlotEnd: "12:00:00",
    timeSlotStart: "11:00:00",
  },
  65394: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65394",
    timeSlotEnd: "13:00:00",
    timeSlotStart: "12:00:00",
  },
  65395: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65395",
    timeSlotEnd: "14:00:00",
    timeSlotStart: "13:00:00",
  },
  65396: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65396",
    timeSlotEnd: "15:00:00",
    timeSlotStart: "14:00:00",
  },
  65397: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65397",
    timeSlotEnd: "16:00:00",
    timeSlotStart: "15:00:00",
  },
  65398: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65398",
    timeSlotEnd: "17:00:00",
    timeSlotStart: "16:00:00",
  },
  65399: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65399",
    timeSlotEnd: "18:00:00",
    timeSlotStart: "17:00:00",
  },
  65400: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65400",
    timeSlotEnd: "19:00:00",
    timeSlotStart: "18:00:00",
  },
  65401: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 2,
    id: "65401",
    timeSlotEnd: "20:00:00",
    timeSlotStart: "19:00:00",
  },
  65402: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65402",
    timeSlotEnd: "09:00:00",
    timeSlotStart: "08:00:00",
  },
  65403: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65403",
    timeSlotEnd: "10:00:00",
    timeSlotStart: "09:00:00",
  },
  65404: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65404",
    timeSlotEnd: "11:00:00",
    timeSlotStart: "10:00:00",
  },
  65405: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65405",
    timeSlotEnd: "12:00:00",
    timeSlotStart: "11:00:00",
  },
  65406: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65406",
    timeSlotEnd: "13:00:00",
    timeSlotStart: "12:00:00",
  },
  65407: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65407",
    timeSlotEnd: "14:00:00",
    timeSlotStart: "13:00:00",
  },
  65408: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65408",
    timeSlotEnd: "15:00:00",
    timeSlotStart: "14:00:00",
  },
  65409: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65409",
    timeSlotEnd: "16:00:00",
    timeSlotStart: "15:00:00",
  },
  65410: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65410",
    timeSlotEnd: "17:00:00",
    timeSlotStart: "16:00:00",
  },
  65411: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65411",
    timeSlotEnd: "18:00:00",
    timeSlotStart: "17:00:00",
  },
  65412: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65412",
    timeSlotEnd: "19:00:00",
    timeSlotStart: "18:00:00",
  },
  65413: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 3,
    id: "65413",
    timeSlotEnd: "20:00:00",
    timeSlotStart: "19:00:00",
  },
  65414: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65414",
    timeSlotEnd: "09:00:00",
    timeSlotStart: "08:00:00",
  },
  65415: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65415",
    timeSlotEnd: "10:00:00",
    timeSlotStart: "09:00:00",
  },
  65416: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65416",
    timeSlotEnd: "11:00:00",
    timeSlotStart: "10:00:00",
  },
  65417: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65417",
    timeSlotEnd: "12:00:00",
    timeSlotStart: "11:00:00",
  },
  65418: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65418",
    timeSlotEnd: "13:00:00",
    timeSlotStart: "12:00:00",
  },
  65419: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65419",
    timeSlotEnd: "14:00:00",
    timeSlotStart: "13:00:00",
  },
  65420: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65420",
    timeSlotEnd: "15:00:00",
    timeSlotStart: "14:00:00",
  },
  65421: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65421",
    timeSlotEnd: "16:00:00",
    timeSlotStart: "15:00:00",
  },
  65422: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65422",
    timeSlotEnd: "17:00:00",
    timeSlotStart: "16:00:00",
  },
  65423: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65423",
    timeSlotEnd: "18:00:00",
    timeSlotStart: "17:00:00",
  },
  65424: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65424",
    timeSlotEnd: "19:00:00",
    timeSlotStart: "18:00:00",
  },
  65425: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 4,
    id: "65425",
    timeSlotEnd: "20:00:00",
    timeSlotStart: "19:00:00",
  },
  65426: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65426",
    timeSlotEnd: "09:00:00",
    timeSlotStart: "08:00:00",
  },
  65427: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65427",
    timeSlotEnd: "10:00:00",
    timeSlotStart: "09:00:00",
  },
  65428: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65428",
    timeSlotEnd: "11:00:00",
    timeSlotStart: "10:00:00",
  },
  65429: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65429",
    timeSlotEnd: "12:00:00",
    timeSlotStart: "11:00:00",
  },
  65430: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65430",
    timeSlotEnd: "13:00:00",
    timeSlotStart: "12:00:00",
  },
  65431: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65431",
    timeSlotEnd: "14:00:00",
    timeSlotStart: "13:00:00",
  },
  65432: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65432",
    timeSlotEnd: "15:00:00",
    timeSlotStart: "14:00:00",
  },
  65433: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65433",
    timeSlotEnd: "16:00:00",
    timeSlotStart: "15:00:00",
  },
  65434: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65434",
    timeSlotEnd: "17:00:00",
    timeSlotStart: "16:00:00",
  },
  65435: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65435",
    timeSlotEnd: "18:00:00",
    timeSlotStart: "17:00:00",
  },
  65436: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65436",
    timeSlotEnd: "19:00:00",
    timeSlotStart: "18:00:00",
  },
  65437: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 5,
    id: "65437",
    timeSlotEnd: "20:00:00",
    timeSlotStart: "19:00:00",
  },
  65438: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65438",
    timeSlotEnd: "09:00:00",
    timeSlotStart: "08:00:00",
  },
  65439: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65439",
    timeSlotEnd: "10:00:00",
    timeSlotStart: "09:00:00",
  },
  65440: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65440",
    timeSlotEnd: "11:00:00",
    timeSlotStart: "10:00:00",
  },
  65441: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65441",
    timeSlotEnd: "12:00:00",
    timeSlotStart: "11:00:00",
  },
  65442: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65442",
    timeSlotEnd: "13:00:00",
    timeSlotStart: "12:00:00",
  },
  65443: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65443",
    timeSlotEnd: "14:00:00",
    timeSlotStart: "13:00:00",
  },
  65444: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65444",
    timeSlotEnd: "15:00:00",
    timeSlotStart: "14:00:00",
  },
  65445: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65445",
    timeSlotEnd: "16:00:00",
    timeSlotStart: "15:00:00",
  },
  65446: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65446",
    timeSlotEnd: "17:00:00",
    timeSlotStart: "16:00:00",
  },
  65447: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65447",
    timeSlotEnd: "18:00:00",
    timeSlotStart: "17:00:00",
  },
  65448: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65448",
    timeSlotEnd: "19:00:00",
    timeSlotStart: "18:00:00",
  },
  65449: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 6,
    id: "65449",
    timeSlotEnd: "20:00:00",
    timeSlotStart: "19:00:00",
  },
  65450: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65450",
    timeSlotEnd: "09:00:00",
    timeSlotStart: "08:00:00",
  },
  65451: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65451",
    timeSlotEnd: "10:00:00",
    timeSlotStart: "09:00:00",
  },
  65452: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65452",
    timeSlotEnd: "11:00:00",
    timeSlotStart: "10:00:00",
  },
  65453: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65453",
    timeSlotEnd: "12:00:00",
    timeSlotStart: "11:00:00",
  },
  65454: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65454",
    timeSlotEnd: "13:00:00",
    timeSlotStart: "12:00:00",
  },
  65455: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65455",
    timeSlotEnd: "14:00:00",
    timeSlotStart: "13:00:00",
  },
  65456: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65456",
    timeSlotEnd: "15:00:00",
    timeSlotStart: "14:00:00",
  },
  65457: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65457",
    timeSlotEnd: "16:00:00",
    timeSlotStart: "15:00:00",
  },
  65458: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65458",
    timeSlotEnd: "17:00:00",
    timeSlotStart: "16:00:00",
  },
  65459: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65459",
    timeSlotEnd: "18:00:00",
    timeSlotStart: "17:00:00",
  },
  65460: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65460",
    timeSlotEnd: "19:00:00",
    timeSlotStart: "18:00:00",
  },
  65461: {
    __typename: "ViewingAvailabilityTimeslot",
    available: true,
    dayOfWeek: 7,
    id: "65461",
    timeSlotEnd: "20:00:00",
    timeSlotStart: "19:00:00",
  },
};
