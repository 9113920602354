// @flow
import { css } from "styled-components";
import { smallHeading } from "@nested/brand";
import moment from "moment-timezone";

const selectedDayWrapper = css`
  padding: 5px;
  margin-right: 15px;
  width: 62px;
`;

const dayOfWeekStyles = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2px;
`;

const dayOfMonthStyles = css`
  ${smallHeading}
  font-size: 21px;
  line-height: 21.6px;
  text-align: center;
`;

export const ViewingDetailDate = ({
  viewingDate,
}: {
  viewingDate: DateTime,
}) => {
  const dayOfWeek = moment(viewingDate).format("ddd");
  const dayOfMonth = moment(viewingDate).format("Do");

  return (
    <div css={selectedDayWrapper}>
      <div css={dayOfWeekStyles}>{dayOfWeek}</div>
      <div css={dayOfMonthStyles}>{dayOfMonth}</div>
    </div>
  );
};
