// @flow
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { getImage } from "@nested/utils";
import { PlaceholderCard } from "@nested/brand";
import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { ENQUIRIES_QUERY } from "../Enquiries/Enquiries";
import { EnquiriesDoughnut } from "../Enquiries/EnquiriesDoughnut";
import {
  SectionWrapper,
  SectionHeader,
  SectionHeaderSubtext,
  UnpaddedSectionCard,
  ExampleBox,
  IconAndText,
  SectionCardPadding,
  ImageAndTextCardContent,
  FullWidthCardButton,
} from "../../components/SectionCard";
import { enquiriesExampleData } from "../Enquiries/enquiriesExampleData";

const ClosedContent = () => {
  const theme = useContext(ThemeContext);
  return (
    <SectionWrapper data-test="enquiries-card">
      <SectionHeader>Enquiries</SectionHeader>
      <UnpaddedSectionCard>
        <SectionCardPadding>
          <ImageAndTextCardContent
            largeText
            text="Enquiries will appear here after listing"
            imageSrc={getImage("your-account/enquiries.png")}
            imageAlt="Enquiries"
            circleColor={theme.palette.blue20}
          />
        </SectionCardPadding>
      </UnpaddedSectionCard>
    </SectionWrapper>
  );
};

const FirstImpressionsContent = () => (
  <SectionWrapper>
    <SectionHeader css="padding-bottom: 0;">Enquiries</SectionHeader>
    <SectionHeaderSubtext> Available after listing</SectionHeaderSubtext>
    <UnpaddedSectionCard css="margin-top: 20px;">
      <SectionCardPadding>
        <IconAndText
          icon={faDesktop}
          text="Find out who wants to take things further after checking out your listing online."
        />
        <ExampleBox css="padding: 0;">
          <div css="padding: 20px 15px;">
            <Link
              to="/listing/interest/enquiries?example=true"
              css="text-decoration: none;"
            >
              <EnquiriesDoughnut enquiries={enquiriesExampleData} />
            </Link>
          </div>
          <FullWidthCardButton
            buttonText="See all example enquiries"
            path="/listing/interest/enquiries?example=true"
          />
        </ExampleBox>
      </SectionCardPadding>
    </UnpaddedSectionCard>
  </SectionWrapper>
);

type Props = {
  deal: ?ListingSectionQuery_publicDeal,
};

export const EnquiriesCard = ({ deal }: Props) => {
  const { data, loading } = usePublicDealQuery(ENQUIRIES_QUERY, {
    skip: !deal?.isListed,
  });

  const enquiries = data?.publicDeal?.buyerEnquiries;

  const hasEnquiries = enquiries && enquiries.length > 0;

  if (deal?.opportunityStatusValue === "s07_closed") {
    return <ClosedContent />;
  }

  if (!deal?.isListed) {
    return <FirstImpressionsContent />;
  }

  if (loading) {
    return (
      <SectionWrapper>
        <PlaceholderCard />
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper data-test="enquiries-card">
      <SectionHeader>Enquiries</SectionHeader>
      <UnpaddedSectionCard>
        <SectionCardPadding>
          {hasEnquiries ? (
            <Link to="/listing/interest/enquiries" css="text-decoration: none;">
              <EnquiriesDoughnut enquiries={enquiries} />
            </Link>
          ) : (
            <EnquiriesDoughnut enquiries={enquiries} />
          )}
        </SectionCardPadding>
        {hasEnquiries && (
          <FullWidthCardButton
            buttonText="View all enquiries"
            path="/listing/interest/enquiries"
          />
        )}
      </UnpaddedSectionCard>
    </SectionWrapper>
  );
};
