// @flow
import { gql } from "@apollo/client";
import { css } from "styled-components";
import { getConfig } from "@nested/config";
import { media } from "@nested/brand";
import { type QueryRenderProps, getImage } from "@nested/utils";
import {
  HeroContent,
  ListedHeroContent,
  type HeroContentProps,
} from "./HeroContent";
import { PublicDealQuery } from "../AuthenticatedQuery";
import { useDeal } from "../../hooks/useDeal";

const { MARKETING_SITE_URI } = getConfig();

export const LEAD_QUERY = gql`
  query HeroLeadInfo($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      currentListingPrice
      dateCompleted
      dateDisinstructed
      dateExchanged
      dateListed
      dateUnderOffer
      externalDealId
      isListed
      listingUrl
      opportunityStatusValue
      property {
        formattedAddress
      }
      sm {
        firstName
        lastName
        phoneNumber
        emailAddress
      }
    }
  }
`;

const wrapper = css`
  border-bottom: ${({ theme }) => theme.palette.hague10} solid 1px;
  display: ${({ noMobile }) => (noMobile ? "none" : "block")};

  ${media.tablet`
    display: block;

    align-self: stretch;
    flex-grow: 1;

    border-left: ${({ theme }) => theme.palette.hague10} solid 1px;
    border-bottom: unset;
  `}

  ${media.desktop`
    max-width: 540px;
  `}
`;

const heroHeadingStyle = css`
  background-color: white;
  box-sizing: border-box;

  > :last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  padding-bottom: 15px;

  ${media.tablet`
    width: 33.3vw;
    
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
  `}

  ${media.desktop`
    width: 30vw;
    max-width: 540px;
  `}
`;

const preLeadContent = {
  heroImg: getImage("account/hero-images/prelead-hero.png"),
  heroHeading: "Welcome to your Nested account",
  buttonCta: "Speak to your Nested agent",
  buttonPath: `${MARKETING_SITE_URI}/get-started`,
  heroDescription:
    "From understanding your local market to managing your sale, your Nested account will accompany you during your home selling journey.",
};

const leadContent = {
  heroImg: getImage("account/hero-images/jamie.jpg"),
  heroHeading: "Expert agents by your side",
  buttonCta: "Book a call with our team",
  buttonPath: "/book-a-call",
  heroDescription:
    "When you sell with Nested, we build a team of local specialists to manage your sale, purchase & chain.",
};

const closedContent = {
  heroIcon: getImage("your-account/viewings-tab/telescope-stars.png"),
  heroBackgroundColor: "yellow40",
  heroHeading: "Set your viewing schedule",
  heroDescription:
    "We’ll always give you 24 hours notice before a viewing, but you can let us know exactly what hours work best for you.",
  buttonCta: "Weekly viewing availability",
  buttonPath: "/listing/viewings/availability",
};

type CustomHeroProps = {|
  noMobile?: boolean,
  ...HeroContentProps,
|};

export const CustomHeroSection = ({ noMobile, ...rest }: CustomHeroProps) => (
  <div css={wrapper} noMobile={noMobile}>
    <div css={heroHeadingStyle}>
      <HeroContent {...rest} />
    </div>
  </div>
);

type Props = {
  noMobile?: boolean,
};

const PreLeadHero = ({ noMobile }: *) => (
  <div css={wrapper} noMobile={noMobile}>
    <div css={heroHeadingStyle}>
      <HeroContent {...preLeadContent} />
    </div>
  </div>
);

const LeadHero = ({ noMobile }: *) => (
  <div css={wrapper} noMobile={noMobile}>
    <div css={heroHeadingStyle}>
      <PublicDealQuery query={LEAD_QUERY}>
        {({ data }: QueryRenderProps<HeroLeadInfo>) => {
          if (data?.publicDeal.opportunityStatusValue === "s07_closed") {
            return <HeroContent {...closedContent} />;
          }
          if (data?.publicDeal.isListed) {
            return <ListedHeroContent deal={data?.publicDeal} />;
          }
          return <HeroContent {...leadContent} />;
        }}
      </PublicDealQuery>
    </div>
  </div>
);

export const HeroSection = ({ noMobile }: Props) => {
  const { dealId } = useDeal();

  if (!dealId) {
    return <PreLeadHero noMobile={noMobile} />;
  }

  return <LeadHero noMobile={noMobile} />;
};
