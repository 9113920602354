// @flow
import { useEffect } from "react";
import { Route, type ContextRouter } from "react-router-dom";
import { useAuth } from "@nested/auth";
import { Login } from "../../pages/Login/Login";

import { AuthenticatedLayout } from "./AuthenticatedLayout";
import { isNativeApp, requestPushPermissions } from "../../gonative";

type Props = {|
  render?: (router: ContextRouter) => React$Element<any>,
  layout?: React$ComponentType<any>,
  component?: React$ComponentType<any>,
  exact?: boolean,
  path: string,
  dontPad?: boolean,
  unauthenticatedRedirect?: string,
|};

export const PrivateRoute = ({
  dontPad,
  layout: Layout = AuthenticatedLayout,
  component: Component,
  render,
  unauthenticatedRedirect,
  ...rest // includes path
}: Props) => {
  const { authenticated } = useAuth();

  useEffect(() => {
    if (authenticated && isNativeApp()) {
      requestPushPermissions();
    }
  }, [authenticated]);

  if (authenticated && render) {
    return <Route {...rest} render={render} />;
  }

  if (authenticated && Component) {
    return (
      <Route
        {...rest}
        render={(match) =>
          Layout ? (
            <Layout dontPad={dontPad}>
              <Component {...match} />
            </Layout>
          ) : (
            <Component {...match} />
          )
        }
      />
    );
  }

  if (unauthenticatedRedirect) {
    return (
      <Route
        component={() => {
          useEffect(() => {
            window.location.assign(unauthenticatedRedirect);
          }, []);
          return null;
        }}
      />
    );
  }

  return <Route component={Login} />;
};
