// @flow
import { css } from "styled-components";
import { Modal, largeParagraph, mediumParagraph, media } from "@nested/brand";
import { formatDateTime } from "@nested/utils/src/formatDate/formatDate";
import {
  VIEWING_BOOKED_STATUS,
  DID_NOT_BOOK_VIEWING_STATUS,
  CHASING_NOW_STATUS,
} from "./utils";
import { StatusText } from "./StatusText";
import {
  ModalAvatar,
  BuyerAvatarLarge,
} from "../../components/BuyerAvatar/BuyerAvatar";
import {
  BookedViewingContent,
  BookedViewingSidebarDetails,
} from "./BookedViewingContent";
import { ChasingNowContent } from "./ChasingNowContent";
import { DidNotBookContent } from "./DidNotBookContent";
import { ExampleCapsuleForModal } from "../../components/SectionCard";

const modalStyle = css`
  max-width: 315px;
  padding: 0;
  ${media.tablet`
    max-width: unset;
    width: 690px;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
  `}
`;

const mobileOnlyBuyerDetails = css`
  padding: 45px 0 20px;
  ${media.tablet`
    display: none;
  `}
`;

const mobileBuyerName = css`
  ${largeParagraph}
  text-align: center;
  font-weight: 500;
  margin: 0;
`;

const mobileEnquiryTime = css`
  ${mediumParagraph}
  text-align: center;
  font-weight: 500;
  opacity: 0.7;
  margin: 0;
`;

const statusStyles = css`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const buyerDetailsSection = css`
  display: none;
  ${media.tablet`
    display: block;
    background-color: ${({ theme }) => theme.palette.hague};
    padding: 15px;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
    width: 240px;
    min-width: 240px;
    padding: 40px 30px;
    border-radius: 5px 0 0 5px;
  `}
`;

const mainWrapper = css`
  flex-grow: 1;
`;

const buyerNameStyle = css`
  ${mobileBuyerName}
  display: none;
  ${media.tablet`
    display: block;
    color: white;
    text-align: left;
    margin: 20px 0 0;
 `}
`;

const buyerEnquiryTimeStyle = css`
  ${mobileEnquiryTime}
  display: none;
  ${media.tablet`
    display: block;
    color: white;
    text-align: left;
 `}
`;

const statusText = css`
  ${buyerEnquiryTimeStyle}
  ${media.tablet`
    opacity: 1;
    color: ${({ orange, theme }) => (orange ? theme.palette.orange : "white")};
  `}
`;
type Props = {
  open: boolean,
  onClose(): void,
  next?: ?() => void,
  previous?: ?() => void,
  buyerEnquiry: EnquiriesPageQuery_publicDeal_buyerEnquiries,
  exampleMode?: boolean,
};

const ModalContent = ({
  buyerEnquiry,
  buyer,
  didNotBookViewing,
  bookedViewing,
  chasingNow,
  concealAvatar,
  enquiredTime,
  updatedAt,
  open,
  exampleMode,
}) => (
  <>
    {exampleMode && <ExampleCapsuleForModal />}
    <ModalAvatar
      withCross={didNotBookViewing}
      withTick={bookedViewing}
      anonymised={concealAvatar}
      avatarUrl={buyer.avatarUrl}
    />
    <div css={mainWrapper}>
      <div css={mobileOnlyBuyerDetails}>
        <h3 css={mobileBuyerName}>
          {buyer.firstName} {buyer.surnameInitial}
        </h3>
        <p css={mobileEnquiryTime}>Enquired {enquiredTime}</p>
        <StatusText status={buyerEnquiry.enquiryStatus} css={statusStyles} />
      </div>
      {chasingNow && (
        <ChasingNowContent
          buyer={buyer}
          enquiryId={buyerEnquiry.id}
          open={open}
          updatedAt={updatedAt}
          exampleMode={exampleMode}
        />
      )}
      {didNotBookViewing && <DidNotBookContent enquiry={buyerEnquiry} />}
      {bookedViewing && (
        <BookedViewingContent
          buyer={buyer}
          enquiryId={buyerEnquiry.id}
          viewingDate={buyerEnquiry.firstViewingTimestamp}
          virtual={buyerEnquiry.firstViewingVirtual}
        />
      )}
    </div>
    <div css={buyerDetailsSection}>
      <BuyerAvatarLarge
        withCross={didNotBookViewing}
        withTick={bookedViewing}
        anonymised={concealAvatar}
        avatarUrl={buyer.avatarUrl}
      />
      <h3 css={buyerNameStyle}>
        {buyer.firstName} {buyer.surnameInitial}
      </h3>
      <p css={buyerEnquiryTimeStyle}>Enquired {enquiredTime}</p>
      {didNotBookViewing && <p css={statusText}>Didn't book a viewing</p>}
      {bookedViewing && (
        <>
          <p css={statusText}>Booked a viewing</p>
          <BookedViewingSidebarDetails buyer={buyer} />
        </>
      )}
      {chasingNow && (
        <p css={statusText} orange>
          We're chasing now
        </p>
      )}
    </div>
  </>
);

export const BuyerEnquiryModal = ({
  open,
  onClose,
  buyerEnquiry,
  next,
  previous,
  exampleMode,
}: Props) => {
  const { buyer, contactLastUpdatedAt, enquiryStatus, datetimeEnquiryCreated } =
    buyerEnquiry;
  const didNotBookViewing = enquiryStatus === DID_NOT_BOOK_VIEWING_STATUS;
  const bookedViewing = enquiryStatus === VIEWING_BOOKED_STATUS;
  const chasingNow = enquiryStatus === CHASING_NOW_STATUS;
  const concealAvatar = chasingNow || didNotBookViewing;

  const enquiredTime = formatDateTime(datetimeEnquiryCreated, {
    appendTime: false,
  });

  const updatedAt = formatDateTime(contactLastUpdatedAt, {
    appendTime: false,
  });

  const props = {
    buyerEnquiry,
    buyer,
    didNotBookViewing,
    bookedViewing,
    chasingNow,
    concealAvatar,
    enquiredTime,
    updatedAt,
    open,
    exampleMode,
  };

  return (
    <Modal
      data-test={`feedback-modal-${buyer.id}`}
      css={modalStyle}
      open={open}
      onClose={onClose}
      next={next}
      previous={previous}
    >
      <ModalContent {...props} />
    </Modal>
  );
};
