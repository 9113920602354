// @flow
import type { Element } from "react";
import { useState, useContext } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { Checkbox, mediumParagraph, media } from "@nested/brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Filter } from "./Filters";

export const PRICE_HIGH_TO_LOW = "SortPriceHighToLow";
export const PRICE_LOW_TO_HIGH = "SortPriceLowToHigh";

const FilterText = styled.label`
  ${mediumParagraph}
`;

const DropDownButton = styled.div`
  background-color: ${({ theme, showFilters }) =>
    showFilters ? theme.palette.hague : "white"};
  label {
    color: ${({ theme, showFilters }) =>
      showFilters ? "white" : theme.palette.hague};
  }
  padding: 0px 15px;
  height: 40px;
  border-radius: 10pt;
  width: calc(50% - 45px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CheckboxItem = ({
  checked,
  count = null,
  filterText,
  id,
  toggleSelect,
}: CheckboxProps) => (
  <Checkbox
    checked={checked}
    onChange={toggleSelect}
    id={id}
    label={styledLabel(filterText, count, id)}
  />
);

const DropDown = styled.div`
  display: ${({ showFilters }) => (showFilters ? "block" : "none")};
  background-color: #faf7f4;
  padding: 20px;
  position: absolute;
  border-bottom-left-radius: 10pt;
  border-bottom-right-radius: 10pt;
  top: 50px;
  width: calc(100% - 40px);
  z-index: 2;
  ${({ sortOptions }) => (sortOptions ? DesktopSortStyles : "")}
  ${CheckboxItem.Checkbox} {
    > label:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const FiltersWrapper = styled.div`
  position: relative;
  background-color: #faf7f4;
  display: flex;
  border-radius: 10pt;
  justify-content: space-evenly;
  width: 100%;
  padding: 10px 0;
  ${media.tablet`
    margin-bottom: 20px;
  `}
  ${media.desktop`
  `}
`;

const DesktopSortStyles = css`
  ${media.tablet`
    top: 50px;
    left: 0;
    &:before {
      top: -30px;
    }
  `}
`;

type CheckboxProps = {
  checked: boolean,
  count?: number,
  filterText: string,
  id: string,
  toggleSelect: (SyntheticEvent<>) => void,
};

const styledLabel = (filterText, count, id): Element<FilterText> => {
  const theme = useContext(ThemeContext);

  return (
    <FilterText data-test={`${id}-checkbox-text`} htmlFor={`${id}`}>
      {filterText}
      <strong style={{ color: theme.palette.terracotta100 }}>{` ${
        count !== null ? `(${count})` : ""
      }`}</strong>
    </FilterText>
  );
};

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  comparables: $ReadOnlyArray<PropertyAnalysisComparables_comparableProperties_comparables>, // eslint-disable-line
  createToggleFunction: (string) => (SyntheticEvent<>) => void,
  updateSortOrder: (string) => void,
  state: {
    otmSelected: boolean,
    soldSelected: boolean,
    underOfferSelected: boolean,
    withdrawnSelected: boolean,
    sortOrder: string,
  },
};

export const ComparableFilters = ({
  comparables,
  createToggleFunction,
  updateSortOrder,
  state: {
    otmSelected,
    soldSelected,
    underOfferSelected,
    withdrawnSelected,
    sortOrder,
  },
}: Props) => {
  const [showFilters, setShowFilters] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);

  const OTMCount = comparables.filter((comp) => comp.status === "OTM").length;
  const soldCount = comparables.filter((comp) => comp.status === "Sold").length;
  const underOfferCount = comparables.filter(
    (comp) => comp.status === "UO",
  ).length;
  const withdrawnCount = comparables.filter(
    (comp) => comp.status === "Withdrawn",
  ).length;

  return (
    <FiltersWrapper>
      <DropDownButton
        sortByButton
        onClick={() => {
          setShowFilters(!showFilters);
          setShowSortBy(false);
        }}
        showFilters={showFilters}
      >
        <FilterText>
          <strong>Filter by</strong>{" "}
          <FontAwesomeIcon
            icon={faChevronDown}
            flip={showFilters ? "vertical" : undefined}
          />
        </FilterText>
      </DropDownButton>
      <DropDown showFilters={showFilters} filterOptions>
        {soldCount > 0 && (
          <CheckboxItem
            data-test="sold-checkbox-item"
            toggleSelect={createToggleFunction("soldSelected")}
            id="sold"
            checked={soldSelected}
            filterText="Sold"
            count={soldCount}
          />
        )}
        {underOfferCount > 0 && (
          <CheckboxItem
            data-test="under-offer-checkbox-item"
            toggleSelect={createToggleFunction("underOfferSelected")}
            id="under-offer"
            checked={underOfferSelected}
            filterText="Under Offer"
            count={underOfferCount}
          />
        )}
        {OTMCount > 0 && (
          <CheckboxItem
            data-test="on-the-market-checkbox-item"
            toggleSelect={createToggleFunction("otmSelected")}
            id="on-the-market"
            checked={otmSelected}
            filterText="For Sale"
            count={OTMCount}
          />
        )}
        {withdrawnCount > 0 && (
          <CheckboxItem
            data-test="on-the-market-checkbox-item"
            toggleSelect={createToggleFunction("withdrawnSelected")}
            id="withdrawn"
            checked={withdrawnSelected}
            filterText="Withdrawn"
            count={withdrawnCount}
          />
        )}
      </DropDown>
      <DropDownButton
        onClick={() => {
          setShowSortBy(!showSortBy);
          setShowFilters(false);
        }}
        showFilters={showSortBy}
        sortByButton
      >
        <FilterText>
          <strong>Sort by</strong>{" "}
          <FontAwesomeIcon
            icon={faChevronDown}
            flip={showSortBy ? "vertical" : undefined}
          />
        </FilterText>
      </DropDownButton>
      <DropDown showFilters={showSortBy} sortOptions>
        <RadioGroup data-test="comparable-filters">
          <Filter
            filterText="Price low to high"
            handleChange={() => {
              updateSortOrder(PRICE_LOW_TO_HIGH);
              setShowSortBy(false);
            }}
            inputDataTest="filter-input-price-low-to-high"
            labelDataTest="filter-label-price-low-to-high"
            isChecked={sortOrder === PRICE_LOW_TO_HIGH}
          />
          <Filter
            filterText="Price high to low"
            handleChange={() => {
              updateSortOrder(PRICE_HIGH_TO_LOW);
              setShowSortBy(false);
            }}
            inputDataTest="filter-input-price-high-to-low"
            labelDataTest="filter-label-price-high-to-low"
            isChecked={sortOrder === PRICE_HIGH_TO_LOW}
          />
        </RadioGroup>
      </DropDown>
    </FiltersWrapper>
  );
};
