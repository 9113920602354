// @flow
import { useEffect, useMemo, useState } from "react";
import { gql } from "@apollo/client";
import { css } from "styled-components";
import { getImage } from "@nested/utils";
import { media, PlaceholderCard } from "@nested/brand";
import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { Confetti } from "../../components/Confetti";
import { LayoutWrapper } from "../PageWrapper";
import { extractRenegotiatedOffers, getHeading, isNewOffer } from "./utils";
import { BuyerDetails } from "./BuyerDetails";
import { BuyerSummary } from "./BuyerSummary";
import { SMDetails, loadingBlockSmall, fade } from "./SMDetails";
import { Breadcrumb } from "../../components/Breadcrumb";

export type Offer = PublicDealOffers_publicDeal_buyerEnquiries_buyerOffers;

export const OFFERS_QUERY = gql`
  query PublicDealOffers($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      sm {
        firstName
        lastName
        emailAddress
        phoneNumber
      }
      buyerEnquiries(type: WITH_OFFERS) {
        id
        buyer {
          id
          avatarUrl
          firstName
          surnameInitial
          buyerPosition
          financialPosition
        }
        buyerOffers {
          id
          amount
          datetimeSubmittedToVendor
          lengthOfDownwardChain
          number
          offerConditions
          placedOn
          renegotiatedOffers {
            id
            newAcceptedOfferAmount
            dateAccepted
          }
          status
          timeFrameConditions
        }
      }
    }
  }
`;

const headingStyle = css`
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  margin: 10px 0 0;
  line-height: 29px;
  ${media.tablet`
    margin-top: 0px;
    text-align: left;
  `}
`;

const subheadingStyle = css`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 5px 0 0;
  line-height: 21px;
  ${media.tablet`
    text-align: left;
  `}
`;

const headingLoadingBlock = css`
  ${loadingBlockSmall}
  height: 20px;
  margin-top: 10px;
  ${media.tablet`
    margin-top: 0;
  `}
`;

const subHeadingLoadingBlock = css`
  ${loadingBlockSmall}
  margin-top: 10px;
`;

const HeadingLoader = () => (
  <div>
    <div css={headingStyle}>
      <div css={headingLoadingBlock} width={240}></div>
    </div>
    <div css={subheadingStyle}>
      <div css={subHeadingLoadingBlock} width={180}></div>
    </div>
  </div>
);

const wrapperStyle = css`
  padding: 30px 15px 48px;
  ${media.tablet`
    position: relative;
    padding: 30px 40px;
    width: 100%;
  `}
`;

const moneyDogStyle = css`
  display: block;
  margin: 0 auto;
  width: 120px;
  ${media.tablet`
    display: none;
  `}
`;

const mobileOffersCard = css`
  background-color: white;
  border-radius: 5px;
  box-shadow: 3px 3px 15px 0 ${({ theme }) => theme.palette.hague};
  padding: 20px 15px;
  margin-top: 20px;
  color: ${({ theme }) => theme.palette.hague};
  ${media.tablet`
    display: none;
  `}
`;

const buyerCard = css`
  position: relative;
  background-color: white;
  border-radius: 10px;
  margin-top: 70px;
  padding: 45px 0 20px;
  width: calc(50% - 20px);
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.hague};

  &:nth-of-type(even) {
    margin-left: 40px;
  }

  ${media.desktop`
    &:nth-of-type(even) {
      margin-left: unset;
    }
    &:not(:nth-of-type(3n-2)) {
      margin-left: 3%;
    }
    width: calc((100% / 3) - 2%);
  `}
`;

const desktopCardsWrapper = css`
  display: none;
  ${media.tablet`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  `};
`;

const placeholderOffer = css`
  ${buyerCard};
  height: 450px;
  opacity: 0.1;

  &:before {
    content: "";
    position: absolute;
    top: -30px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    width: 60px;
    height: 60px;
  }
`;

const offerLoaderCard = css`
  ${placeholderOffer}
  animation: ${fade} linear 1.4s infinite;
`;

const placeholderOfferHideOnTablet = css`
  ${offerLoaderCard}
  ${media.tablet`
    display: none;
  `}
  ${media.desktop`
    display: block;
  `}
`;

const CardsLoader = () => (
  <>
    <div css={offerLoaderCard} />
    <div css={offerLoaderCard} />
    <div css={placeholderOfferHideOnTablet} />
  </>
);

const placeholderOffer1 = css`
  ${placeholderOffer}
  display: ${({ n }) => (n % 2 !== 0 ? "block" : "none")};
  ${media.desktop`
    display: ${({ n }) => (n % 3 !== 0 ? "block" : "none")};
  `}
`;

const placeholderOffer2 = css`
  ${placeholderOffer}
  display: none;
  ${media.desktop`
    display: ${({ n }) => (n % 3 === 1 ? "block" : "none")};
  `}
`;

const mobileOnly = css`
  ${media.tablet`
    display: none;
  `}
`;

// returns copy of buyerEnquiries array, but places enquiry with accepted offer at 0-th position.
const placeAcceptedEnquiryFirst = (buyerEnquiries) => {
  if (!buyerEnquiries) return [];

  const reordered = [];
  buyerEnquiries.forEach((e) => {
    const offers = e.buyerOffers;
    const anyAccepted = offers.some((offer) => offer.status === "Accepted");
    anyAccepted ? reordered.unshift(e) : reordered.push(e);
  });
  return reordered;
};
type Props = {
  confetti?: boolean,
};

export const Offers = ({ confetti = true }: Props) => {
  const { data, error, loading } = usePublicDealQuery(OFFERS_QUERY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const sm = data?.publicDeal?.sm;
  const buyerEnquiries = placeAcceptedEnquiryFirst(
    data?.publicDeal?.buyerEnquiries,
  );

  const offers = useMemo(
    () =>
      extractRenegotiatedOffers(buyerEnquiries.flatMap((b) => b.buyerOffers)),
    [buyerEnquiries],
  );

  if (error) {
    throw error;
  }

  const { heading, subheading } = getHeading(offers);

  const [showConfettiMobile, setShowConfettiMobile] = useState(false);

  useEffect(() => {
    const hasNewOffers = offers.some(isNewOffer);
    setShowConfettiMobile(hasNewOffers);
  }, [offers]);

  return (
    <LayoutWrapper dark>
      <Breadcrumb css={mobileOnly} dark name="Listing" path="/listing" />
      <div css={wrapperStyle}>
        {confetti && (
          <div css={mobileOnly}>
            <Confetti showConfetti={showConfettiMobile} />
          </div>
        )}
        <SMDetails sm={sm} loading={loading} />
        <img
          alt=""
          src={getImage("illustrations/dog-shadow.png")}
          css={moneyDogStyle}
        />
        {loading ? (
          <HeadingLoader />
        ) : (
          <>
            <h1 css={headingStyle}>{heading}</h1>
            {subheading && <h2 css={subheadingStyle}>{subheading}</h2>}
          </>
        )}
        {loading ? (
          <PlaceholderCard css={mobileOnly} />
        ) : (
          <div css={mobileOffersCard}>
            {buyerEnquiries.map((buyerEnquiry) => (
              <BuyerSummary
                sm={sm}
                buyerEnquiry={buyerEnquiry}
                key={buyerEnquiry.id}
              />
            ))}
          </div>
        )}
        <div css={desktopCardsWrapper}>
          {loading ? (
            <CardsLoader />
          ) : (
            <>
              {buyerEnquiries.map((buyerEnquiry, idx) => (
                <div
                  key={buyerEnquiry.id}
                  css={buyerCard}
                  data-test={idx === 0 && `first-buyer-enquiry`}
                >
                  <BuyerDetails
                    buyerEnquiry={buyerEnquiry}
                    confetti={confetti}
                  />
                </div>
              ))}
              <div n={buyerEnquiries.length} css={placeholderOffer1} />
              <div n={buyerEnquiries.length} css={placeholderOffer2} />
            </>
          )}
        </div>
      </div>
    </LayoutWrapper>
  );
};
