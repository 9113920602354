// @flow
import { useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPiggyBank, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext, css } from "styled-components";
import { getImage } from "@nested/utils";
import {
  SectionWrapper,
  SectionHeader,
  ImageAndTextCardContent,
  UnpaddedSectionCard,
  SectionCardPadding,
  FullWidthCardButton,
  SectionCard,
  IconAndText,
  ExampleBox,
} from "../../components/SectionCard";

const linkStyles = css`
  text-decoration: none;
`;

const exampleCardContainer = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const chevronStyles = css`
  display: block;
  padding-left: 15px;
  color: ${({ theme }) => theme.palette.hague20};
  font-size: 21px;
  line-height: 25px;
  cursor: pointer;
`;

const address = css`
  color: ${({ theme }) => theme.palette.blue150};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;

const priceRange = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;

const PROPERTY_ANALYSIS_RELEASE_DATE = moment("2019-08-20");
const AWAITING_ANALYSIS = "awaitingAnalysis";
const FIRST_IMPRESSIONS = "firstImpressions";
const ANALYSIS_AVAILABLE = "analysisAvailable";
const LEGACY_CUSTOMER = "legacyCustomer";

const isListedDateBeforeRelase = (dateListed) => {
  if (!dateListed) return false;
  const momentListDate = moment(dateListed);
  return momentListDate.isBefore(PROPERTY_ANALYSIS_RELEASE_DATE);
};

const getPropertyAnalysisStatus = (deal) => {
  if (!deal) return FIRST_IMPRESSIONS;
  const {
    dateListed,
    isListed,
    opportunityStatusValue,
    settings: { showPropertyAnalysis },
  } = deal;
  // always show property analysis if has been toggled on
  if (showPropertyAnalysis) return ANALYSIS_AVAILABLE;
  // customer is pre-close
  if (!dateListed && opportunityStatusValue !== "s07_closed")
    return FIRST_IMPRESSIONS;
  // closed or listed
  if (opportunityStatusValue === "s07_closed" || isListed)
    return AWAITING_ANALYSIS;
  // customer listed before release
  if (isListedDateBeforeRelase(dateListed)) return LEGACY_CUSTOMER;
  // idk something I missed
  return AWAITING_ANALYSIS;
};

const getPropertyAnalysisCard = (status) => {
  const theme = useContext(ThemeContext);

  switch (status) {
    case ANALYSIS_AVAILABLE:
      return (
        <SectionWrapper data-test="nav-card:property-analysis">
          <SectionHeader>Property analysis</SectionHeader>
          <UnpaddedSectionCard>
            <SectionCardPadding>
              <ImageAndTextCardContent
                largeText
                text="Get a personalised analysis of your home and local area to help you get off to the best possible start"
                imageSrc={getImage("your-account/property-analysis.png")}
                imageAlt="Property analysis"
                circleColor={theme.palette.blue20}
              />
            </SectionCardPadding>
            <FullWidthCardButton
              buttonText="Read yours"
              path="/listing/strategy/property-analysis"
            />
          </UnpaddedSectionCard>
        </SectionWrapper>
      );
    case FIRST_IMPRESSIONS:
      return (
        <SectionWrapper data-test="nav-card:property-analysis">
          <SectionHeader>Property analysis</SectionHeader>
          <SectionCard>
            <IconAndText
              icon={faPiggyBank}
              text="Find out how much your home is worth and how to price it strategically."
            />
            <Link
              to="/listing/strategy/property-analysis?example=true"
              css={linkStyles}
            >
              <ExampleBox css={exampleCardContainer}>
                <div>
                  <div css={address}>123 Example Street</div>
                  <div css={priceRange}>£500,000 - £535,000</div>
                </div>
                <FontAwesomeIcon icon={faChevronRight} css={chevronStyles} />
              </ExampleBox>
            </Link>
          </SectionCard>
        </SectionWrapper>
      );
    case AWAITING_ANALYSIS:
      return (
        <SectionWrapper data-test="nav-card:property-analysis">
          <SectionHeader>Property analysis</SectionHeader>
          <SectionCard>
            <ImageAndTextCardContent
              largeText
              text="Your personalised analysis of your home and local area will appear here"
              imageSrc={getImage("your-account/property-analysis.png")}
              imageAlt="Property analysis"
              circleColor={theme.palette.blue20}
            />
          </SectionCard>
        </SectionWrapper>
      );
    case LEGACY_CUSTOMER:
      return null;
    default:
      return null;
  }
};

type Props = {
  deal: ?ListingSectionQuery_publicDeal,
};

export const PropertyAnalysisCard = ({ deal }: Props) => {
  const propertyAnalysisStatus = getPropertyAnalysisStatus(deal);
  return getPropertyAnalysisCard(propertyAnalysisStatus);
};
