// @flow
import styled from "styled-components";
import { getImage } from "@nested/utils";

const FormError = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 612px;
  background-color: #d72c41;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  line-height: 44px;
  padding: 10px 20px 10px 60px;
  margin: 50px auto 30px auto;
  background-image: url("${getImage("icons/form_error.svg")}");
  background-repeat: no-repeat;
  background-position: 20px center;
`;

export { FormError };
