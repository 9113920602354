// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { mediumParagraph } from "@nested/brand";
import { BuyerAvatarSmall } from "./BuyerAvatar/BuyerAvatar";

const buyerWrapper = css`
  align-items: top;
  background-color: unset;
  border: none;
  display: flex;
  padding: 0;
  text-align: left;
  width: 100%;
  cursor: pointer;
`;

const chevronStyles = css`
  display: none;

  @media (min-width: ${350 / 16}em) {
    display: block;
    padding: 20px 0 0 15px;
    color: ${({ theme }) => theme.palette.hague20};
    font-size: 21px;
    line-height: 25px;
    cursor: pointer;
  }
`;

const enquiryDetailsCard = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
  padding: 0;
`;

const buyerName = css`
  padding: 0;
  ${mediumParagraph}
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue150};
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  flex-grow: 1;
`;

const notInterested = css`
  color: ${({ theme }) => theme.palette.hague40};
`;

const notInterestedText = css`
  color: ${({ theme }) => theme.palette.terracotta500};
`;

const NotInterestedLabel = () => (
  <span css={notInterested}>
    {" "}
    - <span css={notInterestedText}>Not interested</span>
  </span>
);

type Props = {
  children: React$Node,
  buyer: {
    +id: string,
    +firstName: string,
    +surnameInitial: string,
    +financialPosition: ?string,
    +buyerPosition: ?string,
    +avatarUrl: string,
  },
  itemId: number | string,
  onClick: () => void,
  withTickIcon?: boolean,
  withCrossIcon?: boolean,
  anonymisedAvatar?: boolean,
  showNotInterestedLabels?: boolean,
  buyerCurrentlyInterested?: ?boolean,
};

export const BuyerListItem = ({
  children,
  buyer,
  withTickIcon,
  withCrossIcon,
  anonymisedAvatar,
  itemId,
  onClick,
  showNotInterestedLabels,
  buyerCurrentlyInterested,
}: Props) => {
  const { avatarUrl, firstName, surnameInitial } = buyer;

  return (
    <button
      onClick={onClick}
      css={buyerWrapper}
      data-test={`buyers-list-item-${itemId}`}
    >
      <BuyerAvatarSmall
        withTick={withTickIcon}
        withCross={withCrossIcon}
        avatarUrl={avatarUrl}
        anonymised={anonymisedAvatar}
      />
      <div css={enquiryDetailsCard}>
        <div>
          <p css={buyerName}>
            {firstName} {surnameInitial}
            {showNotInterestedLabels && !buyerCurrentlyInterested && (
              <NotInterestedLabel />
            )}
          </p>
          {children}
        </div>
        <FontAwesomeIcon icon={faChevronRight} css={chevronStyles} />
      </div>
    </button>
  );
};
