// @flow
import styled from "styled-components";
import { Milestone } from "./Milestone";
import { Call } from "./Call";
import { SMS } from "./SMS";
import { EmailThread } from "./EmailThread";
import { Note } from "./Note";

const TimelineLine = styled.div`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    width: 2px;
    border-left: 2px solid ${({ theme }) => theme.palette.hague20};
    top: 36px;
    bottom: 45px;
    left: 12px;
  }
`;

type TimelineEvent =
  | {
      __typename: "SaleActivityMilestone",
      id: string,
      note: ?{
        nestedUser: {
          email: string,
          firstName?: string,
        },
        text: string,
      },
      title: string,
      timestamp: string,
    }
  | {
      __typename: "SaleActivityCall",
      id: string,
      nestedUser: {
        email: string,
        firstName?: string,
      },
      note: ?{
        text: string,
      },
      recipient: string,
      timestamp: string,
    }
  | {
      __typename: "SaleActivityEmailThread",
      id: string,
      subject: string,
      emails: Array<{
        id: string,
        senderEmail: string,
        senderName: string,
        snippet: string,
        sentAt: string,
      }>,
    }
  | {
      __typename: "SaleActivityNote",
      id: string,
      nestedUser: {
        email: string,
        firstName?: string,
      },
      text: string,
      timestamp: string,
    }
  | {
      __typename: "SaleActivitySms",
      id: string,
      nestedUser: {
        email: string,
        firstName?: string,
      },
      recipient: string,
      smsBody: string,
      timestamp: string,
    };

// TODO: This should ultimately also handle the private events from the
// `nest/src/components/RightSidePanel/Timeline` queries, deferring that for
// now while I (@seddy) migrate this all across
export const Timeline = ({
  events,
  fetchEmail,
  className,
}: {
  events: Array<TimelineEvent>,
  fetchEmail: (string) => Promise<any>,
  className?: string,
}) => (
  <TimelineLine className={className}>
    {events.map((event, i) => {
      if (event.__typename === "SaleActivityMilestone")
        return (
          <Milestone
            key={i}
            id={event.id}
            note={event.note}
            timestamp={event.timestamp}
            title={event.title}
          />
        );

      if (event.__typename === "SaleActivityCall")
        return (
          <Call
            key={i}
            id={event.id}
            nestedUser={event.nestedUser}
            note={event.note}
            recipient={event.recipient}
            timestamp={event.timestamp}
          />
        );

      if (event.__typename === "SaleActivitySms")
        return (
          <SMS
            key={i}
            id={event.id}
            nestedUser={event.nestedUser}
            recipient={event.recipient}
            smsBody={event.smsBody}
            timestamp={event.timestamp}
          />
        );

      if (event.__typename === "SaleActivityEmailThread")
        return (
          <EmailThread
            key={i}
            id={event.id}
            emails={event.emails}
            fetchEmail={fetchEmail}
            subject={event.subject}
          />
        );

      if (event.__typename === "SaleActivityNote")
        return (
          <Note
            key={i}
            id={event.id}
            text={event.text}
            timestamp={event.timestamp}
            userEmail={event.nestedUser?.email}
            userFirstName={event.nestedUser?.firstName}
          />
        );

      return null;
    })}
  </TimelineLine>
);
