// @flow
import { gql } from "@apollo/client";
import { Redirect } from "react-router-dom";
import { PublicDealQuery } from "../../components/AuthenticatedQuery";
import { PageWrapper } from "../PageWrapper";

export const DEAL_QUERY = gql`
  query ListingSectionQuery($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      externalDealId
      currentListingPrice
      dateListed
      opportunityStatusValue
      isListed
      opportunityStatusValue
      hasViewings
      property {
        type
        postcode
      }
      settings {
        showPropertyAnalysis
      }
      sm {
        actionPlanReviewBookingUrl
      }
    }
  }
`;

export const Listing = () => (
  <PageWrapper>
    <PublicDealQuery query={DEAL_QUERY}>
      {({ data }) => {
        if (data?.publicDeal?.isListed && data?.publicDeal?.hasViewings) {
          return <Redirect to="/listing/viewings" />;
        }
        if (data?.publicDeal?.isListed) {
          return <Redirect to="/listing/interest" />;
        }

        return <Redirect to="/listing/viewings" />;
      }}
    </PublicDealQuery>
  </PageWrapper>
);
