// @flow
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInView } from "react-intersection-observer";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { Button, SmartLink } from "@nested/component-library";
import { CircleImage } from "./CircleImage";

const ImageAndText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  ${media.tablet`
    flex-direction: row;
    padding: 0;
  `}
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  width: 260px;
  margin: 20px 0 0;
  ${media.tablet`
    margin: 0 0 0 40px;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
  `}
`;

const exampleCapsuleStyles = css`
  background-color: ${({ theme }) => theme.palette.attentionRed};
  color: white;
  border-radius: 5px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  padding: 1px 7px;
  display: inline-block;
  margin-bottom: 10px;
  ${media.desktop`
    margin-bottom: 15px;
  `}
`;

export const ExampleCapsule = ({ className }: { className?: string }) => (
  <div className={className} css={exampleCapsuleStyles}>
    Example
  </div>
);

export const ExampleCapsuleForModal = styled(ExampleCapsule)`
  position: absolute;
  top: -11px;
  left: 15px;
`;

const sharedExampleBannerStyle = css`
  position: sticky;
  top: 0;
  margin: -15px 0 -18px 0;
  padding: 15px 0 15px 15px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  ${media.tablet`
    padding: 15px 0 15px 30px;
  `}
  ${media.desktop`
    padding: 15px 0 15px 3.33vw;
  `}
  z-index: 10;
  transition: border-bottom 200ms linear;
  transition: background 200ms linear;
`;
const stickyExampleBannerStyle = css`
  ${sharedExampleBannerStyle};
  background: rgba(255, 255, 255, 0.95);
  border-bottom: ${({ theme }) => theme.palette.hague20} 2px solid;
`;

const hiddenExampleBannerStyle = css`
  ${sharedExampleBannerStyle};
  background: rgba(255, 255, 255, 0);
  border-bottom: transparent solid;
`;

const exampleBannerHeadingStyle = css`
  opacity: ${({ inView }) => (inView ? "0" : "1")};
  transition: opacity 200ms linear;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  padding-left: 10px;
`;

export const StickyExampleBanner = ({ text }: { text: string }) => {
  const [ref, inView] = useInView({ initialInView: true });

  return (
    <>
      <div ref={ref}></div>
      <div css={inView ? hiddenExampleBannerStyle : stickyExampleBannerStyle}>
        <ExampleCapsule css="margin-bottom: 0;" />
        <span css={exampleBannerHeadingStyle} inView={inView}>
          {text}
        </span>
      </div>
    </>
  );
};

export const ExampleBox = styled.div`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.hague20};
  padding: 20px 15px;
  margin-top: 30px;
  position: relative;
  &:before {
    content: "Example";
    background-color: ${({ theme }) => theme.palette.attentionRed};
    color: white;
    border-radius: 5px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: -11px;
    padding: 1px 7px;
  }
`;

const iconAndTextWrapper = css`
  display: flex;
  align-items: center;
`;

const iconStyles = css`
  color: ${({ theme }) => theme.palette.terracotta100};
  margin-right: 16px;
  font-size: 18px;
  line-height: 21px;
`;

const infoTextWithIcon = css`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague80};
  margin: 0;
`;

type IconAndTextProps = {
  text: string,
  icon: any,
  className?: string,
};

export const IconAndText = ({ icon, text, className }: IconAndTextProps) => (
  <div css={iconAndTextWrapper} className={className}>
    <FontAwesomeIcon icon={icon} css={iconStyles} />
    <p css={infoTextWithIcon}>{text}</p>
  </div>
);

type ImageAndTextProps = {
  imageSrc: string,
  imageAlt: string,
  text: ?string,
  circleColor: string,
  largeText?: boolean,
};

export const ImageAndTextCardContent = ({
  imageSrc,
  imageAlt,
  text,
  circleColor,
  largeText,
}: ImageAndTextProps) => (
  <ImageAndText>
    <CircleImage
      circleColor={circleColor}
      imageAlt={imageAlt}
      imageSrc={imageSrc}
    />
    {text && <Text largeText={largeText}>{text}</Text>}
  </ImageAndText>
);

export const SectionWrapper = styled.div`
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const SectionHeader = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 20px;
  ${media.tablet`
    font-size: 21px;
  `}
`;

export const SectionHeaderSubtext = styled.p`
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague70};
`;

export const SectionDescription = styled.div`
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 20px;
  ${media.tablet`
    font-size: 16px;
  `}
`;

export const UnpaddedSectionCard = styled.div`
  border-radius: 5px;
  box-shadow: 1px 4px 10px 1px rgba(53, 63, 82, 0.1);
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  ${media.tablet`
    font-size: 16px;
  `}
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const SectionCardPadding = styled.div`
  padding: 15px;
  ${media.tablet`
    padding: 30px;
  `}
`;

export const SectionCard = styled(UnpaddedSectionCard)`
  padding: 15px;
  ${media.tablet`
    padding: 30px;
  `}
`;

export const SectionDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.hague20};
  border-width: 0;
  color: ${({ theme }) => theme.palette.hague20};
  height: 1px;
  margin: 15px 0;
`;

const optionalSectionLinkStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const linkTextStyles = css`
  color: ${({ theme, disabled }) =>
    disabled ? "#9CB3BC" : theme.palette.blue100};
  font-size: 16px;
  font-weight: 500;
  display: block;
  ${media.tablet`
    margin-top: 21px;
    margin-left: 30px;
  `}
`;

const sectionLinkStyles = css`
  color: ${({ theme, disabled }) =>
    disabled ? "#9CB3BC" : theme.palette.blue100};
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const arrowIconStyles = css`
  color: ${({ theme }) => theme.palette.hague20};
  font-size: 21px;
`;

type Props = {
  path: string,
  displayName: string,
};

export const OptionalSectionLink = ({ path, displayName }: Props) => {
  return (
    <div css={optionalSectionLinkStyles}>
      <SmartLink to={path} css={sectionLinkStyles}>
        {displayName}
      </SmartLink>
      <FontAwesomeIcon icon={faAngleRight} css={arrowIconStyles} />
    </div>
  );
};

const fullWidthButton = css`
  display: block;
  text-align: center;
  min-height: 51px;
  text-decoration: none;
  ${media.tablet`
    min-height: 62px;
    text-align: left;
  `}
`;

const halfWidthButton = css`
  ${fullWidthButton}
  border-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  line-height: 21px;
  padding: 15px 0;
  vertical-align: middle;
  width: 50%;
  &:first-child {
    border-right: ${({ theme }) => theme.palette.hague20} solid 1px;
  }
  ${media.tablet`
    padding: 0;
    min-height: 62px;
    vertical-align: top;
    width: auto;
    &:first-of-type {
      border: none;
    }
  `}
`;

const buttonGroup = css`
  button {
    display: inline-flex;
    justify-content: center;
  }
`;

const DividerNoTopMargin = styled(SectionDivider)`
  margin: 0 0 15px;
`;

const DividerNoMargin = styled(SectionDivider)`
  margin: 0;
`;

type CardButtonProps = {
  buttonText: string,
  path: string,
  disabled?: boolean,
};

export const FullWidthCardButton = ({
  buttonText,
  path,
  disabled,
}: CardButtonProps) => {
  return disabled ? (
    <div css={fullWidthButton}>
      <DividerNoTopMargin />
      <span css={linkTextStyles} disabled={disabled}>
        {buttonText}
      </span>
    </div>
  ) : (
    <SmartLink to={path} disabled={disabled} css={fullWidthButton}>
      <DividerNoTopMargin />
      <span css={linkTextStyles}>{buttonText}</span>
    </SmartLink>
  );
};

type HalfWidthButtonsProps = {
  leftButtonText: string,
  leftButtonPath?: string,
  leftButtonClickHandler?: () => void,
  rightButtonText: string,
  rightButtonPath?: string,
  rightButtonClickHandler?: () => void,
};

export const HalfWidthCardButtons = ({
  leftButtonText,
  leftButtonPath,
  leftButtonClickHandler,
  rightButtonText,
  rightButtonPath,
  rightButtonClickHandler,
}: HalfWidthButtonsProps) => (
  <>
    <DividerNoMargin />
    <div css={buttonGroup}>
      {leftButtonClickHandler && (
        <Button
          onClick={leftButtonClickHandler}
          css={halfWidthButton}
          type="link"
        >
          <span css={linkTextStyles}>{leftButtonText}</span>
        </Button>
      )}
      {leftButtonPath && (
        <SmartLink to={leftButtonPath} disabled={false} css={halfWidthButton}>
          <span css={linkTextStyles}>{leftButtonText}</span>
        </SmartLink>
      )}
      {rightButtonClickHandler && (
        <Button
          onClick={rightButtonClickHandler}
          css={halfWidthButton}
          type="link"
        >
          <span css={linkTextStyles}>{rightButtonText}</span>
        </Button>
      )}
      {rightButtonPath && (
        <SmartLink to={rightButtonPath} disabled={false} css={halfWidthButton}>
          <span css={linkTextStyles}>{rightButtonText}</span>
        </SmartLink>
      )}
    </div>
  </>
);
