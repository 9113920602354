import styled, { css } from "styled-components";
import { media } from "@nested/brand";

const sharedStyles = css`
  margin: 0px;
  color: ${({ theme }) => theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.striverText};
  font-weight: 700;
  font-size: 36px;
  line-height: 43.2px;
`;

export const H1 = styled.h1`
  ${sharedStyles}
`;

export const PageHeader = styled(H1)`
  padding-bottom: 40px;
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

export const VariablePageHeader = styled.h1`
  margin: 0px;
  padding: 30px 15px;
  font-size: 18px;
  font-weight: 500;
  ${media.tablet`
    padding: 0 0 30px;
    font-size: 21px;
  `}

  ${media.desktop`
    ${sharedStyles}
    padding-bottom: 40px;
  `}
`;

export const StandardPageHeader = styled.h1`
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  margin: 0;

  ${media.tablet`
    font-size: 21px;
    line-height: 28px;
  `}

  ${media.desktop`
    ${sharedStyles}
  `}
`;
