import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { getConfig } from "@nested/config";
import { useDeal } from "../../hooks/useDeal";
import { useAuthenticatedQuery } from "../../hooks/useAuthenticatedQuery";
import { ADMIN_DEAL_QUERY } from "./DealProvider";

const { NEST_URI } = getConfig();

const banner = css`
  box-sizing: border-box;
  position: relative;
  margin: 0;
  width: 100%;
  text-align: center;
  background-color: #ff9f8d;
  display: block;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover,
  &:focus {
    background-color: #ffb7a9;
  }
`;

export const AdminBanner = ({ className }) => {
  const { customerMode, dealId } = useDeal();
  const { data } = useAuthenticatedQuery(ADMIN_DEAL_QUERY, {
    variables: {
      dealId,
    },
    skip: !customerMode,
  });

  const address = data?.publicDeal?.property?.formattedAddress;

  if (!address) {
    return null;
  }

  return (
    <a
      className={className}
      href={`${NEST_URI}/deals/${dealId}`}
      target="_blank"
      css={banner}
      data-test="admin-banner"
    >
      Customer mode: {address}
      <FontAwesomeIcon
        icon={faCaretRight}
        css="font-size: 16px; padding-bottom: 3px; padding-left: 4px; vertical-align: middle;"
      />
    </a>
  );
};
