// @flow
import styled from "styled-components";

const Image = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 119px;
  width: 119px;
  position: relative;
`;

const Circle = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  height: 119px;
  width: 119px;:
`;

const ImageOverlay = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 119px;
  width: 119px;
`;

type Props = {
  imageSrc: string,
  imageAlt: string,
  circleColor: string,
};

export const CircleImage = ({ imageSrc, imageAlt, circleColor }: Props) => (
  <Image>
    <Circle color={circleColor} />
    <ImageOverlay alt={imageAlt} src={imageSrc} />
  </Image>
);
