// @flow
import { useMemo } from "react";
import { css } from "styled-components";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faChartArea,
  faChess,
  faCogs,
  faComments,
  faEnvelopeOpenText,
  faMapSigns,
  faMask,
  faCheckSquare,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { useAuth } from "@nested/auth";
import { NestedLogo } from "../../Icons";
import { AdminBanner } from "../DealProvider/AdminBanner";
import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { OFFERS_QUERY } from "../../pages/Offers/Offers";
import {
  extractRenegotiatedOffers,
  isActiveOffer,
} from "../../pages/Offers/utils";
import type { Offer } from "../../pages/Offers/Offers";

const sideBarLogoStyle = css`
  height: 34px;
  width: 30px;
`;

const sideBarMenuStyle = css`
  width: 300px;
  min-height: calc(100vh);
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.hague};

  padding: 0;

  ${media.desktop`
    width: 25vw;
    max-width: 450px;
  `}
`;

const sideBarItemsContainer = css`
  padding: 40px 40px 20px 30px;

  ${media.desktop`
    padding: 40px 2.5vw 0; 
  `}
`;

const sideBarBubbleNumber = css`
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
`;

const bubbleStyle = css`
  border: ${({ noBorder }) => (noBorder ? "none;" : "1px solid #ffffff;")}
  border-radius: 100%;
  width: 24px;
  height: 24px;

  text-align: center;
  line-height: 13px;

  background-color: ${({ theme }) => theme.palette.terracotta100};
`;
const sideBarSubMenuHeadingStyle = css`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 17.75px;
  text-transform: uppercase;
`;

const sideBarSubMenuItemList = css`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  margin-bottom: 10px;
`;

const sideBarSubMenuItemLabel = css`
  color: ${({ current, textColor, theme }) =>
    current ? theme.palette.terracotta100 : textColor};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  margin-left: 16px;

  align-self: center;
  vertical-align: middle;
`;

const sideBarSubMenuItemIcon = css`
  color: ${({ current, theme }) =>
    current ? theme.palette.terracotta100 : "#9db3bb"};
  font-size: 24px;

  width: 30px;
  height: 24px;

  text-align: center;
  display: inline-block;

  vertical-align: middle;
`;

const sideBarSubMenuItemLink = css`
  flex-grow: 1;
`;

const tabUnderlineStyles = css`
  border-bottom: ${({ current, theme, isTab }) => {
    if (isTab && current) {
      return `2px solid ${theme.palette.terracotta100}`;
    }
    if (isTab) {
      return `2px solid ${theme.palette.hague20}`;
    }
    return "none";
  }};
`;

const sideBarSubMenuStyle = css`
  display: flex;

  padding: ${({ noPad }) => (!noPad ? "20px 0px 0px 0px;" : "0px;")};
  ${tabUnderlineStyles}
`;

const desktopOnly = css`
  display: none;
  ${media.desktop`
    display: block;
  `}
`;

type SubMenuItem = {
  label: string,
  icon: string,
  dataTest?: string,
  to?: string,
  noPad?: boolean,
  notifications?: null | number | string,
  textColor?: string,
  isTab?: ?boolean,
  onClick?: () => void,
};

const SideBarMenuItem = ({
  label,
  icon,
  dataTest = "",
  to = "",
  noPad = false,
  notifications = null,
  textColor = "#ffffff",
  isTab = false,
  onClick,
}: SubMenuItem) => {
  const current = Boolean(useRouteMatch(to));

  return (
    <div
      isTab={isTab}
      current={current}
      css={sideBarSubMenuStyle}
      noPad={noPad}
    >
      <Link
        to={{ pathname: to, state: { noScroll: true } }}
        css={sideBarSubMenuItemLink}
        data-test={`nav-side-bar-${dataTest}`}
        onClick={onClick}
      >
        <span css={sideBarSubMenuItemIcon} current={current}>
          <FontAwesomeIcon icon={icon} />
        </span>
        <span
          css={sideBarSubMenuItemLabel}
          current={current}
          textColor={textColor}
        >
          {label}
        </span>
      </Link>
      {notifications !== null ? (
        <span css={bubbleStyle} noBorder>
          <span css={sideBarBubbleNumber}>{notifications}</span>
        </span>
      ) : null}
    </div>
  );
};

const SideBarSubMenu = ({ label = null, children }) => {
  return (
    <div css={"margin: 40px 0px 0px 0px;"}>
      {label ? <p css={sideBarSubMenuHeadingStyle}>{label}</p> : null}
      <div css={sideBarSubMenuItemList}>{children}</div>
    </div>
  );
};

type SubMenuProps = {
  textColor?: string,
  isTab?: ?boolean,
  onItemClick?: () => void,
};

export const ListingSubMenuItems = ({
  textColor,
  isTab,
  onItemClick,
  offers,
}: SubMenuProps & { offers?: Offer[] }) => (
  <>
    <SideBarMenuItem
      isTab={isTab}
      label="Strategy"
      icon={faChess}
      textColor={textColor}
      to="/listing/strategy"
      dataTest="strategy-link"
      onClick={onItemClick}
    />
    <SideBarMenuItem
      isTab={isTab}
      label="Interest"
      icon={faChartArea}
      textColor={textColor}
      to="/listing/interest"
      dataTest="interest-link"
      onClick={onItemClick}
    />
    <SideBarMenuItem
      isTab={isTab}
      label="Viewings"
      icon={faUsers}
      textColor={textColor}
      to="/listing/viewings"
      dataTest="viewings-link"
      onClick={onItemClick}
    />
    {offers && offers.length > 0 && (
      <SideBarMenuItem
        label="Offers"
        icon={faEnvelopeOpenText}
        notifications={offers.filter(isActiveOffer).length}
        to="/listing/offers"
        onClick={onItemClick}
      />
    )}
    <SideBarMenuItem
      isTab={isTab}
      label="Guides"
      icon={faMapSigns}
      textColor={textColor}
      to="/listing/guides"
      dataTest="buyer-guides-link"
      onClick={onItemClick}
    />
  </>
);

export const SaleSubMenuItems = ({
  textColor,
  isTab,
  onItemClick,
}: SubMenuProps) => {
  return (
    <>
      <SideBarMenuItem
        isTab={isTab}
        label="Status"
        icon={faCheckSquare}
        textColor={textColor}
        to="/sale/status"
        dataTest="sale-status"
        onClick={onItemClick}
      />
      <SideBarMenuItem
        isTab={isTab}
        label="Activity"
        icon={faComments}
        textColor={textColor}
        to="/sale/activity"
        dataTest="activity"
        onClick={onItemClick}
      />
    </>
  );
};

const SettingsSubMenu = ({ textColor, onItemClick }: SubMenuProps) => {
  const { admin, demoModeEnabled } = useAuth();
  const location = useLocation();

  return (
    <SideBarSubMenu label="Settings">
      <SideBarMenuItem
        label="Account settings"
        icon={faCogs}
        textColor={textColor}
        to="/settings/account-details"
        dataTest="settings-link"
        onClick={onItemClick}
      />
      {admin && !demoModeEnabled && (
        <SideBarMenuItem
          label="Demo mode"
          icon={faMask}
          textColor={textColor}
          to="#"
          dataTest="settings-link"
          onClick={() => {
            window.location = `${location.pathname}?demo=true`;
          }}
        />
      )}
    </SideBarSubMenu>
  );
};

type MenuProps = {
  notifications: ?number,
  onItemClick?: () => void,
};

export const SideBarMenu = ({
  notifications,
  onItemClick = () => {},
}: MenuProps) => {
  const { data } = usePublicDealQuery(OFFERS_QUERY);
  const buyers = data?.publicDeal?.buyerEnquiries || [];

  const offers = useMemo(
    () => extractRenegotiatedOffers(buyers.flatMap((b) => b.buyerOffers)),
    [buyers],
  );

  return (
    <div css={sideBarMenuStyle}>
      <AdminBanner css={desktopOnly} />
      <div css={sideBarItemsContainer}>
        <Link
          to="/"
          onClick={onItemClick}
          data-test="side-bar-nested-logo-home-link"
        >
          <NestedLogo css={sideBarLogoStyle} />
        </Link>
        {/* Hiding notifications until feature built out */}
        {false && (
          <SideBarSubMenu>
            <SideBarMenuItem
              label="Notifications"
              icon={faBell}
              notifications={notifications}
              to="/notifications"
              dataTest="notifications-link"
              onClick={onItemClick}
            />
          </SideBarSubMenu>
        )}
        <SideBarSubMenu label="Listing">
          <ListingSubMenuItems onItemClick={onItemClick} offers={offers} />
        </SideBarSubMenu>
        <SideBarSubMenu label="Sale">
          <SaleSubMenuItems onItemClick={onItemClick} />
        </SideBarSubMenu>
        <SettingsSubMenu onItemClick={onItemClick} />
      </div>
    </div>
  );
};
