// @flow
import moment from "moment-timezone";
import { css } from "styled-components";
import {
  Modal,
  largeParagraph,
  mediumParagraph,
  smallParagraph,
  Gravatar,
} from "@nested/brand";
import { ModalAvatar } from "../../../components/BuyerAvatar/BuyerAvatar";
import { LI, UL } from "../../../components/UnorderedList";
import { ExampleCapsuleForModal } from "../../../components/SectionCard";

export const virtualViewingDot = css`
  background-color: ${({ theme }) => theme.palette.blue150};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 3px;
  position: relative;
  vertical-align: middle;
  &::after {
    content: "v";
    position: absolute;
    left: 4px;
    top: -1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: lowercase;
    letter-spacing: 0;
    color: white;
  }
`;

const virtualViewingBadge = css`
  ${virtualViewingDot}
  position: absolute;
  top: -39px;
  left: calc(50% + 14px);
  width: 25px;
  height: 25px;
  margin: 0;
  &::after {
    font-size: 24px;
    left: 6px;
    top: 3px;
  }
`;

const modalStyle = css`
  max-width: 315px;
  padding: 45px 20px 30px;
`;

const buyerNameStyle = css`
  ${largeParagraph}
  text-align: center;
  font-weight: 500;
  line-height: 24px;
`;

const viewingTimeStyle = css`
  ${mediumParagraph}
  color: ${({ theme }) => theme.palette.hague70};
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

const saWrapper = css`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const hostedByStyle = css`
  ${smallParagraph};
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 21px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague70};
`;

const gravatarStyle = css`
  flex-grow: 0;
  flex-shrink: 1;
  width: 50px;
  margin: 0px;
  margin-right: 15px;
`;

const saName = css`
  ${smallParagraph};
  line-height: 21px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague130};
`;

const formatStartTime = (timestamp) =>
  moment(timestamp).calendar(moment(), {
    sameDay: "[today at] HH:mm",
    nextDay: "[tomorrow at] HH:mm",
    lastDay: "[yesterday]",
    nextWeek: "Do MMM [at] HH:mm",
    lastWeek: "Do MMM [at] HH:mm",
    sameElse: "Do MMM [at] HH:mm",
  });

export const formatViewingNumber = (n: number) => {
  switch (n) {
    case 1:
      return "First";
    case 2:
      return "Second";
    case 3:
      return "Third";
    case 4:
      return "Fourth";
    case 5:
      return "Fifth";
    default:
      return moment.localeData().ordinal(n);
  }
};

type Props = {
  open: boolean,
  onClose(): void,
  next?: ?() => void,
  previous?: ?() => void,
  exampleMode?: boolean,
  viewing: {
    formattedTime?: string,
    day?: string,
    ...UpcomingViewingDetails_publicDeal_buyerEnquiries_viewings,
    buyer: UpcomingViewingDetails_publicDeal_buyerEnquiries_buyer,
  },
};

export const UpcomingViewingModal = ({
  open,
  onClose,
  viewing,
  next,
  previous,
  exampleMode,
}: Props) => (
  <Modal
    data-test={`upcoming-viewing-modal-${viewing.buyer.id}`}
    next={next}
    previous={previous}
    open={open}
    onClose={onClose}
    css={modalStyle}
    simple
  >
    <div>
      <ModalAvatar avatarUrl={viewing.buyer.avatarUrl} displayOnDesktop />
      {exampleMode && <ExampleCapsuleForModal />}
      {viewing.virtual && <div css={virtualViewingBadge}></div>}

      <div css={buyerNameStyle}>
        {viewing.buyer.firstName} {viewing.buyer.surnameInitial}
      </div>
      <div css={viewingTimeStyle}>
        {viewing.virtual ? "Virtual viewing" : "Viewing"}{" "}
        {formatStartTime(viewing.dateTimeStart)}
      </div>
      {viewing.conductor === "sales_associate" && (
        <div css={saWrapper}>
          <Gravatar css={gravatarStyle} email={viewing.viewingSaEmail} />
          <div css="flex-grow: 1; flex-shrink: 0;">
            <div css={hostedByStyle}>Viewing hosted by</div>
            <div css={saName}>{viewing.viewingSaName}</div>
          </div>
        </div>
      )}
      <UL>
        <LI>{formatViewingNumber(viewing.number)} viewing</LI>
        {viewing.buyer.buyerPosition && <LI>{viewing.buyer.buyerPosition}</LI>}
        {viewing.buyer.financialPosition && (
          <LI>{viewing.buyer.financialPosition}</LI>
        )}
      </UL>
    </div>
  </Modal>
);
