// @flow
import { useState, useEffect } from "react";
import moment from "moment";
import { media, PlaceholderCard, smallParagraph } from "@nested/brand";
import styled, { css } from "styled-components";
import { getImage } from "@nested/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button as OriginalButton } from "@nested/component-library";
import {
  SectionHeader,
  SectionDescription,
  ExampleCapsule,
} from "../../components/SectionCard";
import { TimeSlotButton } from "./TimeSlotButton";
import { Breadcrumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Heading";
import { CustomHeroSection } from "../../components/HeroSection/HeroSection";
import { MainContent, LayoutWrapper } from "../PageWrapper";
import { DayOfWeekPicker } from "./DayOfWeekPicker";

const PageWrapper = styled.div`
  ${media.tablet`
    padding: 10px 30px;
  `}

  ${media.desktop`
    padding: 10px 3.33vw;
  `}
`;

const ContentWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  padding: 30px 15px;
  ${media.tablet`
    padding: 0 0 30px 0;
  `}
  &:last-of-type {
    border-bottom: initial;
  }
`;

const headerExtraStyles = css`
  ${media.desktop`
    display: none;
  `}
`;

const descriptionExtraStyles = css`
  ${media.tablet`
    padding: 10px 0 30px 0;
  `}
  ${media.desktop`
    padding: 0 0 30px 0;
  `}
`;

const dayOfWeekStyles = css`
  ${media.tablet`
    margin-top: 30px;
  `}
`;

const Button = styled(OriginalButton)`
  :disabled {
    background-color: unset;
    cursor: not-allowed;

    :hover {
      background-color: unset;
    }
  }
`;

const slotsGrid = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const exampleText = css`
  ${smallParagraph}
  margin: 0;
  color: ${({ theme }) => theme.palette.attentionRed};
  font-weight: 500;
`;

const savingIndicatorWrapper = css`
  float: right;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.hague50};
  opacity: 0;
  visibility: hidden;
  ${({ saving }) =>
    saving
      ? css`
          opacity: 1;
          visibility: visible;
          transition: opacity 200ms ease, visibility 200ms ease;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `};
`;

const exampleTextContainer = css`
  margin: 10px 10px 0;
  ${media.tablet`
    margin: 10px 0;
  `}
`;

const SavingIndicator = ({ called, saving }) => {
  if (!called) {
    return null;
  }
  if (saving) {
    return (
      <div css={savingIndicatorWrapper}>
        Saving
        <FontAwesomeIcon css="margin-left: 5px;" icon={faSpinner} spin />
      </div>
    );
  }
  return (
    <div css={savingIndicatorWrapper}>
      Saved
      <FontAwesomeIcon css="margin-left: 5px;" icon={faCheck} />
    </div>
  );
};

const multiSelectButtons = css`
  display: flex;
  justify-content: space-between;
  button {
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }
`;

const viewingAvailabilityHeroContent = {
  heroIcon: getImage("your-account/viewings-tab/telescope-stars.png"),
  heroBackgroundColor: "yellow40",
  heroHeading: "Set your viewing schedule",
  heroDescription:
    "We’ll always give you 24 hours notice before a viewing, but you can let us know exactly what hours work best for you.",
};

const formatDay = (day: ?number) =>
  day ? moment().isoWeekday(day).format("dd").slice(0, 1) : null;

const formatTime = (time) => moment(time, "HH:mm:ss").format("ha"); // 4pm

const formatTimesAvailable = (ranges) => {
  if (ranges.length === 0) {
    return "Unavailable";
  }
  return ranges
    .map(
      ({ rangeStart, rangeEnd }) =>
        `${formatTime(rangeStart)}-${formatTime(rangeEnd)}`,
    )
    .join(", ");
};

type Props = {
  calledUpdateAvailability: boolean,
  calledUpdateFullDay: boolean,
  dealId: string,
  exampleMode: boolean,
  loading: boolean,
  saving: boolean,
  savingFullDay: boolean,
  summaryData: ViewingAvailability_publicDeal_viewingAvailability_availabilitySummaries[],
  timeSlots: ViewingAvailability_publicDeal_viewingAvailability_timeslots[],
  updateAvailability: (input: any) => void,
  updateFullDayAvailability: (input: any) => void,
};

export const ViewingAvailabilityUI = ({
  calledUpdateAvailability,
  calledUpdateFullDay,
  dealId,
  exampleMode,
  loading,
  saving,
  savingFullDay,
  summaryData,
  timeSlots,
  updateAvailability,
  updateFullDayAvailability,
}: Props) => {
  const [daySelected, setDaySelected] = useState(1);
  const [availability, setAvailability] = useState({});

  const selectedDayAvailableAllDay =
    availability && !Object.values(availability).includes(false);

  const selectedDayUnavailable =
    availability && !Object.values(availability).includes(true);

  const formattedSummaryData = summaryData.map(({ dayOfWeek, ranges }) => ({
    id: dayOfWeek,
    dayOfWeek: formatDay(dayOfWeek),
    availableTimes: formatTimesAvailable(ranges),
  }));

  const selectedDayTimeSlots = timeSlots.filter(
    (slot) => slot.dayOfWeek === daySelected,
  );

  useEffect(() => {
    const newData = selectedDayTimeSlots.reduce(
      (acc, { timeSlotStart, available }) => ({
        ...acc,
        [timeSlotStart]: available,
      }),
      {},
    );
    setAvailability(newData);
  }, [summaryData, timeSlots, daySelected]);

  const updateFullDayAvailabilityMutation = (available) =>
    updateFullDayAvailability({
      variables: {
        dealId,
        input: {
          available,
          dayOfWeek: daySelected,
        },
      },
      optimisticResponse: {
        updateViewingAvailabilityFullDay: selectedDayTimeSlots.map(
          ({ id }) => ({
            __typename: "ViewingAvailabilityTimeslot",
            id,
            available,
          }),
        ),
      },
    });
  return (
    <LayoutWrapper>
      <CustomHeroSection noMobile {...viewingAvailabilityHeroContent} />
      <MainContent>
        <PageWrapper>
          <Breadcrumb name="Viewings" path="/listing/viewings" />
          {exampleMode && (
            <div css={exampleTextContainer}>
              <ExampleCapsule css="margin-bottom: 5px;" />
              <p css={exampleText}>
                This is a preview, your changes won't be saved.
              </p>
            </div>
          )}
          <PageHeader css="padding-bottom: 30px;">
            Weekly viewing availability
          </PageHeader>
          {loading ? (
            <PlaceholderCard />
          ) : (
            <>
              <ContentWrapper>
                <SectionHeader css={headerExtraStyles}>
                  Weekly viewing availability
                </SectionHeader>
                <SectionDescription css={descriptionExtraStyles}>
                  Keep your schedule open if you can for the best chance of a
                  swift sale. Evenings and weekends are popular viewing times.
                </SectionDescription>
                <DayOfWeekPicker
                  formattedSummaryData={formattedSummaryData}
                  daySelected={daySelected}
                  setDaySelected={setDaySelected}
                />
              </ContentWrapper>
              <ContentWrapper>
                <SavingIndicator
                  called={calledUpdateAvailability || calledUpdateFullDay}
                  saving={saving || savingFullDay}
                />
                <SectionHeader css={dayOfWeekStyles}>
                  {`${moment().isoWeekday(daySelected).format("dddd")}s`}
                </SectionHeader>

                <div css={slotsGrid} data-test="timeslot-grid">
                  {selectedDayTimeSlots.map((slot) => {
                    const slotAvailable = availability[slot.timeSlotStart];

                    return (
                      <TimeSlotButton
                        key={slot.id}
                        slot={slot}
                        slotAvailable={slotAvailable}
                        onClick={() => {
                          updateAvailability({
                            variables: {
                              id: slot.id,
                              input: { available: !slotAvailable },
                            },
                            optimisticResponse: {
                              updateViewingAvailability: {
                                __typename: "ViewingAvailabilityTimeslot",
                                id: slot.id,
                                available: !slotAvailable,
                              },
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
                <div css={multiSelectButtons}>
                  <Button
                    disabled={selectedDayAvailableAllDay}
                    type="link"
                    data-test="update-full-day-availability"
                    onClick={() => updateFullDayAvailabilityMutation(true)}
                  >
                    Available all day
                  </Button>
                  <Button
                    disabled={selectedDayUnavailable}
                    type="link"
                    data-test="update-no-availability-for-day"
                    onClick={() => updateFullDayAvailabilityMutation(false)}
                  >
                    Unavailable
                  </Button>
                </div>
              </ContentWrapper>
            </>
          )}
        </PageWrapper>
      </MainContent>
    </LayoutWrapper>
  );
};
