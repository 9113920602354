// @flow

import { useContext } from "react";
import styled, { css, ThemeContext } from "styled-components";
import moment from "moment";

import { formatPrice, createBedText } from "@nested/utils";
import {
  largeParagraph,
  largeSectionHeading,
  smallParagraph,
  mediumParagraph,
  media,
} from "@nested/brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faHome,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

import { ImagePlaceholder } from "../../ImagePlaceholder";
import { PropertyCard, HorizontalLine } from "../../Section";

import { ComparisonPoints } from "./ComparisonPoints";
import { PhotoCarousel } from "./PhotoCarousel/PhotoCarousel";

const statusToLabel = (status) => {
  switch (status) {
    case "OTM":
      return "For sale";
    case "UO":
      return "Under Offer";
    case "Sold":
      return "Sold";
    case "Withdrawn":
      return "Withdrawn";
    default:
      return "";
  }
};

const getLabelColor = (status, theme) => {
  switch (status) {
    case "OTM":
      return theme.palette.terracotta100;
    case "UO":
      return theme.palette.green;
    case "Sold":
      return theme.palette.yellow100;
    case "Withdrawn":
      return theme.palette.terracotta100;
    default:
      return "";
  }
};

const TextContentWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.hague20};
  border-radius: 0 0 10px 10px;
  ${media.tablet`
    padding: 40px;
  `};
`;

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 20px 20px 0;
  ${media.desktop`
    justify-content: space-between;
  `}
`;

const Address = styled.h3`
  ${largeSectionHeading}
  font-size: 20px;
  margin: 0 20px 0 0;
  line-height: 1.3em;
  width: 100%;

  ${media.desktop`
    ${largeSectionHeading}
    line-height: 1.3em;
  `}
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 20px;
  justify-content: flex-start;
`;

const StatusLabel = styled.p`
  ${smallParagraph};
  margin-top: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: ${({ color }) => color};

  ${media.desktop`
    ${mediumParagraph}
    color: ${({ color }) => color};
  `}
`;

const DetailsItem = styled.div`
  color: #95a0af;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0 10px 5px 0;
  svg {
    margin-right: 10px;
    flex-shrink: 0;
    height: 21px;
    width: 21px;
  }
`;

const DetailsItemText = styled.p`
  ${smallParagraph};
  margin: 4px 5px 0 0;
`;

const PriceText = styled.p`
  ${largeParagraph};
`;

const ListingLink = styled.a`
  margin: 0;
`;

const SpacedLine = styled(HorizontalLine)`
  margin: 20px 0;
`;

const priceDetailsStyle = css`
  p {
    margin-bottom: 0;
    margin-top: 5px;
  }
`;

const PriceDetails = ({
  listingPrice,
  soldDate,
  soldPrice,
  status,
  underOfferDate,
  underOfferPrice,
}) => {
  const theme = useContext(ThemeContext);
  if (status === "Sold" && soldDate && soldPrice) {
    return (
      <div css={priceDetailsStyle}>
        <PriceText data-test="price-sold-data-test">
          <strong>Sold for {formatPrice(parseInt(soldPrice, 10))}</strong>
        </PriceText>
        <p
          data-test="when-sold-data-test"
          css={smallParagraph}
          style={{ color: theme.palette.hague40 }}
        >
          <strong>in {moment(soldDate).format("MMMM YYYY")}</strong>
        </p>
        {listingPrice && (
          <p
            data-test="listed-price-sold-data-test"
            css={smallParagraph}
            style={{ color: theme.palette.hague70 }}
          >
            <strong>
              Listed for {formatPrice(parseInt(listingPrice, 10))}
            </strong>
          </p>
        )}
      </div>
    );
  }
  if (status === "UO" && underOfferDate && underOfferPrice) {
    return (
      <div css={priceDetailsStyle}>
        <PriceText data-test="price-uo-data-test">
          <strong>
            Under offer for {formatPrice(parseInt(underOfferPrice, 10))}
          </strong>
        </PriceText>
        <p
          data-test="when-uo-data-test"
          css={smallParagraph}
          style={{ color: theme.palette.hague40 }}
        >
          <strong>in {moment(underOfferDate).format("MMMM YYYY")}</strong>
        </p>
        {listingPrice && (
          <p
            data-test="listed-price-uo-data-test"
            css={smallParagraph}
            style={{ color: theme.palette.hague70 }}
          >
            <strong>
              Listed for {formatPrice(parseInt(listingPrice, 10))}
            </strong>
          </p>
        )}
      </div>
    );
  }

  if (!listingPrice) return "";

  if (status === "Withdrawn") {
    return (
      <PriceText data-test="withdrawn-price-data-test">
        <strong>
          Previous list price {formatPrice(parseInt(listingPrice, 10))}
        </strong>
      </PriceText>
    );
  }

  return (
    <PriceText data-test="current-listing-price-data-test">
      <strong>
        Currently listed at {formatPrice(parseInt(listingPrice, 10))}
      </strong>
    </PriceText>
  );
};

type Props = {
  comparable: PropertyAnalysisComparables_comparableProperties_comparables, // eslint-disable-line
};

const CreateStatusLabel = ({ status }) => {
  const theme = useContext(ThemeContext);
  const labelColor = getLabelColor(status, theme);
  const labelText = statusToLabel(status);
  return (
    <StatusLabel color={labelColor}>
      <strong>{labelText}</strong>
    </StatusLabel>
  );
};

export const ComparableCard = ({
  comparable: {
    address,
    comparableImages,
    comparisonPoints,
    link,
    listingPrice,
    numberOfBeds,
    propertyType,
    soldDate,
    soldPrice,
    status,
    underOfferPrice,
    underOfferDate,
  },
}: Props) => (
  <PropertyCard data-test="comparable-card/card">
    {comparableImages.length > 0 ? (
      <PhotoCarousel photos={comparableImages.map((image) => image.url)} />
    ) : (
      <ImagePlaceholder link={link} />
    )}
    <TextContentWrapper>
      <AddressWrapper>
        <Address>{address}</Address>
        <CreateStatusLabel status={status} />
      </AddressWrapper>
      <DetailsWrapper>
        {propertyType && (
          <DetailsItem data-test="comparable-card/property-type">
            <FontAwesomeIcon icon={faHome} />
            <DetailsItemText>{propertyType}</DetailsItemText>
          </DetailsItem>
        )}
        {numberOfBeds && (
          <DetailsItem data-test="comparable-card/number-of-beds">
            <FontAwesomeIcon icon={faBed} />
            <DetailsItemText>{createBedText(numberOfBeds)}</DetailsItemText>
          </DetailsItem>
        )}
        {link && (
          <DetailsItem data-test="comparable-card/listing-link">
            <ListingLink href={link} target="_blank">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </ListingLink>
            <ListingLink href={link} target="_blank">
              <DetailsItemText>Listing</DetailsItemText>
            </ListingLink>
          </DetailsItem>
        )}
      </DetailsWrapper>
      <PriceDetails
        listingPrice={listingPrice}
        soldDate={soldDate}
        soldPrice={soldPrice}
        status={status}
        underOfferDate={underOfferDate}
        underOfferPrice={underOfferPrice}
      />
      <SpacedLine />
      {comparisonPoints && comparisonPoints.length > 0 && (
        <ComparisonPoints comparisonPoints={comparisonPoints} />
      )}
    </TextContentWrapper>
  </PropertyCard>
);
