// @flow
import { css } from "styled-components";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { gql } from "@apollo/client";
import { Button } from "@nested/component-library";
import { Sentry } from "@nested/isomorphic-sentry";
import { FormError } from "../../components/FormError";
import { useAuthenticatedMutation } from "../../hooks/useAuthenticatedMutation";
import { Card } from "./Card";
import { TextInput, Label } from "./TextInput";

const required = (value) => (value ? undefined : "Required");

const UPDATE_USER_DETAILS = gql`
  mutation SettingsUpdateCustomerAccount($input: CustomerAccountUpdateInput!) {
    updateCustomerAccount(input: $input) {
      customerAccount {
        firstName
        lastName
      }
    }
  }
`;

const createSubmitHandler = (mutation) => async (state) => {
  try {
    await mutation({
      variables: {
        input: {
          firstName: state.firstName,
          lastName: state.lastName,
        },
      },
    });
    return undefined;
  } catch (e) {
    Sentry.captureException(e);
    return {
      [FORM_ERROR]: "We couldn't update your details. Please try again later",
    };
  }
};

type Props = {|
  firstName: string,
  lastName: string,
|};

const CustomerAccountDetails = ({ firstName, lastName }: Props) => {
  const [updateCustomerAccountDetails] =
    useAuthenticatedMutation(UPDATE_USER_DETAILS);
  return (
    <Form
      initialValues={{
        firstName,
        lastName,
      }}
      onSubmit={createSubmitHandler(updateCustomerAccountDetails)}
      render={({
        handleSubmit,
        hasSubmitErrors,
        hasValidationErrors,
        pristine,
        form,
        submitSucceeded,
        submitError,
        submitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {hasSubmitErrors && (
            <FormError data-test="form-submit-error">{submitError}</FormError>
          )}
          {submitSucceeded ? (
            <Card>
              <h3 data-test="user-details-update-success-message">
                We&apos;ve updated your details!
              </h3>
            </Card>
          ) : (
            <Card>
              <h3>General</h3>

              <Field
                validate={required}
                name="firstName"
                render={({ input, meta }) => (
                  <>
                    <Label htmlFor="first-name">First name</Label>
                    {/* $FlowFixMe cant work out why this is failing here when RFF is fine elsewhere */}
                    <TextInput
                      {...meta}
                      {...input}
                      id="first-name"
                      placeholder="John"
                      data-test="user-details-first-name"
                      className="fs-exclude"
                    />
                  </>
                )}
              />
              <Field
                validate={required}
                name="lastName"
                render={({ input, meta }) => (
                  <>
                    <Label htmlFor="last-name">Last name</Label>
                    {/* $FlowFixMe */}
                    <TextInput
                      {...input}
                      {...meta}
                      id="last-name"
                      placeholder="Smith"
                      data-test="user-details-last-name"
                      className="fs-exclude"
                    />
                  </>
                )}
              />

              <div
                css={css`
                  margin-top: 30px;

                  & button:not(:first-child) {
                    margin-left: 20px;
                  }
                `}
              >
                <Button
                  data-test="user-details-form-submit"
                  type="primary"
                  disabled={pristine || hasValidationErrors || submitting}
                >
                  Save details
                </Button>
                <Button
                  data-test="user-details-form-cancel"
                  onClick={(e) => {
                    e.preventDefault();
                    form.reset();
                  }}
                  type="secondary"
                >
                  Cancel
                </Button>
              </div>
            </Card>
          )}
        </form>
      )}
    />
  );
};

export { CustomerAccountDetails };
