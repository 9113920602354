import styled from "styled-components";
import { smallParagraph } from "@nested/brand";

export const Pagination = styled.div`
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.4);
  display: -ms-flexbox;
  position: absolute;
  margin-bottom: 0px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  display: table;
`;

export const PaginationText = styled.p`
  ${smallParagraph};
  text-align: center;
  color: white;
  display: table-cell;
  vertical-align: middle;
  padding-top: 3px;
`;
