// Utils for interacting with gonative app

export const EVENT_TYPE = "pushInfoUpdated";

export const registerPushInfo = () => {
  // https://docs.gonative.io/push-notifications/sending-personalized-push
  window.gonative_onesignal_info = (payload) => {
    // Stored as a global so it can be read in our React code and posted to eggl
    window.pushInfo = payload;

    const event = new CustomEvent(EVENT_TYPE, { detail: payload });
    window.dispatchEvent(event);
  };
};

export const requestPushPermissions = () => {
  // https://docs.gonative.io/native-js-bridge/nativejs-ios-push-registration
  window.location.href = "gonative://onesignal/register";
};

export const setStatusBarColour = (background, foreground = "dark") => {
  // https://support.gonative.io/help/dynamically-customize-status-bar-visibility-and-style
  window.location.href = `gonative://statusbar/set?style=${foreground}&color=ff${background}`;
};

export const isNativeApp = () => {
  if (typeof navigator === "undefined") {
    return false;
  }

  // https://support.gonative.io/help/how-do-i-detect-usage-coming-from-my-apps
  return navigator.userAgent.indexOf("gonative") > -1;
};
