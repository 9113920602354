// @flow
import { css } from "styled-components";
import { Link } from "react-router-dom";
import { getImage } from "@nested/utils";
import { media } from "@nested/brand";

const container = css`
  background-color: ${({ theme }) => theme.palette.pink70};
  border: 1.5px solid ${({ theme }) => theme.palette.graphicalPink};
  border-radius: 5px;
  margin-top: 30px;
  padding: 15px 20px 15px 90px;
  position: relative;
  ${media.desktop`
    padding: 20px 20px 20px 90px;
  `}
`;

const image = css`
  height: 118px;
  position: absolute;
  top: -20px;
  left: -15px;
  ${media.tablet`
    height: 110px;
  `}
`;

const header = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin: 0;
`;

const description = css`
  font-size: 14px;
  line-height: 17px;
  margin: 5px 0 0 0;
`;

const bookACall = css`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 5px 0 0 0;
`;

export const PreCloseContactCta = ({
  deal,
}: {
  deal: ?ListingSectionQuery_publicDeal,
}) => {
  if (deal?.isListed || deal?.opportunityStatusValue === "s07_closed") {
    return null;
  }

  return (
    <div css={container}>
      <img
        css={image}
        src={getImage("illustrations/hand-sign.png")}
        alt="Hello illustration"
      />
      <h3 css={header}>Not sure where to start?</h3>
      <p css={description}>
        Speak to a Nested adviser today to take the hassle out of home selling.
      </p>
      <Link to="/book-a-call" css={bookACall}>
        Book a call
      </Link>
    </div>
  );
};
