// @flow
import { css } from "styled-components";
import { smallParagraph } from "@nested/brand";
import { formatDateTime } from "@nested/utils/src/formatDate/formatDate";
import { BuyerListItem } from "../../components/BuyerListItem";
import { VIEWING_BOOKED_STATUS, DID_NOT_BOOK_VIEWING_STATUS } from "./utils";
import { StatusText } from "./StatusText";

export const lastUpdatedStyle = css`
  ${smallParagraph};
  font-weight: 500;
`;

type Props = {
  enquiry: EnquiriesPageQuery_publicDeal_buyerEnquiries,
  onClick: () => void,
};

export const BuyerEnquiry = ({ enquiry, onClick }: Props) => {
  const { id, buyer, enquiryStatus, falloutDetails } = enquiry;
  const updatedAt = formatDateTime(enquiry.contactLastUpdatedAt, {
    appendTime: false,
  });

  return (
    <div>
      <BuyerListItem
        buyer={buyer}
        withCrossIcon={enquiryStatus === DID_NOT_BOOK_VIEWING_STATUS}
        withTickIcon={enquiryStatus === VIEWING_BOOKED_STATUS}
        anonymisedAvatar={enquiryStatus !== VIEWING_BOOKED_STATUS}
        itemId={id}
        onClick={onClick}
      >
        <StatusText status={enquiryStatus} teaserText={falloutDetails} />
        <div css={lastUpdatedStyle}>Updated {updatedAt}</div>
      </BuyerListItem>
    </div>
  );
};
