// @flow
import { css } from "styled-components";
import { getImage } from "@nested/utils";
import { largeParagraph } from "../Typography/Paragraphs";
import { regularHeading } from "../Typography/Headings";
import { media } from "../../media";

const cardStyles = css`
  background-color: ${({ theme }) => theme.palette.pink20};
  border: 2px solid ${({ theme }) => theme.palette.pink100};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  text-align: center;
  margin-top: 30px;

  ${media.desktop`
    flex-direction: row;
    text-align: left;
    padding: 36px 40px;
  `}
`;

const imageStyles = css`
  margin: 0 auto;
  max-width: 120px;
  max-height: 120px;

  ${media.desktop`
    margin: auto 40px auto 0;
  `}
`;

const headingStyles = css`
  ${regularHeading}
  margin-bottom: 0px;
  ${media.desktop`
    margin: 0;
  `}
`;

const paragraphStyles = css`
  ${largeParagraph}
  margin: 10px 0 0 0;
`;

type Props = {
  header?: string,
  detail?: string,
};

export const Error = ({
  header = "Component failed to load",
  detail = "We can't load this component at the moment, please try refreshing this page.",
}: Props) => (
  <section css={cardStyles} data-test="broken-component-error">
    <img
      alt="error-bot"
      css={imageStyles}
      src={getImage("illustrations/error-bot.png")}
    />
    <div data-test="broken-component-error-message">
      <p css={headingStyles}>{header}</p>
      <p css={paragraphStyles}>{detail}</p>
    </div>
  </section>
);
