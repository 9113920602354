// @flow
import moment from "moment";
import { sortBy, reverse } from "lodash";
import { type Offer } from "./Offers";

export const getOfferStatus = (offer: Offer) => {
  switch (offer.status) {
    case "Rejected":
      return "Rejected";
    case "Retracted":
      return "Withdrawn";
    case "Renegotiated":
      return "Renegotiated";
    case "Accepted":
    case "Acceptable subject to sale":
      return "Accepted";
    case "No longer accepted":
      return "Fell through";
    default:
      return `${moment.localeData().ordinal(offer.number)} offer`;
  }
};

export const isActiveOffer = (offer: Offer) =>
  [
    "Submitted to vendor",
    "Accepted",
    "Acceptable subject to sale",
    "Agreed pending memo",
  ].includes(offer.status);

const isPendingOffer = (offer: Offer) =>
  ["Submitted to vendor", "Agreed pending memo"].includes(offer.status);

export const isNewOffer = (offer: Offer) => {
  const offerDate = offer.datetimeSubmittedToVendor || offer.placedOn;
  return isPendingOffer(offer) && moment().diff(offerDate, "hours") < 24;
};

const newOffersHeading = (n) => {
  return `You have ${n > 1 ? `${n} new offers` : "a new offer"}!`;
};

const oldOffersHeading = (n) => {
  return `You have ${n > 1 ? `${n} offers` : "an offer"} waiting!`;
};

const defaultHeading = (n) => {
  return `You've had ${n} ${n > 1 ? "offers" : "offer"}`;
};

const newOffersSubheading = (n) => {
  if (n === 0) {
    return null;
  }
  return `You also have ${
    n > 1 ? `${n} other offers` : "another offer"
  } waiting`;
};

const defaultSubheading = (n) => {
  return `${n > 1 ? "All" : "It has been"} rejected or withdrawn`;
};

export const getHeading = (offers: Offer[]) => {
  const acceptedOffers = offers.filter((o) =>
    ["Accepted", "Acceptable subject to sale"].includes(o.status),
  );

  const pendingOffers = offers.filter(isPendingOffer);

  const newOffers = offers.filter(isNewOffer);

  const oldOffers = pendingOffers.filter((o) => !isNewOffer(o));

  if (acceptedOffers.length > 0) {
    return {
      heading: "You're under offer!",
      subheading: null,
    };
  }

  if (newOffers.length > 0) {
    return {
      heading: newOffersHeading(newOffers.length),
      subheading: newOffersSubheading(oldOffers.length),
    };
  }

  if (oldOffers.length > 0) {
    return {
      heading: oldOffersHeading(oldOffers.length),
      subheading: null,
    };
  }

  return {
    heading: defaultHeading(offers.length),
    subheading: defaultSubheading(offers.length),
  };
};

// Turns each renegotiatedOffer into a copy of the parent offer with the date and amount replaced
const transformRenegotiationsToOffers = (offer, renegotiatedOffers) => {
  const renegotiations = sortBy(renegotiatedOffers, ["dateAccepted", "id"]);

  return renegotiations.map((r, i) => ({
    ...offer,
    placedOn: r.dateAccepted,
    amount: r.newAcceptedOfferAmount,
    status: i === renegotiations.length - 1 ? offer.status : "Renegotiated",
  }));
};

// Creates renegotiated offer objects and flattens them into main offers array
export const extractRenegotiatedOffers = (
  offers: $ReadOnlyArray<Offer>,
): Offer[] => {
  const allOffers = offers.flatMap((offer) => {
    if (offer.renegotiatedOffers.length === 0) {
      return [offer];
    }

    const renegotiatedOffers = transformRenegotiationsToOffers(
      offer,
      offer.renegotiatedOffers,
    );
    return [
      {
        ...offer,
        status: "Renegotiated",
      },
      ...renegotiatedOffers,
    ];
  });

  return reverse(sortBy(allOffers, ["placedOn", "id"]));
};
