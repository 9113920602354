// @flow
import { gql } from "@apollo/client";
import moment from "moment-timezone";
import { css } from "styled-components";
import {
  media,
  smallParagraph,
  Error as GenericErrorComponent,
  PlaceholderCard,
} from "@nested/brand";
import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { useAuthenticatedQuery } from "../../hooks/useAuthenticatedQuery";

const POSTCODE_QUERY = gql`
  query OnboardingModalPostcode($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      property {
        postcode
      }
    }
  }
`;

const RM_QUERY = gql`
  query OnboardingModalRm($postcode: String!) {
    postcodeDetails(postcode: $postcode) {
      normalisedPostcode
      relationshipManager {
        id
        firstName
      }
    }
  }
`;

const bookingSection = css`
  padding: 20px 0;
  display: flex;
  ${media.tablet`
    padding: 25px 0;
  `}
`;

const icon = css`
  text-align: center;
  border: solid 2px;
  border-color: ${({ theme }) => theme.palette.hague40};
  padding: 5px;
  width: 30px;
  height: 30px;
`;

const iconDay = css`
  font-size: 16px;
  font-weight: 800;
`;

const iconMonth = css`
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.hague70};
`;

const textWrapper = css`
  padding: 0 15px;
`;

const bookingTimeText = css`
  ${smallParagraph}
  font-weight: 500;
`;

const Icon = ({ datetime }) => {
  const momentDatetime = moment(datetime);
  const day = momentDatetime.format("DD");
  const month = momentDatetime.format("MMM");
  return (
    <div css={icon}>
      <div css={iconDay}>{day}</div>
      <div css={iconMonth}>{month}</div>
    </div>
  );
};

const formatBookingDate = (datetime) =>
  moment(datetime).format("ddd Do MMM [at] HH:mm");

type Props = {|
  agent?: string,
  bookingDate?: string,
  contactBooked?: string,
|};

export const BookingSection = ({
  agent,
  bookingDate,
  contactBooked,
}: Props) => (
  <div css={bookingSection}>
    <Icon datetime={bookingDate} />
    <div css={textWrapper}>
      <div css={smallParagraph}>
        {agent} will {contactBooked} on
      </div>
      <div css={bookingTimeText}>{formatBookingDate(bookingDate)}</div>
    </div>
  </div>
);

export const NoBookingSection = () => {
  const {
    data: postcodeData,
    loading: postcodeDataLoading,
    error: postcodeDataError,
  } = usePublicDealQuery(POSTCODE_QUERY);
  const {
    data: rmData,
    loading: rmDataLoading,
    error: rmDataError,
  } = useAuthenticatedQuery(RM_QUERY, {
    variables: { postcode: postcodeData?.publicDeal?.property?.postcode },
    skip: !postcodeData,
  });

  if (postcodeDataLoading || rmDataLoading) return <PlaceholderCard />;

  if (postcodeDataError || rmDataError) {
    return <GenericErrorComponent error={postcodeDataError || rmDataError} />;
  }

  return (
    <div css={bookingSection}>
      <div css={smallParagraph}>
        {rmData?.postcodeDetails?.relationshipManager?.firstName ||
          "One of our agents"}{" "}
        will be in touch soon.
      </div>
    </div>
  );
};
