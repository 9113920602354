// @flow
import { ViewingCard } from "./ViewingCard";
import { ViewingFeedbackModal } from "./ViewingFeedbackModal";
import { useModalCollection } from "../../hooks/useModalCollection";

type Props = {
  enquiries: {
    ...ViewingFeedbackDeal_publicDeal_buyerEnquiries,
    lastUpdated: ?DateTime,
  }[],
};

export const ViewingCardList = ({ enquiries }: Props) => {
  const { open, selectAndOpen, onClose, selectedItem, next, previous } =
    useModalCollection(enquiries);

  if (enquiries.length === 0) {
    return null;
  }

  return (
    <>
      {enquiries.map((enquiry, index) => (
        <ViewingCard
          onClick={() => selectAndOpen(index)}
          enquiry={enquiry}
          key={enquiry.id}
        />
      ))}
      <ViewingFeedbackModal
        open={open}
        buyerEnquiry={selectedItem}
        onClose={onClose}
        next={next}
        previous={previous}
      />
    </>
  );
};
