// @flow
import styled from "styled-components";
import { gql } from "@apollo/client";
import { type QueryRenderProps } from "@nested/utils";
import { media } from "@nested/brand";
import { H1 } from "../../components/Heading";

import { PasswordReset } from "./PasswordReset";
import { CustomerAccountDetails } from "./CustomerAccountDetails";
import { Logout } from "./Logout";
import { AuthenticatedQuery } from "../../components/AuthenticatedQuery";
import { DefaultLegacyWrapper } from "../PageWrapper";

const QUERY = gql`
  query SettingsAccountDetails {
    customerAccount {
      email
      firstName
      lastName
    }
  }
`;

const SettingsWrapper = styled.div`
  padding: 30px 10px;
  ${media.tablet`
    padding: 20px 0;
  `}
  ${media.tablet`
    padding: 40px 0;
  `}
`;

const Settings = () => (
  <DefaultLegacyWrapper>
    <SettingsWrapper>
      <H1>Account settings</H1>

      <AuthenticatedQuery query={QUERY}>
        {({
          data: { customerAccount },
        }: QueryRenderProps<SettingsAccountDetails>) => (
          <>
            <CustomerAccountDetails
              firstName={customerAccount.firstName || ""}
              lastName={customerAccount.lastName || ""}
            />

            <PasswordReset email={customerAccount.email} />
          </>
        )}
      </AuthenticatedQuery>
      <Logout />
    </SettingsWrapper>
  </DefaultLegacyWrapper>
);

export { Settings };
