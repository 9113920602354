// @flow
import { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { matchPath, useLocation } from "react-router";
import { media } from "@nested/brand";
import { NestedLogoHouseOnly } from "@nested/brand/src/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { SideBarMenu } from "./SideBarMenu";
import { MobileMenu } from "./MobileDrawerMenu";
import { AdminBanner } from "../DealProvider/AdminBanner";

const navStyle = css`
  background-color: white;
  width: 100%;

  ${media.desktop`
    width: 25vw;
    max-width: 450px;
  `}
`;

const fullWidthPageNav = css`
  background-color: white;
  width: 100%;
`;

const navBarStyles = css`
  height: 57px;
  min-width: 320px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: ${({ theme }) => theme.palette.hague10} solid 1px;
`;

const mobileNavStyle = css`
  ${navBarStyles}

  ${media.desktop`
    display: none;
  `};
`;

const sideBarToggle = css`
  display: none;
  height: 100%;
  ${media.desktop`
    display: block;
  `};
`;

const logoWrapper = css`
  flex-grow: 1;
  margin-left: 15px;
  ${media.tablet`
    flex-grow: unset;
    /* Bit of a hack. 36px is the width of the menu hamburger & margin, 
    76px is half the width of 'sale purchase'. Means the 'sale purchase' 
    sits in the center */
    width: calc(50% - 36px - 76px);
  `}
`;

const logoStyle = css`
  height: 28px;
`;

const menuButtonStyle = css`
  width: 21px;
  height: 28px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 0px 0px 0px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
`;

const burgerLineStyle = css`
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.palette.hague};
  margin-bottom: 4px;
`;

const TopNavMobileMenu = styled.div`
  display: flex;
  text-align: right;

  > div:last-child {
    margin: 0;
  }
`;

const TopNavMobileMenuItem = styled.div`
  padding: 3px 0px 0px 0px;
  margin: 0px 20px 0px 0px;
  height: 51px;
  display: flex;
  align-items: center;

  border-bottom: 3px solid transparent;
  border-color: ${({ current, theme }) =>
    current ? theme.palette.terracotta500 : "transparent"};
`;

const TopNavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.hague80};
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 17.75px;
  text-transform: uppercase;
`;

const notificationIcon = css`
  font-size: 21px;
`;

const notificationsNumber = css`
  position: relative;

  font-size: 10.67px;
  font-weight: 500;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: normal;
`;

const notificationsBubble = css`
  position: absolute;
  top: 12px;
  right: 5px;

  border: 1px solid #ffffff;
  border-radius: 100%;
  width: 16px;
  height: 16px;

  text-align: center;
  line-height: 13px;

  background-color: ${({ theme }) => theme.palette.terracotta100};
`;

const notDesktop = css`
  ${media.desktop`
    display: none;
  `}
`;

const Notifications = ({ number }) => {
  return (
    <>
      <span css={notificationIcon}>
        <FontAwesomeIcon icon={faBell} />
      </span>
      <span css={notificationsBubble}>
        <span css={notificationsNumber}>{number}</span>
      </span>
    </>
  );
};

type Props = {
  initialMenuOpen?: boolean,
};

const isCurrentPage = (link, path) => {
  const match = matchPath(path, { path: link, exact: false });

  return Boolean(match);
};

const NavBarItems = ({ notifications, menuOpen, setMenuOpen }) => {
  const { pathname: path } = useLocation();

  return (
    <>
      <MobileMenu
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        notifications={notifications}
      />
      <button
        type="button"
        onClick={() => setMenuOpen(true)}
        css={menuButtonStyle}
      >
        <span css={burgerLineStyle} />
        <span css={burgerLineStyle} />
        <span css={burgerLineStyle} />
      </button>
      <div css={logoWrapper}>
        <Link to="/" data-test="nav-bar-mobile-nested-logo-home-link">
          <NestedLogoHouseOnly css={logoStyle} />
        </Link>
      </div>
      <TopNavMobileMenu>
        <TopNavMobileMenuItem current={isCurrentPage("/listing", path)}>
          <TopNavLink to="/listing" data-test="nav-bar-mobile-listing-link">
            Listing
          </TopNavLink>
        </TopNavMobileMenuItem>
        <TopNavMobileMenuItem current={isCurrentPage("/sale", path)}>
          <TopNavLink to="/sale" data-test="nav-bar-mobile-sales-link">
            Sale
          </TopNavLink>
        </TopNavMobileMenuItem>
        {/* Hiding notifications until feature built out */}
        {false && (
          <TopNavMobileMenuItem current={isCurrentPage("/notifications", path)}>
            <TopNavLink
              to="/notifications"
              data-test="nav-bar-mobile-notifications-link"
            >
              <Notifications number={notifications} />
            </TopNavLink>
          </TopNavMobileMenuItem>
        )}
      </TopNavMobileMenu>
    </>
  );
};

export const NavigationHeader = ({ initialMenuOpen = false }: Props) => {
  const [menuOpen, setMenuOpen] = useState(initialMenuOpen);

  const notifications = 0;

  return (
    <nav data-test="nav-bar" css={navStyle}>
      <AdminBanner css={notDesktop} />
      {/* Top nav bar, hidden on Desktop */}
      <div css={mobileNavStyle}>
        <NavBarItems
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
          notifications={notifications}
        />
      </div>
      {/* Desktop sidebar menu - hidden on mobile */}
      <div css={sideBarToggle}>
        <SideBarMenu notifications={notifications} />
      </div>
    </nav>
  );
};

export const FullWidthPageNav = ({ initialMenuOpen = false }: Props) => {
  const [menuOpen, setMenuOpen] = useState(initialMenuOpen);

  const notifications = 0;

  return (
    <nav data-test="nav-bar" css={fullWidthPageNav}>
      <div css={navBarStyles}>
        <NavBarItems
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
          notifications={notifications}
        />
      </div>
    </nav>
  );
};
