// @flow
import type { Element } from "react";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const wrapperStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  input:focus + div {
    border-color: ${({ theme }) => theme.palette.hague};
    transition: border 200ms ease-in;
  }

  input:focus:checked + div {
    background-color: ${({ theme }) => theme.palette.hague};
  }
`;

const checkboxBorderStyle = css`
  width: 24px;
  height: 24px;
  border: 1px solid
    ${({ theme, checked }) =>
      checked ? theme.palette.hague : theme.palette.hague40};
  border-radius: 4px;
  margin-right: 20px;
  transition: border 200ms ease-out;
  overflow: hidden;
  flex-shrink: 0;
`;

const checkboxBackgroundStyle = css`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.hague80};
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  transition: opacity 50ms linear;
  text-align: center;
`;

const tickStyle = css`
  width: 100%;
  height: 100%;
  color: white;
`;

type Props = {
  checked: boolean,
  className?: string,
  label: string | Element<any>,
  onChange(e: SyntheticInputEvent<HTMLInputElement>): void,
  id?: string,
};

export const Checkbox = ({
  checked,
  className,
  label,
  onChange,
  id,
}: Props) => (
  <label
    className={className}
    css={css`
      display: block;
      cursor: pointer;
    `}
  >
    <div css={wrapperStyle}>
      <input
        css={css`
          /* We want to hide it, but keep it "visible" so you can actually tab
             to it for styling purposes */
          position: absolute;
          z-index: -1;
          opacity: 0;
        `}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        id={id}
        data-test={id ? `${id}-checkbox` : ``}
      />
      <div checked={checked} css={checkboxBorderStyle}>
        <div css={checkboxBackgroundStyle} checked={checked}>
          <FontAwesomeIcon icon={faCheck} css={tickStyle} />
        </div>
      </div>
      <div
        css={css`
          line-height: 24px;
        `}
      >
        {label}
      </div>
    </div>
  </label>
);
