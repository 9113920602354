import { css } from "styled-components";
import { Redirect } from "react-router-dom";
import { getImage } from "@nested/utils";
import { media } from "@nested/brand";
import { ButtonLink } from "@nested/component-library";
import { useAuth } from "@nested/auth";

const wrapperStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  text-align: center;
  padding: 30px 20px 20px;
  background-color: white;
`;

const logoStyle = css`
  height: 180px;
  ${media.tablet`
    height: 250px;
  `}
`;

const headingStyle = css`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  margin: 0 auto;
  max-width: 400px;
  ${media.tablet`
    font-size: 48px;
    line-height: 62px;
  `}
`;

const textStyle = css`
  font-size: 18px;
  line-height: 27px;
  opacity: 0.8;
  margin: 20px auto 0;
  max-width: 400px;
  ${media.tablet`
    font-size: 24px;
    line-height: 36px;
  `}
`;

const buttonsWrapper = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  width: calc(100% - 40px);
  max-width: 400px;
  ${media.tablet`
    width: 100%;
    max-width: 100%;
    bottom: 100px;
  `}
`;

const buttonStyle = css`
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  &:last-child {
    margin-top: 15px;
  }

  ${media.tablet`
    width: 300px;
    display: inline-block;
    &:last-child {
      margin-top: 0;
      margin-left: 30px;
    }
  `}
`;

export const AppLandingPage = () => {
  const { authenticated } = useAuth();

  if (authenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div css={wrapperStyle}>
      <img
        alt="Nested"
        css={logoStyle}
        src={getImage("mobileapp/loading-stacked-dark.svg")}
      />
      <h1 css={headingStyle}>Smarter selling at your fingertips</h1>
      <p css={textStyle}>
        Everything you need to know about your sale, available 24/7.
      </p>
      <div css={buttonsWrapper}>
        <ButtonLink css={buttonStyle} size="large" to="/" type="standard">
          Sign in
        </ButtonLink>
        <ButtonLink
          css={buttonStyle}
          size="large"
          to="https://nested.com"
          type="standard"
        >
          Learn about Nested
        </ButtonLink>
      </div>
    </div>
  );
};
