export const exampleComparableProperties = {
  comparables: [
    {
      address: "Cairo Road",
      comparableImages: [
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/669dd03628013affce17394c302140d38b245c95.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/c5885408672bee315cbafd3513fd5b3b34793516.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/05117d1355d29e32beb93cc1ca300c6ebc7c2d96.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/3869c037e894d11e72c997f068f010bfde62595d.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/a0ebb16e1b3d7da3428ea72280addd4cabeeebc8.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/b6a95c89640c3c303e610be831fa99bb25844552.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/e679c988a7e186c5b13425adcfed55a17b307179.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/a1a02334d67aa87c20df9f87d7657ec15d99b57e.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/e00ed7d08180f9a7ee84f5fe8e854b858f9f70a2.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/796dfdb700360418bea35056348de1023c3038f7.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/cda949c42d61d309f338f1d232196ed9fef292da.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/9d3a07a2228364882bf53d707b3dacfd070f2c40.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/24be398845390d32e49b53fa028f7bcedafbcf07.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/3b2fc06a476f7cdc31da6c93673c40a79ace9d7a.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/a499ede37ed7b4c46263d7f51e64f76b7f875d72.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/bca257f8bb93ab2f74d19310c4754e63d891b638.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/37edffa561f3485d6980e934b58aa777e33f75a7.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/f2a8bd07c0a7fae269cae1b67c2721722ed60438.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/86aa44d387ee4401ad874fe24645b4651d286eea.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/50434c3ed01ab5f3bc558818293cf8a1b2f65de4.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/1462f8749a22b43769e4c8dd595c04803a2b3606.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/8f8033086480abbde732565e11bbdcff7d8bf253.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/98369480/3454f1a89619071b8b40a0a41fbf4ffef4e6a05e.jpg",
        },
      ],
      comparisonPoints: [
        {
          description: "Completely renovated",
          rating: "POSITIVE",
        },
        {
          description: "Private landscaped garden",
          rating: "POSITIVE",
        },
      ],
      link: "https://www.rightmove.co.uk/properties/98369480#/",
      listingPrice: 470000,
      numberOfBeds: 2,
      propertyType: "FLAT",
      soldDate: null,
      soldPrice: null,
      status: "OTM",
      underOfferPrice: null,
      underOfferDate: null,
    },
    {
      address: "Example Street",
      comparableImages: [
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/cb93100b904609693ef0192836b834e657bd7953.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/4eaa5f60d5ec558b6efeb1971b0bea4783ee00a9.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/a5c34c70ec5c6c4456a0c68e2a4e6e8a2ef07b4f.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/d5328a9c8b3f2131202a307ecc29acb2560b44ac.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/61383ac658d9f0241d5e58f41ad9ef385a15c51e.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/3daac8e6be1293a7da13bdd6c5a55697017c0dd7.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/9f59927aaeee7d15758972b93897e1c1f385ec9e.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/69baf40c7b61af12f866e224e4f11ca672429a1c.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/e0b4da56507679bd04c7a28a8065522177726feb.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/a5d48a04dee01ada41fb236fecb40eeb4ae6d05f.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/8c5d085e8b68fe6c1470067912206a6f494d1e57.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/5444c6e1566d822e424de6de396b992bed589632.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/271c835e0911fc4f7f46ae57dc0f3d98989f64c1.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/9b7be3a576c44c27437c1d3ba2655dc4f7880aae.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/3fd21df07818574db5475b10d54b3a05c57506bd.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/6dc23e31d9d505784642c0328000a19dfd812c7e.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/34ba5b5fbf425423c4704e85750be9a8cfd3accb.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/4d644451776fdaf835a90f6632206377ab2fdb07.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/1077c86114be1c8132778a3c1ba7c2709fb0a5fe.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/f0fff083dade6da94facd6b1668d89a3138a6949.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/085c07f49eb02906485f24a64e76ba9cbfb80801.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72787410/1a6b4fd0488903faf72e7d79b2ec1bd8898d022b.jpg",
        },
      ],
      comparisonPoints: [
        {
          description: "Private Balcony",
          rating: "POSITIVE",
        },
        {
          description: "Lovely street",
          rating: "POSITIVE",
        },
      ],
      link: "https://www.rightmove.co.uk/properties/72787410#/",
      listingPrice: 475000,
      numberOfBeds: 2,
      propertyType: "FLAT",
      soldDate: null,
      soldPrice: null,
      status: "UO",
      underOfferPrice: null,
      underOfferDate: null,
    },
    {
      address: "Example Street",
      comparableImages: [
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/087b9cf9934832bd07f505c4050966e3c38f1a7e.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/3e94e84ff7e1245b52f3a17f926cadf720c17aa2.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/205f8f171ee2b2698375da68996693d250a444d4.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/5f0195955ebc10f2d4b450c994363dbfd43c8320.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/f1db26d7d483d5df8e94603eb70912815956a30c.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/d34c22a52eeccef54cdfbe14ed5ba790af0c9623.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/3a12a27b0b58bd8bce1523af90fdf013a9f4158f.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/40982605b5da3cd18e01205a555233517a2112e4.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/603208ff1a1cd0c2501fc807020c0bddecca78f8.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/726d83c4b6f697a0dfdf2c258de93f27b468bd4a.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/1d9381e5925fa7ae617c6f9b08ad9f3c51efa5c4.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/b7ef474a7901a51dd59f7c6f89d277c964c76ada.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/beb3e267249c7d662d8c87f750755a7ca6c0b76a.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/f307097646cb4a3cb854e3c7062c91b8888bdc2b.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/73460748/f138a8da87a51d44d76d3cb6c1987c757b33b1c9.jpg",
        },
      ],
      comparisonPoints: [
        {
          description: "Spacious Kitchen/Diner",
          rating: "POSITIVE",
        },
        {
          description: "Central Walthamstow",
          rating: "POSITIVE",
        },
      ],
      link: "https://www.rightmove.co.uk/properties/73460748#/",
      listingPrice: 485000,
      numberOfBeds: 2,
      propertyType: "FLAT",
      soldDate: null,
      soldPrice: null,
      status: "UO",
      underOfferPrice: null,
      underOfferDate: null,
    },
    {
      address: "Cleveland Park Avenue",
      comparableImages: [
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/e6baa29c11ba1432e5b42fe78411cda59dd67f6d.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/99bce9e0d84b2c8ba1ceb56a53eb8c16b393d0a4.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/4918399155f3d687f98ab45f1a0536a8bf04a770.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/2a72042f5b47e158f2f218cc77d7180cf9290331.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/d40a0c755243bd14ca0e22cd812f12f38788c722.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/5b2c247df3a4a5f9b61803bf8726eaa4ff5614b7.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/09cc893b14b531b833e602575f532cd42296e926.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/022d3b6ad4cd518c9737ba6881fc7ece66fef02d.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/69db833833f5e47ca9177b6dc65f3bee66f66e4a.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/741c956e74ec4c16d8a2025a488425a8de6ab13c.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/b68f037810bdbf84b1fc4ae777bbd3e4442af904.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/d81882f50eb8c173994cfac1b7b427b98e0fdb21.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/1503f08ba23cbe10a9c3c2767e864c6d6c0662da.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/282fc60c1f8c31fa70a05f6c794e20d7587b8f99.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/91a056bd22529359961c6bd364c7b725de66343f.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/aeea12642b39964793f0f2f1ba12515aedbad581.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/045bba59aecb6e34d92441ed417850bf554c53cb.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/2745bfcdf94a9c8548a6372772963a81939f3044.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/3a291d871e4c23ed1443767eea87f2ab62440fc2.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/81259534/2eccd96b2b2a2ac4d08d41638ecd6d8cd2367d1a.jpg",
        },
      ],
      comparisonPoints: [
        {
          description: "60 ft private garden",
          rating: "POSITIVE",
        },
      ],
      link: "https://www.rightmove.co.uk/properties/81259534#/",
      listingPrice: 550000,
      numberOfBeds: 2,
      propertyType: "FLAT",
      soldDate: null,
      soldPrice: null,
      status: "UO",
      underOfferPrice: null,
      underOfferDate: null,
    },
    {
      address: "West Avenue Road",
      comparableImages: [
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/74b71504879925f4e7ee7d65068b14aa0a963fac.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/ded8a038c49438257cacddb651e67c9817ecde75.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/db1389840e7bc6f82d6c6874dea25b2522cea677.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/098443d35bc927198b493ff1d4c0545c4ab22fd3.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/7e943bf6a72478916d8600fb6df1d3841b6f94b1.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/7c020e8421def3e27f4e85f4303258f26ca2abad.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/317eb838aca1c21de743495a3a7ed25d9868e277.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/7895c05133c1f1611a062c7e450df9bb218a0046.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/9021ed7fa0b5ebf1754ef7b6c1192d0b335ef558.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/3b0edc4335094ce5fda1476b545fa47f0a4edd9c.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/043b87c96bc40d6917d0d09705dfa21bb3dad311.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/a5bc808998c662391dbbd29cc22599d7d82aa924.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/0c3c49cf16852ee09eccc08dc6ee9a661c820f11.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/7aba264ee8154dd220d5d31547a2b1c9711bd0f8.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/0aac6406fa5c8a1e8288274e2a1caa76d249d2ef.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/a938dd45270f762d665257bd5846c25ba61b434e.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/981edf973e1f51f3ed00b67daeb3e9b01062b8ce.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/2ff6df0b987282d16d7b3c25a5884bcfa9933efb.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/cb87c4f99074b7e296f3ca13a1231099747e97b4.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/47b441ec6ae5e95be7e87c1e4f1fd9df48d313f3.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/bff0b945b97c87209e903f23eb327c7287bf9981.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/ca774452784189563485ab3a0897168af859088e.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/3aeb30246a6e068b80490984d23888b9e01d4c45.jpg",
        },
        {
          __typename: "ComparableImage",
          url: "https://listing-assets.nested.com/72613935/3059545d23a797666916eb1f21614d193a7d2fb2.jpg",
        },
      ],
      comparisonPoints: [
        {
          description: "Spacious Lounge",
          rating: "POSITIVE",
        },
      ],
      link: "https://www.rightmove.co.uk/properties/72613935#/",
      listingPrice: 550000,
      numberOfBeds: 3,
      propertyType: "FLAT",
      soldDate: null,
      soldPrice: null,
      status: "UO",
      underOfferPrice: null,
      underOfferDate: null,
    },
  ],
};
