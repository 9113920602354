// @flow

import { useContext } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

import { mediumParagraph, sectionHeading } from "@nested/brand";

const Wrapper = styled.div`
  text-align: left;

  div:last-child {
    margin-bottom: 0;
  }
`;

const PointsSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const pointTitleStyle = css`
  ${sectionHeading};
  color: ${({ theme }) => theme.palette.hague50};
  font-size: 12px;
`;

const PointWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  p {
    margin: 4px 0 0 10px;
  }
`;

export const KeyPoints = ({ keyPoints, theme }: KeyPointsProps) => (
  <PointsSection>
    <p css={pointTitleStyle}>
      <strong>Key points</strong>
    </p>

    {keyPoints.map((point, index) => (
      <Point key={index} point={point} theme={theme} />
    ))}
  </PointsSection>
);

export const KeyDifferences = ({
  keyDifferences,
  theme,
}: KeyDifferencesProps) => (
  <PointsSection>
    <p css={pointTitleStyle} color={theme.palette.hague70}>
      <strong>Key differences</strong>
    </p>

    {keyDifferences.map((point, index) => (
      <Point key={index} point={point} theme={theme} />
    ))}
  </PointsSection>
);

const Point = ({ point: { description, rating }, theme }) => (
  <PointWrapper>
    {rating === "NEUTRAL" ? (
      <FontAwesomeIcon icon={faCheck} color={theme.palette.green} />
    ) : (
      <FontAwesomeIcon
        icon={faCircle}
        style={{
          color:
            rating === "POSITIVE"
              ? theme.palette.green
              : theme.palette.terracotta100,
          width: "0.5em",
        }}
      />
    )}
    <p>{description}</p>
  </PointWrapper>
);

type KeyPointsProps = {
  keyPoints: $ReadOnlyArray<PropertyAnalysisComparables_comparableProperties_comparables_comparisonPoints>, // eslint-disable-line
  theme: any,
};

type KeyDifferencesProps = {
  keyDifferences: $ReadOnlyArray<PropertyAnalysisComparables_comparableProperties_comparables_comparisonPoints>, // eslint-disable-line
  theme: any,
};

type Props = {
  comparisonPoints: $ReadOnlyArray<PropertyAnalysisComparables_comparableProperties_comparables_comparisonPoints>, // eslint-disable-line
};

export const ComparisonPoints = ({ comparisonPoints }: Props) => {
  const theme = useContext(ThemeContext);

  if (comparisonPoints.length === 0) return "";

  const keyPoints = comparisonPoints.filter(
    (point) => point.rating === "NEUTRAL",
  );

  const keyDifferences = comparisonPoints.filter(
    (point) => point.rating !== "NEUTRAL",
  );

  return (
    <Wrapper data-test="comparable-card/comparison-points">
      <p css={mediumParagraph}>
        <strong>How this property compares to yours</strong>
      </p>

      {keyPoints.length > 0 && (
        <KeyPoints keyPoints={keyPoints} theme={theme} />
      )}

      {keyDifferences.length > 0 && (
        <KeyDifferences keyDifferences={keyDifferences} theme={theme} />
      )}
    </Wrapper>
  );
};
