import { css } from "styled-components";

export const headingContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0;
`;

export const heading = css`
  font-size: 21px;
  line-height: 31px;
  font-weight: 500;
  margin: 0;
`;

export const headingSubtext = css`
  font-size: 14px;
  line-height: 17px;
  opacity: 0.7;
  margin: 0 0 10px;
  font-weight: 400;
`;

export const listingViewsCounter = css`
  color: ${({ theme }) => theme.palette.blue500};
`;

export const legendBox = css`
  margin-top: 15px;
`;

export const legendItem = css`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 5px;
`;

export const lineLegendItem = css`
  margin-left: 24px;
  &:before {
    content: "";
    width: 15px;
    height: 3px;
    background-color: ${({ theme, lineColor }) =>
      lineColor === "pink"
        ? theme.palette.graphicalPink
        : theme.palette.blue500};
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 5px;
  }
`;

export const peakPeriodLegendItem = css`
  margin-left: 24px;
  &:before {
    content: "";
    width: 11px;
    height: 11px;
    background-color: ${({ theme }) => theme.palette.orange40};
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 2px;
  }
`;

export const legendItemText = css`
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
`;

export const legendItemSubtext = css`
  ${legendItemText}
  opacity: 0.7;
  ${({ noCaps }) =>
    noCaps ? "text-transform: unset; letter-spacing: unset;" : ""}
`;

const filtersWrapper = css`
  text-align: center;
  margin-top: 30px;
`;

const filterStyle = css`
  font-size: 14px;
  height: 24px;
  border-radius: 12px;
  font-weight: 500;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.blue500};
  margin: 0 5px;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.blue500};
  ${({ active }) =>
    active &&
    css`
      color: white;
      background-color: ${({ theme }) => theme.palette.blue500};
    `};
`;

export const SixteenWeeksFilter = ({ filter, setFilter }) => (
  <div css={filtersWrapper}>
    <button active={filter} onClick={() => setFilter(true)} css={filterStyle}>
      Latest 16 weeks
    </button>
    <button active={!filter} onClick={() => setFilter(false)} css={filterStyle}>
      All data
    </button>
  </div>
);
