// @flow

import React from "react";
import { gql } from "@apollo/client";
import styled, { css } from "styled-components";

import { type QueryRenderProps } from "@nested/utils";
import { ErrorMessage, media } from "@nested/brand";

import { ComparableCard } from "./ComparableCard";
import {
  ComparableFilters,
  PRICE_HIGH_TO_LOW,
  PRICE_LOW_TO_HIGH,
} from "./ComparableFilters";
import { AuthenticatedQuery } from "../../../../components/AuthenticatedQuery";
import { exampleComparableProperties } from "./exampleComparableProperties";

export const COMPARABLE_PROPERTIES_QUERY = gql`
  query PropertyAnalysisComparables($externalDealId: ID!) {
    comparableProperties(externalDealId: $externalDealId) {
      comparables {
        address
        comparableImages {
          url
        }
        comparisonPoints {
          description
          rating
        }
        link
        listingPrice
        numberOfBeds
        propertyType
        soldDate
        soldPrice
        status
        underOfferPrice
        underOfferDate
      }
    }
  }
`;

const NoResults = styled.p`
  color: ${({ theme }) => theme.palette.terracotta100};
  font-size: 14px;
  opacity: 0.5;
`;

const NoComparables = () => (
  <ErrorMessage>
    Sorry, we haven&#39;t selected any comparables for you yet
  </ErrorMessage>
);

const ComparableSlider = styled.div`
  display: grid;
  grid-gap: 15px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin: 20px -10px;
  grid-template-columns:
    0px
    repeat(${({ comparablesCount }) => comparablesCount}, calc(64vw)) 5px;
  ${media.tablet`
    display: block;
    overflow: visible;
    margin: 0;
    padding: 0;
  `}
  &::before,
  &::after {
    content: "";
  }
`;

const FilteredResults = ({ children }) => {
  if (children.length === 0)
    return (
      <NoResults>No comparable properties matching those filters</NoResults>
    );
  return children;
};

export const sortPriceHighToLow = (
  a: PropertyAnalysisComparables_comparableProperties_comparables, // eslint-disable-line
  b: PropertyAnalysisComparables_comparableProperties_comparables, // eslint-disable-line
) => {
  const aPrice = a.soldPrice || a.listingPrice || "0";
  const bPrice = b.soldPrice || b.listingPrice || "0";
  return parseInt(bPrice, 10) - parseInt(aPrice, 10);
};

export const sortPriceLowToHigh = (
  a: PropertyAnalysisComparables_comparableProperties_comparables, // eslint-disable-line
  b: PropertyAnalysisComparables_comparableProperties_comparables, // eslint-disable-line
) => {
  const aPrice = a.soldPrice || a.listingPrice || "0";
  const bPrice = b.soldPrice || b.listingPrice || "0";
  return parseInt(aPrice, 10) - parseInt(bPrice, 10);
};

type State = {
  otmSelected: boolean,
  soldSelected: boolean,
  underOfferSelected: boolean,
  withdrawnSelected: boolean,
  compFilteredCount: number,
  sortOrder: string,
};

type Props = {
  externalDealId: ?string,
  exampleMode?: boolean,
};

export class ComparableProperties extends React.Component<Props, State> {
  state = {
    otmSelected: true,
    soldSelected: true,
    underOfferSelected: true,
    withdrawnSelected: true,
    compFilteredCount: 0,
    sortOrder: PRICE_HIGH_TO_LOW,
  };

  createToggleFunction = (option: string) => () => {
    this.setState((prev) => ({
      [option]: !prev[option],
    }));
  };

  updateSortOrder = (newOrder: string) =>
    this.setState(() => ({
      sortOrder: newOrder,
    }));

  meetsFilterCriteria = (status: string) => {
    const { otmSelected, soldSelected, underOfferSelected, withdrawnSelected } =
      this.state;
    switch (true) {
      case status === "OTM" && otmSelected:
      case status === "UO" && underOfferSelected:
      case status === "Withdrawn" && withdrawnSelected:
      case status === "Sold" && soldSelected:
        return true;
      default:
        return false;
    }
  };

  getSortFunction = (sortOrder: string) => {
    switch (sortOrder) {
      case PRICE_LOW_TO_HIGH:
        return sortPriceLowToHigh;
      case PRICE_HIGH_TO_LOW:
        return sortPriceHighToLow;
      default:
        return sortPriceLowToHigh;
    }
  };

  render() {
    const { externalDealId, exampleMode } = this.props;
    const { sortOrder } = this.state;

    return (
      <>
        {externalDealId ? (
          <AuthenticatedQuery
            errorComponentName="Comparable properties"
            query={COMPARABLE_PROPERTIES_QUERY}
            variables={{ externalDealId }}
            skip={exampleMode}
          >
            {({ data }: QueryRenderProps<PropertyAnalysisComparables>) => {
              const comparableProperties = exampleMode
                ? exampleComparableProperties
                : data.comparableProperties;

              if (!comparableProperties) return <NoComparables />;

              const { comparables } = comparableProperties;
              if (!comparables || comparables.length === 0)
                return <NoComparables />;

              const filteredComps = comparables
                .filter((comp) => this.meetsFilterCriteria(comp.status))
                .sort(this.getSortFunction(sortOrder))
                .map((comparable, index) => (
                  <ComparableCard
                    key={index}
                    comparable={comparable}
                    data-test="comparable-card"
                  />
                ));
              return (
                <div
                  css={css`
                    margin-top: 20px;
                  `}
                  data-test="comparable-properties"
                >
                  <ComparableFilters
                    comparables={comparables}
                    state={this.state}
                    createToggleFunction={this.createToggleFunction}
                    updateSortOrder={this.updateSortOrder}
                  />
                  <ComparableSlider comparablesCount={filteredComps.length}>
                    <FilteredResults>{filteredComps}</FilteredResults>
                  </ComparableSlider>
                </div>
              );
            }}
          </AuthenticatedQuery>
        ) : (
          <NoComparables />
        )}
      </>
    );
  }
}
