import React from "react";
import styled from "styled-components";
import T from "prop-types";
import Slider from "react-slick";
import { InView } from "react-intersection-observer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { Pagination, PaginationText } from "./Pagination";
import { SlickStyles } from "./SlickStyles";
import { LightArrow } from "./LightArrows";

const PhotoCarouselContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* This stops the photo poking out the bottom of the carousel and looking all ugly. */
  overflow: hidden;
  ${media.tablet`
    max-width: calc(100vw - 360px); 
  `}
`;

const CarouselContainer = styled.div`
  border-radius: 4px 4px 0 0;
  height: calc(100vw / (690 / 455));
  max-height: 460px;
  position: relative;
  width: 100%;
  ${media.tablet`
    height: calc(90vw / (690 / 455));
  `};
`;

const Img = styled.img`
  border-radius: 4px 4px 0 0;
  height: calc(100vw / (690 / 455));
  max-height: 460px;
  object-fit: cover;
  width: 100%;

  ${media.tablet`
    height: calc(90vw / (690 / 455));
    object-fit: cover;
  `};
`;

class PhotoCarousel extends React.Component {
  defaultSettings = {
    afterChange: (newIndex) => this.setState({ currentIndex: newIndex + 1 }),
    nextArrow: <LightArrow next />,
    prevArrow: <LightArrow />,
    touchMove: false,
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.state = {
      currentIndex: 1,
    };
  }

  /*
   * this is all needed to prevent vertical scrolling on iOS while horizontally swiping
   * see: https://github.com/akiran/react-slick/issues/1240#issuecomment-396484553
   */
  componentDidMount() {
    this.containerRef.current.addEventListener("touchstart", this.touchStart);
    this.containerRef.current.addEventListener("touchmove", this.preventTouch, {
      passive: false,
    });
  }

  componentWillUnmount() {
    this.containerRef.current.removeEventListener(
      "touchstart",
      this.touchStart,
    );
    this.containerRef.current.removeEventListener(
      "touchmove",
      this.preventTouch,
      {
        passive: false,
      },
    );
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e) {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // disable vertical scrolling
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
    return true;
  }

  render() {
    const { className, photos } = this.props;
    const { currentIndex } = this.state;

    return (
      <PhotoCarouselContainer
        ref={this.containerRef}
        className={`${className} chromatic-ignore`}
      >
        <SlickStyles />
        <CarouselContainer>
          <InView triggerOnce>
            {({ ref }) => (
              <div ref={ref}>
                <Slider {...this.defaultSettings}>
                  {photos.map((photoUrl, index) => (
                    <div key={`${photoUrl + index}`} style={{ width: "100%" }}>
                      <Img src={photoUrl} alt="" />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </InView>
          <Pagination>
            <PaginationText>
              <FontAwesomeIcon
                icon={faCamera}
                style={{ marginRight: "10px" }}
              />
              <strong>{`${currentIndex} / ${photos.length}`}</strong>
            </PaginationText>
          </Pagination>
        </CarouselContainer>
      </PhotoCarouselContainer>
    );
  }
}

PhotoCarousel.propTypes = {
  photos: T.array,
};

export { PhotoCarousel };
