// @flow
import { useMutation } from "@apollo/client/react/hooks";
import { type DocumentNode } from "@apollo/client";
import { useAuth } from "@nested/auth";

export const useAuthenticatedMutation = <TData, TVariables>(
  mutation: DocumentNode,
  config: ?Object = {},
) => {
  const auth = useAuth();

  const refetchQueries = config?.refetchQueries || [];
  const queriesWithAuth = refetchQueries.map((query) => ({
    ...query,
    context: { auth },
  }));

  return useMutation<TData, TVariables>(mutation, {
    ...config,
    refetchQueries: queriesWithAuth,
    context: { auth },
  });
};
