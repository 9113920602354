// @flow
import styled from "styled-components";
import {
  largeSectionHeading,
  smallHeading,
  mediumParagraph,
  media,
} from "@nested/brand";

export const Wrapper = styled.div`
  margin: 0;
  ${media.desktop`
    padding: 0;
    margin: 0;
  `}
`;

export const PropertyCard = styled.div`
  border-radius: 0 0 4px 4px;
  background-color: white;
  margin-bottom: 20px;
  display: flex;
  scroll-snap-align: center;
  flex-direction: column;
`;

export const SectionTitle = styled.h2`
  ${largeSectionHeading};
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  background-color: ${({ theme }) => theme.palette.sand100};
  padding: 20px;
  ${media.tablet`
    font-size: 28px;
    background-color: unset;
    padding: 0;
  `}
`;

export const SectionBody = styled.div`
  margin: 30px 0px;
  padding: 0px 20px;
  ${media.tablet`
    padding: 0px;
  `}
`;

export const HorizontalLine = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

export const SectionDescription = styled.p`
  ${mediumParagraph}
  margin: 10px 0;
  width: unset;

  ${media.desktop`
    margin: 10px 0;
    text-align: left;
  `}
`;

export const SectionSubheading = styled.h3`
  ${smallHeading}
`;
