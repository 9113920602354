// @flow
import { AuthenticatedQuery } from "./AuthenticatedQuery";
import { useDeal } from "../../hooks/useDeal";

export const PublicDealQuery: typeof AuthenticatedQuery = ({
  variables = {},
  skip = false,
  ...rest
}) => {
  const { dealId } = useDeal();

  return (
    <AuthenticatedQuery
      {...rest}
      variables={{
        ...variables,
        dealId,
      }}
      skip={!dealId || skip}
    />
  );
};
