import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { useAuth } from "@nested/auth";
import { Sentry } from "@nested/isomorphic-sentry";
import { useAuthenticatedMutation } from "../../hooks/useAuthenticatedMutation";
import { EVENT_TYPE } from "../../gonative";

const MUTATION = gql`
  mutation RegisterPushToken($input: RegisterPushTokenInput!) {
    registerPushToken(input: $input) {
      successful
    }
  }
`;

export const PushTokenRegistration = () => {
  const { authenticated } = useAuth();
  const [mutate] = useAuthenticatedMutation(MUTATION);
  const [token, setToken] = useState(window.pushInfo?.oneSignalUserId);

  useEffect(() => {
    const handler = (e) => {
      setToken(e?.detail?.oneSignalUserId);
    };
    window.addEventListener(EVENT_TYPE, handler);

    return () => {
      window.removeEventListener(EVENT_TYPE, handler);
    };
  }, []);

  useEffect(() => {
    const fn = async () => {
      if (!token || !authenticated) {
        return;
      }

      try {
        const res = await mutate({
          variables: {
            input: {
              token,
            },
          },
        });
        if (!res?.data?.registerPushToken?.successful) {
          Sentry.captureMessage("Failed to register push token", {
            extra: {
              token,
              response: JSON.stringify(res),
            },
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    fn();
  }, [token, authenticated]);

  return null;
};
