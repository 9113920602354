// @flow
import { useState, useEffect } from "react";
import { groupBy } from "lodash";
import { ViewingAvailabilityUI } from "./ViewingAvailabilityUI";
import { initialTimeslots } from "./initialTimeslots";

type timeSlotState = {
  [id: string]: ViewingAvailability_publicDeal_viewingAvailability_timeslots,
};

export const rangesFromTimeslots = (
  timeslots: timeSlotState,
): ViewingAvailability_publicDeal_viewingAvailability_availabilitySummaries[] => {
  const slotArray = Object.values(timeslots);
  const grouped = groupBy(slotArray, (t) => t.dayOfWeek);
  return Object.keys(grouped).map((key) => {
    const slots = grouped[key];
    return {
      __typename: "ViewingAvailabilitySummary",
      dayOfWeek: Number(key),
      ranges: getRangesForDay(slots),
    };
  });
};

const newRange = ({ timeSlotStart, timeSlotEnd }) => {
  return {
    __typename: "ViewingAvailabilityRange",
    rangeStart: timeSlotStart,
    rangeEnd: timeSlotEnd,
  };
};

const updateEndTime = (range, newEndTime) => {
  return {
    ...range,
    rangeEnd: newEndTime,
  };
};

const getRangesForDay = (timeslots) => {
  const { list: ranges, curr: last } = timeslots.reduce(
    ({ list, curr }, slot) => {
      const { available, timeSlotEnd } = slot;
      if (curr === null && available) {
        return { list, curr: newRange(slot) };
      }
      if (curr === null && !available) {
        return { list, curr };
      }
      if (available) {
        return { list, curr: updateEndTime(curr, timeSlotEnd) };
      }
      // not available in this timelot
      return { list: list.concat([curr]), curr: null };
    },
    { list: [], curr: null },
  );
  if (last !== null) {
    return ranges.concat([last]);
  }
  return ranges;
};

const initialSummaryData = rangesFromTimeslots(initialTimeslots);

export const MockViewingAvailability = () => {
  const [timeSlots, setTimeSlots] = useState(initialTimeslots);
  const [summaryData, setSummaryData] = useState(initialSummaryData);
  const [calledUpdateAvailability, setCalledUpdateAvailability] =
    useState(false);
  const [calledUpdateFullDay, setCalledUpdateFullDay] = useState(false);

  const updateAvailability = ({
    variables: {
      id,
      input: { available },
    },
  }) => {
    setCalledUpdateAvailability(true);
    const slot = timeSlots[id];
    const updatedSlot = { ...slot, available };
    setTimeSlots({ ...timeSlots, [id]: updatedSlot });
  };

  const updateFullDayAvailability = ({
    variables: {
      input: { dayOfWeek, available },
    },
  }) => {
    setCalledUpdateFullDay(true);
    const newState = Object.keys(timeSlots).reduce((acc, k) => {
      const curr = timeSlots[k];
      if (curr.dayOfWeek === dayOfWeek) {
        const newSlot = { ...curr, available };
        return { ...acc, [Number(curr.id)]: newSlot };
      }
      return { ...acc, [Number(curr.id)]: curr };
    }, {});
    setTimeSlots(newState);
  };

  useEffect(() => {
    const newSummaryData = rangesFromTimeslots(timeSlots);
    setSummaryData(newSummaryData);
  }, [timeSlots]);

  const arrayTimeslots: any = Object.values(timeSlots);

  return (
    <ViewingAvailabilityUI
      calledUpdateAvailability={calledUpdateAvailability}
      calledUpdateFullDay={calledUpdateFullDay}
      dealId="123"
      exampleMode
      loading={false}
      saving={false}
      savingFullDay={false}
      summaryData={summaryData}
      timeSlots={arrayTimeslots}
      updateAvailability={updateAvailability}
      updateFullDayAvailability={updateFullDayAvailability}
    />
  );
};
