// @flow
import {
  Switch,
  Route,
  withRouter,
  type ContextRouter,
} from "react-router-dom";
import { ScrollToTop } from "@nested/ui";
import { Routes } from "../../Routes";

type Props = ContextRouter;

const MainComponent = ({ location: { pathname } }: Props) => (
  <ScrollToTop pathname={pathname}>
    <Switch>
      <Route path="/" component={Routes} />
    </Switch>
  </ScrollToTop>
);

export const Main = withRouter(MainComponent);
