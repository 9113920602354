import { useEffect } from "react";
import qs from "query-string";
import { useLocation } from "react-router";
import { ErrorPage } from "@nested/brand";
import { Sentry } from "@nested/isomorphic-sentry";

/*
 * If auth0 login fails due to a configuration issue, they will redirect to this page
 * with a bunch of query params containing debug information.
 */
export const ErrorCallback = () => {
  const { search } = useLocation();
  const params = qs.parse(search);

  useEffect(() => {
    Sentry.captureMessage("[ErrorCallback] Auth0 login error", {
      urlParams: params,
    });
  }, []);

  return <ErrorPage refreshPath="/" />;
};
