export const Rightmove = ({ className }) => (
  <svg
    className={className}
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#20D2B2" fillRule="nonzero">
        <path
          d="M13.3429916,15.8649569 L15.825751,15.8649569 L15.8147325,8.41033863 L8.99816392,2.78421159 L2.17792291,8.41033863 L2.17057759,15.8649569 L4.64231809,15.8649569 L9.00183665,12.2548588 L13.3429916,15.8649569 Z M16.119568,18 L12.549684,18 L8.99816392,15.0498899 L5.4356256,18 L1.87308728,18 C1.36992463,18 0.899816406,17.808856 0.547235276,17.4590262 C0.190981557,17.1091966 0,16.6475656 0,16.1534763 L0.0110181913,8.27329195 C0.0110181913,7.78641557 0.201999607,7.32839112 0.547235276,6.98216792 L0.624362474,6.91364457 L8.99816392,0 L17.4527651,6.98577442 C17.7980012,7.33199761 17.9889829,7.79002205 17.9889829,8.27329195 L18,16.1534763 C18,16.6475656 17.8053464,17.1091966 17.4490919,17.4590262 C17.096512,17.8052494 16.6227303,18 16.119568,18"
          id="rightmove"
        ></path>
      </g>
    </g>
  </svg>
);
