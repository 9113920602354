import moment from "moment";

const fourDaysAgo = () => moment().subtract(4, "days");

export const firstImpressionsExampleEnquiry = {
  __typename: "PublicEnquiry",
  id: "9",
  buyer: {
    id: "9",
    avatarUrl:
      "https://nested.imgix.net/account/buyer-avatars/avatars_9_B.png?bg=FFE7AA",
    firstName: "Francis",
    surnameInitial: "Flamingo",
    buyerPosition: "Property to sell - under offer",
    financialPosition: "Mortgage in principle obtained",
    hasPhone: true,
    hasEmail: true,
  },
  enquiryStatus: "VIEWING_BOOKED",
  falloutDetails: null,
  currentlyInterested: true,
  contactLastUpdatedAt: moment()
    .subtract(1, "days")
    .set("hour", 13)
    .set("minute", 0)
    .format("YYYY-MM-DD"),
  lastUpdated: moment()
    .subtract(1, "days")
    .set("hour", 13)
    .set("minute", 0)
    .format("YYYY-MM-DD"),
  datetimeEnquiryCreated: moment().subtract(5, "days").format("YYYY-MM-DD"),
  viewings: [
    {
      _buyerEnquiryId: "2",
      id: "1",
      viewingSaName: "Seymour Holmes",
      viewingSaEmail: "jessie+seymour@nested.com",
      conductor: "sales_associate",
      virtual: false,
      buyerCurrentlyInterested: false,
      dateTimeStart: fourDaysAgo().set({ hour: 10, minute: 0 }).toISOString(),
      number: 1,
      firstImpressions:
        "<p>The viewing went well and the buyer seemed interested.</p>",
      datetimeFirstImpressionsShared: fourDaysAgo()
        .set({ hour: 12, minute: 1 })
        .toISOString(),
      fullFeedback: `<h3>What they liked: </h3><p>The living room and the kitchen especially. The location was a big factor in why they came to see it in the first place too. She also loved the style and standard of finish you have, although he was reminding her she can't make a decision based on the artwork!</p><h3>What they didn't like: </h3><p>The size of the bedrooms - they are worried about having enough storage space in the bedrooms and also in general.</p><h3>Thoughts on price: </h3><p>They thought it was fairly priced</p><h3>Next steps: </h3><p>They are comparing this to houses so need to decide if they would rather be more centrally located and have a flat, or further out for a house.</p>`,
      datetimeFullFeedbackShared: fourDaysAgo()
        .add(1, "day")
        .set({ hour: 9, minute: 2 })
        .toISOString(),
      viewingFeedbackLastUpdated: fourDaysAgo()
        .add(1, "day")
        .set({ hour: 15, minute: 50 })
        .toISOString(),
      followUpFeedback: [
        {
          id: "1",
          datetimeSharedWithSeller: fourDaysAgo()
            .add(2, "day")
            .set({ hour: 9, minute: 40 })
            .toISOString(),
          feedback:
            "<p>They have requested to view the property again, which we’ve just booked </p>",
        },
      ],
    },
  ],
};
