// @flow
import { Fragment, useEffect, useState } from "react";
import { css } from "styled-components";
import moment from "moment";
import {
  Modal,
  largeParagraph,
  mediumParagraph,
  smallParagraph,
  Gravatar,
  media,
} from "@nested/brand";
import { formatDateTime } from "@nested/utils/src/formatDate/formatDate";
import {
  ModalAvatar,
  BuyerAvatarLarge,
} from "../../components/BuyerAvatar/BuyerAvatar";
import { LI, UL } from "../../components/UnorderedList";
import { textOrdinal } from "./utils";
import { ExampleCapsuleForModal } from "../../components/SectionCard";

export const formatFeedback = (feedback: ?string) => {
  if (!feedback) {
    return null;
  }
  if (/<p>/gm.test(feedback)) {
    const nostyles = feedback.replace(/style="[a-zA-Z0-9:;,-\s.()]*"/gi, "");
    return nostyles.replace(/\n/gm, "");
  }
  return feedback;
};

const placeholderText = css`
  ${smallParagraph}
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.blue20};
  border: 1px solid ${({ theme }) => theme.palette.blue40};
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  padding: 10px;
  margin-top: 20px;
`;

const modalStyle = css`
  max-width: 315px;
  padding: 0;
  ${media.tablet`
    max-width: unset;
    width: 690px;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
  `}
`;

const buyerNameStyle = css`
  ${largeParagraph}
  text-align: center;
  font-weight: 500;
  line-height: 24px;
  margin: 5px 0 0;
  ${media.tablet`
    display: none;
  `}
`;

const buyerInterestStyle = css`
  ${mediumParagraph}
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  margin: 0;
  color: ${({ currentlyInterested, theme }) =>
    currentlyInterested ? theme.palette.green : theme.palette.terracotta500};
  ${media.tablet`
    display: none;
  `}
`;

const buyerNameStyleTablet = css`
  ${buyerNameStyle}
  display: none;
  ${media.tablet`
    display: block;
    color: white;
    text-align: left;
    margin: 20px 0 0;
 `}
`;

const buyerInterestStyleTablet = css`
  ${buyerInterestStyle}
  display: none;
  ${media.tablet`
    display: block;
    color: white;
    text-align: left;
    margin: 0 0 20px 0;
  `}
`;

const feedbackHeading = css`
  ${mediumParagraph}
  font-weight: 500;
  margin: 20px 0 0;
`;

const feedbackContainer = css`
  ${smallParagraph}
  margin: 10px 0 0;
  /* Preserves new lines */
  white-space: pre-wrap;
  p strong {
    display: block;
    font-weight: 500;
    margin-top: 20px;
  }
  h3 {
    ${smallParagraph}
    margin: 20px 0 0;
    font-weight: 500;
    & + p {
      margin-top: 0px;
    }
  }
  p {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

const followUpFeedbackContainer = css`
  ${feedbackContainer}
  margin: 0px;
`;

const saWrapper = css`
  display: flex;
  align-items: center;
  margin: 20px 0 0;
`;

const viewingDate = css`
  ${saWrapper}
  div {
    font-weight: 500;
  }
`;

const hostedBy = css`
  ${smallParagraph}
  font-weight: 500;
`;

const updatedText = css`
  ${smallParagraph}
  font-weight: 500;
  margin-top: 20px;
`;

const followUpFeedbackHeading = css`
  ${smallParagraph}
  font-weight: 500;
  margin: 20px 0 0;
`;

const gravatarStyle = css`
  margin: 0 15px 0 0;
`;

const dateHosted = css`
  ${smallParagraph}
`;

const buyerDetailsSection = css`
  background-color: ${({ theme }) => theme.palette.hague};
  padding: 15px;
  border-radius: 0 0 5px 5px;
  li {
    color: white;
  }
  li:before {
    background-color: ${({ theme }) => theme.palette.pink100};
  }
  ul {
    margin-top: 0;
  }
  ${media.tablet`
    box-sizing: border-box;
    width: 240px;
    min-width: 240px;
    padding: 40px 30px;
    border-radius: 5px 0 0 5px;
  `}
`;

const mainWrapper = css`
  flex-grow: 1;
`;

const modalHeaderContent = css`
  padding: 45px 20px 0px;
  ${media.tablet`
    padding: 0;
  `}
`;

const tabsSection = css`
  position: relative;
  height: 55px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  margin-top: 16px;
  ${media.tablet`
    margin-top: 0;
    height: 65px;
  `}
`;

const tabsWrapper = css`
  box-sizing: border-box;
  max-width: 100%;
  margin-top: 20px;
  padding: 0 20px;
  white-space: nowrap;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  /* 
  right-to-left is a Hack to make sure we are scrolled to the far right when
  modal opens. It reverses the element order and may cause mild mind fuck.
   */
  direction: rtl;
  ${media.tablet`
    margin-top: 30px;
    padding: 0 40px;
  `}
`;

const tabStyle = css`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  padding: 0;
  border: none;
  padding-bottom: 14px;
  background: white;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: ${({ theme, selected }) =>
    selected ? `2px solid ${theme.palette.blue150}` : "none"};
  color: ${({ theme, selected }) =>
    selected ? theme.palette.blue150 : theme.palette.hague40};
  &:not(:first-of-type) {
    margin-right: 20px;
    ${media.tablet`
      margin-right: 30px;
    `}
  }
  &:last-of-type {
    margin-left: 20px;
    ${media.tablet`
      margin-left: 40px;
    `}
  }
  /* This is to override left margin when there are 2 tabs... */
  &:nth-of-type(2) {
    margin-left: 0px;
  }
`;

const tabBody = css`
  padding: 10px 20px 20px;
  ${media.tablet`
    padding: 20px 40px 40px;
  `}
`;

const feedbackDivider = css`
  margin: 20px 0px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
`;

const FeedbackTab = ({ viewing, buyerFirstName }) => {
  // Should never happen but the return type of `.find` is nullable
  if (!viewing) {
    return null;
  }
  const {
    firstImpressions,
    fullFeedback,
    viewingSaName,
    viewingSaEmail,
    dateTimeStart,
    viewingFeedbackLastUpdated,
  } = viewing;

  const saHosted = viewing.conductor === "sales_associate";

  const firstImpressionsFormatted = formatFeedback(firstImpressions);
  const fullFeedbackFormatted = formatFeedback(fullFeedback);

  const updatedAt = formatDateTime(
    viewingFeedbackLastUpdated || dateTimeStart,
    {
      appendTime: false,
    },
  );
  const viewingTime = formatDateTime(dateTimeStart, {
    appendTime: true,
  });

  return (
    <div css={tabBody}>
      {viewing.followUpFeedback.map((f) => (
        <Fragment key={f.id}>
          <h4 css={followUpFeedbackHeading}>
            Update: {moment(f.datetimeSharedWithSeller).format("D MMM")}
          </h4>
          <div
            css={followUpFeedbackContainer}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: formatFeedback(f.feedback) }}
          />
        </Fragment>
      ))}
      {viewing.followUpFeedback.length > 0 && viewing.fullFeedback && (
        <hr css={feedbackDivider} />
      )}
      {viewing.fullFeedback && (
        <>
          <h4 css={feedbackHeading}>Detailed feedback</h4>
          <div
            css={feedbackContainer}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: fullFeedbackFormatted }}
          />
        </>
      )}
      {viewing.fullFeedback && viewing.firstImpressions && (
        <hr css={feedbackDivider} />
      )}
      {viewing.firstImpressions && (
        <>
          <h4 css={feedbackHeading}>First impressions</h4>
          <div
            css={feedbackContainer}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: firstImpressionsFormatted }}
          />
        </>
      )}
      {viewing.firstImpressions && !viewing.fullFeedback && (
        <div css={placeholderText}>
          We’re following up with {buyerFirstName} for more detailed feedback
          and will let you know when we hear back.
        </div>
      )}
      {(viewing.fullFeedback || viewing.firstImpressions) && (
        <div css={updatedText}>Updated {updatedAt}</div>
      )}
      {!viewing.fullFeedback && !viewing.firstImpressions && (
        <div css={placeholderText}>
          No feedback yet, we’re following up with {buyerFirstName} and will let
          you know when we hear back.
        </div>
      )}
      {saHosted ? (
        <div css={saWrapper}>
          <Gravatar css={gravatarStyle} email={viewingSaEmail} />
          <div css="flex-grow: 1; flex-shrink: 0;">
            <div css={hostedBy}>Hosted by {viewingSaName}</div>
            <div css={dateHosted}>Viewed {viewingTime}</div>
          </div>
        </div>
      ) : (
        <div css={viewingDate}>
          <div css={dateHosted}>Viewed {viewingTime}</div>
        </div>
      )}
    </div>
  );
};

type Props = {
  buyerEnquiry: {
    ...ViewingFeedbackDeal_publicDeal_buyerEnquiries,
    lastUpdated: ?DateTime,
  },
  open: boolean,
  onClose: () => void,
  next?: ?() => void,
  previous?: ?() => void,
  exampleMode?: boolean,
};

export const ViewingFeedbackModal = ({
  open,
  onClose,
  buyerEnquiry,
  next,
  previous,
  exampleMode,
}: Props) => {
  const { buyer, viewings } = buyerEnquiry;
  const { firstName, avatarUrl } = buyer;

  const hasMultipleViewings = viewings.length > 1;

  const [selectedTab, setSelectedTab] = useState(viewings[0].number);

  useEffect(() => {
    // Reset the selected tab if a different enquiry is selected
    setSelectedTab(viewings[0].number);
  }, [viewings]);

  const selectedViewing = viewings.find(({ number }) => number === selectedTab);

  return (
    <Modal
      open={open}
      onClose={onClose}
      css={modalStyle}
      next={next}
      previous={previous}
      data-test={`feedback-modal-${buyer.id}`}
    >
      {exampleMode && <ExampleCapsuleForModal />}
      <ModalAvatar
        avatarUrl={avatarUrl}
        withCross={!buyerEnquiry?.currentlyInterested}
      />
      <div css={mainWrapper}>
        <div css={modalHeaderContent}>
          <h3 css={buyerNameStyle}>
            {buyer.firstName} {buyer.surnameInitial}
          </h3>
          <p
            currentlyInterested={buyerEnquiry.currentlyInterested}
            css={buyerInterestStyle}
          >
            {buyerEnquiry.currentlyInterested
              ? "Still interested"
              : "Not interested"}
          </p>
        </div>
        {hasMultipleViewings && (
          <div css={tabsSection}>
            <div css={tabsWrapper}>
              {viewings.map(({ number }) => (
                <button
                  key={number}
                  css={tabStyle}
                  selected={selectedTab === number}
                  onClick={() => setSelectedTab(number)}
                >
                  {textOrdinal(number)} viewing
                </button>
              ))}
            </div>
          </div>
        )}
        <FeedbackTab viewing={selectedViewing} buyerFirstName={firstName} />
      </div>
      <div css={buyerDetailsSection}>
        <BuyerAvatarLarge
          avatarUrl={avatarUrl}
          withCross={!buyerEnquiry?.currentlyInterested}
          tabletOnly
        />
        <h3 css={buyerNameStyleTablet}>
          {buyer.firstName} {buyer.surnameInitial}
        </h3>
        <p css={buyerInterestStyleTablet}>
          {buyerEnquiry.currentlyInterested
            ? "Still interested"
            : "Not interested"}
        </p>
        <UL>
          {buyer.buyerPosition && <LI>{buyer.buyerPosition}</LI>}
          {buyer.financialPosition && <LI>{buyer.financialPosition}</LI>}
        </UL>
      </div>
    </Modal>
  );
};
