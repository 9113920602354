// @flow
import { css } from "styled-components";

import { ButtonLink } from "@nested/component-library";
import { media, Modal, ResponsiveImage, smallParagraph } from "@nested/brand";
import { BookingSection, NoBookingSection } from "./BookingSection";
import { H1 } from "../Heading";

const modalStyle = css`
  max-width: 400px;
  padding: 20px 15px;
  ${media.tablet`
    max-width: unset;
    width: 830px;
    padding: 0;
    display: flex;
    flex-direction: row;
  `}
`;

const contentWrapper = css`
  width: 100%;
  ${media.tablet`
    padding: 55px 30px;
  `}
`;

const thankYouStyle = css`
  ${smallParagraph};
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 21px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.hague70};
  ${media.tablet`
    font-size: 14px;
    margin-bottom: 10px;
  `}
`;

const titleStyle = css`
  font-size: 24px;
  ${media.tablet`
    font-size: 34px;
  `}
`;

const illustration = css`
  display: none;
  ${media.tablet`
    display: flex;
    align-items: center;
    border-radius: 5px 0 0 5px;
    width: 115%;
    background-color: ${({ theme }) => theme.palette.sand100};
  `}
`;

const imageStyle = css`
  object-fit: cover;
  width: 100%;
  height: 80%;
`;

const buttonStyle = css`
  box-sizing: border-box;
  display: block;
  text-align: center;
  margin-top: 20px;
  height: 45px;
  padding: 2px 0 5px 0;
  ${media.tablet`
    margin-top: 25px;
    width: 200px;
  `}
`;

type Props = {|
  agent: ?string,
  bookingDate: ?string,
  contactBooked: ?string,
  open: boolean,
  onClose: () => void,
|};

export const OnboardingModal = ({
  open,
  onClose,
  agent,
  bookingDate,
  contactBooked,
}: Props) => (
  <Modal
    open={open}
    onClose={onClose}
    css={modalStyle}
    data-test="onboarding-modal"
    wideModal
  >
    <div css={illustration}>
      <ResponsiveImage
        src="https://nested.imgix.net/illustrations/couple_with_dog.png"
        alt="couple with dog"
        css={imageStyle}
      />
    </div>
    <div css={contentWrapper}>
      <div css={thankYouStyle}>Thank you</div>
      <H1 css={titleStyle}>What happens next?</H1>
      {contactBooked && contactBooked !== "none" && agent && bookingDate ? (
        <BookingSection
          contactBooked={contactBooked}
          agent={agent}
          bookingDate={bookingDate}
        />
      ) : (
        <NoBookingSection />
      )}
      <div css={smallParagraph}>
        While you wait, you can explore your account and see what makes Nested
        the modern agent.
      </div>
      <ButtonLink
        css={buttonStyle}
        onClick={onClose}
        type="primary"
        data-test="access-my-account"
      >
        Access my account
      </ButtonLink>
    </div>
  </Modal>
);
