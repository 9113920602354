// @flow
import { css } from "styled-components";
import { smallParagraph } from "@nested/brand";
import { formatDateTime } from "@nested/utils/src/formatDate/formatDate";
import { BuyerListItem } from "../../components/BuyerListItem";
import { findLastUpdatedFeedback, ordinal } from "./utils";

export const generateTeaserText = (feedback: ?string) => {
  if (!feedback) {
    return null;
  }
  const removeNewlines = feedback.replace(/(\n+|<br>+|<\/p>)/gi, " ");
  const removehtml = removeNewlines.replace(/(<([^>]+)>)/gi, "");
  const removeDoubleSpaces = removehtml.replace(/\s+/gm, " ");
  const removeNbsps = removeDoubleSpaces.replace(/&nbsp;/, " ");
  return removeNbsps.trim();
};

const viewingDetails = css`
  ${smallParagraph}
  span {
    font-weight: 500;
  }
  margin: 0;
`;

const gatheringFeedback = css`
  ${smallParagraph}
  color: #FFAB19;
  font-weight: 500;
  margin: 5px 0 0;
`;

const feedbackTeaser = css`
  ${smallParagraph}
  color: ${({ theme }) => theme.palette.hague70};
  font-style: italic;
  margin: 5px 0 0;
  /* magic multiline ellipsis overflow https://css-tricks.com/line-clampin/ */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  span {
    font-style: normal;
    font-weight: 500;
  }
`;

const updatedAtStyles = css`
  ${smallParagraph}
  font-weight: 500;
  margin: 5px 0 0;
`;

const viewingCardWrapper = css`
  margin-top: 15px;
`;

type Props = {
  enquiry: {
    ...ViewingFeedbackDeal_publicDeal_buyerEnquiries,
    lastUpdated: ?DateTime,
  },
  onClick: () => void,
};

export const ViewingCard = ({ enquiry, onClick }: Props) => {
  const { buyer, viewings, currentlyInterested, lastUpdated } = enquiry;
  const latestViewing = viewings[0];

  const {
    number: greatestViewingNumber,
    dateTimeStart: mostRecentViewingStart,
  } = latestViewing;

  const buyerLastUpdated = lastUpdated || mostRecentViewingStart;

  const { feedbackContent, feedbackHeading } = findLastUpdatedFeedback({
    ...latestViewing,
  });
  const teaserText = generateTeaserText(feedbackContent);
  const updatedAt = formatDateTime(buyerLastUpdated, {
    appendTime: false,
  });
  const viewingTime = formatDateTime(mostRecentViewingStart, {
    appendTime: true,
  });

  return (
    <div css={viewingCardWrapper}>
      <BuyerListItem
        buyer={{ ...buyer }}
        showNotInterestedLabels
        buyerCurrentlyInterested={currentlyInterested}
        withCrossIcon={!currentlyInterested}
        onClick={onClick}
        itemId={buyer.id}
      >
        <p css={viewingDetails}>
          <span>{ordinal(greatestViewingNumber)} viewing</span> {viewingTime}
        </p>
        {feedbackContent ? (
          <p css={feedbackTeaser}>
            <span>{feedbackHeading} - </span>
            {teaserText}
          </p>
        ) : (
          <p css={gatheringFeedback}>Gathering feedback...</p>
        )}
        <p css={updatedAtStyles}>Updated {updatedAt}</p>
      </BuyerListItem>
    </div>
  );
};
