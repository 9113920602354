// @flow
import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import moment from "moment";
import { css } from "styled-components";
import { smallParagraph, media } from "@nested/brand";
import { formatDateTime } from "@nested/utils/src/formatDate/formatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import { AuthenticatedQuery } from "../../components/AuthenticatedQuery";

// This is a function so that moment() is called at the right time
const exampleChasingData = () => ({
  buyerEnquiryAttemptedContact: {
    dayOne: {
      date: moment().subtract(1, "days").format("YYYY-MM-DD"),
      sms: true,
      call: true,
      email: true,
    },
    dayTwo: {
      date: moment().format("YYYY-MM-DD"),
      sms: true,
      call: true,
      email: true,
    },
    dayThree: null,
    taskScheduled: false,
  },
});

const BUYER_ATTEMPTED_CONTACT_QUERY = gql`
  query AttemptedBuyerContact($buyerEnquiryId: String!) {
    buyerEnquiryAttemptedContact(buyerEnquiryId: $buyerEnquiryId) {
      dayOne {
        date
        sms
        call
        email
      }
      dayTwo {
        date
        sms
        call
        email
      }
      dayThree {
        date
        sms
        call
        email
      }
      taskScheduled
    }
  }
`;

const wrapper = css`
  padding: 0 20px 20px;
  ${media.tablet`
    padding: 40px;
  `}
`;

const chasingTextStyles = css`
  ${smallParagraph}
  margin: 0;
`;

const contactStrategy = css`
  margin-top: 20px;
`;

const updatedAtText = css`
  ${smallParagraph}
  margin: 20px 0 0;
  font-weight: 500;
`;

const rowStyle = css`
  display: flex;
  justify-content: space-between;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

const rowHeadingStyle = css`
  font-size: 14px;
  letter-spacing: 1.5px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme, boldText }) =>
    boldText ? theme.palette.hague : theme.palette.hague40};
`;

const icon = css`
  box-sizing: border-box;
  background-color: ${({ highlighted, theme }) =>
    highlighted ? theme.palette.orange : theme.palette.hague40};
  border-radius: 100%;
  width: 24px;
  height: 24px;
  color: white;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
  padding: 4px 5px 6px;
`;

const iconGroup = css`
  display: flex;
`;

export const getDayText = (date: ?string, dayNumber: string) => {
  if (!date) {
    return `Day ${dayNumber}`;
  }
  if (isToday(date)) {
    return "Today";
  }
  return formatDateTime(date, { appendTime: false });
};

const isToday = (date) =>
  !date ? false : moment(date).isSame(moment(), "days");

const TableRow = ({ data, dayNumber, buyer: { hasEmail, hasPhone } }) => {
  const rowHeader = getDayText(data?.date, dayNumber);

  return (
    <div css={rowStyle} data-test={`contact-row-day-${dayNumber}`}>
      <div css={rowHeadingStyle} boldText={data?.date}>
        {rowHeader}
      </div>
      <div css={iconGroup}>
        {hasPhone && (
          <div css={icon} highlighted={data?.call} data-test="phone-icon">
            <FontAwesomeIcon icon={faPhone} />
          </div>
        )}
        {hasEmail && (
          <div css={icon} highlighted={data?.email} data-test="email-icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
        )}
        {hasPhone && (
          <div css={icon} highlighted={data?.sms} data-test="sms-icon">
            <FontAwesomeIcon icon={faCommentDots} />
          </div>
        )}
      </div>
    </div>
  );
};

const getChasingText = (
  { firstName, surnameInitial, hasEmail, hasPhone },
  taskScheduled,
) => {
  if (taskScheduled) {
    return "The buyer asked us to contact them again in the future, we've made a note to follow up.";
  }
  if (!hasPhone) {
    return "This buyer did not provide a phone number, so we will email them for 3 days to try and book a viewing.";
  }
  if (!hasEmail) {
    return "This buyer did not provide an email, so we will call and text them for 3 days to try and book a viewing.";
  }

  return `We’ll call, email, and text ${firstName} ${surnameInitial} for 3 days to try and book a viewing.`;
};

type Props = {
  enquiryId: string,
  buyer: EnquiriesPageQuery_publicDeal_buyerEnquiries_buyer,
  open: ?boolean,
  updatedAt: string,
  exampleMode?: boolean,
};

export const ChasingNowContent = ({
  buyer,
  enquiryId,
  open,
  updatedAt,
  exampleMode,
}: Props) => {
  const [hasBeenOpened, setHasBeenOpened] = useState(open);

  useEffect(() => {
    // Allows us to skip the query until the first time the modal is opened
    if (open) {
      setHasBeenOpened(true);
    }
  }, [open]);

  return (
    <AuthenticatedQuery
      query={BUYER_ATTEMPTED_CONTACT_QUERY}
      variables={{ buyerEnquiryId: enquiryId }}
      skip={!hasBeenOpened || exampleMode}
    >
      {({ data }) => {
        if (!data && !exampleMode) {
          return null;
        }
        const dataToShow = exampleMode ? exampleChasingData() : data;
        const {
          buyerEnquiryAttemptedContact: {
            dayOne,
            dayTwo,
            dayThree,
            taskScheduled,
          },
        } = dataToShow;
        const chasingText = getChasingText(buyer, taskScheduled);

        return (
          <div
            css={wrapper}
            data-test={`enquiries-modal-${enquiryId}-chasing-now`}
          >
            <p css={chasingTextStyles}>{chasingText}</p>
            <div css={contactStrategy}>
              <TableRow data={dayOne} dayNumber="1" buyer={buyer} />
              <TableRow data={dayTwo} dayNumber="2" buyer={buyer} />
              <TableRow data={dayThree} dayNumber="3" buyer={buyer} />
            </div>
            <p css={updatedAtText}>Updated {updatedAt}</p>
          </div>
        );
      }}
    </AuthenticatedQuery>
  );
};
