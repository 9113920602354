// @flow
import { css } from "styled-components";
import { faMapSigns } from "@fortawesome/free-solid-svg-icons";
import { media, PlaceholderCard } from "@nested/brand";
import { DEAL_QUERY } from "../Listing/Listing";
import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { PageHeader } from "../../components/Heading";
import { HeroSection } from "../../components/HeroSection/HeroSection";
import { TabletTabNavigation } from "../../components/Navigation/TabNavigation";
import {
  LayoutWrapper,
  MainContent,
  PurchasePageWrapper as PageWrapper,
} from "../PageWrapper";
import {
  SectionWrapper,
  SectionHeader,
  IconAndText,
  SectionHeaderSubtext,
  UnpaddedSectionCard,
  SectionCardPadding,
  FullWidthCardButton,
  SectionDivider,
} from "../../components/SectionCard";

const pointWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;

  &:before {
    content: "";
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 19px;
    background-color: #ff920f;
    border-radius: 50%;
    margin-left: 3px;
    width: 10px;
    height: 10px;
  }
`;

const BulletPoint = ({
  children,
  className,
}: {
  children: string,
  className?: string,
}) => (
  <div css={pointWrapper} className={className}>
    <p css="text-align: left;margin:20px 0 0;">{children}</p>
  </div>
);

const pointsWrapper = css`
  font-size: 14px;
`;

const firstBulletStyle = css`
  p {
    margin-top: 0;
  }
`;

const dividerStyle = css`
  margin: 15px 0;

  ${media.tablet`
    margin: 30px 0;
  `}
`;

export const BuyingGuides = () => {
  const { loading, data } = usePublicDealQuery(DEAL_QUERY);

  if (loading) {
    return <PlaceholderCard />;
  }

  const closed =
    data?.publicDeal?.isListed ||
    data?.publicDeal?.opportunityStatusValue === "s07_closed";

  return (
    <LayoutWrapper>
      <HeroSection />
      <MainContent>
        <TabletTabNavigation />
        <>
          <PageWrapper>
            <PageHeader data-test="buying-guides-header">Guides</PageHeader>
            <SectionWrapper>
              <SectionHeader>
                Buying guides
                {!closed && (
                  <SectionHeaderSubtext>
                    Available after listing
                  </SectionHeaderSubtext>
                )}
              </SectionHeader>
              <UnpaddedSectionCard data-test="buying-guides-card">
                <SectionCardPadding>
                  <IconAndText
                    icon={faMapSigns}
                    text="Nested's expert buying agents give away the secrets to a successful purchase."
                  />
                  <SectionDivider css={dividerStyle} />
                  <div css={pointsWrapper}>
                    <BulletPoint css={firstBulletStyle}>
                      Killer questions to ask during a viewing.
                    </BulletPoint>
                    <BulletPoint>
                      How to win your ideal home in a competitive market.
                    </BulletPoint>
                    <BulletPoint>
                      How to avoid being in the 30% of purchases that fall
                      through.
                    </BulletPoint>
                  </div>
                </SectionCardPadding>
                <FullWidthCardButton
                  disabled={!closed}
                  buttonText={
                    closed ? "Explore buying guides" : "Available after listing"
                  }
                  path="https://nested.com/buying-resources"
                />
              </UnpaddedSectionCard>
            </SectionWrapper>
          </PageWrapper>
        </>
      </MainContent>
    </LayoutWrapper>
  );
};
