// @flow
import { useMemo } from "react";
import { gql } from "@apollo/client";
import qs from "query-string";
import { css } from "styled-components";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import { reverse, sortBy } from "lodash";
import { media, mediumParagraph, smallParagraph } from "@nested/brand";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  MainContent,
  LayoutWrapper,
  ContentWrapper,
  BreadcrumbWrapper,
} from "../PageWrapper";
import { HeroSection } from "../../components/HeroSection/HeroSection";
import { StandardPageHeader } from "../../components/Heading";
import { BuyerEnquiry } from "./BuyerEnquiry";
import { BuyerEnquiryModal } from "./BuyerEnquiryModal";
import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { useModalCollection } from "../../hooks/useModalCollection";
import { enquiriesExampleData } from "./enquiriesExampleData";
import { StickyExampleBanner } from "../../components/SectionCard";

export const ENQUIRIES_QUERY = gql`
  query EnquiriesPageQuery($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      buyerEnquiries {
        id
        buyer {
          id
          avatarUrl
          firstName
          surnameInitial
          buyerPosition
          financialPosition
          hasEmail
          hasPhone
        }
        datetimeEnquiryCreated
        currentlyInterested
        enquiryStatus
        falloutDetails
        firstViewingTimestamp
        firstViewingVirtual
        contactLastUpdatedAt
      }
    }
  }
`;

const infoText = css`
  ${mediumParagraph}
  margin-top: 20px;
  span {
    font-weight: 500;
  }
  ${media.tablet`
    margin-top: 15px;
  `}
`;

const noEnquiries = css`
  ${smallParagraph}
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.blue20};
  border: 1px solid ${({ theme }) => theme.palette.blue40};
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  padding: 10px;
  margin-top: 20px;

  ${media.desktop`
    margin: 40px;
    height: 24px;
  `}
`;

const enquiriesWrapper = css`
  padding: 10px 15px;
  max-width: 747px;
  margin: auto;
  ${media.tablet`
    padding: 0 0 50px;
  `}
`;

const enquiriesListContainer = css`
  margin-top: 20px;
  ${media.tablet`
    margin-top: 30px;
  `}
  & > div:not(:first-child) {
    margin-top: 17px;
  }
`;

export const Enquiries = () => {
  const { search } = useLocation();
  const { example: exampleMode } = qs.parse(search);
  const { data, loading } = usePublicDealQuery(ENQUIRIES_QUERY);
  const enquiries = data?.publicDeal?.buyerEnquiries || [];
  const enquiriesToShow = exampleMode ? enquiriesExampleData : enquiries;
  const sortedEnquiries = useMemo(() =>
    reverse(sortBy(enquiriesToShow, ["contactLastUpdatedAt"])),
  );

  const { open, selectAndOpen, onClose, selectedItem, next, previous } =
    useModalCollection(sortedEnquiries);

  if (loading) {
    return null;
  }

  if (!data?.publicDeal && !exampleMode) {
    return <Redirect to="/listing/interest" />;
  }

  if (enquiries.length === 0 && !exampleMode) {
    return (
      <div css={noEnquiries}>
        It looks like you don't have any enquiries yet.
      </div>
    );
  }

  return (
    <LayoutWrapper>
      <HeroSection noMobile />
      <MainContent>
        <BreadcrumbWrapper>
          <Breadcrumb name="Interest" path="/listing/interest" />
        </BreadcrumbWrapper>
        {exampleMode && <StickyExampleBanner text="All enquiries" />}
        <ContentWrapper>
          <div css={enquiriesWrapper} data-test="enquiries-page-content">
            <StandardPageHeader>All enquiries</StandardPageHeader>
            <div css={infoText}>
              We’ll chase enquiries <span>9 times</span> over 3 days to qualify
              each buyer and arrange a viewing.
            </div>

            <div css={enquiriesListContainer}>
              {sortedEnquiries.map((enquiry, index) => (
                <BuyerEnquiry
                  onClick={() => {
                    selectAndOpen(index);
                  }}
                  key={enquiry.id}
                  enquiry={enquiry}
                />
              ))}
            </div>
            <BuyerEnquiryModal
              open={open}
              next={next}
              previous={previous}
              onClose={onClose}
              buyerEnquiry={selectedItem}
              exampleMode={exampleMode}
            />
          </div>
        </ContentWrapper>
      </MainContent>
    </LayoutWrapper>
  );
};
