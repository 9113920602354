import { Component } from "react";
import { Sentry } from "@nested/isomorphic-sentry";
import { ErrorMessage } from "@nested/brand";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <ErrorMessage
          data-test="error-boundary"
          icon="error.svg"
          title="Something appears to have gone wrong"
          message="One of our team has been alerted and they'll be working on a fix soon. Please try refreshing the page to see if that fixes the problem"
        />
      );
    }

    return children;
  }
}

export { ErrorBoundary };
