// @flow
import { useContext } from "react";
import { gql } from "@apollo/client";
import { ThemeContext } from "styled-components";
import { getImage } from "@nested/utils";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  SectionWrapper,
  SectionHeader,
  SectionHeaderSubtext,
  IconAndText,
  ImageAndTextCardContent,
  UnpaddedSectionCard,
  SectionCardPadding,
  HalfWidthCardButtons,
  ExampleBox,
  FullWidthCardButton,
} from "../../../components/SectionCard";
import { AuthenticatedQuery } from "../../../components/AuthenticatedQuery/AuthenticatedQuery";
import { UpcomingViewingsSchedule } from "./UpcomingViewingsSchedule";
import { upcomingViewingsFirstImpressionsData } from "../viewingsFirstImpressionsData";

export const UPCOMING_VIEWINGS_QUERY = gql`
  query UpcomingViewingDetails($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      externalDealId
      buyerEnquiries(type: UPCOMING) {
        id
        buyer {
          id
          avatarUrl
          firstName
          surnameInitial
          financialPosition
          buyerPosition
        }
        viewings(type: UPCOMING) {
          id
          number
          dateTimeStart
          conductor
          viewingSaEmail
          viewingSaName
          virtual
        }
      }
    }
  }
`;

const CardWithAvailabilityAndCalendarButtons = ({ children }) => (
  <UnpaddedSectionCard>
    <SectionCardPadding>{children}</SectionCardPadding>
    <HalfWidthCardButtons
      leftButtonPath="/listing/viewings/calendar"
      leftButtonText="Viewing calendar"
      rightButtonPath="/listing/viewings/availability"
      rightButtonText="Set availability"
    />
  </UnpaddedSectionCard>
);

const UpcomingViewingsFirstImpressions = () => (
  <SectionWrapper data-test="nav-card:upcoming-viewings">
    <SectionHeader css="padding-bottom: 0;">Upcoming viewings</SectionHeader>
    <SectionHeaderSubtext>Available after listing</SectionHeaderSubtext>
    <UnpaddedSectionCard css="margin-top: 20px;">
      <SectionCardPadding>
        <IconAndText
          icon={faCalendarAlt}
          text="Keep on top of you calendar with viewing updates and scheduling."
        />
        <ExampleBox css="padding: 0;">
          <div css="padding: 15px;">
            <UpcomingViewingsSchedule
              buyerEnquiries={upcomingViewingsFirstImpressionsData}
              exampleMode
            />
          </div>
          <HalfWidthCardButtons
            leftButtonPath="/listing/viewings/calendar?example=true"
            leftButtonText="Viewing calendar"
            rightButtonPath="/listing/viewings/availability?example=true"
            rightButtonText="My availability"
          />
        </ExampleBox>
      </SectionCardPadding>
    </UnpaddedSectionCard>
  </SectionWrapper>
);

const ClosedContent = ({ theme }: { theme: any }) => (
  <SectionWrapper data-test="nav-card:upcoming-viewings">
    <SectionHeader css="padding-bottom: 0;">Upcoming viewings</SectionHeader>
    <SectionHeaderSubtext>Available after listing</SectionHeaderSubtext>
    <UnpaddedSectionCard css="margin-top: 20px;">
      <SectionCardPadding>
        <ImageAndTextCardContent
          largeText
          text="Upcoming viewings will appear here after booking"
          imageSrc={getImage("your-account/viewings-tab/telescope-stars.png")}
          imageAlt="telescope"
          circleColor={theme.palette.yellow20}
        />
      </SectionCardPadding>
      <FullWidthCardButton
        buttonText="Set availability"
        path="/listing/viewings/availability"
      />
    </UnpaddedSectionCard>
  </SectionWrapper>
);

export const UpcomingViewingsCard = ({
  deal,
}: {
  deal: ?ListingSectionQuery_publicDeal,
}) => {
  const theme = useContext(ThemeContext);

  if (deal && deal.opportunityStatusValue === "s07_closed") {
    return <ClosedContent theme={theme} />;
  }

  if (!deal || !deal?.isListed) {
    return <UpcomingViewingsFirstImpressions />;
  }

  return (
    <SectionWrapper data-test="nav-card:upcoming-viewings">
      <SectionHeader>Upcoming viewings</SectionHeader>
      <AuthenticatedQuery
        query={UPCOMING_VIEWINGS_QUERY}
        variables={{ dealId: deal.externalDealId }}
      >
        {({ data }) => {
          if (
            data?.publicDeal?.buyerEnquiries &&
            data?.publicDeal?.buyerEnquiries.length > 0
          ) {
            return (
              <CardWithAvailabilityAndCalendarButtons>
                <UpcomingViewingsSchedule
                  buyerEnquiries={data?.publicDeal?.buyerEnquiries}
                />
              </CardWithAvailabilityAndCalendarButtons>
            );
          }
          return (
            <CardWithAvailabilityAndCalendarButtons>
              <ImageAndTextCardContent
                largeText
                text="No new viewings"
                imageSrc={getImage(
                  "your-account/viewings-tab/telescope-stars.png",
                )}
                imageAlt="telescope"
                circleColor={theme.palette.yellow20}
              />
            </CardWithAvailabilityAndCalendarButtons>
          );
        }}
      </AuthenticatedQuery>
    </SectionWrapper>
  );
};
