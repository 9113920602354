// @flow
import { useState, useRef, useEffect } from "react";
import moment from "moment-timezone";
import { gql } from "@apollo/client";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { getImage } from "@nested/utils";
import { Confetti } from "../../components/Confetti";
import { DefaultWrapper } from "../PageWrapper";
import { PageHeader, StandardPageHeader } from "../../components/Heading";
import { AuthenticatedQuery } from "../../components/AuthenticatedQuery";
import { useDeal } from "../../hooks/useDeal";

const MILESTONES = gql`
  query SaleStatusMilestones($dealId: ID) {
    saleStatusMilestones(externalDealId: $dealId) {
      isExample
      milestoneGroups {
        title
        milestones {
          id
          description
          reachedAt
        }
      }
    }
  }
`;

type MilestoneType = {
  id: string,
  description: string,
  reachedAt: ?string,
};

type MilestoneGroupType = {
  title: ?string,
  milestones: MilestoneType[],
};

const MilestoneGroupWrapper = styled.div`
  background-color: ${({ $allReached, theme }) =>
    $allReached ? theme.palette.blue10 : "white"};
  border: 1px solid
    ${({ $allReached, theme }) =>
      $allReached ? "transparent" : theme.palette.hague20};
  border-radius: 5px;
  padding: 19px 16px 19px 9px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const BulletPoint = styled.div`
  width: 8px;
  height: 8px;
  min-width: 8px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.blue40};
  margin: 0 4px;
  background-color: ${({ theme, $allReached }) =>
    $allReached ? theme.palette.blue40 : "white"};
  z-index: 1;
`;

const TitleText = styled.div`
  font-weight: 400;
  flex-grow: 1;
  margin-left: 4px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const dropdownCaretStyle = css`
  height: 14px;
  width: 14px;
  min-width: 14px;
  color: ${({ theme }) => theme.palette.blue170};
`;

const Complete = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.hague70};
`;

const GroupTitle = ({ title, allReached, open }) => (
  <TitleWrapper>
    <BulletPoint $allReached={allReached} />
    <TitleText>{title}</TitleText>
    {allReached && <Complete>Complete!</Complete>}
    <FontAwesomeIcon
      icon={open ? faAngleUp : faAngleDown}
      css={dropdownCaretStyle}
    />
  </TitleWrapper>
);

const Milestones = styled.div`
  transition: height 200ms ease-out;
  height: ${({ $open, $height }) => ($open ? $height : "0px")};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MilestoneGroup = ({ group, expand }) => {
  // TODO: Maybe we should let the group title be nullable more generally? So
  // this is more based on whether we have no group title and there's only
  // one milestone. No group title but multiple milestones renders simply
  // with a blank title maybe?
  if (!group.title) {
    const firstMilestone = group?.milestones[0];
    if (!firstMilestone) return null;
    return <SingleMilestoneGroup milestone={firstMilestone} />;
  }

  const [open, setOpen] = useState(expand);

  // See the useEffect for why we have this
  // eslint-disable-next-line no-unused-vars
  const [initialiseHeight, setInitialiseHeight] = useState(false);
  const milestonesRef = useRef();

  useEffect(() => {
    // In order to allow the collapsing/expanding transition, we need to have a
    // milestoneRef.current.scrollHeight. On initial render the `current` is
    // undefined. Consequently on our initial render, the scroll height is 0px;
    //
    // This state update here triggers a second render once the component has
    // mounted, at which point `current` *is* defined and we can set the
    // `Milestones` height appropriately
    setInitialiseHeight(true);
  }, []);

  const allReached = group.milestones.every(
    ({ reachedAt }) => reachedAt !== null,
  );

  // Despite returning above if we don't have a `group.title`, flow complains
  // about it possibly being nullable here :facepalm:, so we have this ternary
  const groupTestId = group.title
    ? group.title.toLowerCase().replace(" ", "-")
    : "";

  return (
    <MilestoneGroupWrapper
      $allReached={allReached}
      data-test={`milestone-group-${groupTestId}`}
      onClick={() => setOpen(!open)}
    >
      <GroupTitle title={group.title} allReached={allReached} open={open} />
      <Milestones
        ref={milestonesRef}
        $open={open}
        $height={
          milestonesRef.current
            ? `${milestonesRef.current.scrollHeight}px`
            : "auto"
        }
      >
        {group.milestones.map((milestone) => (
          <Milestone milestone={milestone} key={milestone.id} />
        ))}
      </Milestones>
    </MilestoneGroupWrapper>
  );
};

const SingleMilestoneGroupWrapper = styled.div`
  background-color: ${({ $allReached, theme }) =>
    $allReached ? theme.palette.blue10 : "white"};
  border: 1px solid
    ${({ $allReached, theme }) =>
      $allReached ? "transparent" : theme.palette.hague20};
  border-radius: ${({ $isCompletedAndReached }) =>
    $isCompletedAndReached ? "5px 5px 0 0" : "5px"};
  padding: 19px 16px 19px 9px;
`;

const SingleMilestoneGroup = ({ milestone }) => {
  const isCompletedAndReached =
    milestone.id === "completed" && Boolean(milestone.reachedAt);

  return (
    <SingleMilestoneGroupWrapper
      $allReached={Boolean(milestone.reachedAt)}
      $isCompletedAndReached={isCompletedAndReached}
      data-test={`milestone-group-${milestone.id}`}
    >
      <Milestone milestone={milestone} noMarginTop />
    </SingleMilestoneGroupWrapper>
  );
};

const MilestoneWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 14px;
  margin-top: ${({ $noMarginTop }) => ($noMarginTop ? "0" : "16")}px;
`;

const Checkbox = styled.div`
  background-color: ${({ theme, $checked }) =>
    $checked ? theme.palette.blue40 : "white"};
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 5px;
  border: solid 2px ${({ theme }) => theme.palette.blue40};

  z-index: 1;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  color: ${({ theme }) => theme.palette.blue170};
`;

const Description = styled.div`
  flex-grow: 1;
  font-weight: 500;
`;

const ReachedAt = styled.div`
  min-width: 52px;
  align-self: flex-start;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: ${({ theme }) => theme.palette.hague70};
`;

const NewLabel = styled.div`
  color: white;
  background-color: ${({ theme }) => theme.palette.green150};
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  padding: 5px;
`;

const formatReachedAt = (reachedAt) => {
  const reachedAtMoment = moment(reachedAt);
  if (reachedAtMoment.year() < moment().year())
    return reachedAtMoment.format("DD MMM 'YY");

  if (reachedAtMoment > moment().subtract(1, "days"))
    return <NewLabel>NEW</NewLabel>;

  return reachedAtMoment.format("DD MMM");
};

const Milestone = ({ milestone, noMarginTop = false }) => {
  const { description, reachedAt } = milestone;
  const formattedReachedAt = reachedAt ? formatReachedAt(reachedAt) : "";
  return (
    <MilestoneWrapper
      data-test={`milestone-${milestone.id}`}
      $noMarginTop={noMarginTop}
    >
      <Checkbox $checked={Boolean(reachedAt)}>
        {reachedAt && <FontAwesomeIcon icon={faCheck} />}
      </Checkbox>
      <Description>{description}</Description>
      <ReachedAt>{formattedReachedAt}</ReachedAt>
    </MilestoneWrapper>
  );
};

const MilestonesSectionWithLine = styled.div`
  margin-top: ${({ $isExample }) => ($isExample ? "32px" : "0")};
  display: flex;
  flex-direction: column;
  gap: 9px;
  font-size: 14px;
  line-height: 18px;

  // This does the line between the checkboxes
  position: relative;
  ::after {
    content: "";
    position: absolute;
    width: 2px;
    border-left: 2px solid ${({ theme }) => theme.palette.blue40};
    pointer-events: none;
    z-index: 0;
    top: 35px;
    bottom: 35px;
    left: 19px;
  }
`;

const ExampleLabel = styled.div`
  background-color: ${({ theme }) => theme.palette.terracotta150};
  color: ${({ theme }) => theme.palette.hague150};
  border-radius: 5px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: -11px;
  padding: 1px 7px;
`;

const ExampleMilestonesContent = styled.div`
  box-shadow: 3px 3px 15px -2px #0a425426;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Subtext = styled.div`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  flex-grow: 1;
`;

const ExampleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ExampleHeaderCheckbox = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: flex;
  color: white;
  background-color: ${({ theme }) => theme.palette.terracotta100};
  border-radius: 5px;
  align-items: center;
  justify-content: space-around;
`;

const TabletMobileHeader = styled(StandardPageHeader)`
  ${media.desktop`
    display: none;
  `}
`;

const CompletedImage = styled.img`
  width: 100%;
  border-radius: 0 0 5px 5px;
  background-color: ${({ theme }) => theme.palette.blue10};
`;

const ExampleMilestones = ({
  milestoneGroups,
}: {
  milestoneGroups: MilestoneGroupType[],
}) => (
  <>
    <TabletMobileHeader>Sale status</TabletMobileHeader>
    <Subtext>Available once under offer</Subtext>
    <ExampleMilestonesContent>
      <ExampleHeader>
        <ExampleHeaderCheckbox>
          <FontAwesomeIcon icon={faCheck} />
        </ExampleHeaderCheckbox>
        <Subtext>
          Track every step of your sale with our simple milestone checklist.
        </Subtext>
      </ExampleHeader>
      <MilestonesSection milestoneGroups={milestoneGroups} isExample />
    </ExampleMilestonesContent>
  </>
);

// Basically, have we completed or exchanged in the last day
const throwConfetti = (milestoneGroups) =>
  milestoneGroups.some((group) => {
    // Neither completed or exchanged has a group title
    if (group.title !== null) return false;

    return group.milestones.every(
      (milestone) =>
        (milestone.id === "completed" || milestone.id === "exchanged") &&
        moment(milestone.reachedAt) > moment().subtract(1, "days"),
    );
  });

export const MilestonesSection = ({
  milestoneGroups,
  isExample = false,
}: {
  milestoneGroups: MilestoneGroupType[],
  isExample?: boolean,
}) => {
  const [showConfetti, setShowConfetti] = useState(false);
  useEffect(() => {
    setShowConfetti(throwConfetti(milestoneGroups));
  }, []);

  const hasExchanged = milestoneGroups.some((group) => {
    if (group.title !== null) return false;

    return group.milestones.every(
      (milestone) => milestone.id === "exchanged" && milestone.reachedAt,
    );
  });

  const hasCompleted = milestoneGroups.some((group) => {
    if (group.title !== null) return false;

    return group.milestones.every(
      (milestone) => milestone.id === "completed" && milestone.reachedAt,
    );
  });

  return (
    <>
      {/* Need a z-index here to make confetti show in front of groups, which have a z-index due to the vertical bar */}
      <div style={{ zIndex: 2, position: "relative" }}>
        <Confetti showConfetti={showConfetti} />
      </div>
      <MilestonesSectionWithLine $isExample={isExample}>
        {isExample && <ExampleLabel>Example</ExampleLabel>}
        {milestoneGroups.map((group, i) => (
          <MilestoneGroup
            group={group}
            key={i}
            expand={!(hasExchanged || hasCompleted)}
          />
        ))}
      </MilestonesSectionWithLine>
      {hasCompleted && (
        <CompletedImage
          alt="completed sale"
          src={getImage("illustrations/completed_sale.svg")}
        />
      )}
    </>
  );
};

export const SaleStatus = () => {
  const { dealId } = useDeal();

  return (
    <DefaultWrapper>
      <PageHeader>Status</PageHeader>
      <AuthenticatedQuery query={MILESTONES} variables={{ dealId }}>
        {({ data }) => {
          if (
            data?.saleStatusMilestones?.isExample &&
            data?.saleStatusMilestones?.milestoneGroups
          ) {
            return (
              <ExampleMilestones
                milestoneGroups={data.saleStatusMilestones.milestoneGroups}
              />
            );
          }

          if (
            data?.saleStatusMilestones?.isExample === false &&
            data?.saleStatusMilestones?.milestoneGroups
          ) {
            return (
              <MilestonesSection
                milestoneGroups={data.saleStatusMilestones.milestoneGroups}
              />
            );
          }

          return "";
        }}
      </AuthenticatedQuery>
    </DefaultWrapper>
  );
};
