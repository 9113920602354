// @flow
import { Route, type ContextRouter } from "react-router-dom";
import { UnauthenticatedLayout } from "./UnauthenticatedLayout";
import { Login } from "../../pages/Login/Login";

type Props = {|
  render?: (router: ContextRouter) => React$Element<any>,
  layout?: React$ComponentType<any>,
  component?: React$ComponentType<any>,
  exact?: boolean,
  path?: string,
|};

export const PublicRoute = ({
  layout: Layout = UnauthenticatedLayout,
  component: Component,
  render,
  ...rest
}: Props) => {
  if (render) {
    return <Route {...rest} render={render} />;
  }

  if (Component) {
    return (
      <Route
        {...rest}
        render={(match) =>
          Layout ? (
            <Layout path={rest.path}>
              <Component {...match} />
            </Layout>
          ) : (
            <Component {...match} />
          )
        }
      />
    );
  }

  return <Route component={Login} />;
};
