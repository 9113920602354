// @flow
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { TimelineItem, detailStyle } from "./TimelineItem";

type Props = {
  id: string,
  text: string,
  timestamp: ?string,
  userEmail: ?string,
  userFirstName: ?string,
};

export const Note = ({
  id,
  text,
  timestamp,
  userEmail,
  userFirstName,
}: Props) => (
  <TimelineItem
    color="yellow"
    icon={faFileAlt}
    primaryHeading="Note"
    testId={`timeline-note-${id}`}
    timestamp={timestamp}
    userEmail={userEmail}
    userFirstName={userFirstName}
  >
    <div css={detailStyle}>{text}</div>
  </TimelineItem>
);
