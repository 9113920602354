import styled, { keyframes } from "styled-components";
import { Card } from "@nested/ui";

const fade = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
`;

const Content = styled.div`
  animation: ${fade} linear 1.4s infinite;
`;

export const PlaceholderCard = ({ className }) => (
  <Card data-test="placeholder-card" className={className}>
    <Content>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="280"
        height="148"
        viewBox="0 0 280 148"
      >
        <path
          fill="#EBF4FF"
          fillRule="evenodd"
          d="M0,40 L90,40 L90,54 L0,54 L0,40 Z M110,40 L210,40 L210,54 L110,54 L110,40 Z M190,0 L225,0 L225,10 L190,10 L190,0 Z M245,0 L280,0 L280,10 L245,10 L245,0 Z M170,10 L95,10 L95,0 L170,0 L170,10 Z M75,10 L0,10 L0,0 L75,0 L75,10 Z M0,134 L190,134 L190,148 L0,148 L0,134 Z M190,94 L280,94 L280,104 L190,104 L190,94 Z M170,104 L75,104 L75,94 L170,94 L170,104 Z M55,104 L0,104 L0,94 L55,94 L55,104 Z"
        />
      </svg>
    </Content>
  </Card>
);
