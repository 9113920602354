// @flow
import { gql } from "@apollo/client";
import { useLocation } from "react-router";
import qs from "query-string";

import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { useAuthenticatedMutation } from "../../hooks/useAuthenticatedMutation";

import { useDeal } from "../../hooks/useDeal";
import { ViewingAvailabilityUI } from "./ViewingAvailabilityUI";
import { MockViewingAvailability } from "./MockViewingAvailability";

const VIEWING_AVAILABILITY = gql`
  query ViewingAvailability($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      externalDealId
      viewingAvailability {
        availabilitySummaries {
          dayOfWeek
          ranges {
            rangeStart
            rangeEnd
          }
        }
        timeslots {
          id
          dayOfWeek
          timeSlotStart
          timeSlotEnd
          available
        }
      }
    }
  }
`;

const UPDATE_AVAILABILITY = gql`
  mutation UpdateViewingAvailability(
    $id: ID!
    $input: UpdateViewingAvailabilityInput!
  ) {
    updateViewingAvailability(id: $id, input: $input) {
      id
      available
    }
  }
`;
const UPDATE_FULL_DAY_AVAILABILITY = gql`
  mutation UpdateViewingAvailabilityFullDay(
    $dealId: String!
    $input: UpdateViewingAvailabilityFullDayInput!
  ) {
    updateViewingAvailabilityFullDay(externalDealId: $dealId, input: $input) {
      id
      available
    }
  }
`;

export const ViewingAvailability = () => {
  const { search } = useLocation();
  const { example: exampleMode } = qs.parse(search);

  if (exampleMode) {
    return <MockViewingAvailability />;
  }

  const { dealId } = useDeal();
  const { data, loading } = usePublicDealQuery(VIEWING_AVAILABILITY, {});
  const [
    updateAvailability,
    { called: calledUpdateAvailability, loading: saving },
  ] = useAuthenticatedMutation(UPDATE_AVAILABILITY, {
    refetchQueries: [{ query: VIEWING_AVAILABILITY, variables: { dealId } }],
    awaitRefetchQueries: true,
  });

  const [
    updateFullDayAvailability,
    { called: calledUpdateFullDay, loading: savingFullDay },
  ] = useAuthenticatedMutation(UPDATE_FULL_DAY_AVAILABILITY, {
    refetchQueries: [{ query: VIEWING_AVAILABILITY, variables: { dealId } }],
    awaitRefetchQueries: true,
  });

  const summaryData =
    data?.publicDeal?.viewingAvailability?.availabilitySummaries || [];

  const timeSlots = data?.publicDeal?.viewingAvailability?.timeslots || [];

  return (
    <ViewingAvailabilityUI
      calledUpdateAvailability={calledUpdateAvailability}
      calledUpdateFullDay={calledUpdateFullDay}
      dealId={dealId}
      exampleMode={exampleMode}
      loading={loading}
      saving={saving}
      savingFullDay={savingFullDay}
      summaryData={summaryData}
      timeSlots={timeSlots}
      updateAvailability={updateAvailability}
      updateFullDayAvailability={updateFullDayAvailability}
    />
  );
};
