// @flow
import { useMemo, useState } from "react";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "@nested/brand";
import { formatPrice } from "@nested/utils";
import {
  getOfferStatus,
  extractRenegotiatedOffers,
  isActiveOffer,
  isNewOffer,
} from "./utils";
import { BuyerDetails, NewBadge } from "./BuyerDetails";

const offerSummaryWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:not(:first-child) {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid ${({ theme }) => theme.palette.hague10};
  }
`;

const avatarWrapper = css`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
`;
const avatarStyle = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const offerDetailsStyle = css`
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

const buyerNameStyle = css`
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue150};
`;

const offerAmountStyle = css`
  color: ${({ theme }) => theme.palette.hague130};
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
`;

const offerChevronWrapperStyle = css`
  color: ${({ theme }) => theme.palette.hague20};
  flex-grow: 0;
  flex-shrink: 0;
`;

const chevronStyle = css`
  display: none;
  /* 360px */
  @media (min-width: 22.5em) {
    display: inline-block;
  }
`;

const modalStyle = css`
  padding: 45px 0px 20px;
  max-width: 390px;
`;

const newBadgeStyle = css`
  margin-right: 0px;
  /* 360px */
  @media (min-width: 22.5em) {
    margin-right: 10px;
  }
`;

type ModalProps = {
  open: boolean,
  onClose(): any,
  buyerEnquiry: PublicDealOffers_publicDeal_buyerEnquiries,
  sm?: PublicDealOffers_publicDeal_sm,
};

export const BuyerModal = ({ open, onClose, buyerEnquiry, sm }: ModalProps) => (
  <Modal
    data-test={`buyer-modal-${buyerEnquiry.id}`}
    open={open}
    onClose={onClose}
    css={modalStyle}
  >
    <BuyerDetails buyerEnquiry={buyerEnquiry} sm={sm} />
  </Modal>
);

type Props = {
  buyerEnquiry: PublicDealOffers_publicDeal_buyerEnquiries,
  sm?: PublicDealOffers_publicDeal_sm,
};

export const BuyerSummary = ({ buyerEnquiry, sm }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { buyer } = buyerEnquiry;

  const offers = useMemo(
    () => extractRenegotiatedOffers(buyerEnquiry.buyerOffers),
    [buyerEnquiry],
  );

  const latestOffer = offers[0];
  const offerActive = isActiveOffer(latestOffer);

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        data-test={`buyer-summary-${buyer.id}`}
        onClick={() => setModalOpen(true)}
        css={offerSummaryWrapper}
      >
        <div css={avatarWrapper}>
          <img src={buyer.avatarUrl} css={avatarStyle} alt="" />
        </div>
        <div css={offerDetailsStyle}>
          <div css={buyerNameStyle}>
            {buyer.firstName} {buyer.surnameInitial}
          </div>
          <div active={offerActive} css={offerAmountStyle}>
            {formatPrice(parseInt(latestOffer.amount, 10))} -{" "}
            {getOfferStatus(latestOffer)}
          </div>
        </div>
        <div css={offerChevronWrapperStyle}>
          {isNewOffer(latestOffer) && <NewBadge css={newBadgeStyle} />}
          <FontAwesomeIcon css={chevronStyle} icon={faChevronRight} size="lg" />
        </div>
      </div>
      <BuyerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        buyerEnquiry={buyerEnquiry}
        sm={sm}
      />
    </>
  );
};
