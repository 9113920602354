// @flow
import { media, Gravatar } from "@nested/brand";
import { css, keyframes } from "styled-components";

export const fade = keyframes`
  0% {
    opacity: 0.11;
  }

  50% {
    opacity: 0.05;
  }

  100% {
    opacity: 0.1;
  }
`;

export const loadingBlockSmall = css`
  background-color: white;
  opacity: 0.1;
  width: ${({ width }) => `${width}px`};
  height: 15px;
  margin-top: 5px;
  border-radius: 8px;
  animation: ${fade} linear 1.4s infinite;
  margin: 0 auto;
  ${media.tablet`
    margin: 5px 0 0;
  `}
`;

const smWrapper = css`
  display: none;

  ${media.tablet`
    flex-direction: row;
    position: absolute;
    top: 30px;
    right: 40px;
    display: flex;
    align-items: center;
  `}
`;

const agentHeadingStyle = css`
  opacity: 0.7;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  text-transform: uppercase;
`;

const agentNameStyle = css`
  font-weight: 500;
  font-size: 400;
  line-height: 21px;
`;

const gravatarStyle = css`
  margin: 0 15px 0 0;
`;

const avatarPlaceholder = css`
  ${gravatarStyle}
  border-radius: 50%;
  height: 50px;
  margin: 10px 25px 0 5px;
  width: 50px;
  background-color: white;
  opacity: 0.1;
  animation: ${fade} linear 1.4s infinite;
`;

const contactHeadingsStyle = css`
  opacity: 0.7;
  font-size: 14px;
  line-height: 21px;
  margin-left: 30px;
  text-align: right;
  font-weight: 500;
`;

const contactDetailsStyle = css`
  font-size: 14px;
  line-height: 21px;
  margin-left: 20px;
  font-weight: 500;
`;

type Props = {
  loading: boolean,
  sm: PublicDealOffers_publicDeal_sm,
};

export const SMDetails = ({ sm, loading }: Props) => {
  if (loading) {
    return (
      <div css={smWrapper}>
        <div>
          <div css={avatarPlaceholder}></div>
        </div>
        <div>
          <div css={agentHeadingStyle}>Your estate agent</div>
          <div css={loadingBlockSmall} width={150}></div>
        </div>
        <div css={contactHeadingsStyle}>
          <div>phone</div>
          <div>email</div>
        </div>
        <div css={contactDetailsStyle}>
          <div css={loadingBlockSmall} width={140}></div>
          <div css={loadingBlockSmall} width={160}></div>
        </div>
      </div>
    );
  }

  return (
    <>
      {sm && (
        <div css={smWrapper}>
          <div>
            <Gravatar css={gravatarStyle} email={sm.emailAddress} />
          </div>
          <div>
            <div css={agentHeadingStyle}>Your estate agent</div>
            <div css={agentNameStyle}>
              {sm.firstName} {sm.lastName}
            </div>
          </div>
          <div css={contactHeadingsStyle}>
            <div>phone</div>
            <div>email</div>
          </div>
          <div css={contactDetailsStyle}>
            <div>{sm.phoneNumber}</div>
            <div>{sm.emailAddress}</div>
          </div>
        </div>
      )}
    </>
  );
};
