// @flow
import { useQuery } from "@apollo/client/react/hooks";
import { type DocumentNode } from "@apollo/client";
import { useAuth } from "@nested/auth";

export const useAuthenticatedQuery = <TData, TVariables>(
  query: DocumentNode,
  config: ?Object = {},
) => {
  const auth = useAuth();

  return useQuery<TData, TVariables>(query, {
    ...config,
    context: { auth },
  });
};
