import { createGlobalStyle } from "styled-components";

export const CalendarStyle = createGlobalStyle`
.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  display: flex;
}
.react-calendar__navigation button {
  min-width: 44px;
  text-transform: uppercase;
  color: #024255;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 21px;
  background: none;
  font-weight: 500;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  cursor: pointer;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  color: #004358;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 17px;
  opacity: 0.5;
  abbr {
    text-decoration: none;
  }
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: silver !important;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.7em 0.4em;
  background: none;
  color: #00435A;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 22px;
  font-weight: 500;
  abbr {
    border: 1px solid transparent;
    display: inline-block;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    /* some slight offsets to center text in the highlight circles */
    padding-left: 2px;
    padding-bottom: 2px;
  }
  /*  Days with viewings */
  &.highlight abbr {
    border: 1px solid #DAEDFA;
    background-color: #DAEDFA;
    border-radius: 100%;
  }
  /* We don't want highlights on blank squares from prev/next month */
  &.highlight.react-calendar__month-view__days__day--neighboringMonth abbr {
    background-color: unset;
    border: none;
  } 
  &.highlight:enabled:hover abbr,
  &.highlight:enabled:focus abbr {
    cursor: pointer;
  }
}
.react-calendar__tile:disabled {
  abbr {
    background-color: #f0f0f0;
  }
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* Hover styles */
  cursor: pointer;
}
.react-calendar__tile--now {
  /* Today's date styles */
  abbr {
    border: 1px solid #0A4254;
    border-radius: 100%;
  }
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* Today's date hover/focus styles */
  cursor: pointer;
}
.react-calendar__tile--hasActive {
  abbr {
    background: #76baff;
    border-radius: 100%;
    border: 1px solid #76baff;
  }
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  cursor: pointer;
}
.react-calendar__tile--active {
  /* Currently selected day styles */
  abbr {
    border: 1px solid #2D89D9 !important;
    background-color: #2D89D9 !important;
    border-radius: 100%;
    color: white;
  }
  &.react-calendar__tile--now abbr {
    border: 1px solid #0A4254 !important;
  }
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  cursor: pointer;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  cursor: pointer;
}`;
