import moment from "moment";

const tomorrow = () => {
  if (moment().isoWeekday() === 6) {
    return moment().add(2, "day").startOf("day");
  }

  return moment().add(1, "day").startOf("day");
};

const createPastDate = (daysAgo, hour, minute) => {
  return moment()
    .subtract(daysAgo, "days")
    .set("hour", hour)
    .set("minute", minute)
    .format("YYYY-MM-DD");
};

const fourDaysAgo = () => {
  const d = moment().subtract(4, "days");
  if (d.isoWeekday() === 7) {
    d.isoWeekday(6);
  }
  return d.startOf("day");
};

export const upcomingViewingsFirstImpressionsData = [
  {
    id: "1",
    buyer: {
      id: "1",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_1_B.png?bg=B5DBF5",
      firstName: "Ester",
      surnameInitial: "R",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(2, 16, 11),
    datetimeEnquiryCreated: moment().subtract(4, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "1",
        id: "9",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: true,
        number: 1,
        datetimeFirstImpressionsShared: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
        dateTimeStart: tomorrow().set({ hour: 14, minute: 0 }).toISOString(),
      },
    ],
  },
  {
    id: "3",
    buyer: {
      id: "3",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_3_B.png?bg=E5D8CA",
      firstName: "Will",
      surnameInitial: "T",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(7, 9, 45),
    datetimeEnquiryCreated: moment().subtract(8, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "3",
        id: "13",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: true,
        number: 2,
        datetimeFirstImpressionsShared: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
        dateTimeStart: tomorrow().set({ hour: 15, minute: 0 }).toISOString(),
      },
    ],
  },
  {
    id: "5",
    buyer: {
      id: "5",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_5_B.png?bg=B5DBF5",
      firstName: "Emma",
      surnameInitial: "V",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Mortgage in principle obtained",
      buyerOffers: [],
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(6, 17, 11),
    datetimeEnquiryCreated: moment().subtract(7, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "5",
        id: "12",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: true,
        number: 2,
        datetimeFirstImpressionsShared: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
        dateTimeStart: tomorrow().set({ hour: 14, minute: 45 }).toISOString(),
      },
    ],
  },
  {
    id: "10",
    buyer: {
      id: "10",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_2_B.png?bg=FE5D8CA",
      firstName: "Jessie",
      surnameInitial: "B",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    currentlyInterested: true,
    falloutDetails: null,
    contactLastUpdatedAt: createPastDate(2, 13, 59),
    datetimeEnquiryCreated: moment().subtract(4, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "10",
        id: "10",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: true,
        number: 1,
        datetimeFirstImpressionsShared: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
        dateTimeStart: tomorrow().set({ hour: 14, minute: 15 }).toISOString(),
      },
    ],
  },
  {
    id: "11",
    buyer: {
      id: "11",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_6_B.png?bg=FFEEF1",
      firstName: "Matthew",
      surnameInitial: "D",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    currentlyInterested: true,
    falloutDetails: null,
    contactLastUpdatedAt: createPastDate(2, 16, 40),
    datetimeEnquiryCreated: moment().subtract(4, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "11",
        id: "11",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: true,
        buyerCurrentlyInterested: true,
        number: 1,
        datetimeFirstImpressionsShared: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
        dateTimeStart: tomorrow()
          .add(1, "days")
          .set({ hour: 14, minute: 30 })
          .toISOString(),
      },
    ],
  },
];

const enquiriesWithPastViewings = [
  {
    id: "2",
    buyer: {
      id: "2",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_2_B.png?bg=FFEEF1",
      firstName: "Ed",
      surnameInitial: "S",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Cash buyer",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(8, 14, 57),
    datetimeEnquiryCreated: moment().subtract(9, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "2",
        id: "1",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: false,
        dateTimeStart: fourDaysAgo().set({ hour: 10, minute: 0 }).toISOString(),
        number: 1,
        firstImpressions: null,
        datetimeFirstImpressionsShared: null,
        fullFeedback: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
      },
    ],
  },
  {
    id: "4",
    buyer: {
      id: "4",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_4_B.png?bg=FFE7AA",
      firstName: "Tom",
      surnameInitial: "B",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(6, 16, 37),
    datetimeEnquiryCreated: moment().subtract(8, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "4",
        id: "3",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: true,
        dateTimeStart: fourDaysAgo()
          .set({ hour: 10, minute: 30 })
          .toISOString(),
        number: 1,
        firstImpressions: null,
        datetimeFirstImpressionsShared: null,
        fullFeedback: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
      },
    ],
  },
  {
    id: "6",
    buyer: {
      id: "6",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_6_B.png?bg=FFE7AA",
      firstName: "Dmitrijs",
      surnameInitial: "J",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: false,
    contactLastUpdatedAt: createPastDate(4, 10, 27),
    datetimeEnquiryCreated: moment().subtract(6, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "6",
        id: "5",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: false,
        dateTimeStart: fourDaysAgo().set({ hour: 11, minute: 0 }).toISOString(),
        number: 1,
        firstImpressions: null,
        datetimeFirstImpressionsShared: null,
        fullFeedback: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
      },
    ],
  },
  {
    id: "7",
    buyer: {
      id: "7",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_7_B.png?bg=E5D8CA",
      firstName: "Harry",
      surnameInitial: "M",
      buyerPosition: "No property to sell - First time buyer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: false,
    contactLastUpdatedAt: createPastDate(4, 9, 15),
    datetimeEnquiryCreated: moment().subtract(6, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "7",
        id: "6",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: false,
        dateTimeStart: fourDaysAgo()
          .set({ hour: 11, minute: 15 })
          .toISOString(),
        number: 1,
        firstImpressions: null,
        datetimeFirstImpressionsShared: null,
        fullFeedback: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
      },
    ],
  },
  {
    id: "8",
    buyer: {
      id: "8",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_8_B.png?bg=FFEEF1",
      firstName: "Anne-Laure",
      surnameInitial: "C",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: true,
    contactLastUpdatedAt: createPastDate(3, 9, 11),
    datetimeEnquiryCreated: moment().subtract(6, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "8",
        id: "7",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: true,
        dateTimeStart: fourDaysAgo()
          .set({ hour: 11, minute: 30 })
          .toISOString(),
        number: 1,
        firstImpressions: null,
        datetimeFirstImpressionsShared: null,
        fullFeedback: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
      },
    ],
  },
  {
    id: "9",
    buyer: {
      id: "9",
      avatarUrl:
        "https://nested.imgix.net/account/buyer-avatars/avatars_9_B.png?bg=FFE7AA",
      firstName: "Morgan",
      surnameInitial: "S",
      buyerPosition: "Property to sell - under offer",
      financialPosition: "Mortgage in principle obtained",
      hasPhone: true,
      hasEmail: true,
    },
    enquiryStatus: "VIEWING_BOOKED",
    falloutDetails: null,
    currentlyInterested: false,
    contactLastUpdatedAt: createPastDate(3, 11, 11),
    datetimeEnquiryCreated: moment().subtract(5, "days").format("YYYY-MM-DD"),
    viewings: [
      {
        _buyerEnquiryId: "9",
        id: "8",
        viewingSaName: "Seymour Holmes",
        viewingSaEmail: "jessie+seymour@nested.com",
        conductor: "sales_associate",
        virtual: false,
        buyerCurrentlyInterested: false,
        dateTimeStart: fourDaysAgo()
          .set({ hour: 11, minute: 45 })
          .toISOString(),
        number: 1,
        firstImpressions: null,
        datetimeFirstImpressionsShared: null,
        fullFeedback: null,
        datetimeFullFeedbackShared: null,
        viewingFeedbackLastUpdated: null,
        followUpFeedback: [],
      },
    ],
  },
];

export const allViewingsFirstImpressionsData = enquiriesWithPastViewings.concat(
  upcomingViewingsFirstImpressionsData,
);
