import { css } from "styled-components";
import { ResponsiveImage, smallParagraph } from "@nested/brand";

const loadingWrapper = css`
  min-height: 80vh;
  display: flex;
`;

const imageWrapper = css`
  width: 234px;
  height: 234px;
  margin: auto;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.sand40};
  text-align: center;
`;

const loadingTextStyles = css`
  text-transform: uppercase;
  letter-spacing: 3px;
  color: ${({ theme }) => theme.palette.hague};
  font-weight: 500;
  ${smallParagraph}
`;

const imageStyles = css`
  width: 98px;
  height: 96px;
  padding: 44px 0 10px 78px;
  display: block;
`;

export const AuthLoading = () => (
  <div css={loadingWrapper}>
    <div css={imageWrapper}>
      <ResponsiveImage
        alt="loading"
        src="https://images.prismic.io/nested/23d79ea3-ca24-4556-b17d-34f8c6703cf2_wise_owl.png?auto=compress,format"
        css={imageStyles}
      />
      <span css={loadingTextStyles}>
        Loading
        <br />
        your account
      </span>
    </div>
  </div>
);
