// @flow
import { useMemo } from "react";
import { gql } from "@apollo/client";
import styled, { css } from "styled-components";
import { Redirect } from "react-router-dom";
import { type QueryRenderProps } from "@nested/utils";
import { largeSectionHeading, media } from "@nested/brand";
import { VIEWING_FEEDBACK_FRAGMENT } from "./ViewingFeedbackFragment";
import { PublicDealQuery } from "../../components/AuthenticatedQuery";
import { Breadcrumb } from "../../components/Breadcrumb";
import { ViewingCardList } from "./ViewingCardList";
import { NoViewings } from "./NoViewingsPlaceholder";
import { MainContent, LayoutWrapper } from "../PageWrapper";
import { HeroSection } from "../../components/HeroSection/HeroSection";
import { TabFilters } from "../../components/TabFilters";
import { sortEnquiriesByViewingUpdateDate } from "./utils";

export const VIEWING_FEEDBACK_QUERY = gql`
  query ViewingFeedbackDeal($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      ...viewingFeedback
    }
  }
  ${VIEWING_FEEDBACK_FRAGMENT}
`;

const PageWrapper = styled.div`
  ${media.tablet`
    padding: 10px 30px;
  `}

  ${media.desktop`
    padding: 10px 3.33vw;
  `}
`;

const headingStyles = css`
  padding: 0 15px;
  ${largeSectionHeading}
  margin: 0;
  font-size: 18px;
  line-height: 27px;

  ${media.tablet`
    padding: 0;
    ${largeSectionHeading}
    margin-bottom: 30px;
  `}
`;

const allFeedbackWrapper = css`
  margin-top: 30px;
  padding: 0 15px;

  ${media.tablet`
    padding: 0;
  `};

  ${media.desktop`
    margin-top: 40px;
  `}
`;

const feedbackWrapper = css`
  padding: 30px 0;
  max-width: 747px;
  margin: auto;
  ${media.tablet`
    padding: 0 0 50px;
  `}
`;

type Props = { match: { params: { filter?: string } } };

export const ViewingFeedback = ({
  match: {
    params: { filter },
  },
}: Props) => (
  <LayoutWrapper>
    <HeroSection noMobile />
    <MainContent>
      <PageWrapper>
        <Breadcrumb name="Viewings" path="/listing/viewings" />
        <PublicDealQuery
          errorComponentName="viewing feedback"
          query={VIEWING_FEEDBACK_QUERY}
        >
          {({ data }: QueryRenderProps<ViewingFeedbackDeal>) => {
            if (!data?.publicDeal) {
              return <Redirect to="/listing/viewings" />;
            }

            const {
              publicDeal: { buyerEnquiries },
            } = data;

            return (
              <PageContent buyerEnquiries={buyerEnquiries} filter={filter} />
            );
          }}
        </PublicDealQuery>
      </PageWrapper>
    </MainContent>
  </LayoutWrapper>
);

const filterEnquiries = (enquiries) =>
  enquiries.filter((e) => e.currentlyInterested);

const PageContent = ({ buyerEnquiries, filter }) => {
  const hasBuyerFeedback = buyerEnquiries.length > 0;
  const sortedEnquiries =
    useMemo(
      () => sortEnquiriesByViewingUpdateDate(buyerEnquiries),
      [buyerEnquiries],
    ) || [];
  const activeEnquiries = useMemo(
    () => filterEnquiries(sortedEnquiries),
    [sortedEnquiries],
  );
  const enquiries = filter === "interested" ? activeEnquiries : sortedEnquiries;

  return (
    <div css={feedbackWrapper} data-test="viewing-feedback">
      <h2 css={headingStyles}>All viewing feedback</h2>
      <TabFilters
        leftFilterText="All feedback"
        leftFilterPath="/listing/viewings/viewing-feedback"
        rightFilterText={`Still interested (${activeEnquiries.length})`}
        rightFilterPath="/listing/viewings/viewing-feedback/interested"
      />
      <div css={allFeedbackWrapper}>
        {hasBuyerFeedback ? (
          <ViewingCardList enquiries={enquiries} />
        ) : (
          <NoViewings />
        )}
      </div>
    </div>
  );
};
