// @flow
import { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import Calendar from "react-calendar";
import { useLocation } from "react-router";
import qs from "query-string";
import moment from "moment-timezone";
import styled, { css } from "styled-components";
import { groupBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { media, PlaceholderCard } from "@nested/brand";
import { CalendarStyle } from "./ViewingCalendarStyle";
import { MainContent, LayoutWrapper } from "../PageWrapper";
import { usePublicDealQuery } from "../../hooks/usePublicDealQuery";
import { Breadcrumb } from "../../components/Breadcrumb";
import { VariablePageHeader } from "../../components/Heading";
import { HeroSection } from "../../components/HeroSection/HeroSection";
import {
  formatDayString,
  formatTime,
} from "../Viewings/UpcomingViewings/UpcomingViewingsSchedule";
import { ViewingsOnDay, dateFormat } from "./ViewingDetail";
import { ExampleCapsule } from "../../components/SectionCard";
import { allViewingsFirstImpressionsData } from "../Viewings/viewingsFirstImpressionsData";

export const ALL_VIEWINGS_QUERY = gql`
  query AllViewingsDetails($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      externalDealId
      buyerEnquiries(type: ALL_CONFIRMED) {
        id
        buyer {
          id
          avatarUrl
          firstName
          surnameInitial
          financialPosition
          buyerPosition
        }
        viewings(type: ALL_CONFIRMED) {
          id
          number
          dateTimeStart
          conductor
          viewingSaEmail
          viewingSaName
          virtual
        }
      }
    }
  }
`;

const aggregateDays = (viewings) => {
  const transformed = viewings.map((viewing) => ({
    ...viewing,
    date: dateFormat(viewing.dateTimeStart), // 2000-12-01
    day: formatDayString(viewing.dateTimeStart), // Wed 7th
    formattedTime: formatTime(viewing.dateTimeStart), // 10:00
  }));
  return groupBy(transformed, (v) => v.date);
};

const formatAndSortViewings = (viewings) => {
  return viewings
    .flatMap(({ viewings: buyerViewings, buyer }) =>
      buyerViewings.map((v) => ({ ...v, buyer })),
    )
    .sort(({ dateTimeStart: startA }, { dateTimeStart: startB }) =>
      moment(startA).diff(moment(startB)),
    );
};

const PageWrapper = styled.div`
  ${media.tablet`
    padding: 10px 30px;
  `}

  ${media.desktop`
    padding: 10px 3.33vw;
  `}
`;

const calendarWrapper = css`
  padding: 0 15px 30px;
  max-width: 747px;
  margin: auto;
  border-bottom: ${({ theme }) => theme.palette.hague10} solid 1px;
  ${media.tablet`
    padding: 40px 0;
    border-top: ${({ theme }) => theme.palette.hague10} solid 1px;
  `}
`;

const exampleCapsuleWrapper = css`
  padding: 20px 15px 0;
  ${media.tablet`
    padding: 0;
    margin-bottom: 15px;
  `}
`;

const NextLabel = () => (
  <FontAwesomeIcon color="#2D89D9" icon={faChevronRight} />
);

const PrevLabel = () => (
  <FontAwesomeIcon color="#2D89D9" icon={faChevronLeft} />
);

export const ViewingCalendar = () => {
  const { search } = useLocation();
  const { date: urlDate, example: exampleMode } = qs.parse(search);

  const { data, loading } = usePublicDealQuery(ALL_VIEWINGS_QUERY, {
    skip: exampleMode,
  });
  const viewings = data?.publicDeal?.buyerEnquiries || [];
  const viewingsToShow = exampleMode
    ? allViewingsFirstImpressionsData
    : viewings;
  const viewingsIncludingBuyer = formatAndSortViewings(viewingsToShow);
  const [allViewings, setViewings] = useState({});

  const preSelectedDay = moment(urlDate).isValid() ? moment(urlDate) : moment();
  const [selectedDay, setSelectedDay] = useState(preSelectedDay.toDate());

  useEffect(() => {
    const days = aggregateDays(viewingsIncludingBuyer);
    setViewings(days);
  }, [data]);

  if (loading) return <PlaceholderCard />;

  return (
    <LayoutWrapper>
      <HeroSection noMobile />
      <MainContent>
        <PageWrapper>
          <Breadcrumb name="Viewings" path="/listing/viewings" />
          {exampleMode && (
            <div css={exampleCapsuleWrapper}>
              <ExampleCapsule css="margin-bottom: 0;" />
            </div>
          )}
          <VariablePageHeader>Viewing calendar</VariablePageHeader>
          <div>
            <div css={calendarWrapper} data-test="viewing-calendar">
              <CalendarStyle />
              <Calendar
                minDetail="month"
                prev2Label={null}
                next2Label={null}
                nextLabel={<NextLabel />}
                prevLabel={<PrevLabel />}
                defaultValue={selectedDay}
                formatShortWeekday={(locale, date) =>
                  moment(date).format("dd")[0]
                }
                tileClassName={({ date }) => {
                  if (allViewings[dateFormat(date)]) {
                    return "highlight";
                  }
                  return null;
                }}
                onChange={(date) => {
                  setSelectedDay(date);
                }}
              />
            </div>
          </div>
          <ViewingsOnDay
            selectedDay={selectedDay}
            allViewings={allViewings}
            exampleMode={exampleMode}
          />
        </PageWrapper>
      </MainContent>
    </LayoutWrapper>
  );
};
