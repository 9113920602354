// @flow
import { useContext } from "react";
import { gql } from "@apollo/client";
import styled, { css, ThemeContext } from "styled-components";
import {
  ErrorMessage,
  largeParagraph,
  mediumParagraph,
  smallParagraph,
  ResponsiveImage,
  media,
} from "@nested/brand";
import { ButtonLink } from "@nested/component-library";
import type { QueryRenderProps } from "@nested/utils";

import { PlaceholderCard } from "@nested/ui";
import { formatPriceWithKOrM } from "@nested/utils/src/formatPrice/formatPrice";
import { SectionBody, SectionTitle, Wrapper } from "../Section";
import { AuthenticatedQuery } from "../../../components/AuthenticatedQuery";
import { ErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { firstImpressionsMockDeal } from "../firstImpressionsMockDeal";

export const RM_RECOMMENDED_LISTING_PRICES = gql`
  query PropertyAnalysisListingPrices($externalDealId: String!) {
    publicDeal(externalDealId: $externalDealId) {
      id
      rmRecommendedListPrices {
        rmRecommendedMinListPrice
        rmRecommendedListPrice
        rmRecommendedMaxListPrice
      }
    }
  }
`;

type Props = {
  externalDealId: ?string,
  exampleMode?: boolean,
};

const PricesSection = styled.section`
  display: flex;
  flex-direction: column-reverse;
  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
  `}
`;

const SinglePriceSection = styled.section`
  display: flex;
  flex-direction: column-reverse;
  max-width: 550px;
  ${media.tablet`
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: -30px;
  `}
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${media.tablet`
    align-self: start;
    flex-direction: column;
    max-width: 80%;
    p {
      text-align: center;
    }
  `}
`;

const RedPrice = styled(Price)`
  justify-content: flex-end;
  margin: 0 0 0 7%;
  ${media.tablet`
    margin: 0;
    margin-top: 0%;
  `}
`;

const YellowPrice = styled(Price)`
  justify-content: center;
  margin: 5% 4%;
  ${media.tablet`
    margin: 0;
    margin-top: 4%;
  `}
`;

const GreenPrice = styled(Price)`
  justify-content: flex-start;
  margin: 0 7% 0 0;
  ${media.tablet`
    margin: 0;
    margin-top: 8%;
  `}
`;

const SinglePrice = styled(Price)`
  flex-direction: column;
  margin-top: 15px;
  text-align: center;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.sand40};
  padding: 25px 30px 15px 30px;
  ${media.tablet`
    width: 80%;
    margin-bottom: 30px;
    max-width: 380px;
  `}
`;

const priceStyle = css`
  ${largeParagraph};
  flex: auto;
  font-size: 26px;
  margin: 0;
  ${media.tablet`
    font-size: 24px;
  `}
`;

const priceImageStyle = css`
  height: 45px;
  flex: auto;
  flex-grow: 0;
`;

const chanceToSellStyle = css`
  ${smallParagraph};
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  flex: auto;
  margin: 15px 10px;
`;

const whyThisPriceStyle = css`
  ${smallParagraph};
  text-align: center;
`;

const horizontalLineStyle = css`
  display: none;
  ${media.tablet`
    display: block;
    position: absolute;
    z-index: 0;
    top: 88%;
    left: -5px;
    width: 100%;
    border-radius: 4px;
    height: 40px;
    background-color: ${({ theme }) => theme.palette.sand80};
    transform: skew(45deg, 0deg);
  `}
`;

const SectionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 620px;
`;

type CardProps = {
  prices: {
    greenPrice: ?number,
    yellowPrice: ?number,
    redPrice: ?number,
  },
  nonNullPrices: Array<number>,
};

const RecommendedListPriceBase = () => (
  <>
    <div css={horizontalLineStyle} />
    <BasketballPlayerImageTablet
      src="https://nested.imgix.net/your-account/property-analysis/basketball-player.png"
      alt="basketball-player"
    />
  </>
);

const GreenPriceCard = ({ theme, greenPrice }) => (
  <GreenPrice>
    <p
      css={priceStyle}
      style={{ color: theme.palette.green }}
      data-test="recommended-min-price"
    >
      <strong>{formatPriceWithKOrM(greenPrice)}</strong>
    </p>
    <p css={chanceToSellStyle}>
      <strong>No sweat</strong>
    </p>
    <ResponsiveImage
      style={{ height: "40px" }}
      src="https://nested.imgix.net/your-account/property-analysis/green-net.png"
      alt="basket-min-price"
      css={priceImageStyle}
    />
  </GreenPrice>
);

const YellowPriceCard = ({ theme, yellowPrice }) => (
  <YellowPrice>
    <p
      css={priceStyle}
      style={{ color: theme.palette.yellow100 }}
      data-test="recommended-yellow-price"
    >
      <strong>{formatPriceWithKOrM(yellowPrice)}</strong>
    </p>
    <p css={chanceToSellStyle}>
      <strong>You've got a great shot</strong>
    </p>
    <ResponsiveImage
      style={{ height: "40px" }}
      src="https://nested.imgix.net/your-account/property-analysis/yellow-net.png"
      alt="basket-mid-price"
      css={priceImageStyle}
    />
  </YellowPrice>
);

const RedPriceCard = ({ theme, redPrice }) => (
  <RedPrice>
    <p
      css={priceStyle}
      style={{ color: theme.palette.terracotta100 }}
      data-test="recommended-red-price"
    >
      <strong>{formatPriceWithKOrM(redPrice)}</strong>
    </p>
    <p css={chanceToSellStyle}>
      <strong>Test the market</strong>
    </p>
    <ResponsiveImage
      style={{ height: "40px" }}
      src="https://nested.imgix.net/your-account/property-analysis/red-net.png"
      alt="basket-max-price"
      css={priceImageStyle}
    />
  </RedPrice>
);

const RecommendedListPriceCard = ({
  prices: { greenPrice, yellowPrice, redPrice },
  nonNullPrices,
}: CardProps) => {
  const theme = useContext(ThemeContext);
  return (
    <SectionCardWrapper>
      {yellowPrice && (greenPrice || redPrice) && (
        <p
          css={smallParagraph}
          style={{ color: theme.palette.hague80, marginTop: 0 }}
        >
          <em>
            <strong>Test the market... Or a swift sale?</strong>
          </em>
        </p>
      )}
      {nonNullPrices.length === 1 && (
        <SinglePriceSection data-test="single-price-section">
          <SinglePrice>
            <p
              css={priceStyle}
              style={{ color: theme.palette.green }}
              data-test="recommended-min-price"
            >
              <strong>{formatPriceWithKOrM(nonNullPrices[0])}</strong>
            </p>
            <p css={chanceToSellStyle}>
              <strong>Why this price?</strong>
            </p>
            <p css={whyThisPriceStyle}>
              This is the initial asking price we’d recommend in order to gain
              immediate interest on the market and compete with similar local
              properties.
            </p>
          </SinglePrice>
        </SinglePriceSection>
      )}
      {nonNullPrices.length > 1 && (
        <PricesSection>
          <RecommendedListPriceBase />
          {greenPrice && (
            <GreenPriceCard theme={theme} greenPrice={greenPrice} />
          )}
          {yellowPrice && (
            <YellowPriceCard theme={theme} yellowPrice={yellowPrice} />
          )}
          {redPrice && <RedPriceCard theme={theme} redPrice={redPrice} />}
        </PricesSection>
      )}
    </SectionCardWrapper>
  );
};

const basketballContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 550px;
`;

const BasketballPlayerImageTablet = styled(ResponsiveImage)`
  display: none;
  ${media.tablet`
    display: inline;
    flex: auto;
    height: 160px;
    margin-right: 15px;
    margin-top: 12%;
    flex-grow: 0;
    z-index: 1;
  `}
  ${media.desktop`
    height: 180px;
  `}
`;

const BasketballPlayerImageMobile = styled(ResponsiveImage)`
  flex: auto;
  height: 140px;
  margin-right: 15px;
  display: inline;
  ${media.tablet`
    display: none;
  `}
`;

const basketballPlayerTextStyle = css`
  ${mediumParagraph};
  font-size: 14px;
  flex: auto;
  line-height: 20px;
  ${media.tablet`
    ${mediumParagraph}
  `}
`;

export const RecommendedListPriceSection = ({
  externalDealId,
  exampleMode,
}: Props) => {
  return (
    <Wrapper
      data-test="recommended-list-price-section"
      id="recommended-list-price-section"
    >
      <SectionTitle>Choosing your listing price</SectionTitle>
      <SectionBody
        css={css`
          ${media.desktop`
            overflow: visible;
          `}
        `}
      >
        <ErrorBoundary>
          <AuthenticatedQuery
            query={RM_RECOMMENDED_LISTING_PRICES}
            variables={{ externalDealId }}
            placeholder={PlaceholderCard}
            skip={exampleMode}
          >
            {({ data }: QueryRenderProps<PropertyAnalysisListingPrices>) => {
              const publicDeal = exampleMode
                ? firstImpressionsMockDeal
                : data?.publicDeal;
              if (!publicDeal) {
                return <ErrorMessage brokenComponentName="list prices" />;
              }

              const nullifyPriceIfZero = (price) =>
                price === 0 ? null : price;

              const greenPrice = nullifyPriceIfZero(
                publicDeal.rmRecommendedListPrices.rmRecommendedMinListPrice,
              );
              const yellowPrice = nullifyPriceIfZero(
                publicDeal.rmRecommendedListPrices.rmRecommendedListPrice,
              );
              const redPrice = nullifyPriceIfZero(
                publicDeal.rmRecommendedListPrices.rmRecommendedMaxListPrice,
              );

              const nonNullPrices = (([
                greenPrice,
                yellowPrice,
                redPrice,
              ].filter(
                (price) => typeof price === "number",
              ): Array<any>): Array<number>);

              return (
                <>
                  <RecommendedListPriceCard
                    prices={{ greenPrice, yellowPrice, redPrice }}
                    nonNullPrices={nonNullPrices}
                  />
                  {(yellowPrice || redPrice) && (
                    <div css={basketballContainerStyle}>
                      <BasketballPlayerImageMobile
                        src="https://nested.imgix.net/your-account/property-analysis/basketball-player.png"
                        alt="basketball-player"
                      />

                      <p css={basketballPlayerTextStyle}>
                        <em>
                          80% of accepted offers come within 62 days of listing
                          at a new price. If you haven't sold by then, it might
                          be time to consider taking an easier shot.
                        </em>
                      </p>
                    </div>
                  )}

                  <p css={mediumParagraph} style={{ maxWidth: "550px" }}>
                    <strong>
                      The asking price you choose depends on your plans and how
                      long you&apos;re willing to wait to sell.{" "}
                    </strong>
                    Nested will track your performance as soon as you go on the
                    market to make sure you&apos;re getting sufficient response
                    from buyers.
                  </p>

                  <ButtonLink type="primary" to="/listing/interest">
                    See performance
                  </ButtonLink>
                </>
              );
            }}
          </AuthenticatedQuery>
        </ErrorBoundary>
      </SectionBody>
    </Wrapper>
  );
};
