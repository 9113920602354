// @flow
import styled, { css } from "styled-components";
import {
  mediumParagraph,
  ResponsiveImage,
  smallParagraph,
  media,
} from "@nested/brand";

const ImagePlaceholderWrapper = styled.div`
  text-align: center;
  padding: 0 5vw;
  background-color: ${({ theme }) => theme.palette.pink40};
  border-radius: 4px 4px 0 0;
  height: calc(100vw / (690 / 455));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 690px;
  ${media.tablet`
    height: calc(90vw / (690 / 455));
    max-height: 460px;
  `}
`;

const SellerPlaceholderWrapper = styled(ImagePlaceholderWrapper)`
  background-color: ${({ theme }) => theme.palette.pink40};
  text-align: center;
  position: relative;
  padding: 0 40px;
  height: 220px;
  ${media.tablet`
    max-height: unset;
    height: 280px;
  `}
  ${media.desktop`
    height: auto;
    width: 100%;
    border-radius: 4px 0 0 4px;
  `}
`;

const Link = styled.a`
  color: ${({ theme }) => theme.palette.hague};
  text-decoration: underline;
`;

type Props = { link: ?string };

export const ImagePlaceholder = ({ link }: Props) => (
  <ImagePlaceholderWrapper>
    <CameraImage
      src="https://nested.imgix.net/your-account/placeholder-camera.png"
      alt="camera"
    />
    <p css={smallParagraph}>
      Sorry, no photos available.{" "}
      {link && (
        <Link href={link} target="_blank">
          See the listing here.
        </Link>
      )}
    </p>
  </ImagePlaceholderWrapper>
);

const frameStyle = css`
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
`;

const topFrameStyle = css`
  &:before {
    ${frameStyle};
    top: -10px;
    left: -10px;
  }
  &:after {
    ${frameStyle};
    top: -10px;
    right: -10px;
  }
`;

const bottomFrameStyle = css`
  &:before {
    ${frameStyle};
    bottom: -10px;
    left: -10px;
  }
  &:after {
    ${frameStyle};
    bottom: -10px;
    right: -10px;
  }
`;

const CameraImage = styled(ResponsiveImage)`
  width: 100px;
  max-width: 140px;
  margin: 0 auto 2vw;
  ${media.tablet`
    width: 100%;
  `}
`;

export const SellerHomeImagesPlaceholder = () => (
  <SellerPlaceholderWrapper>
    <div css={topFrameStyle}>
      <div css={bottomFrameStyle}>
        <CameraImage
          src="https://nested.imgix.net/your-account/placeholder-camera.png"
          alt="camera"
        />

        <p css={mediumParagraph}>
          <strong>We&apos;ll take some professional photos soon</strong>
        </p>
      </div>
    </div>
  </SellerPlaceholderWrapper>
);
