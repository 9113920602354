import { useContext } from "react";
import { css, ThemeContext } from "styled-components";
import {
  largeParagraph,
  media,
  mediumParagraph,
  smallParagraph,
} from "@nested/brand";
import { getImage } from "@nested/utils";
import { formatDateTime } from "@nested/utils/src/formatDate/formatDate";
import { CircleImage } from "../../components/CircleImage";

const wrapperStyle = css`
  padding: 0 20px 20px;
  ${media.tablet`
    padding: 40px;
  `}
`;

const lastUpdatedStyle = css`
  ${smallParagraph};
  font-weight: 500;
`;

const falloutStyle = css`
  margin-top: 0px;
  ${mediumParagraph};
  font-style: italic;
  opacity: 0.8;
`;

const noFalloutWrapper = css`
  display: none;

  ${media.tablet`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const imageWrapper = css`
  padding: 20px;
`;

const noFalloutStyle = css`
  ${largeParagraph}
  text-align: center;
  font-weight: 500;
  line-height: 24px;
  padding: 0 15px;

  p {
    margin: 0;
  }
`;

const noFalloutLastUpdatedStyle = css`
  ${lastUpdatedStyle}
  ${media.tablet`
    display: none;
  `}
`;

export const DidNotBookContent = ({ enquiry }) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      data-test={`enquiries-modal-${enquiry.id}-did-not-book`}
      css={wrapperStyle}
    >
      {enquiry.falloutDetails ? (
        <>
          <p css={falloutStyle}>“{enquiry.falloutDetails}”</p>
          <div css={lastUpdatedStyle}>
            Updated{" "}
            {formatDateTime(enquiry.contactLastUpdatedAt, {
              appendTime: false,
            })}
          </div>
        </>
      ) : (
        <>
          <div css={noFalloutWrapper}>
            <div css={imageWrapper}>
              <CircleImage
                circleColor={theme.palette.blue20}
                imageAlt="Booked a viewing"
                imageSrc={getImage("your-account/enquiry-no-reason.png")}
              />
            </div>
            <div css={noFalloutStyle}>
              <p>The enquirer didn't specify why they didn't book a viewing.</p>
            </div>
          </div>
          <div css={noFalloutLastUpdatedStyle}>
            Updated{" "}
            {formatDateTime(enquiry.contactLastUpdatedAt, {
              appendTime: false,
            })}
          </div>
        </>
      )}
    </div>
  );
};
