// @flow

import styled, { css } from "styled-components";
import { media } from "@nested/brand";

import { HeroSection } from "../components/HeroSection/HeroSection";
import { TabletTabNavigation } from "../components/Navigation/TabNavigation";

// This wraps the custom content & sidebar/hero (excludes nav)
export const LayoutWrapper = styled.div`
  background-color: white;
  padding: 0px;
  margin: 0px;

  max-width: 600px;
  width: 100%;
  min-height: calc(100vh - 57px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ dark }) =>
    dark &&
    css`
      background-color: ${({ theme }) => theme.palette.hague};
      color: white;
      ${media.desktop`
      background-color: ${({ theme }) => theme.palette.hague130};
      `}
    `}

  ${media.tablet`
    /* This is what pushes the hero section into a sidebar */
    flex-direction: row-reverse;
    justify-content: flex-end;

    max-width: 1350px;
  `}
`;

export const LegacyLayoutWrapper = styled(LayoutWrapper)`
  ${media.tablet`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

// This wraps the custom content of the page, i.e. excludes nav, hero and sidebars
export const MainContent = styled.div`
  width: 100%;

  ${media.tablet`
    min-width: 479px;
    max-width: 800px;
  `};

  ${media.desktop`
    max-width: 810px;
  `}
`;

export const PageWrapper = styled.div`
  padding: 30px 15px;

  ${media.tablet`
    padding: 30px;
  `}

  ${media.desktop`
    padding: 40px 3.33vw;
  `}
`;

export const PurchasePageWrapper = styled.div`
  padding: 30px 15px;

  ${media.tablet`
    padding: 40px 30px;
  `}

  ${media.desktop`
    padding: 40px 3.33vw;
  `}
`;

export const LegacyPageWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 0 20px;
  ${media.tablet`
    padding: 10px 30px;
  `}
  ${media.desktop`
    padding: 10px 3.33vw 50px 3.33vw;
  `}
`;

export const BreadcrumbWrapper = styled.div`
  padding-bottom: 15px;
  ${media.tablet`
    padding: 10px 30px 0 30px;
  `} ${media.desktop`
    padding: 10px 3.33vw 0 3.33vw;
  `};
`;

// This wraps the main content of the page, the stuff beneath the Breadcrumb
export const ContentWrapper = styled.div`
  padding: 0 0 10px 0;
  ${media.tablet`
    padding: 10px 30px 10px 30px;
  `} ${media.desktop`
    padding: 10px 3.33vw 10px 3.33vw;
  `};
`;

type Props = { children: React$Node };

// Used for most parent pages (i.e. tabs)
export const DefaultWrapper = ({ children }: Props) => (
  <LayoutWrapper>
    <HeroSection />
    <MainContent>
      <TabletTabNavigation />
      <PageWrapper>{children}</PageWrapper>
    </MainContent>
  </LayoutWrapper>
);

// Used for child pages (i.e. tools) migrated from account V1
export const DefaultLegacyWrapper = ({ children }: Props) => (
  <LegacyLayoutWrapper>
    <MainContent>
      <LegacyPageWrapper>{children}</LegacyPageWrapper>
    </MainContent>
  </LegacyLayoutWrapper>
);
