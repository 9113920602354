// @flow
import { css } from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { media, smallParagraph } from "@nested/brand";

const container = css`
  box-sizing: border-box;
  height: 50px;
  padding: 14px 15px;
  border-bottom: ${({ theme, dark }) =>
      dark ? "rgba(255, 255, 255, 0.2)" : theme.palette.hague10}
    solid 1px;
  ${media.tablet`
    border-bottom: none;
    height: 66px;
    padding: 30px 0 15px;
  `}
`;

const text = css`
  ${smallParagraph}
  font-weight: 500;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ dark }) =>
    dark &&
    css`
      color: white;
    `}

  ${media.tablet`
    font-size: 16px;
  `}
`;

const linkStyles = css`
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const iconWrapper = css`
  margin-left: 0px;
  opacity: ${({ dark }) => (dark ? "0.5" : "1")};
`;

const pageNameStyle = css`
  margin-left: 5px;
`;

type Props = {
  name: string,
  path: string,
  dark?: boolean,
  className?: string,
};

export const Breadcrumb = ({ className, name, path, dark }: Props) => {
  return (
    <div className={className} css={container} dark={dark}>
      <p css={text} dark={dark}>
        <Link to={path} css={linkStyles}>
          <span css={iconWrapper} dark={dark}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
          <span css={pageNameStyle}>{name}</span>
        </Link>
      </p>
    </div>
  );
};
