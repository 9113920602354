// @flow
import { gql } from "@apollo/client";
import styled, { css } from "styled-components";

import { PlaceholderCard } from "@nested/ui";
import {
  ErrorMessage,
  largeSectionHeading,
  sectionHeading,
  smallParagraph,
  largeParagraph,
  media,
} from "@nested/brand";
import type { QueryRenderProps } from "@nested/utils";
import { formatPrice, formatDateWithMoment } from "@nested/utils";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthenticatedQuery } from "../../../components/AuthenticatedQuery";
import {
  PropertyCard,
  SectionBody,
  SectionDescription,
  SectionTitle,
  Wrapper,
} from "../Section";
import { SellerHomeImagesPlaceholder } from "../ImagePlaceholder";
import { firstImpressionsMockDeal } from "../firstImpressionsMockDeal";

export const PROPERTY_DETAILS_QUERY = gql`
  query PropertyAnalysisPropertyDetails($externalDealId: String!) {
    publicDeal(externalDealId: $externalDealId) {
      id
      property {
        formattedAddress
        summaryForPropertyAnalysis
        mostRecentSale {
          amount
          date
        }
        keyFeatures {
          id
          description
        }
      }
    }
  }
`;

const SectionWrapper = styled.div`
  max-width: 690px;
  margin: 0 auto 10px;
  ${media.desktop`
    margin: 0 auto 30px;
    margin: 0;
  `}
`;

const Address = styled.h3`
  ${largeSectionHeading};
  font-size: 20px;
  text-align: left;
  margin: 0 0 20px 0;
  ${media.desktop`
    font-size: 22px;
  `}
`;

const LastKnownSalesPrice = styled.p`
  ${largeParagraph};
  margin: 0;
  color: ${({ theme }) => theme.palette.terracotta100};
  ${media.desktop`
    font-size: 22px;
  `}
`;

const Label = styled.p`
  ${sectionHeading};
  font-size: 10px;
  letter-spacing: 2px;
  margin-bottom: 5px;
  ${media.desktop`
    font-size: 12px;
  `}
`;

const CardPlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 30px;
  `}
`;

const Card = styled(PropertyCard)`
  padding: 20px 0 10px;
  margin-bottom: 0;
  ${media.desktop`
    margin-left: 40px;
    min-height: 236px;
    max-width: 381px;
    display: flex;
    justify-content: center;
  `}
`;

const PropertySummary = styled(SectionDescription)`
  white-space: pre-wrap;
  word-break: break-word;
`;

const KeyFeaturesCard = styled.div`
  background-color: ${({ theme }) => theme.palette.sand40};
  padding: 10px 20px;
  margin-top: 0;
  margin-bottom: 30px;
  border-radius: 4px;
  max-width: 690px;
`;

const tickStyle = css`
  color: ${({ theme }) => theme.palette.green};
  margin-right: 10px;
`;

const KeyFeaturesHeading = styled.p`
  ${sectionHeading};
  margin-top: 0.8em;
  letter-spacing: 1px;
`;

const featureTextStyle = css`
  ${smallParagraph};
  margin: 0;
`;

const featuresWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
`;

const KeyFeature = styled.div`
  display: flex;
  margin-bottom: 5px;
  padding-left: 20px;
`;

type Props = {
  externalDealId: ?string,
  exampleMode?: boolean,
};

export const PropertyDetailsSection = ({
  externalDealId,
  exampleMode,
}: Props) => (
  <Wrapper id="property-details-section">
    <SectionTitle data-test="property-details-title">
      Your property details
    </SectionTitle>
    <SectionBody>
      <AuthenticatedQuery
        query={PROPERTY_DETAILS_QUERY}
        variables={{ externalDealId }}
        placeholder={PlaceholderCard}
        skip={exampleMode}
      >
        {({ data }: QueryRenderProps<PropertyAnalysisPropertyDetails>) => {
          const publicDeal = exampleMode
            ? firstImpressionsMockDeal
            : data?.publicDeal;
          const {
            property: {
              formattedAddress,
              keyFeatures,
              mostRecentSale,
              summaryForPropertyAnalysis,
            },
          } = publicDeal;
          return !publicDeal ? (
            <ErrorMessage />
          ) : (
            <SectionWrapper>
              <CardPlaceholderWrapper>
                <SellerHomeImagesPlaceholder />
                <Card>
                  <Address data-test="formatted-address">
                    {formattedAddress}
                  </Address>
                  {mostRecentSale && mostRecentSale.amount && (
                    <>
                      <Label>Last known sales price</Label>
                      <LastKnownSalesPrice data-test="last-sale-price">
                        <strong>
                          {formatPrice(parseInt(mostRecentSale.amount, 10)) ||
                            "N/A"}
                        </strong>
                      </LastKnownSalesPrice>
                      {mostRecentSale && mostRecentSale.date && (
                        <Label>
                          {formatDateWithMoment(mostRecentSale.date.toString())}
                        </Label>
                      )}
                    </>
                  )}
                </Card>
              </CardPlaceholderWrapper>
              {keyFeatures.length > 0 && (
                <>
                  <KeyFeaturesCard>
                    <KeyFeaturesHeading>Key features</KeyFeaturesHeading>
                    <div css={featuresWrapperStyle}>
                      {keyFeatures.length > 0 &&
                        keyFeatures.map(({ description }, index) => (
                          <KeyFeature key={index}>
                            <FontAwesomeIcon icon={faCheck} css={tickStyle} />
                            <p css={featureTextStyle}>{description}</p>
                          </KeyFeature>
                        ))}
                    </div>
                  </KeyFeaturesCard>
                </>
              )}
              {summaryForPropertyAnalysis && (
                <>
                  <Address>Summary of the property</Address>
                  <PropertySummary left data-test="property-summary">
                    {summaryForPropertyAnalysis}
                  </PropertySummary>
                </>
              )}
            </SectionWrapper>
          );
        }}
      </AuthenticatedQuery>
    </SectionBody>
  </Wrapper>
);
