// @flow
import type { QueryRenderProps } from "@nested/utils";
import { PropertyAnalysisCard } from "./PropertyAnalysisCard";
import { ActionPlan } from "../ActionPlan/ActionPlanCard";
import { DefaultWrapper } from "../PageWrapper";
import { DEAL_QUERY } from "../Listing/Listing";
import { PublicDealQuery } from "../../components/AuthenticatedQuery";
import { PageHeader } from "../../components/Heading";
import { PreCloseContactCta } from "../../components/PreCloseContactCta/PreCloseContactCta";

export const Strategy = () => {
  return (
    <DefaultWrapper>
      <PageHeader>Strategy</PageHeader>
      <PublicDealQuery query={DEAL_QUERY}>
        {({ data }: QueryRenderProps<ListingSectionQuery>) => (
          <>
            <ActionPlan deal={data?.publicDeal} />
            <PropertyAnalysisCard deal={data?.publicDeal} />
            <PreCloseContactCta deal={data?.publicDeal} />
          </>
        )}
      </PublicDealQuery>
    </DefaultWrapper>
  );
};
