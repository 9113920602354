import { useContext, useMemo } from "react";
import { css, ThemeContext } from "styled-components";
import { DoughnutChart, media } from "@nested/brand";
import { VIEWING_BOOKED_STATUS, DID_NOT_BOOK_VIEWING_STATUS } from "./utils";

const chartAndLegendWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  flex-wrap: wrap;
  justify-content: center;
  ${media.tablet`
    justify-content: flex-start;
  `}
`;

const chartWrapperStyle = css`
  padding-right: 20px;
`;

const chartCentreStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
`;

const bigNumber = css`
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
`;

const smallWord = css`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;

const legendItemStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:first-child) {
    margin-top: 5px;
  }
`;

const legendValue = css`
  font-size: 28px;
  line-height: 36px;
  padding-right: 15px;
  font-weight: 500;
  width: 36px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  color: ${({ color }) => color};
`;

const underlineHover = css`
  &:hover {
    text-decoration: underline;
  }
`;

const legendWrapper = css`
  min-width: 133px;
  flex-grow: 1;
  flex-basis: 0;
`;

const legendLabel = css`
  color: #023344;
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;
  flex-grow: 1;
  padding-right: 10px;
  text-decoration: none;
  ${({ noEnquiries }) => (noEnquiries ? "" : underlineHover)}
`;

const chartOptions = {
  cutout: "70%",
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const getChartValues = (enquiries) => {
  if (!enquiries) {
    return [0, 0, 0];
  }
  return enquiries.reduce(
    ([chasing, booked, noped], { enquiryStatus }) => {
      if (enquiryStatus === DID_NOT_BOOK_VIEWING_STATUS) {
        return [chasing, booked, noped + 1];
      }
      if (enquiryStatus === VIEWING_BOOKED_STATUS) {
        return [chasing, booked + 1, noped];
      }
      return [chasing + 1, booked, noped];
    },
    [0, 0, 0],
  );
};

export const EnquiriesDoughnut = ({ enquiries }) => {
  const theme = useContext(ThemeContext);

  const values = useMemo(() => getChartValues(enquiries), [enquiries]);

  const [chasing, booked, noped] = values;
  const noEnquiries = chasing + booked + noped === 0;

  const chartData = {
    labels: ["We're chasing now", "Booked a viewing", "Didn't book a viewing"],
    datasets: [
      {
        backgroundColor: [
          theme.palette.orange,
          theme.palette.green500,
          theme.palette.hague,
        ],
        data: noEnquiries ? [0, 0, 100] : values,
      },
    ],
  };
  return (
    <div css={chartAndLegendWrapper}>
      <div css={chartWrapperStyle} data-test="enquiries-donut">
        <DoughnutChart
          height={130}
          width={130}
          data={chartData}
          chartOptionsOverride={chartOptions}
        >
          <div css={chartCentreStyle}>
            <div css={bigNumber}>{chasing + booked + noped}</div>
            <div css={smallWord}>Enquiries</div>
          </div>
        </DoughnutChart>
      </div>
      <div css={legendWrapper}>
        <div css={legendItemStyle}>
          <div css={legendValue} color={theme.palette.orange}>
            {chasing}
          </div>
          <div css={legendLabel} noEnquiries={noEnquiries}>
            We're chasing now
          </div>
        </div>
        <div css={legendItemStyle}>
          <div css={legendValue} color={theme.palette.green500}>
            {booked}
          </div>
          <div css={legendLabel} noEnquiries={noEnquiries}>
            Booked a viewing
          </div>
        </div>
        <div css={legendItemStyle}>
          <div css={legendValue} color={theme.palette.hague}>
            {noped}
          </div>
          <div css={legendLabel} noEnquiries={noEnquiries}>
            Didn't book a viewing
          </div>
        </div>
      </div>
    </div>
  );
};
