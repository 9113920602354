// @flow
import { useMemo } from "react";
import moment from "moment-timezone";
import { css } from "styled-components";
import { groupBy } from "lodash";
import {
  UpcomingViewingModal,
  virtualViewingDot,
} from "./UpcomingViewingModal";
import { useModalCollection } from "../../../hooks/useModalCollection";

export const formatDayString = (date: string) => moment(date).format("ddd Do"); // Wed 7th
export const formatTime = (date: string) => moment(date).format("H:mm"); // 10:00

type TransformedViewing = {
  ...UpcomingViewingDetails_publicDeal_buyerEnquiries_viewings,
  buyer: UpcomingViewingDetails_publicDeal_buyerEnquiries_buyer,
};

export const structureViewingTimes = (viewings: TransformedViewing[]) => {
  const transformed = viewings
    .sort(({ dateTimeStart: startA }, { dateTimeStart: startB }) =>
      moment(startA).diff(moment(startB)),
    )
    .map((viewing) => ({
      viewing,
      day: formatDayString(viewing.dateTimeStart),
      time: formatTime(viewing.dateTimeStart),
    }));

  const groupedByDay = groupBy(transformed, (v) => v.day);

  // Because all the keys are strings e.g. "Thu 1", the ordering
  // should reflect insertion order
  // $FlowFixMe
  return Object.keys(groupedByDay).map((key) => ({
    day: key,
    viewings: groupedByDay[key].map(({ viewing, time }) => ({ viewing, time })),
  }));
};

const dayContainer = css`
  display: flex;
  margin-bottom: 6px;
  :last-of-type {
    margin-bottom: 0;
  }
`;

const textStyles = css`
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 21px;
  font-weight: 500;
  text-transform: uppercase;
`;

const dayText = css`
  ${textStyles}
  color: ${({ theme }) => theme.palette.hague50};
  width: 56px;
  margin-right: 26px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 3px;
  white-space: nowrap;
`;

const timeCapsule = css`
  ${textStyles}
  color: ${({ theme }) => theme.palette.blue150};
  background-color: ${({ theme }) => theme.palette.blue10};
  text-align: center;
  border-radius: 5px;
  margin: 3px 4px;
  border: none;
  outline: none;
  display: inline-block;
  padding: 0 6px;
  cursor: pointer;
  transition: transform 0.1s linear, background-color 0.1s linear;
  &:hover {
    background-color: ${({ theme }) => theme.palette.blue20};
    transform: translateY(-1px);
  }
`;

const timesContainer = css`
  display: flex;
  flex-wrap: wrap;
  & > span {
    margin-right: 10px;
  }
  :last-child {
    margin-right: 0;
  }
`;

const legend = css`
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  span {
    margin-left: 5px;
  }
  div {
    margin-left: 0;
  }
`;

const includesVirtualViewings = (viewings) => {
  const virtualViewing = viewings.find(({ virtual }) => virtual);
  return Boolean(virtualViewing);
};

const UpcomingViewing = ({ time, viewing, onClick }) => {
  return (
    <button onClick={onClick} key={viewing.id} css={timeCapsule}>
      {time}
      {viewing.virtual && <div css={virtualViewingDot}></div>}
    </button>
  );
};

type Props = {
  buyerEnquiries: UpcomingViewingDetails_publicDeal_buyerEnquiries[],
  exampleMode?: boolean,
};

export const UpcomingViewingsSchedule = ({
  buyerEnquiries,
  exampleMode,
}: Props) => {
  const viewings = buyerEnquiries.flatMap(
    ({ buyer, viewings: viewingsForBuyer }) => {
      return viewingsForBuyer.map((v) => ({ ...v, buyer }));
    },
  );

  const nextThreeDaysOfViewings = useMemo(
    () => structureViewingTimes(viewings).slice(0, 3),
    [viewings],
  );

  const hasVirtualViewings = includesVirtualViewings(viewings);
  const sortedViewings = nextThreeDaysOfViewings
    .flatMap((d) => d.viewings)
    .map(({ viewing }) => viewing);

  const { open, selectAndOpen, onClose, selectedItem, next, previous } =
    useModalCollection(sortedViewings);

  // If this causes bugs in the future, I'm sorry.
  let viewingIndex = 0;

  return (
    <div>
      <UpcomingViewingModal
        open={open}
        onClose={onClose}
        next={next}
        previous={previous}
        viewing={selectedItem}
        exampleMode={exampleMode}
      />
      {nextThreeDaysOfViewings.map(({ day, viewings: dayViewings }, index) => {
        return (
          <div css={dayContainer} key={`day-${index}`}>
            <div css={dayText}>{day}</div>
            <div css={timesContainer}>
              {dayViewings.map(({ viewing, time }) => {
                // Required to scope the current value of the variable to this function.
                const currentIndex = viewingIndex;
                viewingIndex += 1;

                return (
                  <UpcomingViewing
                    key={viewing.id}
                    time={time}
                    viewing={viewing}
                    onClick={() => selectAndOpen(currentIndex)}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
      {hasVirtualViewings && (
        <div css={legend}>
          <div css={virtualViewingDot}></div> <span>Virtual viewing</span>
        </div>
      )}
    </div>
  );
};
