// @flow
import { useLocation } from "react-router";
import { MetaTags as DefaultMetaTags } from "@nested/brand";
import { getImage } from "@nested/utils";
import { isNativeApp } from "../gonative";

const DEFAULT_TITLE = "Your Account";

const getUrl = () => {
  if (typeof window === "undefined") {
    return "";
  }

  return encodeURI(window.location.href);
};

const MetaTags = () => {
  useLocation(); // To trigger re-renders on location change

  return (
    <DefaultMetaTags disableZoom={isNativeApp()}>
      <meta property="og:site_name" content="Nested" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={DEFAULT_TITLE} />
      <meta property="og:image" content={getImage("nested_og_image.png")} />
      <title>{DEFAULT_TITLE} - Nested</title>
      <link
        href="https://fonts.googleapis.com/css?family=Reenie+Beanie&display=swap"
        rel="stylesheet"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta
        name="apple-itunes-app"
        content={`app-id=1544094142, app-argument=${getUrl()}`}
      />
      <link
        rel="icon"
        sizes="192x192"
        href="https://images.prismic.io/nested/0f440baf-f473-4eaa-8184-1115d0ebb16d_192x192.png?auto=compress,format"
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="https://images.prismic.io/nested/0f440baf-f473-4eaa-8184-1115d0ebb16d_192x192.png?auto=compress,format"
      />
      <link
        rel="icon"
        sizes="512x512"
        href="https://images.prismic.io/nested/7c8a24b4-a990-472d-a9fe-76fb88afda33_512x512.png?auto=compress,format"
      />
      <link
        rel="apple-touch-icon"
        sizes="512x512"
        href="https://images.prismic.io/nested/7c8a24b4-a990-472d-a9fe-76fb88afda33_512x512.png?auto=compress,format"
      />
      <script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
      />
    </DefaultMetaTags>
  );
};

export { MetaTags };
