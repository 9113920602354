import React from "react";
import { Sentry } from "@nested/isomorphic-sentry";
import { ErrorPage } from "@nested/brand";

export class ErrorHandler extends React.Component<
  { children: React$Node },
  { hasError: boolean },
> {
  state = {};

  componentDidCatch(error, info) {
    Sentry.captureException(error, { info: JSON.stringify(info) });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}
