// @flow
import { useContext } from "react";
import { css, ThemeContext } from "styled-components";
import { Route, Switch } from "react-router";
import { media } from "@nested/brand";
import { OfferBanner } from "./OfferBanner";

import { ListingSubMenuItems, SaleSubMenuItems } from "./SideBarMenu";

const tabSharedStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  margin: 0px;
  padding: 0px;

  > div {
    min-width: 80px;
    flex-grow: 1;
    padding: 0px;
  }

  > div > a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

const mobileNavigationTabs = css`
  ${tabSharedStyles}
  > div {
    max-width: 105px;
  }

  > div > a {
    flex-direction: column;
  }

  > div > a > span {
    margin: 0px;
  }

  > div > a > span:last-child {
    margin-top: 10px;
    font-size: 14px;
  }

  ${media.tablet`
    display: none;
  `}
`;

const tabletNavigationTabs = css`
  ${tabSharedStyles}
  display: none;

  ${media.tablet`
    display: flex;
  `}
  margin-top: 40px;
  padding: 0 30px;
  > div {
    padding-bottom: 15px;
  }
  > div > a {
    flex-direction: row;
    justify-content: center;
  }
  > div > a > span:last-child {
    margin-left: 10px;
  }
  ${media.desktop`
    display: none;
  `}
`;

const mobileOnly = css`
  display: block;
  ${media.tablet`
    display: none;
  `}
`;

const tabletOnly = css`
  display: none;
  ${media.tablet`
    display: block;
  `}
  ${media.desktop`
    display: none;
  `}
`;

export const MobileTabNavigation = () => {
  const theme = useContext(ThemeContext);

  const clickHandler = (e) => {
    if (e?.currentTarget) {
      // $FlowFixMe the body definitely does exist but flow thinks it might not
      const bodyRect = document.body.getBoundingClientRect();
      const elementRect = e.currentTarget.getBoundingClientRect();
      const top = elementRect.top - bodyRect.top;
      window.scrollTo(0, top);
    }
  };

  return (
    <Switch>
      <Route path="/listing">
        <div css={mobileOnly}>
          <OfferBanner />
        </div>
        <div css={mobileNavigationTabs}>
          <ListingSubMenuItems
            textColor={theme.palette.hague80}
            onItemClick={clickHandler}
          />
        </div>
      </Route>
      <Route path="/sale">
        <div css={mobileNavigationTabs}>
          <SaleSubMenuItems
            textColor={theme.palette.hague80}
            onItemClick={clickHandler}
          />
        </div>
      </Route>
    </Switch>
  );
};

export const TabletTabNavigation = () => {
  const theme = useContext(ThemeContext);
  return (
    <div>
      <Switch>
        <Route path="/listing">
          <div css={tabletOnly}>
            <OfferBanner />
          </div>
          <div css={tabletNavigationTabs}>
            <ListingSubMenuItems textColor={theme.palette.hague80} isTab />
          </div>
        </Route>
        <Route path="/sale">
          <div css={tabletNavigationTabs}>
            <SaleSubMenuItems textColor={theme.palette.hague80} isTab />
          </div>
        </Route>
      </Switch>
    </div>
  );
};
