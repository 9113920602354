// @flow
import { ExtendedQuery, type ExtendedQueryProps } from "@nested/utils";
import { Error as GenericErrorComponent, PlaceholderCard } from "@nested/brand";
import { useAuth } from "@nested/auth";

const AuthenticatedQuery = <TData, TVariables>({
  ErrorComponent = GenericErrorComponent,
  PlaceholderComponent = PlaceholderCard,
  children,
  ...rest
}: $Rest<
  ExtendedQueryProps<TData, TVariables> & {|
    ErrorComponent?: React$ComponentType<{}>,
    children?: any,
  |},
  {|
    PlaceholderComponent?: React$ComponentType<{}>,
  |},
>) => {
  const auth = useAuth();

  // This allows us to put authenticated queries on unauthenticated pages like thank-you without
  // loads of noisy sentry errors
  if (!auth.authenticated) {
    return null;
  }

  return (
    <ExtendedQuery
      {...rest}
      placeholder={PlaceholderComponent}
      context={{ auth }}
      // We don't need to add a sentry here as ExtendedQuery already handles it
      errorHandler={() => <ErrorComponent />}
    >
      {children}
    </ExtendedQuery>
  );
};

export { AuthenticatedQuery };
