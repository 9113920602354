// @flow
import { useContext } from "react";
import { css, ThemeContext } from "styled-components";
import { ButtonLink } from "@nested/component-library";
import { largeParagraph, media } from "@nested/brand";
import { getImage } from "@nested/utils";
import { CircleImage } from "../../components/CircleImage";
import { LI, UL } from "../../components/UnorderedList";

const contentWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  margin: 0 -20px -20px;

  ${media.tablet`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  `}
`;

const listWrapper = css`
  flex-grow: 1;
  margin: 0px;
  padding: 0 20px 20px;

  ${media.tablet`
    display: none;
  `}
`;

const imageWrapper = css`
  display: none;

  ${media.tablet`
    display: block;
    padding: 20px;
  `}
`;

const desktopContentStyles = css`
  display: none;

  ${media.tablet`
    display:block; 

    ${largeParagraph}
    text-align: center;
    font-weight: 500;
    line-height: 24px;

    p {
      margin: 0;
    }
  `}
`;

const buttonStyles = css`
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  padding: 15px;
  line-height: 19px;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.palette.hague20};
  border-radius: 0 0 4px 4px;

  ${media.tablet`
    border-top: none;
    font-size: 14px;
  `}
`;

type Props = {
  buyer: EnquiriesPageQuery_publicDeal_buyerEnquiries_buyer,
  enquiryId: string,
  viewingDate: ?string,
  virtual: ?boolean,
};

export const BookedViewingContent = ({
  buyer,
  enquiryId,
  viewingDate,
  virtual,
}: Props) => {
  const theme = useContext(ThemeContext);

  return (
    <div
      data-test={`enquiries-modal-${enquiryId}-booked-a-viewing`}
      css={contentWrapper}
    >
      <UL css={listWrapper}>
        {buyer.buyerPosition && <LI>{buyer.buyerPosition}</LI>}
        {buyer.financialPosition && <LI>{buyer.financialPosition}</LI>}
      </UL>
      <div css={imageWrapper}>
        <CircleImage
          circleColor={virtual ? theme.palette.blue20 : theme.palette.yellow20}
          imageAlt="Booked a viewing"
          imageSrc={
            virtual
              ? getImage("your-account/enquiry-virtual.png")
              : getImage("your-account/enquiry-viewing.png")
          }
        />
      </div>
      <div css={desktopContentStyles}>
        <p>
          Good news, {buyer.firstName} is{" "}
          {virtual ? "visiting virtually" : "coming to visit"}.
        </p>
        <p>Find viewings in your calendar.</p>
      </div>
      <ButtonLink
        css={buttonStyles}
        to={
          viewingDate
            ? `/listing/viewings/calendar?date=${viewingDate}`
            : "/listing/viewings/calendar"
        }
        type="link"
      >
        View calendar
      </ButtonLink>
    </div>
  );
};

const sidebarDetailsStyles = css`
  li {
    color: white;
    line-height: 21px;
    font-weight: 400;
  }

  li::before {
    background-color: ${({ theme }) => theme.palette.pink100};
  }
`;

type SidebarProps = {
  buyer: EnquiriesPageQuery_publicDeal_buyerEnquiries_buyer,
};

export const BookedViewingSidebarDetails = ({ buyer }: SidebarProps) => {
  return (
    <div css={sidebarDetailsStyles}>
      <UL>
        {buyer.buyerPosition && <LI>{buyer.buyerPosition}</LI>}
        {buyer.financialPosition && <LI>{buyer.financialPosition}</LI>}
      </UL>
    </div>
  );
};
