// @flow
import type { QueryRenderProps } from "@nested/utils";
import { ViewingFeedbackCard } from "../ViewingFeedback/ViewingFeedbackCard";
import { UpcomingViewingsCard } from "./UpcomingViewings/UpcomingViewingsCard";
import { DefaultWrapper } from "../PageWrapper";
import { DEAL_QUERY } from "../Listing/Listing";
import { PublicDealQuery } from "../../components/AuthenticatedQuery";
import { PageHeader } from "../../components/Heading";
import { PreCloseContactCta } from "../../components/PreCloseContactCta/PreCloseContactCta";

export const Viewings = () => (
  <DefaultWrapper>
    <PageHeader>Viewings</PageHeader>
    <PublicDealQuery query={DEAL_QUERY}>
      {({ data }: QueryRenderProps<ListingSectionQuery>) => (
        <>
          <UpcomingViewingsCard deal={data?.publicDeal} />
          <ViewingFeedbackCard deal={data?.publicDeal} />
          <PreCloseContactCta deal={data?.publicDeal} />
        </>
      )}
    </PublicDealQuery>
  </DefaultWrapper>
);
