// @flow
import { useHistory } from "react-router";
import { gql } from "@apollo/client";
import styled from "styled-components";

import { largeParagraph, media, CalendlyWidget } from "@nested/brand";
import { getConfig } from "@nested/config";
import { AuthenticatedQuery } from "../../components/AuthenticatedQuery";
import { useDeal } from "../../hooks/useDeal";
import { BackButton } from "../../components/BackButton";
import { H1 } from "../../components/Heading";
import { DefaultLegacyWrapper } from "../PageWrapper";

const { MARKETING_SITE_URI } = getConfig();

const TitleParagraph = styled.p`
  ${largeParagraph}
  margin-top: 30px;

  ${media.tablet`
    max-width: 534px;
  `}

  ${media.desktop`
    max-width: 600px;
  `}
`;

const ACCOUNT_AND_PROPERTY_QUERY = gql`
  query AccountAndPropertyDetails($dealId: String!) {
    customerAccount {
      email
      firstName
      lastName
    }
    publicDeal(externalDealId: $dealId) {
      id
      property {
        postcode
      }
    }
  }
`;

const RM_QUERY = gql`
  query RMCallBookingUrl($postcode: String!) {
    postcodeDetails(postcode: $postcode) {
      normalisedPostcode
      relationshipManager {
        id
        introCallBookingUrl
      }
    }
  }
`;

const MainSection = styled.div`
  padding: 20px 20px 0;

  ${media.tablet`
    padding: 0px;
  `};
`;

const PageTitle = () => (
  <div>
    <H1 data-test="book-a-call-heading">When can we call?</H1>
    <TitleParagraph left data-test="book-a-call-copy">
      Let us know a convenient time to chat. We'll talk you through selling with
      Nested, and answer any questions you might have.
    </TitleParagraph>
  </div>
);

export const BookACall = () => {
  const history = useHistory();
  const goToHome = () => history.push("/");
  const { dealId } = useDeal();

  // We can't let preleads book in with RMs - it would be chaos
  if (!dealId) {
    // Opens onboarding in new tab, then returns them to page they clicked from
    window.open(`${MARKETING_SITE_URI}/get-started`);
    history.goBack();
  }

  return (
    <DefaultLegacyWrapper>
      <BackButton />
      <MainSection>
        <PageTitle />
        <AuthenticatedQuery
          query={ACCOUNT_AND_PROPERTY_QUERY}
          variables={{ dealId }}
        >
          {({ data: { customerAccount, publicDeal } }) => (
            <AuthenticatedQuery
              query={RM_QUERY}
              variables={{
                postcode: publicDeal.property.postcode,
              }}
            >
              {({
                data: {
                  postcodeDetails: { relationshipManager },
                },
              }) => (
                <CalendlyWidget
                  email={customerAccount.email}
                  name={`${customerAccount.firstName} ${customerAccount.lastName}`}
                  phone="" // leaves field blank in Calendly form, so user is required to input phone number
                  url={relationshipManager?.introCallBookingUrl}
                  onSuccess={goToHome}
                  onFailure={goToHome}
                />
              )}
            </AuthenticatedQuery>
          )}
        </AuthenticatedQuery>
      </MainSection>
    </DefaultLegacyWrapper>
  );
};
