// @flow
import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { max, sum } from "lodash";
import { css } from "styled-components";
import {
  headingContainer,
  heading,
  headingSubtext,
  legendBox,
  legendItem,
  lineLegendItem,
  listingViewsCounter,
  legendItemText,
  legendItemSubtext,
  peakPeriodLegendItem,
  SixteenWeeksFilter,
} from "./chartStyles";

ChartJS.register(
  annotationPlugin,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const xAxisLabel = css`
  &:after {
    content: "Weeks";
    position: absolute;
    bottom: -12px;
    left: calc(50% - 8px);
    font-size: 12px;
    font-weight: 500;
  }
`;

const graphContainer = css`
  margin: 30px 0 5px;
  position: relative;
  ${xAxisLabel}
`;

const getXAxis = (moreThan16Weeks, weeks, filter) => {
  if (moreThan16Weeks) {
    const filteredWeeks = filter ? apply16WeeksFilter(weeks) : weeks;
    return {
      "x-axis": {
        type: "category",
        labels: filteredWeeks,
        position: "top",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      "x-labels": {
        type: "linear",
        labels: filteredWeeks,
        min: filter ? filteredWeeks[0] : 0,
        suggestedMax: filteredWeeks[filteredWeeks.length - 1],
        ticks: {
          stepSize: 1,
          font: {
            size: 12,
            family: "Euclid Circular B, sans-serif",
          },
          color: "rgba(7, 51, 67, 0.4)",
          padding: 5,
          maxRotation: 0,
          maxTicksLimit: 16,
        },
        grid: {
          color: "rgba(2, 66, 85, 0.2)",
          borderDashOffset: -140,
          borderDash: [10, 500],
          zeroLineWidth: 0,
          z: 3,
          drawTicks: false,
        },
      },
    };
  }
  return {
    "x-axis": {
      labels: weeks,
      grid: {
        color: "rgba(2, 66, 85, 0.2)",
        borderDashOffset: -139,
        borderDash: [10, 500],
        zeroLineWidth: 0,
        z: 3,
        drawTicks: false,
      },
      ticks: {
        font: {
          size: 12,
          family: "Euclid Circular B, sans-serif",
        },
        color: "rgba(7, 51, 67, 0.4)",
        maxRotation: 0,
        padding: 10,
      },
    },
  };
};

// This is real data calculated in BigQuery, but it won't change often so wasn't worth moving to
// ElasticSearch and querying from the backend.
// We calculated the average number of enquiries per week for listings listed between 2023-05-01 and 2024-05-02. Only
// the deal latest listings were taken into account in the calculation – see `Eggl.Public.Resolvers.ListingPerformanceResolver`
// in `elixir-umbrella`.
const averagesByType = {
  house: [6, 2, 1, 1, 1, 1],
  flat: [6, 2, 1, 1, 1, 1],
};

type Props = {
  deal: ListingPerformanceQuery_publicDeal,
  exampleView?: boolean,
};

const apply16WeeksFilter = (list) => {
  if (list.length <= 16) {
    return list;
  }

  return list.slice(list.length - 16, list.length);
};

const getWeeks = (enquiries) => {
  if (enquiries.length < 4) {
    return [1, 2, 3, 4];
  }
  return enquiries.map((e) => e.weekNumber);
};

export const WeeklyEnquiries = ({ deal, exampleView }: Props) => {
  const [filter, setFilter] = useState(true);
  const {
    weeklyEnquiries,
    hasPriceAdjustment,
    property: { type },
  } = deal;

  const enquiries = weeklyEnquiries.map((e) => e.enquiries);
  const total = sum(enquiries);
  const weeks = getWeeks(weeklyEnquiries);
  const averages = weeks.map((n) => averagesByType[type][n - 1] || 0);
  const moreThan16Weeks = weeks.length > 16;

  const chartOptions = {
    plugins: {
      legend: { display: false },
      tooltip: {
        titleFont: {
          family: "Euclid Circular B, sans-serif",
          style: "normal",
        },
        bodyFont: { family: "Euclid Circular B, sans-serif" },
        bodySpacing: 5,
        position: "nearest",
        enabled: true,
        mode: "index",
        callbacks: {
          title: (context) => {
            return `Week ${context[0].label}`;
          },
          label: ({ formattedValue }) => ` ${formattedValue}`,
        },
      },
      annotation:
        filter && moreThan16Weeks
          ? undefined
          : {
              drawTime: "beforeDatasetsDraw",
              annotations: [
                {
                  type: "box",
                  xScaleID: "x-axis",
                  yScaleID: "y-axis",
                  xMax: 1.5,
                  backgroundColor: "rgba(255, 193, 0, 0.1)",
                  borderColor: "rgba(255, 193, 0, 0.1)",
                  borderWidth: 1,
                },
              ],
            },
    },
    maintainAspectRatio: false,
    scales: {
      ...getXAxis(moreThan16Weeks, weeks, filter),
      "y-axis": {
        beginAtZero: true,
        min: 0,
        suggestedMax: max([...enquiries, ...averages]),
        ticks: {
          maxTicksLimit: 4,
          font: {
            size: 10,
            family: "Euclid Circular B, sans-serif",
          },
          color: "rgba(7, 51, 67, 0.4)",
          padding: 10,
        },
        grid: {
          zeroLineWidth: 0,
          zeroLineColor: "rgba(7, 51, 67, 0.4)",
          drawBorder: false,
          drawTicks: false,
          color: "rgba(2, 66, 85, 0.2)",
          z: -1,
        },
      },
    },
  };

  const datasets = {
    datasets: [
      {
        label: "enquiries",
        data: filter ? apply16WeeksFilter(enquiries) : enquiries,
        minBarLength: 3,
        backgroundColor: "#2D88D9",
        borderWidth: 1,
        yAxisID: "y-axis",
        xAxisID: "x-axis",
        maxBarThickness: 34,
      },
      {
        label: "averages",
        data: filter ? apply16WeeksFilter(averages) : averages,
        minBarLength: 3,
        backgroundColor: "#FF92A1",
        yAxisID: "y-axis",
        xAxisID: "x-axis",
        maxBarThickness: 34,
      },
    ],
  };
  return (
    <>
      <div css={headingContainer}>
        <h2 css={heading}>
          <span css={listingViewsCounter}>{total}</span>{" "}
          {total === 1 ? "enquiry" : "enquiries"}
          {hasPriceAdjustment && (
            <p css={headingSubtext}>Since last price adjustment</p>
          )}
        </h2>
      </div>
      <div css={legendBox}>
        <div css={legendItem}>
          <div css={lineLegendItem} lineColor="blue">
            <p css={legendItemText}>
              {exampleView ? "Example enquiries" : "Your enquiries"}
            </p>
            {exampleView && (
              <p css={legendItemSubtext} noCaps>
                123 Example Street
              </p>
            )}
          </div>
        </div>
        <div css={legendItem}>
          <div css={lineLegendItem} lineColor="pink">
            <p css={legendItemText}>Average enquiries</p>
            {!exampleView && <p css={legendItemSubtext}>for {type}s</p>}
          </div>
        </div>
        <div css={legendItem}>
          <div css={peakPeriodLegendItem}>
            <p css={legendItemText}>Peak interest period</p>
          </div>
        </div>
      </div>
      {moreThan16Weeks && (
        <SixteenWeeksFilter filter={filter} setFilter={setFilter} />
      )}
      <div
        aria-label="Average listing views graph for the first 4 weeks of listing"
        role="img"
        className="chromatic-ignore"
        css={graphContainer}
      >
        <Bar height={200} options={chartOptions} data={datasets} />
      </div>
    </>
  );
};
