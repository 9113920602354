// @flow
import "@nested/polyfills";
import moment from "moment-timezone";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ResizeObserver from "resize-observer-polyfill";
import { Main } from "./app/components/Main/Main";
import { Providers } from "./app/components/Providers";
import { PushTokenRegistration } from "./app/components/PushTokenRegistration/PushTokenRegistration";
import {
  isNativeApp,
  setStatusBarColour,
  registerPushInfo,
} from "./app/gonative";

const root = document.getElementById("app");

moment.tz.setDefault("Europe/London");

if (!root) {
  throw new Error("Could not find root element");
}

if (isNativeApp()) {
  registerPushInfo();
  setStatusBarColour("ffffff");
}

window.ResizeObserver = window.ResizeObserver || ResizeObserver;

render(
  <BrowserRouter>
    <Providers>
      <PushTokenRegistration />
      <Main />
    </Providers>
  </BrowserRouter>,
  root,
);
