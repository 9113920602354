// @flow
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { css } from "styled-components";
import { formatPrice } from "@nested/utils";
import { Gravatar } from "@nested/brand";
import { Confetti } from "../../components/Confetti";
import { BuyerAvatarMedium } from "../../components/BuyerAvatar/BuyerAvatar";
import { LI, UL } from "../../components/UnorderedList";
import {
  extractRenegotiatedOffers,
  getOfferStatus,
  isActiveOffer,
  isNewOffer,
} from "./utils";

const latestAmountStyle = css`
  text-align: center;
  color: ${({ theme }) => theme.palette.hague130};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const buyerName = css`
  text-align: center;
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

const separatorStyle = css`
  margin: 20px 0px;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.palette.hague10};
`;

const offersWrapper = css`
  padding: 0px 20px;
  margin: 0;
`;

const offerDateStyle = css`
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.hague130};
`;

const offerAmountStyle = css`
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.hague130};
  font-weight: 500;
`;

const offerStatusStyle = css`
  float: right;
`;

const offerStyle = css`
  opacity: ${({ active }) => (active ? "1" : "0.5")};

  &:not(:first-child) {
    margin-top: 15px;
  }
`;

const dtStyle = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const ddStyle = css`
  font-size: 14px;
  line-height: 17px;
  margin: 0;
`;

const newBadgeStyle = css`
  text-transform: uppercase;
  color: white;
  line-height: 21px;
  padding: 0px 5px 0px 7px;
  background-color: ${({ theme }) => theme.palette.hague130};
  border-radius: 4px;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
  display: inline-block;
`;

const newBadgeTopRight = css`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const smWrapper = css`
  padding: 0 15px;
`;

const gravatarStyle = css`
  margin: 0 15px 0 0;
`;

const agentDetailsWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const agentHeadingStyle = css`
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  opacity: 0.7;
  line-height: 21px;
  font-weight: 500;
`;

const agentNameStyle = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const contactDetailsWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`;

const contactDetailsHeadings = css`
  line-height: 21px;
  font-size: 14px;
  text-align: right;
  margin-right: 20px;
  font-weight: 500;
  opacity: 0.7;
  padding-left: 5px;
  & > :first-child {
    margin-bottom: 5px;
  }
`;

const contactDetails = css`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue150};
  & > :first-child {
    margin-bottom: 5px;
  }
`;

const confettiConfig = {
  elementCount: 100,
  width: "12px",
  height: "12px",
};

const positionCardAvatar = css`
  display: inline-block;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
`;

export const NewBadge = ({ className }: { className?: string }) => (
  <div css={newBadgeStyle} className={className}>
    New
  </div>
);

const OfferSummary = ({ offer }) => (
  <div
    data-test={`offer-summary-${offer.id}`}
    active={isActiveOffer(offer)}
    css={offerStyle}
  >
    <div css={offerDateStyle}>
      {moment(offer.placedOn).format("Do MMMM YYYY")}
      <div css={offerAmountStyle}>
        <div css={offerStatusStyle}>{getOfferStatus(offer)}</div>
        {formatPrice(parseInt(offer.amount, 10))}
      </div>
    </div>
  </div>
);

const getChainText = (length) => {
  switch (length) {
    case "To be confirmed":
      return "Length of chain to be confirmed";
    case "N/A":
      return "Chain unconfirmed";
    case "0":
      return "A chain free buyer";
    case "1":
      return "In a chain 1 property long";
    default:
      // 2-9 and 10+
      return `In a chain ${length} properties long`;
  }
};

type Props = {
  buyerEnquiry: PublicDealOffers_publicDeal_buyerEnquiries,
  sm?: PublicDealOffers_publicDeal_sm,
  confetti?: boolean,
};

export const BuyerDetails = ({ buyerEnquiry, sm, confetti }: Props) => {
  const { buyer } = buyerEnquiry;
  const offers = useMemo(
    () => extractRenegotiatedOffers(buyerEnquiry.buyerOffers),
    [buyerEnquiry],
  );

  const [showConfetti, setShowConfetti] = useState(false);

  const latestOffer = offers[0];

  useEffect(() => {
    setShowConfetti(isNewOffer(latestOffer));
  }, [latestOffer]);

  return (
    <>
      {isNewOffer(latestOffer) && <NewBadge css={newBadgeTopRight} />}
      {confetti && (
        <Confetti showConfetti={showConfetti} customConfig={confettiConfig} />
      )}
      <div css={positionCardAvatar}>
        <BuyerAvatarMedium avatarUrl={buyer.avatarUrl} />
      </div>
      <div css={latestAmountStyle}>
        {formatPrice(parseInt(latestOffer.amount, 10))}
      </div>
      <div css={buyerName}>
        {buyer.firstName} {buyer.surnameInitial}
      </div>
      <hr css={separatorStyle} />
      <div css={offersWrapper}>
        {offers.map((offer) => (
          <OfferSummary key={offer.id} offer={offer} />
        ))}
      </div>
      {(buyer.financialPosition ||
        buyer.buyerPosition ||
        latestOffer.lengthOfDownwardChain) && (
        <>
          <hr css={separatorStyle} />
          <div css={offersWrapper}>
            <UL>
              {buyer.buyerPosition && <LI>{buyer.buyerPosition}</LI>}
              {buyer.financialPosition && <LI>{buyer.financialPosition}</LI>}
              {latestOffer.lengthOfDownwardChain && (
                <LI>{getChainText(latestOffer.lengthOfDownwardChain)}</LI>
              )}
            </UL>
          </div>
        </>
      )}
      <hr css={separatorStyle} />
      <dl css={offersWrapper}>
        <dt css={dtStyle}>Time frame conditions:</dt>
        <dd css={ddStyle}>
          {latestOffer.timeFrameConditions
            ? latestOffer.timeFrameConditions
            : "None"}
        </dd>
        <dt css={dtStyle}>Offer conditions:</dt>
        <dd css={ddStyle}>
          {latestOffer.offerConditions ? latestOffer.offerConditions : "None"}
        </dd>
      </dl>
      {sm && (
        <>
          <hr css={separatorStyle} />
          <div css={smWrapper}>
            <div css={agentDetailsWrapper}>
              <Gravatar css={gravatarStyle} email={sm.emailAddress} />
              <div>
                <div css={agentHeadingStyle}>Your Nested agent</div>
                <div css={agentNameStyle}>
                  {sm.firstName} {sm.lastName}
                </div>
              </div>
            </div>
            <div css={contactDetailsWrapper}>
              <div css={contactDetailsHeadings}>
                <div>phone</div>
                <div>email</div>
              </div>
              <div css={contactDetails}>
                <div>{sm.phoneNumber}</div>
                <div>{sm.emailAddress}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
