// @flow
import moment from "moment";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

const timeSlot = css`
  box-sizing: border-box;
  width: calc((100% - 30px) / 6);
  margin-right: 6px;
  margin-bottom: 7px;
  &:nth-child(6n) {
    margin-right: 0px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme, isAvailable }) =>
    isAvailable ? theme.palette.green : "initial"};
  border: 1px solid
    ${({ theme, isAvailable }) =>
      isAvailable ? theme.palette.green : theme.palette.hague20};
  border-radius: 12px;
  color: ${({ theme, isAvailable }) =>
    isAvailable ? "white" : theme.palette.hague70};
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  text-align: center;
  cursor: pointer;
`;

const checkIcon = css`
  color: white;
  font-size: 12px;
  margin-top: 5px;
`;

const timesIcon = css`
  color: ${({ theme }) => theme.palette.terracotta500};
  font-size: 12px;
  margin-top: 5px;
`;

const formatTimeToNumber = (time) => moment(time, "HH:mm:ss").format("h"); // 4

type Props = {
  slot: ViewingAvailability_publicDeal_viewingAvailability_timeslots,
  onClick: () => void,
  slotAvailable: boolean,
};

export const TimeSlotButton = ({ slot, onClick, slotAvailable }: Props) => (
  <div
    data-test="timeslot-button"
    key={slot.id}
    css={timeSlot}
    isAvailable={slotAvailable}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex={0}
  >
    <FontAwesomeIcon
      icon={slotAvailable ? faCheck : faTimes}
      css={slotAvailable ? checkIcon : timesIcon}
      data-test={`timeslot-icon-available-${slotAvailable ? "true" : "false"}`}
    />
    {formatTimeToNumber(slot.timeSlotStart)}-
    {formatTimeToNumber(slot.timeSlotEnd)}
  </div>
);
