export const firstImpressionsMockDeal = {
  externalDealId: "1234",
  settings: { showPropertyAnalysis: true },
  rmRecommendedListPrices: {
    rmRecommendedMinListPrice: 500000,
    rmRecommendedListPrice: 525000,
    rmRecommendedMaxListPrice: 550000,
  },
  property: {
    formattedAddress: "123 Example Street",
    keyFeatures: [],
    mostRecentSale: {
      amount: 515000,
      date: "2017-10-20",
    },
    summaryForPropertyAnalysis: `The good news is that your flat stands out compared to a lot of what else is on offer by virtue of a completely private, west facing garden from a period conversion. Add to that the current buoyancy in the market fuelled by the stamp duty holiday and the consistent popularity of the local area and now is a great time to be selling and achieving a great price.

All this said, unfortunately the market did dip since you bought the flat, so you will not be expecting a big profit on the sale. That said you shouldn't be selling at a loss either.

The best list price to market at is £525,000. 

The reason I wouldn't suggest higher is that you get to competing with 3 beds above £525k. While you are one of the best 2-beds out there, factually you just cannot add another bedroom in to the space currently. If you did want to test the market we could start at £550,000 but would need to reduce to £525,000 after 2 weeks if it didn't sell.

On the lower end to list under £500,000 would be doing you an injustice and would attract buyers with too low a budget for your home. The 'Offers Over' £500,000 strategy would work well though if you decide you want a quicker sale. We are currently seeing some excellent results with properties selling for up to 8% over their marketing price. 

Ultimately it is your choice, but once you have reviewed my recommendations alongside the comparable sales and your competition in the area please give me a call to discuss the best strategy for you. `,
  },
};
