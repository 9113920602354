// @flow
import styled from "styled-components";

const RadioCircle = styled.span`
  background-color: white;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  display: inline-block;
  height: 15px;
  margin-right: 15px;
  position: relative;
  top: 2px;
  visibility: visible;
  width: 15px;

  input:checked + & {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.palette.hague80};
  }

  input:focus + &,
  input:active + & {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.palette.terracotta100};
  }

  input + &::after {
    content: "";
    height: 11px;
    width: 11px;
    border-radius: 11px;
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: transparent;
  }

  input:checked:not(:focus) + &::after {
    background-color: ${({ theme }) => theme.palette.hague80};
  }

  input:focus:checked + &::after,
  input:checked:active + &::after {
    background-color: ${({ theme }) => theme.palette.terracotta100};
  }
`;

const RadioLabel = styled.label`
  padding: 10px;
  cursor: pointer;
  user-select: none;
  transition: border 200ms ease;

  &:first-child {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  &:last-child {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:not(:first-child) {
    border-top: 0;
  }
`;

const RadioInput = styled.input`
  /* 
  Styles to visually hide whilst not impairing use by screenreaders 
  https://a11yproject.com/posts/how-to-hide-content/
  */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
`;

type Props = {
  handleChange: (SyntheticEvent<>) => void,
  isChecked: boolean,
  labelDataTest: string,
  inputDataTest: string,
  filterText: string,
};

export const Filter = ({
  handleChange,
  isChecked,
  labelDataTest,
  inputDataTest,
  filterText,
}: Props) => (
  <RadioLabel data-test={labelDataTest}>
    <RadioInput
      data-test={inputDataTest}
      type="radio"
      onChange={handleChange}
      checked={isChecked}
    />
    <RadioCircle />
    {filterText}
  </RadioLabel>
);
