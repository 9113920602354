// @flow
import { useContext } from "react";
import { gql } from "@apollo/client";
import { css, ThemeContext } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImage } from "@nested/utils";
import {
  faBolt,
  faSnowflake,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import {
  SectionWrapper,
  SectionHeader,
  UnpaddedSectionCard,
  SectionCard,
  ImageAndTextCardContent,
  SectionCardPadding,
  SectionHeaderSubtext,
  ExampleBox,
  IconAndText,
} from "../../components/SectionCard";
import { ListingViews } from "./ListingViews";
import { WeeklyEnquiries } from "./WeeklyEnquiries";
import { PublicDealQuery } from "../../components/AuthenticatedQuery/PublicDealQuery";
import { listingPerformanceExampleData } from "./listingPerformanceExampleData";

const yellowBox = css`
  background-color: ${({ theme }) => theme.palette.orange10};
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
`;

const blueBox = css`
  ${yellowBox}
  background-color: ${({ theme }) => theme.palette.blue10};
  margin-bottom: 10px;
`;

const lightningBolt = css`
  color: ${({ theme }) => theme.palette.orange60};
  margin-right: 15px;
  font-size: 24px;
`;

const snowflake = css`
  color: ${({ theme }) => theme.palette.blue100};
  margin-right: 15px;
  font-size: 24px;
`;

const infoBoxText = css`
  font-size: 14px;
  line-height: 17px;
  margin: 0;
`;

type Props = {
  deal: ?ListingSectionQuery_publicDeal,
};

const LISTING_PERFORMANCE_QUERY = gql`
  query ListingPerformanceQuery($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      currentListingPrice
      hasPriceAdjustment
      dateUnderOffer
      property {
        postcode
        type
        bedrooms
      }
      dailyPortalViews {
        dayNumber
        views
      }
      averageDailyPortalViews {
        dayNumber
        views
      }
      weeklyEnquiries {
        weekNumber
        enquiries
      }
    }
  }
`;

const FirstImpressionsContent = () => (
  <SectionWrapper>
    <SectionHeader css="padding-bottom: 0;">Listing performance</SectionHeader>
    <SectionHeaderSubtext> Available after listing</SectionHeaderSubtext>
    <UnpaddedSectionCard css="margin-top: 20px;">
      <SectionCardPadding>
        <IconAndText
          icon={faChartBar}
          text="See how many people are looking at your home online vs the local
            average."
        />
        <ExampleBox css="padding-top: 0;">
          <ListingViews deal={listingPerformanceExampleData} exampleView />
          <WeeklyEnquiries deal={listingPerformanceExampleData} exampleView />
        </ExampleBox>
      </SectionCardPadding>
    </UnpaddedSectionCard>
  </SectionWrapper>
);

const ClosedContent = () => {
  const theme = useContext(ThemeContext);

  return (
    <SectionWrapper>
      <SectionHeader>Listing performance</SectionHeader>
      <SectionCard>
        <ImageAndTextCardContent
          largeText
          text="Listing performance will appear here after listing"
          imageSrc={getImage("your-account/performance-tracker.png")}
          circleColor={theme.palette.pink40}
          imageAlt="Listing performance"
        />
      </SectionCard>
    </SectionWrapper>
  );
};

export const ListingPerformance = ({ deal }: Props) => {
  if (deal?.opportunityStatusValue === "s07_closed") {
    return <ClosedContent />;
  }

  if (!deal?.isListed) {
    return <FirstImpressionsContent />;
  }

  return (
    <SectionWrapper>
      <SectionHeader>Listing performance</SectionHeader>
      <PublicDealQuery query={LISTING_PERFORMANCE_QUERY}>
        {({ data }) => (
          <UnpaddedSectionCard>
            <SectionCardPadding>
              {data?.publicDeal?.dateUnderOffer ? (
                <div css={blueBox}>
                  <FontAwesomeIcon icon={faSnowflake} css={snowflake} />
                  <p css={infoBoxText}>
                    We've frozen your performance data while you're under offer.
                  </p>
                </div>
              ) : (
                <div css={yellowBox}>
                  <FontAwesomeIcon icon={faBolt} css={lightningBolt} />
                  <p css={infoBoxText}>
                    Interest will peak in the first fortnight while your listing
                    is still fresh.
                  </p>
                </div>
              )}
              <ListingViews deal={data.publicDeal} />
              <WeeklyEnquiries deal={data.publicDeal} />
            </SectionCardPadding>
          </UnpaddedSectionCard>
        )}
      </PublicDealQuery>
    </SectionWrapper>
  );
};
