// @flow
import styled, { css } from "styled-components";
import { formatPrice, getImage } from "@nested/utils";
import { media, Gravatar } from "@nested/brand";
import { ButtonLink } from "@nested/component-library";
import { useAuth } from "@nested/auth";
import { getConfig } from "@nested/config";
import moment from "moment";

import { MobileTabNavigation } from "../Navigation/TabNavigation";

const daysInThePast = (date) => moment().diff(moment(date), "days");
const formatDate = (date) => moment(date).format("Do MMMM YYYY");

const forDays = (date: string): string => {
  const days = daysInThePast(date);
  if (days === 0) {
    return "today";
  }
  if (days === 1) {
    return "for 1 day";
  }
  return `for ${days} days`;
};

export const generateStatusText = ({
  dateUnderOffer,
  dateCompleted,
  dateExchanged,
  dateDisinstructed,
  dateListed,
}: HeroLeadInfo_publicDeal) => {
  if (dateDisinstructed) {
    return `Disinstructed on ${formatDate(dateDisinstructed)}`;
  }
  if (dateCompleted) {
    return `Completed on ${formatDate(dateCompleted)}`;
  }
  if (dateExchanged) {
    return `Exchanged on ${formatDate(dateExchanged)}`;
  }
  if (dateUnderOffer) {
    return `Under offer ${forDays(dateUnderOffer)}`;
  }
  if (dateListed) {
    return `Listed ${forDays(dateListed)}`;
  }
  return null;
};

const heroStyles = css`
  height: 28vh;
  max-height: 203px;
  position: relative;
  z-index: 1;

  ${media.tablet`
    width: 100%;
    height: auto;
    padding-top: 50%;
  `}
`;

const heroImage = css`
  ${heroStyles}
  background: url(${({ backgroundImage }) => backgroundImage}) no-repeat center
    center;
  background-size: cover;
`;

const heroIconStyles = css`
  height: 28vh;
  max-height: 203px;
  background-color: ${({ theme, backgroundColor }) =>
    theme.palette[backgroundColor]};
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.tablet`
    width: 100%;
    height: auto;
    padding: calc(25% - 60px) 0;
  `}
`;

const iconStyles = css`
  width: 120px;
  height: 120px;
  ${media.tablet`
    width: 102px;
    height: 102px;
  `}
  ${media.desktop`
    width: 120px;
    height: 120px;
  `}
`;

const imageWrapper = css`
  position: relative;
`;

const textContent = css`
  margin: 20px 15px 30px;

  ${media.tablet`
    margin: 20px 20px 30px;
  `}

  ${media.desktop`
    margin: 30px 2.5vw;
  `}
`;

const primaryHeadingStyle = css`
  color: ${({ theme }) => theme.palette.hague};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  ${media.tablet`
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  `}

  ${media.desktop`
    font-size: 21px;
    line-height: 28px;
    margin: 0;
  `}
`;

const headingDescriptionStyle = css`
  color: ${({ theme }) => theme.palette.hague};
  text-align: left;
  font-size: 14px;
  line-height: 21px;
  margin: 8px 0 0;

  ${media.tablet`
    font-size: 16px;
    line-height: 24px;
    margin: 8px 0 0;
  `}
`;

const Button = styled(ButtonLink)`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 21px;
  padding: 10px;
  height: unset;
  min-width: 84px;
  display: block;
  text-align: center;
  flex-basis: 1;
`;

const CtaButton = styled(ButtonLink)`
  box-sizing: border-box;
  display: block;
  text-align: center;
  margin-top: 20px;
  height: 50px;
  padding: 5px 0;
`;

const addressText = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  ${media.tablet`
    font-size: 24px;
    line-height: 30px;
  `}

  ${media.desktop`
    font-size: 21px;
    line-height: 28px;
  `}
`;

const listingInfo = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 10px 0;

  ${media.tablet`
    margin: 20px 20px 30px;
    padding: 0;
  `}

  ${media.desktop`
    margin: 30px 2.5vw;
  `}

  > div {
    margin-right: 10px;
  }
`;

const listingInfoText = css`
  font-size: 14px;
  line-height: 21px;
  margin: 5px 0;
`;

const priceText = css`
  color: ${({ theme }) => theme.palette.green};
  font-weight: 500;
`;

const placeholderImage = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.pink100};
  background-size: cover;
  height: 28vh;
  max-height: 203px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet`
    width: 100%;
    height: auto;
    padding-top: 50%;
  `}
`;

const placeholderHouse = css`
  height: 120px;
  ${media.tablet`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  `}
`;

const smInfo = css`
  display: none;
  ${media.tablet`
    display: block;
    margin: 30px;
    border-top: 1px solid ${({ theme }) => theme.palette.hague20};
    padding-top: 30px;
  `}
`;

const smNameAndGravatar = css`
  display: flex;
  align-items: center;
  img {
    margin: 0 15px 0 0;
  }
`;

const yourAgentText = css`
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 21px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague70};
`;

const agentName = css`
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  font-weight: 500;
`;

const contactDetails = css`
  margin-top: 20px;
  & > div:nth-of-type(2) {
    margin-top: 5px;
  }
`;

const contactLabel = css`
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.hague70};
  text-align: right;
  font-weight: 500;
  width: 43px;
`;

const contactValue = css`
  margin: 0 0 0 20px;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: ${({ theme }) => theme.palette.blue100};
  font-weight: 500;
`;

const contactDetailLine = css`
  display: flex;
`;

export type HeroContentProps = {|
  heroImg?: string,
  heroIcon?: string,
  heroBackgroundColor?: string,
  heroHeading: string,
  heroDescription: string,
  buttonCta?: string,
  buttonPath?: string,
|};

export const HeroContent = ({
  buttonCta,
  buttonPath,
  heroBackgroundColor,
  heroDescription,
  heroHeading,
  heroIcon,
  heroImg,
}: HeroContentProps) => {
  return (
    <div>
      {heroImg && <div backgroundImage={heroImg} css={heroImage}></div>}
      {heroIcon && (
        <div backgroundColor={heroBackgroundColor} css={heroIconStyles}>
          <img src={heroIcon} alt="illustration" css={iconStyles} />
        </div>
      )}
      <div css={textContent}>
        <p css={primaryHeadingStyle}>{heroHeading}</p>
        <p css={headingDescriptionStyle}>{heroDescription}</p>
        {buttonPath && buttonCta && (
          <CtaButton to={buttonPath} type="primary">
            {buttonCta}
          </CtaButton>
        )}
      </div>
      <MobileTabNavigation />
    </div>
  );
};

const removePostcode = (address) => {
  const split = address.split(", ");
  if (split.length === 1) {
    return address;
  }
  return split.slice(0, -1).join(", ");
};

export const ListedHeroContent = ({
  deal,
}: {
  deal: HeroLeadInfo_publicDeal,
}) => {
  const {
    listingUrl,
    property: { formattedAddress },
    currentListingPrice,
    dateCompleted,
    dateDisinstructed,
    dateExchanged,
    dateUnderOffer,
    sm,
  } = deal;
  const { demoModeEnabled } = useAuth();
  const { MARKETING_SITE_URI } = getConfig();

  const showListingPrice =
    !dateCompleted && !dateDisinstructed && !dateExchanged && !dateUnderOffer;
  const statusText = generateStatusText(deal);
  const addressMinusPostcode = removePostcode(formattedAddress);

  return (
    <div>
      <div css={imageWrapper}>
        <div css={placeholderImage}>
          <img
            css={placeholderHouse}
            alt="placeholder"
            src={getImage(
              "your-account/your-buyers-reports/no_photo_building.png",
            )}
          />
        </div>
        <div
          css={heroImage}
          backgroundImage={getImage(
            `property-listings/${deal.externalDealId}/1?auto=format,compress`,
          )}
        />
      </div>
      <div css={listingInfo}>
        <div>
          <p css={addressText} data-test="hero-section-address">
            {addressMinusPostcode}
          </p>
          <p css={listingInfoText}>
            {currentListingPrice && showListingPrice && (
              <span css={priceText} data-test="hero-section-price">
                {formatPrice(parseFloat(currentListingPrice))}
              </span>
            )}
            {currentListingPrice && showListingPrice && statusText && (
              <span> - </span>
            )}
            {statusText && (
              <span data-test="hero-section-days-listed">{statusText}</span>
            )}
          </p>
        </div>
        {listingUrl && (
          <Button
            to={demoModeEnabled ? `${MARKETING_SITE_URI}/for-sale` : listingUrl}
            data-test="hero-section-listing-link"
            target="_blank"
          >
            My listing
          </Button>
        )}
      </div>
      {sm && (
        <div css={smInfo}>
          <div css={smNameAndGravatar}>
            <Gravatar email={sm.emailAddress} />
            <div>
              <p css={yourAgentText}>Your nested agent</p>
              <p css={agentName}>
                {sm.firstName} {sm.lastName}
              </p>
            </div>
          </div>
          <div css={contactDetails}>
            <div css={contactDetailLine}>
              <div css={contactLabel}>phone</div>
              <div css={contactValue}>{sm.phoneNumber}</div>
            </div>
            <div css={contactDetailLine}>
              <div css={contactLabel}>email</div>
              <div css={contactValue}>{sm.emailAddress}</div>
            </div>{" "}
          </div>
        </div>
      )}
      <MobileTabNavigation />
    </div>
  );
};
