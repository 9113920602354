// @flow
import { useEffect } from "react";
import qs from "query-string";
import { media, mediumHeading, mediumParagraph } from "@nested/brand";
import styled, { css } from "styled-components";
import { Sentry } from "@nested/isomorphic-sentry";

import { gql } from "@apollo/client";
import { useLocation } from "react-router";
import type { QueryRenderProps } from "@nested/utils";
import { useDeal } from "../../hooks/useDeal";
import { useAuthenticatedMutation } from "../../hooks/useAuthenticatedMutation";
import { PublicDealQuery } from "../../components/AuthenticatedQuery";
import { RecommendedListPriceSection } from "./RecommendedListPrice/RecommendedListPriceSection";
import { ComparablePropertiesSection } from "./ComparableProperties/ComparablePropertiesSection";
import { PropertyDetailsSection } from "./PropertyDetails/PropertyDetailsSection";
import { HorizontalLine } from "./Section";
import {
  MainContent,
  LayoutWrapper,
  ContentWrapper,
  BreadcrumbWrapper,
} from "../PageWrapper";

import { Breadcrumb } from "../../components/Breadcrumb";
import { StickyExampleBanner } from "../../components/SectionCard";
import { firstImpressionsMockDeal } from "./firstImpressionsMockDeal";

export const PROPERTY_ANALYSIS_QUERY = gql`
  query PropertyAnalysisDeal($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      externalDealId
      settings {
        showPropertyAnalysis
      }
    }
  }
`;

export const UPDATE_PROPERTY_ANALYSIS_LAST_VIEWED = gql`
  mutation UpdatePropertyAnalysisLastViewed($dealId: ID!) {
    updatePropertyAnalysisLastViewed(externalDealId: $dealId) {
      id
    }
  }
`;

const MainSection = styled.div`
  padding: 10px 15px 0;
  ${media.tablet`
    padding: 0px;
  `};
`;

const titleStyle = css`
  margin: 0;
  ${mediumHeading}

  ${media.tablet`
    ${mediumHeading}
    margin-bottom: 30px;
  `}
`;

const NoPropertyAnalysis = () => (
  <ContentWrapper data-test="property-analysis-not-ready">
    <MainSection>
      <h1 css={titleStyle}>Your property analysis isn&apos;t complete yet!</h1>
      <p css={mediumParagraph}>
        Your agent will share this with you once it&apos;s ready. In the
        meantime, why not check out our other account tools?
      </p>
    </MainSection>
  </ContentWrapper>
);

const propertyAnalysisDescriptionStyle = css`
  ${mediumParagraph};
  margin-bottom: 30px;
  max-width: 550px;
`;

const horizontalLineStyle = css`
  display: none;
  ${media.tablet`
    display: block;
    margin: 30px 0;
  `}
`;

const introText = (exampleMode) => {
  return exampleMode
    ? `This is an example of the personalised analysis your Nested agent will conduct on your home and local area.`
    : `This is your personalised Nested analysis of your home and local area.`;
};

export const PropertyAnalysis = () => {
  const { customerMode, dealId } = useDeal();
  const { search } = useLocation();
  const [updatePropertyAnalysisLastViewed] = useAuthenticatedMutation(
    UPDATE_PROPERTY_ANALYSIS_LAST_VIEWED,
  );
  const { example: exampleMode } = qs.parse(search);

  useEffect(() => {
    const run = async () => {
      try {
        if (customerMode || exampleMode || !dealId) {
          return;
        }

        const { error } = await updatePropertyAnalysisLastViewed({
          variables: { dealId },
        });

        if (error) {
          throw error;
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    run();
  }, [customerMode, exampleMode, dealId]);

  return (
    <LayoutWrapper
      css={`
        ${media.tablet`
          flex-direction: column;
          justify-content: flex-start;
        `}
      `}
    >
      <MainContent>
        <BreadcrumbWrapper>
          <Breadcrumb name="Strategy" path="/listing/strategy" />
        </BreadcrumbWrapper>
      </MainContent>
      {exampleMode && (
        <StickyExampleBanner text="Property analysis" css="width: 100vw;" />
      )}
      <MainContent>
        <PublicDealQuery
          errorComponentName="Property Analysis"
          query={PROPERTY_ANALYSIS_QUERY}
          skip={exampleMode}
        >
          {({ data }: QueryRenderProps<PropertyAnalysisDeal>) => {
            const deal = exampleMode
              ? firstImpressionsMockDeal
              : data?.publicDeal;

            if (!customerMode && !deal?.settings?.showPropertyAnalysis) {
              return <NoPropertyAnalysis />;
            }

            const { externalDealId } = deal;

            return (
              <>
                <ContentWrapper>
                  <MainSection>
                    <h1 css={titleStyle}>Property analysis</h1>
                    <p
                      css={propertyAnalysisDescriptionStyle}
                      data-test="property-analysis-copy"
                    >
                      {introText(exampleMode)} This information will enable you
                      to make the best decisions as you look to sell your home.
                    </p>
                  </MainSection>
                  <RecommendedListPriceSection
                    externalDealId={externalDealId}
                    exampleMode={exampleMode}
                  />
                  <HorizontalLine css={horizontalLineStyle} />
                  <HorizontalLine css={horizontalLineStyle} />
                  <PropertyDetailsSection
                    externalDealId={externalDealId}
                    exampleMode={exampleMode}
                  />
                  <HorizontalLine css={horizontalLineStyle} />
                  <ComparablePropertiesSection
                    externalDealId={externalDealId}
                    exampleMode={exampleMode}
                  />
                </ContentWrapper>
              </>
            );
          }}
        </PublicDealQuery>
      </MainContent>
    </LayoutWrapper>
  );
};
