// @flow
import styled from "styled-components";
import { media, mediumParagraph } from "@nested/brand";

export const Card = styled.div`
  margin-top: 30px;
  border: 1px solid ${({ theme }) => theme.palette.hague20};
  border-radius: 4px;
  padding: 30px 20px;

  ${media.tablet`
    padding: 30px;
  `}

  & > h3 {
    color: ${({ theme }) => theme.palette.hague};
    font-family: ${({ theme }) => theme.fonts.euclid};
    font-size: 26px;
    font-weight: 500;
    margin: 0;
  }

  & > p {
    ${mediumParagraph}

    max-width: 600px;
    margin: 20px 0 0;

    ${media.desktop`
      margin: 30px 0 0 ;
    `}
  }
`;
