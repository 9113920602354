import { gql } from "@apollo/client";

export const VIEWING_FEEDBACK_FRAGMENT = gql`
  fragment viewingFeedback on PublicDeal {
    id
    externalDealId
    buyerEnquiries(type: COMPLETED) {
      id
      currentlyInterested
      buyer {
        id
        firstName
        surnameInitial
        financialPosition
        buyerPosition
        avatarUrl
      }
      viewings(type: COMPLETED) {
        id
        number
        conductor
        viewingFeedbackLastUpdated
        datetimeFirstImpressionsShared
        datetimeFullFeedbackShared
        dateTimeStart
        firstImpressions
        followUpFeedback {
          id
          datetimeSharedWithSeller
          feedback
        }
        fullFeedback
        shareViewingFeedback
        viewingSaEmail
        viewingSaName
      }
    }
    sm {
      emailAddress
      firstName
      lastName
      phoneNumber
    }
  }
`;
