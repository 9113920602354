export const ArrowHead = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 100">
    <g fill="none" fillRule="evenodd">
      <polyline
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        points="26 41.5 37 30 48 41.5"
        transform="rotate(90 37 35.75)"
      />
    </g>
  </svg>
);
