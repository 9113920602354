// @flow

import { useState } from "react";
import { useLocation } from "react-router";
import qs from "query-string";
import styled from "styled-components";
import { media } from "@nested/brand";
import { OnboardingModal } from "../OnboardingModal/OnboardingModal";
import { ErrorHandler } from "../ErrorHandler/ErrorHandler";
import {
  NavigationHeader,
  FullWidthPageNav,
} from "../Navigation/NavigationHeader";

type Props = {
  children: React$Element<any>,
  dontPad?: boolean,
};

// This wraps the entire page including navigation
export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.desktop`
    flex-direction: row;
    align-items: unset;
  `};
`;

const FullWidthPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthenticatedLayout = ({ children }: Props) => {
  const { search } = useLocation();
  const params = qs.parse(search);
  const { contactBooked, agent, bookingDate } = params;

  const [modalOpen, setModalOpen] = useState(contactBooked);

  return (
    <ErrorHandler>
      <OuterWrapper>
        <NavigationHeader />
        {contactBooked && (
          <OnboardingModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            contactBooked={contactBooked}
            agent={agent}
            bookingDate={bookingDate}
          />
        )}
        {children}
      </OuterWrapper>
    </ErrorHandler>
  );
};

export const FullWidthPageLayout = ({ children }: Props) => (
  <ErrorHandler>
    <FullWidthPageWrapper>
      <FullWidthPageNav />
      {children}
    </FullWidthPageWrapper>
  </ErrorHandler>
);
