// @flow
import styled from "styled-components";
import { media } from "@nested/brand";
import { ArrowHead } from "../../../../../Icons";

const Wrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  /* This is necessary for safari  */
  z-index: 1;
`;

const ArrowButton = styled.button`
  background: linear-gradient(to right, transparent, #222 500%) no-repeat;
  padding: 0;
  pointer-events: all;
  cursor: pointer;
  &:before {
    content: "";
  }
  width: 75px;
  height: 100%;
  right: 0;
  position: absolute;
  display: block;
  outline: none;
  border: none;
  border-radius: 4px;
  svg {
    width: 75px;
    height: 75px;
    ${media.desktop`
      height: 90px;
    `};
  }

  svg g {
    transform: translate(0, 13px);
    ${media.desktop`
      transform: translate(-10px, 13px);
    `};
  }

  z-index: 1;

  ${({ next }) =>
    next ||
    `
    left: 0;
    transform: rotateY(180deg);`}
`;

type Props = {
  next?: boolean,
  onClick?: () => void,
};

export const LightArrow = ({ next, onClick }: Props) => (
  <Wrapper>
    <ArrowButton next={next} onClick={onClick}>
      <ArrowHead />
    </ArrowButton>
  </Wrapper>
);
