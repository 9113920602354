import styled from "styled-components";
import { media } from "../media";

export const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.05);
  margin-bottom: 100px;
  padding: 10px;
  position: relative;
  width: 100%;

  ${media.tablet`
    padding: 40px;
  `};
`;
