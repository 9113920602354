// @flow
import { css } from "styled-components";
import {
  mediumHeading,
  largeHeading,
  largeParagraph,
  media,
} from "@nested/brand";
import { Button } from "@nested/component-library";

const wrapperStyle = css`
  background-color: white;
  margin-top: 40px;
  padding: 0px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  ${media.tablet`
    padding: 0px 20px;
    margin-top: 0px;
  `};
`;

const headingStyle = css`
  ${mediumHeading}
  margin: 39px auto 0;
  max-width: 878px;
  ${media.desktop`
    ${largeHeading}
  `}
`;

const paragraphStyle = css`
  ${largeParagraph}
  margin: 30px auto;
  max-width: 624px;
`;

const imageStyle = css`
  width: 160px;
`;

type Props = {
  retry(): any,
};

export const AuthInitError = ({ retry }: Props) => {
  return (
    <div css={wrapperStyle} data-test="auth-init-error">
      <div>
        <img
          className="chromatic-ignore"
          css={imageStyle}
          alt=""
          src="https://nested.imgix.net/icons/broken-page.gif"
        />
        <h1 css={headingStyle}>We couldn't log you in</h1>
        <p css={paragraphStyle}>
          One of our team has been alerted and they'll be working on a fix soon.
          Please try clicking the button below to try again.
        </p>
        <Button
          onClick={() => {
            retry();
          }}
          size="large"
          type="primary"
        >
          Try again
        </Button>
      </div>
    </div>
  );
};
