// @flow
import { css } from "styled-components";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { setChartGlobalDefaults } from "@nested/brand/src/ChartJSDefaults";

ChartJS.register(ArcElement, Tooltip, Legend);

setChartGlobalDefaults();

const wrapperStyles = css`
  display: inline-block;
  margin: auto;
  position: relative;
  z-index: 0;
`;

const defaultChartOptions = {
  // https://www.chartjs.org/docs/latest/charts/doughnut.html
  rotation: -0.5 * Math.PI,
  cutout: "93%",
  maintainAspectRatio: false,
  responsive: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

type chartOptionsType = {
  cutout?: string,
  plugins: {
    legend?: { display?: boolean },
    tooltip?: { display?: boolean },
  },
  maintainAspectRatio?: boolean,
  responsive?: boolean,
  rotation?: number,
};

type dataType = {
  datasets: Array<any>,
  labels: Array<string>,
};

type Props = {
  ariaLabel: string,
  chartOptionsOverride?: chartOptionsType,
  children?: React$Node,
  className?: string,
  data: dataType,
  height?: number,
  width?: number,
};

export const DoughnutChart = ({
  ariaLabel,
  chartOptionsOverride,
  children,
  className,
  data,
  height = 190,
  width = 190,
}: Props) => {
  const chartOptions = { ...defaultChartOptions, ...chartOptionsOverride };
  return (
    <div
      className={`${className || ""} chromatic-ignore`}
      css={wrapperStyles}
      aria-label={ariaLabel}
      role="img"
    >
      {children}
      <Doughnut
        width={width}
        height={height}
        options={chartOptions}
        data={data}
      />
    </div>
  );
};
