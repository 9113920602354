// @flow
import styled, { css } from "styled-components";
import moment from "moment";
import { gql } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTasks } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { formatPrice } from "@nested/utils";
import { ButtonLink } from "@nested/component-library";
import {
  SectionCard,
  SectionDivider,
  SectionHeader,
  SectionWrapper,
  UnpaddedSectionCard,
  IconAndText,
} from "../../components/SectionCard";
import { AuthenticatedQuery } from "../../components/AuthenticatedQuery";

const ACTION_PLAN_TASKS = gql`
  query GetActionPlan($dealId: String) {
    actionPlan(dealId: $dealId) {
      dateCompleted
      taskText
    }
  }
`;

const bulletPoint = css`
  content: "";
  width: 6px;
  height: 6px;
  min-width: 6px;
  border-radius: 50%;
`;

const UL = styled.ul`
  list-style-type: none;
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  li::before {
    ${bulletPoint}
    margin: 8px 20px 0 5px;
    border: 2px solid ${({ theme }) => theme.palette.blue20};
  }
`;

const listItemBasics = css`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  vertical-align: middle;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const verticalLine = css`
  content: "";
  border-left: 2px solid ${({ theme }) => theme.palette.blue20};
  height: 100%;
  position: absolute;
  top: 18px;
  width: 1px;
`;

const PreListTask = styled.li`
  ${listItemBasics}
  padding-bottom: 8px;
  &:after {
    ${verticalLine}
    left: 9px;
  }
  &:last-child:after {
    height: 0px;
  }
`;

const PostListTask = styled.li`
  ${listItemBasics}
  padding: 0 15px;
  ${media.tablet`
    padding: 0 30px;
  `}
  &:after {
    ${verticalLine}
    left: 24px;
    ${media.tablet`
      left: 39px;
    `}
  }
`;

const BookACall = styled.div`
  background-color: ${({ theme }) => theme.palette.hague90};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  padding: 20px 15px;
  position: relative;
  ${media.tablet`
    padding: 20px 30px;
  `}
  &:before {
    ${bulletPoint}
    margin: 10px 20px 0 5px;
    border: 2px solid white;
  }
  &:after {
    content: "";
    border-left: 2px solid white;
    height: 30px;
    position: absolute;
    top: 0px;
    left: 24px;
    ${media.tablet`
      left: 39px;
    `}
  }
`;

const bookACallHeader = css`
  font-weight: 500;
  margin-top: 5px;
`;

const bookACallText = css`
  font-weight: 400;
  line-height: 20px;
  padding: 10px 0;
`;

const taskDetails = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
  width: 100%;
`;

const completedDate = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-weight: 400;
  margin-left: 10px;
  white-space: nowrap;
`;

const listPriceHeader = css`
  font-size: 21px;
  font-weight: 500;
  line-height: 25px;
  padding: 20px 15px 0 15px;
  ${media.tablet`
    padding: 30px 30px 0 30px;
  `}
`;

const listPriceSubText = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-weight: 500;
  padding: 0 15px;
  ${media.tablet`
    padding: 0 30px;
  `}
`;

const unpaddedDivider = css`
  margin: 15px;
  ${media.tablet`
    margin: 25px 30px;
  `}
`;

const Button = styled(ButtonLink)`
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  width: calc(100% - 35px);
  ${media.tablet`
    width: 160px;
  `}
`;

const checkbox = css`
  background-color: ${({ theme, $isLastCompleted }) =>
    $isLastCompleted ? theme.palette.blue150 : theme.palette.blue20};
  height: 20px;
  width: 20px;
  border-radius: 5px;
  position: absolute;
  top: 2px;
  left: 15px;
  z-index: 1;
  font-size: 12px;
  ${media.tablet`
    left: 30px;
  `}
`;

const tickStyle = css`
  color: ${({ theme, $isLastCompleted }) =>
    $isLastCompleted ? "white" : theme.palette.blue150};
  position: absolute;
  top: 5px;
  left: 4px;
`;

const formatCompletedDate = (date) => moment(date).format("D MMM");

type unformattedTasksList = Array<{
  taskText: string,
  dateCompleted: ?string,
}>;

type formattedTasksList = Array<{
  taskText: string,
  dateCompleted: ?string,
  isLastCompletedTask?: boolean,
}>;

export const formatTasks = (
  tasksList: unformattedTasksList,
): formattedTasksList => {
  const nullifiedFutureDates = tasksList.map((task) =>
    moment().startOf("day").diff(moment(task.dateCompleted), "days") < 0
      ? { ...task, dateCompleted: null }
      : task,
  );

  const lastCompletedTask = nullifiedFutureDates
    .filter((task) => task.dateCompleted !== null)
    .slice(-1)[0];

  return nullifiedFutureDates.map((task) =>
    task === lastCompletedTask
      ? { ...task, isLastCompletedTask: true }
      : { ...task },
  );
};

export const ActionPlan = ({
  deal,
}: {
  deal: ?ListingSectionQuery_publicDeal,
}) => {
  return (
    <SectionWrapper>
      <SectionHeader>Action Plan</SectionHeader>
      <AuthenticatedQuery
        query={ACTION_PLAN_TASKS}
        variables={{ dealId: deal?.externalDealId || null }}
      >
        {({ data: { actionPlan } }) => {
          if (!deal || !deal.isListed) {
            return (
              <SectionCard>
                <IconAndText
                  icon={faTasks}
                  text="We’ll keep you informed of our comprehensive approach to
                selling."
                  css="margin: 10px 0 30px 10px;"
                />
                <UL>
                  {actionPlan.map((task, i) => (
                    <PreListTask key={i}>
                      <div>{task.taskText}</div>
                    </PreListTask>
                  ))}
                </UL>
              </SectionCard>
            );
          }

          const listedActionPlanTasks = formatTasks(actionPlan);

          return (
            <UnpaddedSectionCard>
              <div css={listPriceHeader}>
                Listed at {formatPrice(parseFloat(deal.currentListingPrice))}
              </div>
              <div css={listPriceSubText}>Review 2 weeks after listing</div>
              <SectionDivider css={unpaddedDivider} />
              <UL>
                {listedActionPlanTasks.map((task, i) => (
                  <PostListTask key={i}>
                    <div css={taskDetails}>
                      {task.dateCompleted && (
                        <div
                          css={checkbox}
                          $isLastCompleted={task.isLastCompletedTask || null}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            css={tickStyle}
                            $isLastCompleted={task.isLastCompletedTask || null}
                          />
                        </div>
                      )}
                      <div>{task.taskText}</div>
                      <div css={completedDate}>
                        {task.dateCompleted
                          ? `${formatCompletedDate(task.dateCompleted)}`
                          : null}
                      </div>
                    </div>
                  </PostListTask>
                ))}
                <BookACall>
                  <div>
                    <div css={bookACallHeader}>
                      Strategy review call after 2 weeks
                    </div>
                    <div css={bookACallText}>
                      Check in with your agent to discuss changes we could make
                      to your approach if you aren't getting enough interest
                    </div>

                    {deal?.sm?.actionPlanReviewBookingUrl && (
                      <Button
                        to={deal?.sm?.actionPlanReviewBookingUrl}
                        data-test="book-a-review-call-button"
                      >
                        Book a review call
                      </Button>
                    )}
                  </div>
                </BookACall>
              </UL>
            </UnpaddedSectionCard>
          );
        }}
      </AuthenticatedQuery>
    </SectionWrapper>
  );
};
