// @flow
import { createContext, useEffect, useState } from "react";
import { gql } from "@apollo/client";
import qs from "query-string";
import { useHistory, useLocation } from "react-router";
import { Sentry } from "@nested/isomorphic-sentry";
import { useApolloClient } from "@apollo/client/react/hooks";
import { AuthLoading, AuthInitError, useAuth } from "@nested/auth";

export const DealContext = createContext<{
  customerAccountId: ?string,
  customerMode: boolean,
  dealId: ?string,
}>({
  dealId: null,
  customerMode: false,
  customerAccountId: null,
});

const DEAL_QUERY = gql`
  query DealContextLatestDeal {
    latestPublicDeal {
      id
      externalDealId
    }
  }
`;

export const ADMIN_DEAL_QUERY = gql`
  query DealContextAdminDeal($dealId: String!) {
    publicDeal(externalDealId: $dealId) {
      id
      externalDealId
      customerAccountId
      property {
        formattedAddress
      }
    }
  }
`;

type Props = {
  children: React$Node,
};

export const DealProvider = ({ children }: Props) => {
  const auth = useAuth();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [dealId, setDealId] = useState();
  const [customerAccountId, setCustomerAccountId] = useState();
  const apollo = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [customerMode, setCustomerMode] = useState(false);
  const { deal: adminDealId } = qs.parse(search);

  const initialize = async () => {
    try {
      if (!auth.authenticated) {
        return;
      }

      setLoading(true);
      setError(false);

      // Customer mode
      if (adminDealId && auth.admin) {
        const { data } = await apollo.query({
          query: ADMIN_DEAL_QUERY,
          errorPolicy: "ignore", // Allows us to fall back to latestPublicDeal if this fails
          context: { auth },
          variables: { dealId: adminDealId },
        });

        if (data?.publicDeal?.externalDealId) {
          setCustomerAccountId(data.publicDeal.customerAccountId);
          setDealId(adminDealId);
          setCustomerMode(true);
          history.push(pathname);
          return;
        }
      }

      const { data } = await apollo.query({
        query: DEAL_QUERY,
        context: { auth },
      });

      setDealId(data?.latestPublicDeal?.externalDealId);
    } catch (e) {
      Sentry.captureMessage(
        "[DealProvider] Failed to load latest public deal",
        {
          extra: {
            error: JSON.stringify(e),
          },
        },
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (dealId && window.FS && typeof window.FS.setUserVars === "function") {
      window.FS.setUserVars({
        deal_str: dealId,
        customerMode_str: `https://account.nested.com/?deal=${dealId}`,
        nest_str: `https://nest.nested.com/deals/${dealId}`,
      });
    }
  }, [dealId]);

  if (loading) {
    return <AuthLoading />;
  }

  if (error) {
    return <AuthInitError retry={initialize} />;
  }

  return (
    <DealContext.Provider
      value={{
        customerAccountId,
        customerMode,
        dealId,
      }}
    >
      {children}
    </DealContext.Provider>
  );
};
