// @flow
import styled from "styled-components";
import { TextInput as OriginalTextInput } from "@nested/brand";

export const TextInput = styled(OriginalTextInput)`
  border-radius: 8px;
  height: 50px;
  box-sizing: border-box;
  margin-top: 5px;
  max-width: 500px;
`;

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
`;
