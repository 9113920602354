// @flow

import styled, { css } from "styled-components";
import { mediumParagraph, media } from "@nested/brand";
import { SectionBody, SectionTitle, Wrapper } from "../Section";
import { ComparableProperties } from "./ComparableProperties/ComparableProperties";
import { ErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";

type Props = {
  externalDealId: ?string,
  exampleMode?: boolean,
};

const ComparablePropertySectionBody = styled(SectionBody)`
  margin: 10px -10px;
  padding: 0 10px 0 20px;
  margin-right: 0;
  overflow: hidden;
  ${media.tablet`
    padding: 0;
    overflow: visible;
    margin: 10px 0px;
  `}
`;

const descriptionStyle = css`
  ${mediumParagraph};
  margin: 0;
  padding: 10px 0 10px 10px;
  ${media.tablet`
    padding: 0;
  `}
`;

export const ComparablePropertiesSection = ({
  externalDealId,
  exampleMode,
}: Props) => (
  <Wrapper id="comparable-properties-section">
    <SectionTitle data-test="comparables-title">
      Similar properties
    </SectionTitle>
    <ComparablePropertySectionBody>
      <ErrorBoundary>
        <p css={descriptionStyle} data-test="comparables-description">
          We use similar homes to yours as a benchmark to guide our sales
          strategy so we get you the best price for your home.
        </p>
        <ComparableProperties
          externalDealId={externalDealId}
          exampleMode={exampleMode}
        />
      </ErrorBoundary>
    </ComparablePropertySectionBody>
  </Wrapper>
);
