// @flow
import { useAuthenticatedQuery } from "./useAuthenticatedQuery";
import { useDeal } from "./useDeal";

export const usePublicDealQuery: typeof useAuthenticatedQuery = (
  query,
  config = {},
) => {
  const { dealId } = useDeal();

  return useAuthenticatedQuery(query, {
    ...config,
    variables: {
      ...(config?.variables || {}),
      dealId,
    },
    skip: !dealId,
  });
};
