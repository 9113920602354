// @flow
import { getConfig } from "@nested/config";

const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_CONNECTION } = getConfig();

const PASSWORD_RESET_URL = `https://${AUTH0_DOMAIN}/dbconnections/change_password`;

/*
 * For some reason, auth0-spa-js doesn't have a function for requesting a password
 * reset email, so rather than include the massive auth0.js package, we just build
 * our own as it's a very simple API call.
 */
export const resetPassword = async (email: string) => {
  const response = await fetch(PASSWORD_RESET_URL, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify({
      client_id: AUTH0_CLIENT_ID,
      connection: AUTH0_CONNECTION,
      email,
    }),
  });

  if (response.status !== 200) {
    const body = await response.text();
    throw new Error(`Failed to reset password: ${body}`);
  }
};
