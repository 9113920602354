// @flow
import { useState } from "react";

type ModalProps<T> = {
  open: boolean,
  selectedItem: T,
  selectAndOpen: (n: number) => void,
  onClose: () => void,
  next: ?() => void,
  previous: ?() => void,
};

export const useModalCollection = <T>(collection: T[]): ModalProps<T> => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onClose = () => setOpen(false);

  const selectAndOpen = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const next =
    selectedIndex < collection.length - 1
      ? () => {
          setSelectedIndex(selectedIndex + 1);
        }
      : undefined;

  const previous =
    selectedIndex > 0
      ? () => {
          setSelectedIndex(selectedIndex - 1);
        }
      : undefined;

  const selectedItem = collection[selectedIndex] || collection[0];

  return {
    open,
    selectAndOpen,
    onClose,
    selectedItem,
    next,
    previous,
  };
};
