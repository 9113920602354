// @flow
import { useState } from "react";
import { formatPriceWithKOrM } from "@nested/utils";
import { Rightmove } from "@nested/brand/src/icons";
import { media } from "@nested/brand";
import { css } from "styled-components";
import { useAuth } from "@nested/auth";
import { max, meanBy, range, sum } from "lodash";
import {
  headingContainer,
  heading,
  headingSubtext,
  legendBox,
  legendItem,
  lineLegendItem,
  listingViewsCounter,
  legendItemText,
  legendItemSubtext,
  peakPeriodLegendItem,
  SixteenWeeksFilter,
} from "./chartStyles";
import { ListingViewsChart } from "./ListingViewsChart";

const xAxisLabel = css`
  &:after {
    content: "Weeks";
    position: absolute;
    bottom: -17px;
    left: calc(50% - 8px);
    font-size: 12px;
    font-weight: 500;
  }
`;

const graphContainer = css`
  margin: 30px 0 5px;
  position: relative;
  ${xAxisLabel}
`;

const noDataYetLabel = css`
  background-color: ${({ theme }) => theme.palette.hague70};
  border: 2px solid ${({ theme }) => theme.palette.hague70};
  color: white;
  font-size: 14px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  display: inline;
  width: 75%;
  ${media.tablet`
    width: auto;
  `}
`;

const getPriceRange = (amount) => {
  const price = parseInt(amount, 10);
  if (price <= 800000) {
    const lower = Math.floor((price - 1) / 100000) * 100000;
    const upper = lower + 100000;
    return `${formatPriceWithKOrM(lower)}-${formatPriceWithKOrM(upper)}`;
  }
  if (price <= 1000000) {
    return "£800K-£1M";
  }
  if (price <= 1200000) {
    return "£1M-£1.2M";
  }
  if (price <= 1400000) {
    return "£1.2M-£1.4M";
  }

  return "£1.4M+";
};

export const padToWeeks = (
  views: $ReadOnlyArray<ListingPerformanceQuery_publicDeal_dailyPortalViews>,
) => {
  const remainder = views.length % 7;
  if (remainder === 0) {
    return views;
  }

  const extras = range(views.length + 1, views.length + 8 - remainder).map(
    (n) => ({
      dayNumber: n,
      views: null,
    }),
  );
  return [...views, ...extras];
};

export const nullsToZeros = (
  list: $ReadOnlyArray<ListingPerformanceQuery_publicDeal_dailyPortalViews>,
) => {
  const result: ListingPerformanceQuery_publicDeal_dailyPortalViews[] =
    list.slice();
  let nullCount = 0;

  list.forEach((item, index) => {
    if (item.views === null) {
      nullCount += 1;
      return;
    }

    if (nullCount <= 3) {
      return;
    }

    for (let i = index - nullCount; i < index; i += 1) {
      result[i] = {
        ...result[i],
        views: 0,
      };
    }
    nullCount = 0;
  });
  return result;
};

export const flattenAfterFiveWeeks = (
  views: ListingPerformanceQuery_publicDeal_averageDailyPortalViews[],
  points: number,
) => {
  if (views.length <= 35) {
    return views;
  }

  const weekThreeToFiveAverage = Math.round(
    meanBy(views.slice(20, 34), "views"),
  );

  const transformedViews = views.map<*>((v) => {
    if (v.dayNumber <= 35) {
      return v;
    }
    return {
      dayNumber: v.dayNumber,
      views: weekThreeToFiveAverage,
    };
  });

  const avgDataLength = transformedViews.length;

  // seller data extends beyond avg data
  if (avgDataLength < points) {
    const diff = points - avgDataLength;
    const extraPoints = range(0, diff).map((x) => ({
      dayNumber: avgDataLength + x,
      views: weekThreeToFiveAverage,
    }));
    return [...transformedViews, ...extraPoints];
  }

  return transformedViews;
};

type Props = {
  deal: ListingPerformanceQuery_publicDeal,
  exampleView?: boolean,
};

const addDayZero = (list) => [{ dayNumber: 0, views: 0 }, ...list];

export const ListingViews = ({ deal, exampleView }: Props) => {
  const { demoModeEnabled } = useAuth();
  const [filter, setFilter] = useState(true);
  const sellerListingViews = addDayZero(
    nullsToZeros(padToWeeks(deal.dailyPortalViews)),
  );
  const points = max([sellerListingViews.length, 28]);
  const averageListingViews = addDayZero(
    flattenAfterFiveWeeks(
      deal.averageDailyPortalViews.slice(0, points),
      points,
    ),
  );

  const propertyType = deal.property.type;
  const beds = deal.property.bedrooms;
  const bedsText = beds === 0 ? "studio" : `${beds} bed`;
  const rangeText = getPriceRange(deal.currentListingPrice);
  const outcode = deal.property.postcode.split(" ")[0];
  const priceAdjusted = deal.hasPriceAdjustment;

  const propertyText = `${propertyType}s`;

  const totalListingViews = sum(sellerListingViews.map(({ views }) => views));

  return (
    <>
      <div css={headingContainer}>
        <h2 css={heading}>
          <span css={listingViewsCounter}>{totalListingViews}</span> listing
          views
          {priceAdjusted && (
            <p css={headingSubtext}>Since last price adjustment</p>
          )}
        </h2>
        <Rightmove />
      </div>
      <div css={legendBox}>
        <div css={legendItem}>
          <div css={lineLegendItem} lineColor="blue">
            <p css={legendItemText}>
              {exampleView ? "Example listing views" : "Your listing views"}
            </p>
            {exampleView && (
              <p css={legendItemSubtext} noCaps>
                123 Example Street
              </p>
            )}
          </div>
        </div>
        <div css={legendItem}>
          <div css={lineLegendItem} lineColor="pink">
            <p css={legendItemText}>
              {exampleView
                ? "Local average listing views"
                : "Avg listing views"}
            </p>
            {!exampleView && (
              <p css={legendItemSubtext}>
                {demoModeEnabled
                  ? `${rangeText} ${bedsText} ${propertyText} in your area`
                  : `for ${rangeText} ${bedsText} ${propertyText} in ${outcode}`}
              </p>
            )}
          </div>
        </div>
        <div css={legendItem}>
          <div css={peakPeriodLegendItem}>
            <p css={legendItemText}>Peak interest period</p>
          </div>
        </div>
      </div>
      {deal.dailyPortalViews.length > 112 && (
        <SixteenWeeksFilter filter={filter} setFilter={setFilter} />
      )}
      <div
        aria-label="Average listing views graph for the first 4 weeks of listing"
        role="img"
        className="chromatic-ignore"
        css={graphContainer}
      >
        {totalListingViews === 0 && (
          <span css={noDataYetLabel}>
            Your data will appear after&nbsp;24&nbsp;hours
          </span>
        )}
        <ListingViewsChart
          averageListingViews={averageListingViews}
          sellerListingViews={sellerListingViews}
          filter={filter}
        />
      </div>
    </>
  );
};
