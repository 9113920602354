import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { Sentry } from "@nested/isomorphic-sentry";
import { getConfig } from "@nested/config";
import { resetPassword } from "./resetPassword";
import { useTemporaryToken } from "./useTemporaryToken";
import { AuthLoading } from "./AuthLoading";
import { AuthInitError } from "./AuthInitError";

const {
  AUTH0_CALLBACK_ACCOUNT_URI,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_LOGOUT_URI,
} = getConfig();

const AUTH0_CLIENT_CONFIG = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  scope: "openid profile email",
  cacheLocation: "localstorage",
  useRefreshTokens: true,
  redirectUri: AUTH0_CALLBACK_ACCOUNT_URI,
  authorizeTimeoutInSeconds: 10, // default is 60
  sessionCheckExpiryDays: 90, // defaults to 1, needs to be the number of days our refresh token is valid for
};

const isAdminEmail = (email) => {
  const domain = email.split("@")[1];
  return domain === "nested.com";
};

const isExpired = (jwt) => {
  const { exp } = jwtDecode(jwt);
  const now = new Date().getTime() / 1000;
  if (exp < now) {
    return true;
  }
  return false;
};

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const InternalAuthProvider = ({ children }) => {
  const { temporaryToken, removeTemporaryToken } = useTemporaryToken();
  const location = useLocation();
  const [demoModeEnabled, setDemoModeEnabled] = useState(false);
  const {
    isAuthenticated,
    isLoading,
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
    user,
    loginWithRedirect,
    logout: auth0Logout,
  } = useAuth0();

  useEffect(() => {
    const { demo } = qs.parse(location.search);
    if (demo === "true") {
      setDemoModeEnabled(true);
    }
  }, []);

  const admin = useMemo(() => {
    if (!user?.email) {
      return false;
    }

    return isAdminEmail(user.email);
  }, [user]);

  const loggedInWithTemporaryToken = !isAuthenticated && temporaryToken;

  const getAccount = () => {
    if (isAuthenticated) {
      return user;
    }

    if (loggedInWithTemporaryToken) {
      return jwtDecode(temporaryToken);
    }

    return null;
  };

  const redirectToLoginPage = (state) => loginWithRedirect({ appState: state });

  const logout = () => {
    if (loggedInWithTemporaryToken) {
      removeTemporaryToken();
      window.location.assign(AUTH0_LOGOUT_URI);
      return;
    }

    auth0Logout({
      returnTo: AUTH0_LOGOUT_URI,
    });
  };

  const getToken = async () => {
    if (loggedInWithTemporaryToken) {
      if (isExpired(temporaryToken)) {
        logout();
      }
      return temporaryToken;
    }

    // Ensure there is a valid ID token saved in the cache. If so, this is a noop, if not it will fetch a new one.
    // For some reason it only returns an access token, so we still have to fetch the ID token from the cache as well
    await getAccessTokenSilently();

    // The raw token has a double underscore prefix to differentiate it from the parsed fields
    const { __raw } = await getIdTokenClaims();
    return __raw;
  };

  useEffect(() => {
    if (error) {
      if (error.message === "Unknown or invalid refresh token.") {
        // The only way to recover from this error is to log out and back in again
        logout();
        return;
      }

      Sentry.captureException(error);
    }
  }, [error]);

  if (!process.browser || isLoading) {
    return <AuthLoading />;
  }

  if (error) {
    return <AuthInitError retry={logout} />;
  }

  return (
    <AuthContext.Provider
      value={{
        account: getAccount(),
        admin,
        authenticated: loggedInWithTemporaryToken || isAuthenticated,
        demoModeEnabled,
        getToken,
        logout,
        redirectToLoginPage,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProvider = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.replace(appState?.returnTo || "/");
  };

  return (
    <Auth0Provider
      {...AUTH0_CLIENT_CONFIG}
      onRedirectCallback={onRedirectCallback}
    >
      <InternalAuthProvider>{children}</InternalAuthProvider>
    </Auth0Provider>
  );
};
