// @flow
import { Button } from "@nested/component-library";
import { useAuth } from "@nested/auth";
import { Card } from "./Card";

export const Logout = () => {
  const { logout } = useAuth();

  return (
    <Card>
      <Button onClick={() => logout()} type="standard">
        Logout
      </Button>
    </Card>
  );
};
