import styled, { css } from "styled-components";
import { getImage } from "@nested/utils";
import {
  mediumHeading,
  sectionHeading,
  mediumParagraph,
  largeParagraph,
  media,
} from "@nested/brand";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { LegacyLayoutWrapper } from "../PageWrapper";

const imageBackground = css`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.pink40};
  padding: 30px 40px;
  text-align: center;
  ${media.tablet`
    display: none;
  `}
`;

const imageStyle = css`
  height: 300px;
`;

const tabletImage = css`
  display: none;
  ${media.tablet`
    display: block;
    position: absolute;
    right: 40px;
    bottom: 30px;
    width: 30%;
  `}
  ${media.desktop`
    width: 35%;
  `}
`;

const container = css`
  padding: 15px;
  position: relative;
  ${media.tablet`
    box-sizing: border-box;
    padding: 100px 30px 40px;
    background-color: ${({ theme }) => theme.palette.pink40};
    min-height: calc(100vh - 57px);
  `}
  ${media.desktop`
    padding: 100px 3.33vw;
    min-height: 100%;
  `}
`;

const subtitle = css`
  ${sectionHeading}
  margin: 0;
  ${media.tablet`
    color: ${({ theme }) => theme.palette.terracotta80};
    font-size: 16px;
    letter-spacing: 1.71px;
    line-height: 21px;
  `}
`;

const title = css`
  ${largeParagraph}
  font-weight: 500;
  margin: 0;
  ${media.tablet`
    ${mediumHeading}
    margin: 10px 0 0;
  `}
  ${media.desktop`
    font-size: 36px;
    line-height: 43.2px;
  `}
`;

const description = css`
  ${mediumParagraph}
  font-size: 14px;
  line-height: 21px;
  opacity: 0.8;
  margin: 20px 0 30px;
  ${media.tablet`
    font-size: 18px;
    line-height: 27px;
    max-width: 400px;
  `}
`;

const LinkOption = styled(Link)`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  padding: 10px 0;
  &:last-of-type {
    border-bottom: none;
  }
  ${media.tablet`
    max-width: 400px;
    border-color: ${({ theme }) => theme.palette.terracotta80};
    &:hover {
      background-color: ${({ theme }) => theme.palette.pink20};
    }
  `}
`;

const linkText = css`
  color: ${({ theme }) => theme.palette.blue100};
  font-weight: 500;
  ${media.tablet`
    color: ${({ theme }) => theme.palette.hague80};
  `}
`;

const iconStyle = css`
  color: ${({ theme }) => theme.palette.hague10};
  ${media.tablet`
    color: ${({ theme }) => theme.palette.terracotta80};
  `}
`;

const LinkListItem = ({ to, text }) => (
  <LinkOption to={to}>
    <span css={linkText}>{text}</span>
    <FontAwesomeIcon icon={faChevronRight} css={iconStyle} />
  </LinkOption>
);

export const NotFound = () => {
  return (
    <LegacyLayoutWrapper>
      <div css={imageBackground}>
        <img
          src={getImage("your-account/not-found-spaceship.png")}
          alt="alien house abduction"
          css={imageStyle}
        />
      </div>
      <div css={container}>
        <p css={subtitle}>404 page not found</p>
        <h1 css={title}>This page has been abducted</h1>
        <p css={description}>
          Looks like this page doesn’t exist, not to worry. We’ll help you find
          your way home soon.
        </p>
        <LinkListItem to="/listing" text="I want to sell my home" />
        <LinkListItem to="/book-a-call" text="Speak to a Nested agent" />
        <img
          src={getImage("your-account/not-found-spaceship.png")}
          alt="alien house abduction"
          css={tabletImage}
        />
      </div>
    </LegacyLayoutWrapper>
  );
};
