// @flow
import { css } from "styled-components";
import { smallParagraph } from "@nested/brand";
import { VIEWING_BOOKED_STATUS, DID_NOT_BOOK_VIEWING_STATUS } from "./utils";

const statusStyles = css`
  ${smallParagraph}
  font-weight: 500;
`;

const statusGreen = css`
  ${statusStyles}
  color: ${({ theme }) => theme.palette.enquiryGreen};
`;

const statusOrange = css`
  ${statusStyles}
  color: ${({ theme }) => theme.palette.orange};
`;

const teaserTextStyles = css`
  ${smallParagraph}
  font-style: italic;
  /* magic multiline ellipsis overflow https://css-tricks.com/line-clampin/ */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  span {
    font-style: normal;
  }
`;

type Props = {
  status: string,
  className?: string,
  teaserText?: ?string,
};

export const StatusText = ({ status, className, teaserText }: Props) => {
  if (status === VIEWING_BOOKED_STATUS) {
    return (
      <div css={statusGreen} className={className}>
        Booked a viewing
      </div>
    );
  }
  if (status === DID_NOT_BOOK_VIEWING_STATUS) {
    return teaserText ? (
      <div css={teaserTextStyles} className={className}>
        <span css={statusStyles}>Didn't book a viewing:&nbsp;</span>
        {teaserText}
      </div>
    ) : (
      <div css={statusStyles} className={className}>
        Didn't book a viewing
      </div>
    );
  }
  return (
    <div css={statusOrange} className={className}>
      We're chasing now
    </div>
  );
};
