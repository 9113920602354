// @flow
import { ThemeProvider } from "styled-components";
import { ApolloProvider, type ApolloClient } from "@apollo/client";
import { createApolloClient } from "@nested/utils";
import { AuthProvider } from "@nested/auth";
import { theme } from "@nested/brand";
import { MetaTags } from "./MetaTags";
import { DealProvider } from "./DealProvider/DealProvider";

type Props = {
  children: React$Node,
  apolloClient?: ApolloClient,
};

export const updatedTheme = {
  ...theme,
  breakpoints: {
    tablet: 900,
    desktop: 1200,
    desktopMax: 1800,
  },
};

const Providers = ({
  children,
  apolloClient: client = createApolloClient(),
}: Props) => (
  <>
    <MetaTags />
    <ThemeProvider theme={updatedTheme}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <DealProvider>{children}</DealProvider>
        </AuthProvider>
      </ApolloProvider>
    </ThemeProvider>
  </>
);

export { Providers };
