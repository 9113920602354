// @flow
import { useEffect } from "react";
import { type DocumentNode, type FetchPolicy } from "@apollo/client";
import { useQuery, type ApolloError } from "@apollo/client/react/hooks";
import { Sentry } from "@nested/isomorphic-sentry";

export type QueryRenderProps<TData> = {
  data: TData,
  refetching: boolean,
  startPolling(interval: number): void,
  stopPolling(): void,
};

export type Props<TData, TVariables> = {
  query: DocumentNode,
  variables?: TVariables,
  placeholder: React$ComponentType<{}>,
  errorHandler?: React$ComponentType<{
    error: ApolloError,
    refetching: boolean,
  }>,
  children(props: QueryRenderProps<TData>): React$Node,
  context?: any,
  fetchPolicy?: FetchPolicy,
  nextFetchPolicy?: FetchPolicy,
  skip?: boolean,
};

/**
 * The ExtendedQuery is an abstraction over Apollo's `useQuery` hook
 * which does two things:
 *
 * 1. Initial loading and error states with fallback components,
 * allowing the `data` prop to be correctly typed in the children function.
 * 2. Passes a `refetching` prop to the children function which can be used
 * to handle loading states after initial load has finished, for example
 * when a variable is changed.
 *
 * It relies heavily on the `networkStatus` prop provided by Apollo, so here
 * is a brief summary of what its values mean:
 * https://github.com/apollographql/apollo-client/blob/master/packages/apollo-client/src/core/networkStatus.ts
 */
export const ExtendedQuery = <TData, TVariables>({
  query,
  children,
  variables,
  placeholder: Placeholder,
  errorHandler: ErrorHandler,
  context,
  fetchPolicy,
  nextFetchPolicy,
  skip,
}: Props<TData, TVariables>) => {
  const { data, error, networkStatus, startPolling, stopPolling } = useQuery<
    TData,
    TVariables,
  >(query, {
    context,
    notifyOnNetworkStatusChange: true,
    variables,
    fetchPolicy,
    nextFetchPolicy,
    skip,
  });

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, { info: JSON.stringify(error) });
    }
  }, [error]);

  const refetching = [2, 4].includes(networkStatus);

  /*
   * Two cases for showing the placeholder:
   *
   * 1. Initial fetch
   * 2. We're refetching following a network error, which clears the
   *    error object but leaves us with no data
   */
  if (networkStatus === 1 || (refetching && !data)) {
    return <Placeholder />;
  }

  if (error) {
    if (!ErrorHandler) {
      throw error;
    }

    return <ErrorHandler refetching={refetching} error={error} />;
  }

  /*
   * When we get to this point we can be certain that data has been fetched and does not
   * have errors, so we can cast it to any and then back to the correct TData type
   */
  return children({
    refetching,
    startPolling,
    stopPolling,
    data: ((data: any): TData),
  });
};
