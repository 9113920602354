import styled from "styled-components";
import { smallParagraph } from "@nested/brand";

export const UL = styled.ul`
  list-style-type: none;
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  li::before {
    content: "";
    width: 8px;
    height: 8px;
    min-width: 8px;
    margin: 8px 15px 0 5px;
    background-color: ${({ theme }) => theme.palette.blue100};
    border-radius: 50%;
    line-height: 24px;
    display: inline-block; /* Needed to add space between the bullet and the text */
  }
`;

export const LI = styled.li`
  ${smallParagraph}
  line-height: 24px;
  vertical-align: middle;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague90};
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
