// @flow
import { css } from "styled-components";
import moment from "moment-timezone";
import { mediumParagraph, media } from "@nested/brand";
import {
  formatViewingNumber,
  UpcomingViewingModal,
  virtualViewingDot,
} from "../Viewings/UpcomingViewings/UpcomingViewingModal";
import { ViewingDetailDate } from "./ViewingDetailDate";
import { BuyerAvatarSmall } from "../../components/BuyerAvatar/BuyerAvatar";
import { useModalCollection } from "../../hooks/useModalCollection";

export const dateFormat = (date: string) => moment(date).format("YYYY-MM-DD");

const viewingWrapper = css`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  padding-top: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  &:first-of-type {
    padding-top: 0;
  }
`;

const buyerName = css`
  border: none;
  background-color: unset;
  padding: 0;
  ${mediumParagraph}
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blue150};
  font-size: 14px;
  line-height: 20px;
  text-align: left;
`;

const viewingInfo = css`
  ${mediumParagraph}
  font-size: 14px;
`;

const viewingTime = css`
  font-weight: 500;
`;

const BuyerName = ({ firstName, surnameInitial, virtual }) => (
  <div css={buyerName}>
    {firstName} {surnameInitial}
    {virtual && <div css={virtualViewingDot}></div>}
  </div>
);

const ViewingInfo = ({ viewing: { formattedTime, number, virtual } }) => (
  <div css={viewingInfo}>
    <span css={viewingTime}>{formattedTime}</span> -{" "}
    {formatViewingNumber(number)} viewing {virtual && "- Virtual"}
  </div>
);

const ViewingDetail = ({ viewing, onClick }) => {
  const {
    buyer: { avatarUrl, firstName, surnameInitial },
    virtual,
  } = viewing;

  return (
    <button
      onClick={onClick}
      key={viewing.id}
      css={viewingWrapper}
      data-test="individual-viewing-detail"
    >
      <div>
        <BuyerName
          firstName={firstName}
          surnameInitial={surnameInitial}
          virtual={virtual}
        />
        <ViewingInfo viewing={viewing} />
      </div>
      <BuyerAvatarSmall avatarUrl={avatarUrl} />
    </button>
  );
};

const upcomingViewingsWrapper = css`
  display: flex;
  margin: 30px 15px;
  max-width: 360px;
  ${media.tablet`
    margin: 40px 0;
  `}
`;

const viewingListStyles = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const noViewingsText = css`
  ${mediumParagraph}
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 14px;
`;

type Viewing = {
  formattedTime?: string,
  day?: string,
  ...UpcomingViewingDetails_publicDeal_buyerEnquiries_viewings,
  buyer: UpcomingViewingDetails_publicDeal_buyerEnquiries_buyer,
};

type Props = {
  selectedDay: ?DateTime,
  allViewings: ?{ [string]: Viewing[] },
  exampleMode?: boolean,
};

export const ViewingsOnDay = ({
  selectedDay,
  allViewings,
  exampleMode,
}: Props) => {
  if (!selectedDay || !allViewings) return null;

  const viewings = allViewings[dateFormat(selectedDay)];

  const { open, selectAndOpen, onClose, selectedItem, next, previous } =
    useModalCollection(viewings || []);

  return (
    <div css={upcomingViewingsWrapper}>
      <ViewingDetailDate viewingDate={selectedDay} />
      <div css={viewingListStyles}>
        {viewings && viewings.length > 0 ? (
          <>
            {viewings.map((viewing, index) => (
              <ViewingDetail
                key={viewing.id}
                onClick={() => selectAndOpen(index)}
                viewing={viewing}
              />
            ))}
            <UpcomingViewingModal
              open={open}
              onClose={onClose}
              viewing={selectedItem}
              next={next}
              previous={previous}
              exampleMode={exampleMode}
            />
          </>
        ) : (
          <p css={noViewingsText}>No viewings</p>
        )}
      </div>
    </div>
  );
};
