// @flow
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAuth } from "@nested/auth";

const Login = () => {
  const { redirectToLoginPage } = useAuth();
  const { pathname, search } = useLocation();
  useEffect(() => {
    redirectToLoginPage({ returnTo: `${pathname}${search}` });
  }, []);

  return null;
};

export { Login };
