// @flow
import { ErrorHandler } from "../ErrorHandler/ErrorHandler";
import { NavigationHeader } from "../Navigation/NavigationHeader";
import { OuterWrapper } from "../PrivateRoute/AuthenticatedLayout";

type Props = { children: React$Node, path: string };

const UnauthenticatedLayout = ({ children, path }: Props) => {
  return (
    <ErrorHandler>
      <OuterWrapper>
        <NavigationHeader path={path} />
        {children}
      </OuterWrapper>
    </ErrorHandler>
  );
};

export { UnauthenticatedLayout };
