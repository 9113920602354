// @flow
import styled from "styled-components";
import { media, mediumParagraph, largeSectionHeading } from "@nested/brand";

const Section = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.hague20};
  border-radius: 8px;
  ${media.tablet`
    padding: 30px;
  `}
`;

const Title = styled.h3`
  ${largeSectionHeading}
  font-weight: 500;
  font-size: 16px;
  ${media.tablet`
    font-size: 18px;
  `}
`;
const Text = styled.p`
  ${mediumParagraph}
  margin-top: 20px;
`;

export const NoViewings = () => (
  <Section>
    <Title>You haven&apos;t had any viewings yet!</Title>
    <Text>
      Once you have, we&apos;ll notify you whenever you receive new feedback.
    </Text>
  </Section>
);
