import { css } from "styled-components";
import {
  mediumParagraph,
  largeHeading,
  mediumHeading,
  ResponsiveImage,
  media,
} from "@nested/brand";
import { ButtonLink } from "@nested/component-library";

import { LegacyLayoutWrapper } from "../PageWrapper";

const container = css`
  box-sizing: border-box;
  padding: 30px 10px;
  width: 100%;
  text-align: center;
  ${media.tablet`
    text-align: left;
    padding: 0;
    max-width: 550px;
    padding: 0 30px;
    margin: 50px auto;
  `}
  p:last-of-type {
    margin: 15px 0 0;
  }
`;

const headingStyle = css`
  margin: 15px 0 0;
  ${mediumHeading}
  ${media.tablet`
  ${largeHeading}
    margin: 30px 0 0;
  `}
`;

const paragraphStyles = css`
  margin: 15px 0 0;
  ${mediumParagraph}
  ${media.tablet`
    margin: 30px 0 0;
  `}
  strong {
    font-weight: 500;
  }
`;

const buttonStyle = css`
  margin-top: 30px;
`;

const imageStyle = css`
  width: 100%;
`;

export const ThankYou = () => (
  <LegacyLayoutWrapper>
    <div css={container} data-test="thank-you-component">
      <ResponsiveImage
        src="https://nested.imgix.net/illustrations/couple_with_dog.png"
        alt="couple with dog"
        css={imageStyle}
      />
      <h1 css={headingStyle}>
        We're excited to show you all that Nested has to offer
      </h1>
      <p css={paragraphStyles}>
        Thanks for your details, that is all we need to know. Please go ahead
        and explore the tools and data you now have at your disposal.
      </p>
      <ButtonLink to="/listing" type="primary" css={buttonStyle}>
        View our tools
      </ButtonLink>
    </div>
  </LegacyLayoutWrapper>
);
