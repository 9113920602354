// @flow
import moment from "moment";
import { find } from "lodash";

type Viewing = {
  +viewingFeedbackLastUpdated: ?string,
  +dateTimeStart: string,
  +number: number,
};

export const maxLastUpdated = (viewings: Viewing[]) => {
  return viewings.reduce(
    (acc, { viewingFeedbackLastUpdated, dateTimeStart }) => {
      const lastUpdate = viewingFeedbackLastUpdated || dateTimeStart;
      if (!acc) {
        return lastUpdate;
      }
      return moment(lastUpdate).isAfter(moment(acc)) ? lastUpdate : acc;
    },
    null,
  );
};

type Enquiries = $ReadOnlyArray<ViewingFeedbackDeal_publicDeal_buyerEnquiries>;

type SortedEnquiry = {
  ...ViewingFeedbackDeal_publicDeal_buyerEnquiries,
  lastUpdated: ?DateTime,
};

export const sortEnquiriesByViewingUpdateDate = (
  buyerEnquiries: Enquiries,
): SortedEnquiry[] =>
  [...buyerEnquiries]
    .map((enquiry) => {
      // sort viewings list in reverse chronological order (e.g. 3rd to 1st)
      const sorted = [...enquiry.viewings].sort(
        ({ number: viewingANumber }, { number: viewingBNumber }) => {
          return viewingBNumber - viewingANumber;
        },
      );
      // Surface latest update time to enquiry
      return {
        ...enquiry,
        viewings: sorted,
        lastUpdated: maxLastUpdated([...enquiry.viewings]),
      };
    })
    // sort by latest update time (most recent to oldest)
    .sort(({ lastUpdated: buyer1Update }, { lastUpdated: buyer2Update }) => {
      return moment(buyer2Update).diff(moment(buyer1Update));
    });

export const ordinal = (number: number) => moment.localeData().ordinal(number);

export const textOrdinal = (number: number) => {
  switch (number) {
    case 1:
      return "First";
    case 2:
      return "Second";
    case 3:
      return "Third";
    case 4:
      return "Fourth";
    case 5:
      return "Fifth";
    case 6:
      return "Sixth";
    default:
      return "";
  }
};

export type CompletedViewing = {
  conductor: ?string,
  viewingFeedbackLastUpdated: ?DateTime,
  dateTimeStart: DateTime,
  datetimeFirstImpressionsShared: ?DateTime,
  datetimeFullFeedbackShared: ?DateTime,
  firstImpressions: ?string,
  fullFeedback: ?string,
  id: number,
  number: number,
  shareViewingFeedback: boolean,
  viewingSaEmail: ?string,
  viewingSaName: ?string,
  followUpFeedback: $ReadOnlyArray<ViewingFeedbackDeal_publicDeal_buyerEnquiries_viewings_followUpFeedback>,
};

export const findLastUpdatedFeedback = ({
  firstImpressions,
  datetimeFirstImpressionsShared,
  fullFeedback,
  datetimeFullFeedbackShared,
  followUpFeedback,
  viewingFeedbackLastUpdated,
}: CompletedViewing): {
  feedbackContent: ?string,
  feedbackHeading: ?string,
} => {
  const noFeedback = { feedbackContent: null, feedbackHeading: null };

  // No feedback
  if (!viewingFeedbackLastUpdated) return noFeedback;

  // Any one of the follow up feedbacks
  const matchingFollowUp = find(followUpFeedback, {
    datetimeSharedWithSeller: viewingFeedbackLastUpdated,
  });
  if (matchingFollowUp) {
    return {
      feedbackContent: matchingFollowUp.feedback,
      feedbackHeading: "Follow up",
    };
  }

  // Full feedback or first impressions
  switch (viewingFeedbackLastUpdated) {
    case datetimeFullFeedbackShared:
      return {
        feedbackContent: fullFeedback,
        feedbackHeading: "Detailed feedback",
      };
    case datetimeFirstImpressionsShared:
      return {
        feedbackContent: firstImpressions,
        feedbackHeading: "First impressions",
      };
    default:
      return noFeedback;
  }
};
