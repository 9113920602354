// @flow
import annotationPlugin from "chartjs-plugin-annotation";
import { max, reverse } from "lodash";
import { Chart } from "react-chartjs-2";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";

ChartJS.register(
  ArcElement,
  annotationPlugin,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
);

const WEEK_16 = 7 * 16;

export const roundUpToNearest7 = (n: number) => Math.ceil(n / 7) * 7;

const createChartOptions = (suggestedXMax, xAxis, animate) => {
  const moreThan16Weeks = roundUpToNearest7(xAxis.length) / 7 > 16;
  const axisLabels = xAxis.reduce((acc, x) => {
    if (x > 0 && x % 7 === 0) {
      return acc.concat([x / 7]);
    }
    return acc;
  }, []);

  const upTo16WeeksGraphLabels = {
    type: "category",
    labels: axisLabels,
    offset: true,
    ticks: {
      font: {
        size: 12,
        family: "Euclid Circular B, sans-serif",
      },
      color: "rgba(7, 51, 67, 0.4)",
      padding: 5,
      maxRotation: 0,
    },
    grid: {
      color: "rgba(2, 66, 85, 0.2)",
      borderDashOffset: -149,
      borderDash: [10, 500],
      zeroLineWidth: 0,
      offset: true,
      z: 3,
      drawTicks: false,
    },
  };

  const after16WeeksGraphLabels = {
    type: "linear",
    labels: xAxis,
    min: 0,
    max: suggestedXMax,
    ticks: {
      callback: (value) => [`${value / 7}`],
      stepSize: 7,
      font: {
        size: 12,
        family: "Euclid Circular B, sans-serif",
      },
      color: "rgba(7, 51, 67, 0.4)",
      padding: 5,
      maxRotation: 0,
      maxTicksLimit: 16,
    },
    grid: {
      color: "rgba(2, 66, 85, 0.2)",
      borderDashOffset: -149,
      borderDash: [10, 500],
      zeroLineWidth: 0,
      z: 3,
      drawTicks: false,
    },
  };

  const windowSize = xAxis.filter((v) => v < 14).length;

  return {
    animation: {
      duration: animate ? 1000 : 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          family: "Euclid Circular B, sans-serif",
          style: "normal",
        },
        bodyFont: { family: "Euclid Circular B, sans-serif" },
        bodySpacing: 5,
        position: "nearest",
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          title: (context) => {
            return `Day ${context[0].label}`;
          },
          label: ({ formattedValue }) => `${formattedValue}`,
          labelColor: ({ dataset }) => ({
            borderColor: "rgba(0,0,0,0)",
            backgroundColor: dataset.borderColor,
          }),
        },
      },
      annotation: {
        drawTime: "beforeDatasetsDraw",
        annotations: [
          {
            type: "box",
            xScaleID: "x-axis",
            yScaleID: "y-axis",
            xMin: 0,
            xMax: windowSize,
            backgroundColor: "rgba(255, 193, 0, 0.1)",
            borderColor: "rgba(255, 193, 0, 0.1)",
            borderWidth: 1,
          },
        ],
      },
    },
    maintainAspectRatio: false,
    scales: {
      "y-axis": {
        type: "linear",
        position: "left",
        beginAtZero: true,
        suggestedMin: 0,
        ticks: {
          font: {
            size: 10,
            family: "Euclid Circular B, sans-serif",
          },
          color: "rgba(7, 51, 67, 0.4)",
          maxTicksLimit: 4,
          padding: 10,
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: "rgba(2, 66, 85, 0.2)",
          z: -1,
        },
      },
      "x-axis": {
        type: "category",
        labels: xAxis,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
        },
      },
      "x-labels": moreThan16Weeks
        ? after16WeeksGraphLabels
        : upTo16WeeksGraphLabels,
    },
  };
};

const createDatasets = (averageLine, sellerLine, lastPoint) => {
  return {
    datasets: [
      {
        label: "Your listing views",
        borderColor: "#2D88D9",
        pointHitRadius: 0,
        pointRadius: (point) => {
          if (point.dataIndex === lastPoint.dayNumber) {
            return 3;
          }
          return 0;
        },
        pointBorderWidth: (point) => {
          if (point.dataIndex === lastPoint.dayNumber) {
            return 3;
          }
          return 1;
        },
        pointBackgroundColor: "white",
        spanGaps: true,
        yAxisID: "y-axis",
        xAxisID: "x-axis",
        borderCapStyle: "round",
        fill: false,
        tension: 0.4,
        data: sellerLine,
        z: 1,
      },
      {
        label: "Average listing views",
        borderColor: "#FF92A1",
        pointRadius: 0,
        spanGaps: true,
        yAxisID: "y-axis",
        xAxisID: "x-axis",
        borderCapStyle: "round",
        fill: false,
        tension: 0.4,
        data: averageLine,
      },
    ],
  };
};

type Props = {
  averageListingViews: $ReadOnly<{
    dayNumber: number,
    views: ?number,
  }>[],
  sellerListingViews: $ReadOnly<{
    dayNumber: number,
    views: ?number,
  }>[],
  filter: boolean,
};

const applySixteenWeeksFilter = (list) => {
  if (list.length <= WEEK_16) {
    return list;
  }
  return list.slice(list.length - WEEK_16, list.length);
};

export const ListingViewsChart = ({
  averageListingViews,
  sellerListingViews,
  filter,
}: Props) => {
  const filteredlistingviews = filter
    ? applySixteenWeeksFilter(sellerListingViews)
    : sellerListingViews;

  const filteredAverageListingViews = filter
    ? applySixteenWeeksFilter(averageListingViews)
    : averageListingViews;

  const animate = sellerListingViews.length <= WEEK_16;

  const xAxisDataset =
    filteredAverageListingViews.length > filteredlistingviews.length
      ? filteredAverageListingViews
      : filteredlistingviews;
  const xAxis = xAxisDataset.map(({ dayNumber }) => dayNumber);

  const maxDay = max(xAxis);
  const suggestedXMax = roundUpToNearest7(maxDay);
  const lastPoint = reverse(filteredlistingviews.slice()).find(
    (v) => v.views,
  ) || {
    dayNumber: 0,
    views: 0,
  };
  const sellerLine = filteredlistingviews.map(({ views }) => views);
  const averageLine = filteredAverageListingViews.map(({ views }) => views);
  const datasets = createDatasets(averageLine, sellerLine, lastPoint);
  const chartOptions = createChartOptions(suggestedXMax, xAxis, animate);

  return (
    <Chart type="line" height={200} options={chartOptions} data={datasets} />
  );
};
