// @flow
import { NavLink } from "react-router-dom";
import { css } from "styled-components";
import { media } from "@nested/brand";

const filtersStyle = css`
  margin-top: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: flex-start;
  padding-left: 15px;
  ${media.tablet`
    padding: 0;
  `}
`;

const filterLinkStyle = css`
  text-decoration: none;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague70};
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
  &.active {
    border-bottom: 3px solid ${({ theme }) => theme.palette.blue150};
    color: ${({ theme }) => theme.palette.blue150};
  }

  margin-right: 30px;
  ${media.tablet`
    margin-right: 40px;
  `}
`;

type TabFilterProps = {
  leftFilterText: string,
  rightFilterText: string,
  leftFilterPath: string,
  rightFilterPath: string,
};

export const TabFilters = ({
  leftFilterText,
  rightFilterText,
  leftFilterPath,
  rightFilterPath,
}: TabFilterProps) => (
  <div css={filtersStyle}>
    <NavLink
      css={filterLinkStyle}
      exact
      to={{
        pathname: leftFilterPath,
        state: { noScroll: true },
      }}
    >
      {leftFilterText}
    </NavLink>
    <NavLink
      css={filterLinkStyle}
      to={{
        pathname: rightFilterPath,
        state: { noScroll: true },
      }}
    >
      {rightFilterText}
    </NavLink>
  </div>
);
