// @flow
export const createBedText = (n: ?number) => {
  if (typeof n !== "number") {
    return "";
  }
  if (n === 0) {
    return "studio";
  }
  if (n === 1) {
    return "1 bed";
  }
  return `${n} beds`;
};
